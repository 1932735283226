import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { signOut } from 'aws-amplify/auth';
import {
    LOGIN_ROUTE,
    PROFILE_NAME_ROUTE,
    PROFILE_ROLE_ROUTE
} from '../../Routes.js';
import { cameraIcon, editIcon } from '../../assets/images/images.js';
import Button from '../../components/Button/Button.js';
import Avatar from '../../components/Avatar/Avatar.js';
import ProfilePhotoModal from '../../modules/Modals/ProfilePhotoModal.js';
import { UserContext } from '../../Contexts.js';
import './Profile.scss';
import { updateEmployee, updateTask } from '../../graphql/mutations.js';
import { updateTasksAtCheckout } from '../../utils/taskUtils.js';

const Profile = () => {
    const { employee, location, tasks, position, unsubscribe } = useContext(UserContext);
    const [editPhotoOpen, setEditPhotoOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const client = generateClient();
    
    const handleSignOut = async () => {
        try {
            setBtnLoading(true);
            await signOut();
            client.graphql({
                query: updateEmployee,
                variables: {
                    input: {
                        id: employee.id,
                        receivedBoostNotification: false,
                        checkedIn: false,
                        employeeRoleId: null
                    }
                }
            }); // Check out
            
            await updateTasksAtCheckout({tasks, employee, client, location});
            
            unsubscribe(position); // Unsubscribe from notification service worker
            console.log('>> SIGNED OUT');
            navigate(LOGIN_ROUTE);
        } catch (err) {
            console.error(err);
            setBtnLoading(false);
        };
    };

    return (
        <div className="profile-page page-padding">
            <div className="profile-page-header">
                <div className="profile-header-text">
                    <h2 className="page-heading">Profile</h2>
                    <h1 className="profile-name">{ employee?.firstName + ' ' + employee?.lastName }</h1>
                </div>
                <div className="profile-header-img">
                    <Avatar className="profile-img" size="xl" employee={employee} />

                    <Button
                        className="change-profile-btn"
                        kind="icon-only"
                        buttonText=""
                        icon={cameraIcon}
                        onClick={() => setEditPhotoOpen(true)}
                    />
                </div>
            </div>

            <ProfilePhotoModal
                title={"Update Photo"}
                open={editPhotoOpen}
                onClose={() => setEditPhotoOpen(false)}
            />

            <div className="profile-page-content">
                <div className="profile-content-row">
                    <h4 className="profile-content-label">Name</h4>
                    <p className="profile-content-value">{ employee?.firstName + ' ' + employee?.lastName }</p>
                    <span className="edit-icon-wrapper" onClick={() => navigate(PROFILE_NAME_ROUTE)}><img className="edit-icon" src={editIcon} alt="Edit icon" /></span>
                    <hr />
                </div>
                {!employee?.isManager && <div className="profile-content-row">
                    <h4 className="profile-content-label">Default Role</h4>
                    <p className="profile-content-value">{ employee?.defaultRole?.name || 'N/A' }</p>
                    <span className="edit-icon-wrapper" onClick={() => navigate(PROFILE_ROLE_ROUTE)}><img className="edit-icon" src={editIcon} alt="Edit icon" /></span>
                    <hr />
                </div>}
                <div className="profile-content-row">
                    <h4 className="profile-content-label">Email Address</h4>
                    <p className="profile-content-value">{ employee?.email || 'N/A' }</p>
                    <hr />
                </div>
                {/* <div className="profile-content-row">
                    <h4 className="profile-content-label">Phone Number</h4>
                    <p className="profile-content-value">{ employee?.phone || 'N/A' }</p>
                    <hr />
                </div> */}
                <div className="profile-content-row">
                    <h4 className="profile-content-label">Store Number</h4>
                    <p className="profile-content-value">{ location?.storeNumber || 'N/A' }</p>
                    <hr />
                </div>
            </div>

            <Button
                buttonText="Sign Out"
                destructive
                onClick={handleSignOut}
                className="sign-out-btn"
                isLoading={btnLoading}
            />
        </div>
    );
};

export default Profile;
