import { uploadData } from 'aws-amplify/storage';

export const savePhoto = async (file) => {
    try {
        var timestamp = Date.now();
        const result = await uploadData({
            key: timestamp + '_' + file.name,
            data: file
        }).result;
        // console.log('Succeeded: ', result);
        return result;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    };
};