import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { noMentionsIcon } from "../../assets/images/images";
import { MENTIONS_ROUTE, MENTIONS_TEAM_MENTIONS_ROUTE } from '../../Routes';
import { UserContext } from '../../Contexts';
import Button from '../../components/Button/Button';
import MentionCard from '../../components/MentionCard/MentionCard';
import './MentionsList.scss';

const MentionsList = (props) => {
    const { paginationAmount = 10 } = props;
    const { employee } = useContext(UserContext);
    const [mentions, setMentions] = useState(props.data || []);
    const [showLength, setShowLength] = useState(paginationAmount);
    const navigate = useNavigate();

    useEffect( () => {
        setMentions([...(props.data ? props.data : [])]);
    }, [props]);

    const _handleButtonPress = () => {
        if (!props.paginate) {
            if (employee.isManager) {
                navigate(MENTIONS_TEAM_MENTIONS_ROUTE);
            } else {
                navigate(MENTIONS_ROUTE);
            }
        } else {
            if (showLength + paginationAmount > mentions.length) {
                setShowLength(mentions.length);
            } else {
                setShowLength(showLength + paginationAmount);
            };
        };
    };


    const NoMentions = () => {
        return (
            <div className="no-mentions-wrapper">
                <img src={noMentionsIcon} alt="no mentions to show icon" />
                <h2>No Mentions Yet</h2>
            </div>
        )
    };

     // should this check against current sprint? Only not show week 1?
    return (
        <div className='mentions-wrapper'>
            {props.showTitle &&
                <div className='mentions-text-block'>
                    <h2 className='heading-section'>
                        {employee?.isManager ? 'Mentions' : 'Your Mentions'}
                    </h2>
                </div>}
                {(!mentions || mentions.length < 1) ? <NoMentions />
                :
            <ul className={`mentions-list-wrapper ${props.kind} ${props.className || ''}`}>
                {mentions.map((item, index) => {
                    // With this, we can pass just the paginationAmount to limit the size of a stand alone list.
                    // So long as we don't pass the paginate prop. If both props are passed, then we won't receive the View All Mentions button
                    if ((index + 1) <= showLength || (!props.paginate && !props.paginationAmount)) {
                        return <MentionCard
                            {...props}
                            key={index}
                            mention={item} />
                    }
                })}
                {!props.paginate && !props.fullList && <Button buttonText='View All Mentions' onClick={() => _handleButtonPress()} />}
                {props.paginate && showLength < mentions.length && <Button buttonText='View More' kind="ghost" onClick={() => _handleButtonPress()} />}
            </ul>}
        </div>
    )
};

export default MentionsList;