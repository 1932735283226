import { clockIcon } from '../../assets/images/images.js';
import Input from '../Input/Input.js';
import './TimePicker.scss';

const TimePicker = props => {

    return (
        <div className={`time-picker-wrapper ${props.className ?? props.className}`}>
            <label
                className={`time-picker-label label`}
                htmlFor={props.id ? props.id : `${props.label}-time-picker`}>
                {props.label}
            </label>
            <Input
                type="time"
                pattern="[0-9]*"
                inputmode="numeric"
                value={props.value}
                onChange={props.onChange}
                step="15 min"
                className="time-input"></Input>
                <img className="time-icon" src={clockIcon} alt={''} />
        </div>
    )
};

export default TimePicker;