import { logoShield } from '../../assets/images/images.js';
import './Loader.scss';

const Loader = props => {

    return (
            <div className="loading-wrapper">
                <div className="loading-overlay">
                    <img className="loading-icon" src={logoShield} alt="" />
                </div>
            </div>
    )
}

export default Loader;
