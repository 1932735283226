export const getLocalTime = date => {
    const time = new Date(date);
    const localTime = time.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    return localTime;
};

export const checkIfExpired = (expiredDateTime, n) => {
    const expirationDate = new Date(expiredDateTime);
    const now = n ? new Date(n) : Date.now();

    return expirationDate < now;
};

export const getDiffText = diffObj => {
    const { diffDays, diffHours, diffMinutes } = diffObj;

    if (diffDays > 0) {
        return diffDays + ' days';
    } else if (diffHours > 0) {
        return diffHours + ' hrs';
    } else if (diffMinutes > 0) {
        return diffMinutes + ' mins';
    } else {
        return 'less than a minute'
    };
};

export const getTimeDiff = (date1, date2) => {
    const oneMinute = 60 * 1000;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;
    const expirationTime = new Date(date1);
    const now = new Date(date2);

    const diffMinutes = Math.round((expirationTime - now) / oneMinute);
    const diffHours = Math.round((expirationTime - now) / oneHour);
    const diffDays = Math.round((expirationTime - now) / oneDay);
    
    return { diffDays, diffHours, diffMinutes }
};

export const getWeekDayFromDate = date => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = new Date(date).getDay();
    const weekDay = days[dayIndex];
    return weekDay;
};

export const formatDate = date => {
    const dateFormatted = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(date);
    return dateFormatted;
};

export const formatTime = (date, hour12 = false) => {
    let timeFormatted = new Intl.DateTimeFormat("en-US", {
        hour12, // Should format to 12 hour AM/PM intervals
        hour: "2-digit",
        minute: "2-digit"
    }).format(date);
    const timeSplit = timeFormatted.split(':');
    const hours = timeSplit[0];
    const minutes = timeSplit[1];
    // For some reason, this will sometimes return 24 at midnight instead of 00.
    // This fixes that and ensures that it always returns a proper time.
    if (hours === '24') {
        timeFormatted = `00:${minutes}`;
    };
    return timeFormatted;
};