import './Input.scss';

const InputFooter = props => {
    return (
        <div className={`input-footer ${props.show ? 'show' : 'hide'}`}>
            {props.helperText && <p className="input-footer-helper">{props.helperText}</p>}
            {props.characterCounter && <p className="input-footer-helper">{props.currentCharacterLength}/{props.maxLength}</p>}
            {props.subLink && <a className="input-footer-subLink" href={props?.subLink}>{props.subLinkText}</a>}
        </div>
    )
};

export default InputFooter;