import { loadingIcon, rejectIcon } from '../../assets/images/images';
import './Button.scss';

const Button = props => {
    return (
        <button
            // {...props}
            className={`btn-main${props.size ? ' btn-' + props.size : ''}${props.kind ? ' btn-' + props.kind : ''}${props.disabled ? ' btn-disabled' : ''}${props.destructive || props.rejected ? ' btn-reject' : ''} ${props.className}`}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type}
        >
            {props.isLoading && <img id="btn-loading-spinner" src={loadingIcon} alt="Loading spinner" />}
            {!props.isLoading && <div className={`btn-content${props.iconPosition ? " icon-" + props.iconPosition : ""}`}>
                {(props.icon || props.rejected) && <img className="btn-icon" src={props.rejected ? rejectIcon : props.icon} alt={props.iconAltText || "Action Icon"} />}
                {props.buttonText}
            </div>}
        </button>
    )
};

export default Button;