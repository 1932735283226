import { useState, useContext } from 'react';
import { UserContext } from '../../Contexts.js';
import Tabs from '../../components/Tabs/Tabs';
import LeaderboardList from '../../components/List/LeaderboardList';
import './Leaderboard.scss';

const Leaderboard = props => {
    const [showTrophies, setShowTrophies] = useState();
    const { challenge } = useContext(UserContext);

    const testData = {
        "tabs": [
            {
                label: 'Weekly Rank',
            },
            {
                label: 'Trophies',
            }
        ]
    }

    return (
        <div className='leaderboard-wrapper'>
            <div className='leaderboard-text-block'>
                <h2 className='heading-section'> 
                    Leaderboard (Week {challenge?.getCurrentSprint().sprintIndex} of 4)
                </h2>
                <p className='sub-body'>
                    Earn points for your team by completing tasks to increase your Weekly Rank.
                </p>
            </div>
            <Tabs id="leaderboard-tabs" kind="pill" data={testData.tabs} onChange={() => setShowTrophies(!showTrophies)}/>
            <LeaderboardList kind={showTrophies ? 'trophies' : 'points'}/>
        </div>
    )
};

export default Leaderboard;