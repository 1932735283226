import './TeamBadge.scss';

const TeamBadge = (props) => {
    
    let firstNumber = Number(props?.storeNumber?.charAt(0));
    let thirdNumber = Number(props?.storeNumber?.charAt(2)); // using third number as it works best for pilot

    const _renderTeamFlags = () => (
        <g id="YOUR_TEAM">
            <g className="st1">
                <path className="st2" d="M282.1,130.4l-63.3-33.6l27-50.8l63,33.5c2.7,1.4,2.5,5.4-0.3,6.5L286,95.2c-2.4,1-3.7,3.4-3.3,6l4.6,25.5
                    C287.8,129.5,284.7,131.8,282.1,130.4z"/>
                <path className="st3" d="M258.4,41.5c2.6-4.8,0.7-10.8-4.1-13.4s-10.8-0.7-13.4,4.1c-1.8,3.4-1.4,7.4,0.6,10.3l-33.8,63.6
                    c-1.2,2.2-0.3,4.9,1.9,6.1s4.9,0.3,6.1-1.9l33.8-63.6C253.1,46.8,256.6,44.9,258.4,41.5z"/>
                <path className="st4" d="M259,39.9c0.2-0.5,0.3-1,0.4-1.5c0.6-4-1.3-8.2-5.1-10.2c-4.8-2.6-10.8-0.7-13.4,4.1
                    c-1.8,3.4-1.4,7.4,0.6,10.3l-18.2,34.3c4.4,2.3,9.8,0.7,12.2-3.7L249,47.6c0.2-0.3,0.3-0.6,0.4-0.9c0.3,0,0.6,0,1,0
                    c0.1,0,0.3,0,0.4,0c0.3,0,0.6-0.1,0.9-0.2c0.1,0,0.2,0,0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.2-0.1,0.3-0.1
                    c0.3-0.1,0.6-0.3,0.9-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.3,0.8-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.5-0.4,0.7-0.6
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.5,0.6-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.4,0.5-0.8,0.8-1.2c0.3-0.5,0.5-1,0.6-1.5
                    C259,40,259,39.9,259,39.9z"/>
                <path className="st5" d="M287.3,126.6l-4.6-25.5c-0.5-2.5,0.9-5,3.3-6l22.5-9.2c1.4-0.6,2.2-1.9,2.2-3.2c-2.4-3.3-6.7-4.7-10.7-3.1
                    l-17.5,7.1c-6.3,2.5-9.9,9.2-8.7,15.9l2.6,14.5l-36-19.1c-4.4-2.3-9.8-0.7-12.2,3.7l53.7,28.6
                    C284.7,131.8,287.8,129.5,287.3,126.6z"/>
                <path className="st6" d="M249.7,36.8h-0.1c-0.9,1.7-3,2.4-4.7,1.4c-1.7-0.9-2.4-3-1.4-4.7c1.8-3.5,6.1-4.8,9.6-2.9
                    c1.7,0.9,2.4,3,1.4,4.7S251.4,37.7,249.7,36.8z"/>
            </g>
            <g className="st1">
                <path className="st2" d="M57.1,130.4l63.3-33.6L93.4,46l-63,33.5c-2.7,1.4-2.5,5.4,0.3,6.5l22.5,9.2c2.4,1,3.7,3.4,3.3,6l-4.6,25.5
                    C51.4,129.5,54.5,131.8,57.1,130.4z"/>
                <path className="st3" d="M80.8,41.5c-2.6-4.8-0.7-10.8,4.1-13.4c4.8-2.6,10.8-0.7,13.4,4.1c1.8,3.4,1.4,7.4-0.6,10.3l33.8,63.6
                    c1.2,2.2,0.3,4.9-1.9,6.1s-4.9,0.3-6.1-1.9L89.7,46.7C86.1,46.8,82.6,44.9,80.8,41.5z"/>
                <path className="st4" d="M80.1,39.9c-0.2-0.5-0.3-1-0.4-1.5c-0.6-4,1.3-8.2,5.1-10.2c4.8-2.6,10.8-0.7,13.4,4.1
                    c1.8,3.4,1.4,7.4-0.6,10.3l18.2,34.3c-4.4,2.3-9.8,0.7-12.2-3.7L90.2,47.6c-0.2-0.3-0.3-0.6-0.4-0.9c-0.3,0-0.6,0-1,0
                    c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0-0.2,0-0.4-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0-0.2-0.1-0.3-0.1
                    c-0.3-0.1-0.6-0.3-0.9-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.5-0.3-0.8-0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.5-0.4-0.7-0.6
                    c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.5-0.6-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.4-0.5-0.8-0.8-1.2c-0.3-0.5-0.5-1-0.6-1.5
                    C80.2,40,80.2,39.9,80.1,39.9z"/>
                <path className="st5" d="M51.9,126.6l4.6-25.5c0.5-2.5-0.9-5-3.3-6L30.7,86c-1.4-0.6-2.2-1.9-2.2-3.2c2.4-3.3,6.7-4.7,10.7-3.1
                    l17.5,7.1c6.3,2.5,9.9,9.2,8.7,15.9l-2.6,14.5l36-19.1c4.4-2.3,9.8-0.7,12.2,3.7l-53.7,28.6C54.5,131.8,51.4,129.5,51.9,126.6z"/>
                <path className="st6" d="M89.5,36.8L89.5,36.8c1,1.7,3.1,2.4,4.8,1.4c1.7-0.9,2.4-3,1.4-4.7c-1.8-3.5-6.1-4.8-9.6-2.9
                    c-1.7,0.9-2.4,3-1.4,4.7S87.8,37.7,89.5,36.8z"/>
            </g>
        </g>
    );

    const _renderBG = (num) => {
        return (
            <g id="BACKGROUNDS">
                {num <= 1 ?
                    <g id="BG_ONE">
                        <circle className="st7" cx="170" cy="88" r="80" />
                        <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
                c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                        <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
                c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                        <circle className="st10" cx="170" cy="88" r="64" />
                    </g>
                    : num === 2 ?
                        <g id="BG_TWO">
                            <path className="st7" d="M250,88c0,22.1-9,42.1-23.4,56.6C212.1,159,192.1,168,170,168s-42.1-9-56.6-23.4C99,130.1,90,110.1,90,88
                s9-42.1,23.4-56.6C127.9,17,147.9,8,170,8s42.1,9,56.6,23.4C241,45.9,250,65.9,250,88z"/>
                            <path className="st11" d="M226.6,31.4L170,88l-56.6-56.6C127.9,17,147.9,8,170,8S212.1,17,226.6,31.4z" />
                            <path className="st11" d="M226.6,144.6C212.1,159,192.1,168,170,168s-42.1-9-56.6-23.4L170,88L226.6,144.6z" />
                            <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
                c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                            <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
                c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>

                        </g>
                        : num === 3 ?
                            <g id="BG_THREE">
                                <path className="st7" d="M250,88c0,44.2-35.8,80-80,80s-80-35.8-80-80c0-18,6-34.6,16-48c14.6-19.4,37.8-32,64-32s49.4,12.6,64,32
            C244,53.4,250,70,250,88z"/>
                                <path className="st11" d="M234,40l-64,128L106,40c14.6-19.4,37.8-32,64-32S219.4,20.6,234,40z" />
                                <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
            c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                                <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
            c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                            </g>
                            : num === 4 ?
                                <g id="BG_FOUR">
                                    <path className="st7" d="M250,88c0,44.2-35.8,80-80,80c-9.6,0-18.8-1.7-27.3-4.8c-22.2-8.1-39.9-25.7-47.9-47.9
            C91.7,106.8,90,97.6,90,88c0-44.2,35.8-80,80-80c9.6,0,18.8,1.7,27.3,4.8c22.2,8.1,39.9,25.7,47.9,47.9
            C248.3,69.2,250,78.4,250,88z"/>
                                    <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
            c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                                    <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
            c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                                    <path className="st11" d="M245.2,60.7L142.7,163.2c-22.2-8.1-39.9-25.7-47.9-47.9L197.3,12.8C219.5,20.8,237.2,38.5,245.2,60.7z" />
                                </g>
                                : num === 5 ?
                                    <g id="BG_FIVE">
                                        <path className="st7" d="M250,88c0,23.2-9.8,44-25.6,58.7c-11.3,10.5-25.7,17.8-41.6,20.3c-4.2,0.7-8.4,1-12.8,1s-8.6-0.3-12.8-1
                c-15.9-2.6-30.3-9.8-41.6-20.3C99.8,132,90,111.2,90,88s9.8-44,25.6-58.7c11.3-10.5,25.7-17.8,41.6-20.3c4.2-0.7,8.4-1,12.8-1
                s8.6,0.3,12.8,1c15.9,2.6,30.3,9.8,41.6,20.3C240.2,44,250,64.8,250,88z"/>
                                        <path className="st11" d="M157.2,9v158c-15.9-2.6-30.3-9.8-41.6-20.3V29.3C126.9,18.8,141.3,11.6,157.2,9z" />
                                        <path className="st11" d="M224.4,29.3v117.3c-11.3,10.5-25.7,17.8-41.6,20.3V9C198.7,11.6,213.1,18.8,224.4,29.3z" />
                                        <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
                c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                                        <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
                c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                                    </g>
                                    : num === 6 ?
                                        <g id="BG_SIX">
                                            <circle className="st7" cx="170" cy="88" r="80" />
                                            <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
                c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                                            <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
                c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                                            <path className="st11" d="M144.6,29.2C121.9,39.1,106,61.7,106,88c0,35.3,28.7,64,64,64c26.3,0,48.9-15.9,58.8-38.6
                c-6,1.7-12.3,2.6-18.8,2.6c-37.6,0-68-30.4-68-68C142,41.5,142.9,35.2,144.6,29.2z"/>
                                        </g>
                                        : num === 7 ?
                                            <g id="BG_SEVEN">
                                                <path className="st7" d="M250,88c0,44.2-35.8,80-80,80c-22.1,0-42.1-9-56.6-23.4C99,130.1,90,110.1,90,88c0-44.2,35.8-80,80-80
            c22.1,0,42.1,9,56.6,23.4C241,45.9,250,65.9,250,88z"/>
                                                <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
            c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                                                <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
            c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                                                <path className="st11" d="M250,88h-80V8c22.1,0,42.1,9,56.6,23.4C241,45.9,250,65.9,250,88z" />
                                                <path className="st11" d="M170,88v80c-22.1,0-42.1-9-56.6-23.4C99,130.1,90,110.1,90,88H170z" />
                                            </g>
                                            : <g id="BG_EIGHT">
                                                <path className="st7" d="M250,88c0,44.2-35.8,80-80,80V8C214.2,8,250,43.8,250,88z" />
                                                <path className="st11" d="M170,8v160c-44.2,0-80-35.8-80-80S125.8,8,170,8z" />
                                                <path className="st8" d="M146.8,19.4c1.9,4,0.1,8.8-3.9,10.6c-9.5,4.4-17.8,11.1-24.1,19.5c-2.7,3.5-7.7,4.2-11.2,1.6
        c-3.5-2.7-4.2-7.7-1.6-11.2c7.9-10.5,18.2-18.9,30.1-24.4C140.2,13.6,145,15.4,146.8,19.4z"/>
                                                <path className="st9" d="M239.3,109.3c4.1,1.8,5.9,6.5,4.2,10.5c-5.6,12.9-14.4,24-25.7,32.4c-11.2,8.4-24.5,13.6-38.4,15.3
        c-4.4,0.5-8.4-2.6-8.9-7s2.6-8.4,7-8.9c11.1-1.3,21.7-5.5,30.7-12.2s16.1-15.6,20.5-25.9C230.5,109.4,235.2,107.5,239.3,109.3z"/>
                                            </g>
                }
            </g>
        )
    }

    const _renderIcon = (num) => {
        return (
            <g id="ICONS">
                {num <= 1 ?
                    <g id="ONE">
                        <path className="st12" d="M149.7,82.3h40.7c0.6,0,1,0.4,1,1V92c0,0.6-0.4,1-1,1h-40.7c-0.6,0-1-0.4-1-1v-8.7
        C148.7,82.8,149.1,82.3,149.7,82.3z"/>
                        <path className="st2" d="M128.3,82.3H169c0.6,0,1,0.4,1,1V92c0,0.6-0.4,1-1,1h-40.7c-0.6,0-1-0.4-1-1v-8.7
        C127.3,82.8,127.8,82.3,128.3,82.3z"/>
                        <path className="st13" d="M128.3,93h83.3c0.6,0,1,0.4,1,1v8.7c0,0.6-0.4,1-1,1h-83.3c-0.6,0-1-0.4-1-1V94
        C127.3,93.4,127.8,93,128.3,93z"/>
                        <path className="st2" d="M171,82.3h40.7c0.6,0,1,0.4,1,1V92c0,0.6-0.4,1-1,1H171c-0.6,0-1-0.4-1-1v-8.7C170,82.8,170.4,82.3,171,82.3z" />
                        <path className="st14" d="M203.1,76.9c-5.4,0-5.4-3.3-10.9-3.3c-5.4,0-5.4,3.3-10.9,3.3c-5.4,0-5.4-3.3-10.9-3.3c-0.2,0-0.4,0-0.6,0
        c-0.2,0-0.4,0-0.6,0c-5.4,0-5.4,3.3-10.9,3.3c-5.4,0-5.4-3.3-10.9-3.3c-5.4,0-5.4,3.3-10.9,3.3c-4.6,0-5.5-2.8-8.9-3.3v6.9
        c0,2.4,1.3,4.6,3.3,5.7c1.4,0.8,2.7,1.5,5.6,1.5c5.4,0,5.7-4.1,11.1-4.1c5.4,0,5.2,4.1,10.6,4.1c5.4,0,6-4.1,11.4-4.1
        s6,4.1,11.4,4.1s5.2-4.1,10.6-4.1c5.4,0,5.7,4.1,11.1,4.1c2.9,0,4.2-0.7,5.6-1.5c2-1.1,3.3-3.3,3.3-5.7v-6.9
        C208.6,74.1,207.7,76.9,203.1,76.9z"/>
                        <path className="st15" d="M208.7,79c2.7,0,4.6-2.1,3.8-4.2c-4.6-11.7-16.7-20.7-31.8-23.7c-3.4-0.7-7,3.1-10.7,3.1s-7.2-3.8-10.7-3.1
        c-15.1,2.9-27.2,12-31.8,23.7c-0.8,2.1,1.1,4.2,3.8,4.2H208.7z"/>
                        <path className="st4" d="M170.9,57.9c-13.8,0-26.4,6.5-32.2,16.4c-1.6,2.7-4.6,4.4-7.9,4.6c-1.9-0.2-3.2-1.5-3.4-2.9
        c0-0.3,0.1-0.5,0.2-0.8c2.5-6.3,7-11.9,12.9-16.2c5.3-3.8,11.8-6.5,18.9-7.9c1.8-0.4,3.7,0.6,5.6,1.5c1.7,0.8,3.4,1.6,5.1,1.6
        s3.4-0.8,5.1-1.6c1.9-0.9,3.7-1.8,5.5-1.5C179.7,54.9,175.7,57.9,170.9,57.9z"/>
                        <path className="st16" d="M163.3,56.7c0.2,1.7-0.9,3.2-2.4,3.5c-5.7,1-11.1,3.5-15.5,7.3c-1.2,1.1-3,0.8-3.9-0.5s-0.7-3.3,0.5-4.3
        c5.2-4.5,11.5-7.5,18.1-8.6C161.7,53.9,163.1,55,163.3,56.7z"/>
                        <path className="st15" d="M199.1,125h-58.2c-7.5,0-13.6-6.7-13.6-14.9V108c0-2.4,1.7-4.3,3.9-4.3h77.6c2.1,0,3.9,1.9,3.9,4.3v2.1
        C212.7,118.3,206.6,125,199.1,125z"/>
                        <path className="st5" d="M212.7,110.1V108c0-2.4-1.7-4.3-3.8-4.3H205c-1.2,1.8-2,4-2,6.4s-1.7,4.3-3.8,4.3h-25
        c-5.3,0-9.6,4.8-9.6,10.7h34.6C206.6,125,212.7,118.3,212.7,110.1z"/>
                    </g>
                    : num === 2 ?
                        <g id="TWO">
                            <path className="st17" d="M198.9,119h-58.2c-7.5,0-13.6-6.1-13.6-13.6v-3.9c0-2.1,1.7-3.9,3.9-3.9h77.6c2.1,0,3.9,1.7,3.9,3.9v3.9
        C212.5,112.9,206.4,119,198.9,119z"/>
                            <path className="st17" d="M140.8,55H199c7.5,0,13.6,6.1,13.6,13.6v3.9c0,2.1-1.7,3.9-3.9,3.9h-77.6c-2.1,0-3.9-1.7-3.9-3.9v-3.9
        C127.2,61.1,133.3,55,140.8,55z"/>
                            <path className="st18" d="M212.5,105.4v-3.9c0-2.1-1.7-3.9-3.9-3.9h-1.9c-2.4,1.8-3.9,4.6-3.9,7.8c0,2.1-1.7,3.9-3.9,3.9h-40.7
        c-5.4,0-9.7,4.3-9.7,9.7h50.4C206.4,119,212.5,112.9,212.5,105.4z"/>
                            <path className="st19" d="M127.2,68.6v3.9c0,2.1,1.7,3.9,3.9,3.9h1.9c2.4-1.8,3.9-4.6,3.9-7.8c0-2.1,1.7-3.9,3.9-3.9h40.7
        c5.4,0,9.7-4.3,9.7-9.7h-50.4C133.3,55,127.2,61.1,127.2,68.6z"/>
                            <path className="st20" d="M147.7,104.1l-9.9-6.4h42l-22.9,7.5C153.8,106.1,150.2,105.7,147.7,104.1z" />
                            <path className="st21" d="M128.2,76.3h83.3c0.6,0,1,0.4,1,1V86c0,0.6-0.4,1-1,1h-83.3c-0.6,0-1-0.4-1-1v-8.7
        C127.2,76.8,127.6,76.3,128.2,76.3z"/>
                            <path className="st22" d="M127.7,87H212c0.3,0,0.5,0.2,0.5,0.5v9.7c0,0.3-0.2,0.5-0.5,0.5h-84.3c-0.3,0-0.5-0.2-0.5-0.5v-9.7
        C127.2,87.2,127.4,87,127.7,87z"/>
                        </g>
                        : num === 3 ?
                            <g id="THREE">
                                <path className="st12" d="M149.7,82.3h40.7c0.6,0,1,0.4,1,1V92c0,0.6-0.4,1-1,1h-40.7c-0.6,0-1-0.4-1-1v-8.7
        C148.7,82.8,149.1,82.3,149.7,82.3z"/>
                                <path className="st13" d="M128.3,93h83.3c0.6,0,1,0.4,1,1v8.7c0,0.6-0.4,1-1,1h-83.3c-0.6,0-1-0.4-1-1V94
        C127.3,93.4,127.8,93,128.3,93z"/>
                                <path className="st2" d="M128.3,82.3H169c0.6,0,1,0.4,1,1V92c0,0.6-0.4,1-1,1h-40.7c-0.6,0-1-0.4-1-1v-8.7
        C127.3,82.8,127.8,82.3,128.3,82.3z"/>
                                <path className="st2" d="M171,82.3h40.7c0.6,0,1,0.4,1,1V92c0,0.6-0.4,1-1,1H171c-0.6,0-1-0.4-1-1v-8.7C170,82.8,170.4,82.3,171,82.3z
        "/>
                                <path className="st14" d="M203.1,76.9c-5.4,0-5.4-3.3-10.9-3.3c-5.4,0-5.4,3.3-10.9,3.3c-5.4,0-5.4-3.3-10.8-3.3c-0.2,0-0.4,0-0.6,0
        c-0.2,0-0.4,0-0.6,0c-5.4,0-5.4,3.3-10.9,3.3c-5.4,0-5.4-3.3-10.9-3.3c-5.4,0-5.4,3.3-10.9,3.3c-4.6,0-5.5-2.8-8.9-3.3v6.9
        c0,2.4,1.3,4.6,3.3,5.7c1.4,0.8,2.7,1.5,5.6,1.5c5.4,0,5.7-4.1,11.1-4.1c5.4,0,5.2,4.1,10.6,4.1c5.4,0,6-4.1,11.4-4.1
        s6,4.1,11.4,4.1s5.2-4.1,10.6-4.1c5.4,0,5.7,4.1,11.1,4.1c2.9,0,4.2-0.7,5.6-1.5c2-1.1,3.3-3.3,3.3-5.7v-6.9
        C208.6,74.1,207.7,76.9,203.1,76.9z"/>
                                <path className="st15" d="M208.7,79c2.7,0,4.6-2.1,3.8-4.2c-4.6-11.7-16.7-20.7-31.8-23.7c-3.4-0.7-7,3.1-10.7,3.1s-7.2-3.8-10.7-3.1
        c-15.1,2.9-27.2,12-31.8,23.7c-0.8,2.1,1.1,4.2,3.8,4.2H208.7z"/>
                                <path className="st4" d="M170.9,57.9c-13.8,0-26.4,6.5-32.2,16.4c-1.6,2.7-4.6,4.4-7.9,4.6c-1.9-0.2-3.2-1.5-3.4-2.9
        c0-0.3,0.1-0.5,0.2-0.8c2.5-6.3,7-11.9,12.9-16.2c5.3-3.8,11.8-6.5,18.9-7.9c1.8-0.4,3.7,0.6,5.6,1.5c1.7,0.8,3.4,1.6,5.1,1.6
        s3.4-0.8,5.1-1.6c1.9-0.9,3.7-1.8,5.5-1.5C179.7,54.9,175.7,57.9,170.9,57.9z"/>
                                <path className="st16" d="M163.3,56.7c0.2,1.7-0.9,3.2-2.4,3.5c-5.7,1-11.1,3.5-15.5,7.3c-1.2,1.1-3,0.8-3.9-0.5s-0.7-3.3,0.5-4.3
        c5.2-4.5,11.5-7.5,18.1-8.6C161.7,53.9,163.1,55,163.3,56.7z"/>
                                <path className="st15" d="M199.1,125h-58.2c-7.5,0-13.6-6.7-13.6-14.9V108c0-2.4,1.7-4.3,3.9-4.3h77.6c2.1,0,3.9,1.9,3.9,4.3v2.1
        C212.7,118.3,206.6,125,199.1,125z"/>
                                <path className="st5" d="M212.7,110.1V108c0-2.4-1.7-4.3-3.8-4.3H205c-1.2,1.8-2,4-2,6.4s-1.7,4.3-3.8,4.3h-25
        c-5.3,0-9.6,4.8-9.6,10.7h34.6C206.6,125,212.7,118.3,212.7,110.1z"/>
                            </g>
                            : num === 4 ?
                                <g id="FOUR">
                                    <path className="st23" d="M200,123.8h-60c-7.7,0-14-6.3-14-14v-2c0-2.2,1.8-4,4-4h80c2.2,0,4,1.8,4,4v2
        C214,117.5,207.7,123.8,200,123.8z"/>
                                    <path className="st5" d="M214,109.8v-2c0-2.2-1.8-4-4-4h-4c-1.3,1.7-2,3.7-2,6c0,2.2-1.8,4-4,4h-26c-5.5,0-10,4.5-10,10h36
        C207.7,123.8,214,117.5,214,109.8z"/>
                                    <path className="st20" d="M155.1,111.2l22.9-7.5h-42l9.9,6.4C148.4,111.8,152,112.2,155.1,111.2z" />
                                    <path className="st24" d="M208,103.8h-76c-2.2,0-4-1.8-4-4v-6c0-2.2,1.8-4,4-4h76c2.2,0,4,1.8,4,4v6C212,102,210.2,103.8,208,103.8z"
                                    />
                                    <path className="st20" d="M194.1,96.2l9.9-6.4h-42l22.9,7.5C188,98.2,191.6,97.8,194.1,96.2z" />
                                    <path className="st14" d="M203.1,84.2c-5.4,0-5.4-3.3-10.9-3.3c-5.4,0-5.4,3.3-10.9,3.3c-5.4,0-5.4-3.3-10.8-3.3c-0.2,0-0.4,0-0.6,0
        c-0.2,0-0.4,0-0.6,0c-5.4,0-5.4,3.3-10.9,3.3c-5.4,0-5.4-3.3-10.9-3.3c-5.4,0-5.4,3.3-10.9,3.3c-4.6,0-5.5-2.8-8.9-3.3v6.9
        c0,2.4,1.3,4.6,3.3,5.7c1.4,0.8,2.7,1.5,5.6,1.5c5.4,0,5.7-4.1,11.1-4.1c5.4,0,5.2,4.1,10.6,4.1c5.4,0,6-4.1,11.4-4.1
        s6,4.1,11.4,4.1s5.2-4.1,10.6-4.1c5.4,0,5.7,4.1,11.1,4.1c2.9,0,4.2-0.7,5.6-1.5c2-1.1,3.3-3.3,3.3-5.7v-6.9
        C208.6,81.3,207.7,84.2,203.1,84.2z"/>
                                    <path className="st23" d="M209.9,88.2c2.8,0,4.8-2.7,3.9-5.2c-5.9-17.8-23.2-30.8-43.8-30.8s-37.9,13-43.8,30.8
        c-0.9,2.6,1.1,5.2,3.9,5.2H209.9z"/>
                                    <path className="st4" d="M137.4,81.9c5.9-11.9,18.7-19.7,32.6-19.7c5.2,0,9.4-3.9,9.9-8.9c-3.2-0.7-6.5-1.1-9.9-1.1
        c-20.5,0-37.9,12.9-43.8,30.8c-0.6,1.7,0.2,3.5,1.5,4.5c0.2,0,0.5,0,0.7,0C132.1,87.5,135.7,85.4,137.4,81.9z"/>
                                    <path className="st25" d="M194,82.2c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4C190,80.4,191.8,82.2,194,82.2z" />
                                    <path className="st25" d="M170,82.2c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4C166,80.4,167.8,82.2,170,82.2z" />
                                    <path className="st25" d="M182,74.2c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S179.8,74.2,182,74.2z" />
                                    <path className="st16" d="M162.6,60.8c0.4,1.6-0.5,3.2-2.1,3.7c-6,1.6-11.5,4.7-15.7,8.9c-1.2,1.2-3.1,1.2-4.2,0
        c-1.2-1.2-1.2-3.1,0-4.2c5-5,11.4-8.6,18.4-10.4C160.6,58.2,162.2,59.2,162.6,60.8z"/>
                                </g>
                                : num === 5 ?
                                    <g id="FIVE">
                                        <path className="st20" d="M174,37.3h-8c-1.1,0-2,0.9-2,2v64h12v-64C176,38.2,175.1,37.3,174,37.3z" />
                                        <path className="st20" d="M198,55.3h-8c-1.1,0-2,0.9-2,2v46h12v-46C200,56.2,199.1,55.3,198,55.3z" />
                                        <path className="st20" d="M150,53.3h-8c-1.1,0-2,0.9-2,2v48h12v-48C152,54.2,151.1,53.3,150,53.3z" />
                                        <path className="st3" d="M162,43.3h-8c-1.1,0-2,0.9-2,2v58h12v-58C164,44.2,163.1,43.3,162,43.3z" />
                                        <path className="st3" d="M186,47.3h-8c-1.1,0-2,0.9-2,2v54h12v-54C188,48.2,187.1,47.3,186,47.3z" />
                                        <path className="st2" d="M199.2,73.3c-2.8,0-5.2,2.1-5.7,4.8C191.3,89,181.6,97.3,170,97.3s-21.3-8.2-23.5-19.2
        c-0.6-2.8-2.9-4.8-5.7-4.8H131c-2.6,0-4.5,2.4-3.9,4.9l10.8,47.8c1.2,5.5,6.1,9.4,11.7,9.4h40.8c5.6,0,10.5-3.9,11.7-9.4
        l10.8-47.8c0.6-2.5-1.3-4.9-3.9-4.9C209,73.3,199.2,73.3,199.2,73.3z"/>
                                        <path className="st5" d="M209,95.6l-6.9,30.4c-1.2,5.5-6.1,9.4-11.7,9.4H172c0-5.5,4.5-10,10-10h8.4c0.9,0,1.8-0.6,2-1.6l4.7-20.6
        C198.2,97.7,203.6,94.4,209,95.6z"/>
                                        <path className="st4" d="M142.5,101.1c1.2,5.4-2.2,10.7-7.5,12l-7.9-34.9c-0.6-2.5,1.3-4.9,3.9-4.9h9.8c1.2,0,2.3,0.4,3.2,1
        c-0.3,3.1-2,5.8-4.5,7.4c-0.7,0.4-1,1.3-0.8,2.1L142.5,101.1z"/>
                                        <path className="st26" d="M139,102.3c-1.4,0-2.6-0.9-2.9-2.3l-3-13c-0.4-1.6,0.6-3.2,2.2-3.6c1.6-0.4,3.2,0.6,3.6,2.2l3,13
        c0.4,1.6-0.6,3.2-2.2,3.6C139.4,102.3,139.2,102.3,139,102.3z"/>
                                    </g>
                                    : num === 6 ?
                                        <g id="SIX">
                                            <path className="st27" d="M194,54h-48c-3.3,0-6,2.7-6,6v6h60v-6C200,56.7,197.3,54,194,54z" />
                                            <path className="st2" d="M170,74c-1.7,0-3-1.3-3-3V39c0-1.4,1-2.6,2.4-2.9l19-4c1.6-0.3,3.2,0.7,3.6,2.3c0.3,1.6-0.7,3.2-2.3,3.6
        L173,41.4V71C173,72.7,171.7,74,170,74z"/>
                                            <path className="st2" d="M186.9,134h-33.7c-3,0-5.5-2.2-5.9-5.1L138.4,72l63.2,0.1l-8.8,56.8C192.3,131.8,189.8,134,186.9,134z" />
                                            <path className="st20" d="M202,80h-64c-2.2,0-4-1.8-4-4v-8c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4v8C206,78.2,204.2,80,202,80z" />
                                            <path className="st5" d="M192.8,128.9l7.6-48.9c0,0-10.4,0-11.4,8l-5.3,34.3c-0.2,1-1,1.7-2,1.7H176c-5.5,0-10,4.5-10,10h20.9
        C189.8,134,192.3,131.8,192.8,128.9z"/>
                                            <path className="st28" d="M173,54.1c-1,0-2-0.1-3-0.1s-2,0-3,0.1V64h6V54.1z" />
                                            <path className="st4" d="M167,54c0,5.5-4.5,10-10,10h-7c0,4.3-2.7,8-6.6,9.4c-1.1,3.1-3.7,5.5-6.9,6.3c-1.4-0.6-2.5-2-2.5-3.7v-8
        c0-2.2,1.8-4,4-4h2v-4c0-3.3,2.7-6,6-6C146,54,167,54,167,54z"/>
                                            <path className="st26" d="M159,64h-10c-1.7,0-3-1.3-3-3s1.3-3,3-3h10c1.7,0,3,1.3,3,3S160.7,64,159,64z" />
                                        </g>
                                        : num === 7 ?
                                            <g id="SEVEN">
                                                <path className="st3" d="M208,95c-3.4,0-6.4-1.5-8.4-3.9c-1.2-1.5-3.3-1.7-4.9-0.7c-2.1,1.3-4.7,1.9-7.4,1.5c-4.1-0.6-7.6-3.7-8.8-7.7
        c-0.3-0.9-0.4-1.7-0.4-2.5c-0.1-2-1.7-3.5-3.7-3.7c-0.8,0-1.7-0.2-2.5-0.5c-3.9-1.2-7-4.6-7.7-8.6c-0.5-2.8,0.1-5.4,1.4-7.6
        c0.9-1.5,0.7-3.4-0.5-4.6c-1.9-2-3.1-4.7-3.1-7.7c0-0.4,0-0.9,0.1-1.3c0.3-2.7-2-4.9-4.6-4.2c-21,5.4-36.2,25.3-34.3,48.3
        c1.8,22.2,19.8,40.3,42.1,42.1c23.1,1.8,42.9-13.3,48.3-34.3c0.7-2.6-1.6-4.9-4.2-4.6C208.9,95,208.4,95,208,95z"/>
                                                <path className="st5" d="M200.6,105.2c-6.8,12.5-20.4,19.8-34.6,18.7c-5.1-0.4-9.6,3.1-10.6,8c3.1,1,6.4,1.7,9.8,1.9
        c22.6,1.8,42.1-12.7,48-33.1C208.5,98.9,203.1,100.7,200.6,105.2z"/>
                                                <path className="st4" d="M151,56.8c4.4-2.6,6.1-8,4.1-12.6c-19.7,6.2-33.8,25.4-32,47.6c0.3,3.4,1,6.6,1.9,9.8c4.9-1,8.4-5.4,8-10.6
        C132,76.9,138.8,63.8,151,56.8z"/>
                                                <path className="st29" d="M136.3,66.7c2.7-4.1,6.2-7.7,10.2-10.6" />
                                                <path className="st3" d="M179,70c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5C174,67.8,176.2,70,179,70z" />
                                                <path className="st3" d="M194,80c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S191.8,80,194,80z" />
                                                <path className="st3" d="M184,54c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S181.8,54,184,54z" />
                                                <path className="st30" d="M147,102c3.9,0,7-3.1,7-7s-3.1-7-7-7s-7,3.1-7,7S143.1,102,147,102z" />
                                                <path className="st30" d="M151,78c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S148.2,78,151,78z" />
                                                <path className="st30" d="M167,98c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S164.2,98,167,98z" />
                                                <path className="st30" d="M179,116c3.9,0,7-3.1,7-7s-3.1-7-7-7s-7,3.1-7,7S175.1,116,179,116z" />
                                            </g>
                                            : <g id="EIGHT">
                                                <path className="st31" d="M154.6,111.9c-6.2-3.6-7.2-13.5-2.3-22.1c3.7-6.5,9.8-10.4,15.2-10.4l27.2-47.1c1.4-2.4,4.4-3.2,6.8-1.8
        c2.4,1.4,3.2,4.4,1.8,6.8l-27.2,47.1c2.7,4.7,2.4,11.9-1.4,18.4C169.9,111.4,160.8,115.5,154.6,111.9z"/>
                                                <path className="st32" d="M181.6,42.9c4.3,0,8.2,3,8.2,7.7c0,1.7-0.5,3.3-1.4,4.6c4.3,0.3,7.8,3.9,7.8,8.3c0,4.6-3.7,8.3-8.3,8.3
        h-37.1c-4.6,0-8.3-3.7-8.3-8.3c0-3.6,2.3-6.7,5.5-7.8c-0.2-0.7-0.3-1.4-0.4-2.1c-0.1-3.8,3-6.9,6.8-7.1l3.4-0.6
        c4.8-0.3,9-3.2,11.1-7.4l1.2-2.4c0.4-0.7,1.1-1.3,1.9-1.3C174.4,34.7,179.3,37,181.6,42.9z"/>
                                                <path className="st4" d="M155.5,52.7l3.1-0.5c6.8-0.6,13-4.8,16.1-10.9l1.2-2.4c0.4-0.8,0.6-1.6,0.7-2.5c-1.7-1.2-3.4-1.7-4.5-1.7
        c-0.8,0-1.5,0.6-1.9,1.3l-1.2,2.4c-2.1,4.3-6.4,7.1-11.1,7.4l-3.4,0.6c-3.8,0.3-6.9,3.4-6.8,7.1c0,0.6,0.1,1.1,0.2,1.6
        c0.2,0.9,1.1,1.4,2,1.2c1.2-0.3,2.8-1,4.2-3.1c0.1-0.2,0.4-0.4,0.8-0.5C155,52.8,155.3,52.7,155.5,52.7z"/>
                                                <path className="st33" d="M167.9,46.4c1.6-1.3,3-2.8,4.1-4.6" />
                                                <path className="st27" d="M194,54h-48c-3.3,0-6,2.7-6,6v6h60v-6C200,56.7,197.3,54,194,54z" />
                                                <path className="st34" d="M142.8,66c-0.3-0.8-0.4-1.6-0.4-2.5c0-3.6,2.3-6.7,5.5-7.8c-0.2-0.5-0.3-1.1-0.3-1.6h41.5
        c-0.2,0.4-0.4,0.8-0.7,1.2c4.3,0.3,7.8,3.9,7.8,8.3c0,0.9-0.1,1.7-0.4,2.5h-53V66z"/>
                                                <path className="st26" d="M186.9,134h-33.7c-3,0-5.5-2.2-5.9-5.1L138.4,72l63.2,0.1l-8.8,56.8C192.3,131.8,189.8,134,186.9,134z" />
                                                <path className="st27" d="M202,80h-64c-2.2,0-4-1.8-4-4v-8c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4v8C206,78.2,204.2,80,202,80z" />
                                                <path className="st5" d="M192.8,128.9l7.6-48.9c0,0-10.4,0-11.4,8l-5.3,34.3c-0.2,1-1,1.7-2,1.7H176c-5.5,0-10,4.5-10,10h20.9
        C189.8,134,192.3,131.8,192.8,128.9z"/>
                                                <path className="st4" d="M167,54c0,5.5-4.5,10-10,10h-7c0,4.3-2.7,8-6.6,9.4c-1.1,3.1-3.7,5.5-6.9,6.3c-1.4-0.6-2.5-2-2.5-3.7v-8
        c0-2.2,1.8-4,4-4h2v-4c0-3.3,2.7-6,6-6C146,54,167,54,167,54z"/>
                                                <path className="st26" d="M159,64h-10c-1.7,0-3-1.3-3-3s1.3-3,3-3h10c1.7,0,3,1.3,3,3S160.7,64,159,64z" />
                                            </g>}
            </g >

        )
    };

    const _renderFirstPlace = () => (
        <g id="FIRST_PLACE">
            <path className="st2" d="M208.1,54l-2.1-5l-5.3,1.4c-1.4,0.4-2.6-1.1-2-2.4l6.8-13.6l12.5,6.2l-6.8,13.6
		C210.6,55.4,208.7,55.4,208.1,54z"/>
            <path className="st2" d="M226.8,54l2.1-5l5.3,1.4c1.4,0.4,2.6-1.1,2-2.4l-6.8-13.6l-12.5,6.2l6.8,13.6C224.3,55.4,226.2,55.3,226.8,54z
		"/>
            <path className="st3" d="M240.6,23.6c0,2-2.4,3.6-2.9,5.4c-0.5,1.9,0.8,4.5-0.2,6.1c-1,1.7-3.9,1.9-5.2,3.2c-1.4,1.4-1.6,4.3-3.2,5.2
		c-1.6,1-4.3-0.3-6.1,0.2s-3.4,2.9-5.4,2.9s-3.6-2.4-5.4-2.9c-1.9-0.5-4.5,0.8-6.1-0.2c-1.7-1-1.9-3.9-3.2-5.2
		c-1.4-1.4-4.3-1.6-5.2-3.2c-1-1.6,0.3-4.3-0.2-6.1c-0.5-1.8-2.9-3.4-2.9-5.4s2.4-3.6,2.9-5.4c0.5-1.9-0.8-4.5,0.2-6.1
		c1-1.7,3.9-1.9,5.2-3.2c1.4-1.4,1.6-4.3,3.2-5.2c1.6-1,4.3,0.3,6.1-0.2s3.4-2.9,5.4-2.9s3.6,2.4,5.4,2.9c1.9,0.5,4.5-0.8,6.1,0.2
		c1.7,1,1.9,3.9,3.2,5.2c1.4,1.4,4.3,1.6,5.2,3.2c1,1.6-0.3,4.3,0.2,6.1C238.1,20,240.6,21.6,240.6,23.6z"/>
            <path className="st4" d="M214.1,8.2c2.4-1,3.7-3.6,3-6.2c-0.1-0.5-0.4-0.9-0.6-1.4c-1.6,0.6-2.9,2.3-4.5,2.7c-1.9,0.5-4.5-0.8-6.1,0.2
		c-1.7,1-1.9,3.9-3.2,5.2c-1.4,1.4-4.3,1.6-5.2,3.2c-1,1.6,0.3,4.3-0.2,6.1c-0.4,1.5-2.1,2.9-2.7,4.5c0.4,0.3,0.9,0.5,1.4,0.6
		s0.9,0.2,1.4,0.2c2,0,3.9-1.2,4.8-3.1C203.4,14.3,208.1,9.5,214.1,8.2z"/>
            <path className="st5" d="M237.7,29c0.5-1.8,2.9-3.4,2.9-5.4s-2.4-3.6-2.9-5.4c-0.4-1.6,0.4-3.6,0.1-5.2c-0.5,0-1,0-1.5,0.2
		c-2.6,0.7-4.1,3.1-3.8,5.7c0.5,1.5,0.7,3.1,0.7,4.8c0,7.5-5.3,13.8-12.4,15.4c-2.4,1-3.7,3.6-3,6.2c0.1,0.5,0.4,0.9,0.6,1.4
		c1.6-0.6,2.9-2.3,4.5-2.7c1.9-0.5,4.5,0.8,6.1-0.2c1.7-1,1.9-3.9,3.2-5.2s4.3-1.6,5.2-3.2C238.4,33.5,237.2,30.9,237.7,29z"/>
            <path className="st20" d="M217.5,39.3c8.7,0,15.7-7,15.7-15.7s-7-15.7-15.7-15.7s-15.7,7-15.7,15.7S208.8,39.3,217.5,39.3z" />
            <path className="st3" d="M217.5,35.1c6.4,0,11.5-5.2,11.5-11.5S223.9,12,217.5,12S206,17.2,206,23.5S211.1,35.1,217.5,35.1z" />
            <path className="st36" d="M219.7,23.7c0,1.7,0,3.5,0,5.2c0,1.2-0.8,2.1-1.9,2.1c-1.1,0-2-0.9-2-2.1c0-2.6,0-5.1,0-7.7
		c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.3-0.3-0.2c-0.4,0.1-0.8,0.3-1.3,0.4c-0.7,0.2-1.3,0-1.6-0.6c-0.3-0.5-0.3-1.2,0.1-1.8
		c0.2-0.3,0.5-0.5,0.9-0.6c1-0.3,1.9-0.8,2.7-1.3c1.4-0.9,3-0.3,3.4,1.2c0.1,0.2,0.1,0.4,0.1,0.6C219.7,20.2,219.7,22,219.7,23.7z"
            />
        </g>
    );

    const _renderYouBadge = () => (
        <g id="YOU_BADGE">
            <path className="st20" d="M153.5,152h33c8.6,0,15.5,6.9,15.5,15.5l0,0c0,8.6-6.9,15.5-15.5,15.5h-33c-8.6,0-15.5-6.9-15.5-15.5l0,0
		C138,158.9,144.9,152,153.5,152z"/>
            <path className="st35" d="M147.8,160.5h4.4l2.8,5.9h0.1l2.8-5.9h4.4l-5.3,10v4.6h-3.9v-4.6L147.8,160.5z M176.8,167.7
		c0,1.6-0.3,3-0.9,4.1c-0.6,1.1-1.5,1.9-2.5,2.5c-1.1,0.6-2.3,0.9-3.6,0.9s-2.5-0.3-3.6-0.9c-1.1-0.6-1.9-1.4-2.5-2.5
		s-0.9-2.5-0.9-4.1s0.3-3,0.9-4.1c0.6-1.1,1.5-2,2.5-2.5c1.1-0.6,2.3-0.9,3.6-0.9s2.5,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.5,2.5
		C176.5,164.7,176.8,166.1,176.8,167.7z M172.7,167.7c0-0.9-0.1-1.6-0.3-2.2c-0.2-0.6-0.6-1.1-1-1.4c-0.4-0.3-1-0.5-1.6-0.5
		c-0.7,0-1.2,0.2-1.7,0.5c-0.4,0.3-0.8,0.8-1,1.4c-0.2,0.6-0.3,1.3-0.3,2.2s0.1,1.6,0.3,2.2c0.2,0.6,0.6,1.1,1,1.4
		c0.4,0.3,1,0.5,1.7,0.5s1.2-0.2,1.6-0.5c0.4-0.3,0.8-0.8,1-1.4C172.6,169.3,172.7,168.6,172.7,167.7z M187.2,160.5h3.9v9.3
		c0,1.1-0.3,2.1-0.8,2.9c-0.5,0.8-1.3,1.4-2.2,1.9c-0.9,0.4-2,0.7-3.3,0.7s-2.4-0.2-3.3-0.7c-0.9-0.4-1.7-1.1-2.2-1.9
		c-0.5-0.8-0.8-1.8-0.8-2.9v-9.3h3.9v9c0,0.4,0.1,0.9,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3
		c0.5,0,0.9-0.1,1.2-0.3s0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.2v-9H187.2z"/>
        </g>
    );

    return (
        <svg className="team-badge-wrapper" version="1.1" id="BADGE" x="0px" y="0px" viewBox="0 0 340 183">
            {props.isUserTeam && _renderTeamFlags()}
            {_renderBG(firstNumber)}
            {_renderIcon(thirdNumber)}
            {props.isFirstPlace && _renderFirstPlace()}
            {props.isUser && _renderYouBadge()}

        </svg>
    )
}
export default TeamBadge;    