import { useState, useEffect, useContext } from 'react';
import Modal from '../../components/Modal/Modal';
import { UserContext } from '../../Contexts.js';
import LeaderboardList from '../../components/List/LeaderboardList';

const LeaderboardResultsModal = (props) => {
    const { challenge } = useContext(UserContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!challenge?.previousSprintID) return;
        let previousSprint = challenge.previousSprintID;

        let showLeaderBoard = localStorage.getItem(`${'weeklyLeaderboard-' + previousSprint}`);
        if (!showLeaderBoard) {
            setOpen(true);
            localStorage.setItem('weeklyLeaderboard-' + previousSprint, `seen on ${new Date().toDateString()}`);
        }
    }, []);

    return (
        <Modal {...props}
            onClose={() => setOpen(false)}
            open={open}
        >
            <LeaderboardList kind="weeklyResults" />
        </Modal>
    )
}
export default LeaderboardResultsModal;