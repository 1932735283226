import { useState, useEffect, useContext } from 'react';
import { generateClient } from 'aws-amplify/api';
import { employeesByLocationID } from '../../graphql/queries';
import Modal from '../../components/Modal/Modal';
import { firstPlaceIcon, successIcon } from '../../assets/images/images.js'
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import Avatar from '../../components/Avatar/Avatar';
import { createMVPVote } from '../../graphql/mutations';
import { UserContext } from '../../Contexts.js';
import { trackEvent } from '../../utils/anayticsUtils.js';

const MVPVoteModal = (props) => {
    const client = generateClient();
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeID, setSelectedEmployeeID] = useState(null);
    const [votedForEmployee, setVotedForEmployee] = useState(null);
    const [voteComplete, setVoteComplete] = useState(null);
    const [loading, setLoading] = useState(false);
    const { employee, location, challenge } = useContext(UserContext);

    useEffect(() => {
        if (employee && location) {
            employeesPromise();
        }
    }, [employee, location]);

    const employeesPromise = async () => {
        try {
            const response = await client.graphql({
                query: employeesByLocationID,
                variables: {
                    locationID: employee?.locationID
                }
            });
            const employees = response.data.employeesByLocationID.items;
            setEmployees(employees);
        }catch(err) {
            console.error('Error while trying to fetch employees.', err);
        };
    }

    const handleSelectChange = (e) => {
		setSelectedEmployeeID(e.target.value);

        for (var i=0; i<employees.length; i++) {
            if (employees[i].id === e.target.value) {
                setVotedForEmployee(employees[i]);
            }
        }
	};

    const votePromise = async () => {

		setLoading(true);
		try {
			const response = await client.graphql({ query: createMVPVote, variables: { input: 
                {
                    sprintID: challenge.previousSprintID, 
                    submittedByID: employee.id, 
                    votedForID: selectedEmployeeID,
                    storeNumber: location.storeNumber,
                    sprintIndex: challenge.getCurrentSprint().sprintIndex - 1
                } 
            } })
            .catch((err) => {
                console.log(err);
            });
            setVoteComplete(true);

            // analytics
            trackEvent('mvp_vote', employee.id, location.storeNumber, {
                "voted_for": selectedEmployeeID
            });

		} catch (err) {
			console.error('An error occurred while voting.', err);
			setLoading(false);
		};

        setLoading(false);
	};

    return (
        <Modal
            {...props}
            centerContent={true}
            onClose={props.onClose}
            padding={true}
            kind="special"
            icon={voteComplete ? successIcon : firstPlaceIcon}
            title={voteComplete ? 'Thanks for Voting!' : 'Vote for the ' + challenge?.getCurrentSprint().name + ' MVP!'}>
            {!voteComplete && <>
                    <p className='sub-body'>
                        Who really brought it this week to get your team on top? Vote for them and, if they win MVP, they’ll receive an Amazon Gift Card!
                    </p>
                    <Select
                        onChange={handleSelectChange}
                        placeholder="Choose Your MVP"
                        value={selectedEmployeeID}
                        data={employees.map(teamEmployee => {
                            if (!teamEmployee.isManager && (location.id === teamEmployee.locationID) && (teamEmployee.id !== employee.id)) {
                                return {
                                    label: teamEmployee.firstName + ' ' + teamEmployee.lastName,
                                    value: teamEmployee.id
                                }
                            } else {
                                return false;
                            }
                        })}
                    />
                </> }
            {voteComplete && <>
                    <Avatar size="lg" employee={votedForEmployee} />
                    <h5 className='heading-m'>You Voted for {votedForEmployee.firstName + ' ' + votedForEmployee.lastName}!</h5>

                </> }
                <Button 
                    buttonText={voteComplete ? 'Done' : 'Vote'} 
                    size="lg" 
                    type="submit" 
                    onClick={!voteComplete ? votePromise : () => props.onClose()} 
                    disabled={!voteComplete && selectedEmployeeID === null ? true : false}/>
        </Modal>
    )
}
export default MVPVoteModal;