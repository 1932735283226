import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Amplify } from "aws-amplify";
import {
  signIn,
  confirmSignIn
} from 'aws-amplify/auth';
import {
  CHECK_IN_ROUTE,
  DASHBOARD_ROUTE,
  TERMS_ROUTE,
  // PRIVACY_ROUTE
} from '../../Routes.js';
import { logo } from "../../assets/images/images.js";
import Input from '../../components/Input/Input.js';
import Button from '../../components/Button/Button.js';
import awsExports from "../../aws-exports";
import { UserContext } from "../../Contexts.js";
import "@aws-amplify/ui-react/styles.css";
import "./Login.scss";
import { trackEvent } from "../../utils/anayticsUtils.js";
import { signOut } from 'aws-amplify/auth';

Amplify.configure(awsExports);

const Login  = ({ auth }) => {
  const {
    getStartingData,
    userSession,
    setUserSession, 
    contactUs,
    setInitLoad,
    employee,
    location
  } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let contactUsLink = `https://docs.google.com/forms/d/e/1FAIpQLSeezKAfjjLAiSDQZSMYCaW0GXz2KENo-Q35o2xIKzCyatpddg/viewform?usp=sf_link`;

  useEffect(() => {
    // Test for valid email (SUPER simple, matches: email@domain.<tld>)
    if (/\S+@\S+\.\S+/.test(email)) {
      // valid email
      setDisabled(false);
    } else {
      // invalid email
      setDisabled(true);
    }
  }, [email]);

  const handleSignIn = async event => {
    event.preventDefault();
    setIsLoading(true);

    // sign out the user first
    console.log(">> signing out user...");
    await signOut();

    console.log(">> Signing in as: " + email);

    try {
      const response = await signIn({
        username: email,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP'
        }
      });

      setUserSession(response);
      setHasError(false);
      setCodeSent(true);
    } catch (err) {
      console.error(err);
      setHasError(true);
    }
    setIsLoading(false);
  };
  
  const handleCodeSubmit = async event => {
    event.preventDefault();
    // console.log(">> Submitting code: " + code);
    
    if (userSession?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
      setIsLoading(true);
      try {
        const output = await confirmSignIn({ challengeResponse: code });
        // console.log(output);
        if (output?.nextStep?.signInStep === "DONE") {
          const startingData = await getStartingData(null, email);

          if (startingData) {
            setInitLoad(true);
            setHasError(false);
  
            // login complete, fire analytics
            trackEvent('login', startingData.employeeData.id, startingData.locationData.storeNumber);
  
            navigate(DASHBOARD_ROUTE);
            // if (startingData.employeeData.isManager) {
            // } else {
            //   navigate(CHECK_IN_ROUTE);
            // };
          }
        } else {
          throw new Error(`Incorrect challenge code: ${output?.nextStep?.additionalInfo?.attemptsLeft} attempts remaining`);
        };
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        setHasError(true);
      };
    } else {
      console.error('Sign in step not correct. Current step: ' + userSession?.nextStep?.signInStep + '. Should be: CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE');
      setHasError(true);
    };
  };

  return (
    <main className="login-page page-padding">
      <img className="login-logo" src={logo} alt="Task Force logo" />

      <div className="login-card">
        <h1 className="login-card-header">{codeSent ? 'Code Sent' : 'Sign In'}</h1>

        {codeSent && <p className="login-card-message">
          {`We just sent a single-use identification code to ${email}. This code will expire in 3 minutes.`}
        </p>}

        <form onSubmit={codeSent ? handleCodeSubmit : handleSignIn}>
          <div className="login-page-input-wrapper">
            {codeSent ?
              <Input
                label="Identification Code"
                placeholder="AB-1234"
                hasError={hasError}
                value={code}
                onChange={e => setCode(e.target.value)}
                required
              /> :
              <Input
                label="Email Address"
                placeholder="email@domain.com"
                hasError={hasError}
                value={email}
                onChange={e => setEmail(e.target.value.toLowerCase())}
                required
              />
            }

            {hasError && <div className="login-error-message">
              {codeSent ? 'Incorrect code' : 'Email address not found'}
            </div>}
            {codeSent && <div className="resend-btn-wrapper">
              <button className="resend-btn" onClick={event => handleSignIn(event)} type="button">
                Resend Code
              </button>
            </div>}
          </div>

          {codeSent ?
            <Button
              className="login-page-btn"
              buttonText="Submit"
              disabled={code === ""}
              type="submit"
              isLoading={isLoading}
            /> :
            <Button
              className="login-page-btn"
              buttonText="Sign In"
              disabled={disabled}
              type="submit"
              isLoading={isLoading}
            />
          }
        </form>
        <Link to={contactUsLink} target="_blank">
          <Button
            className="login-page-btn"
            kind="ghost"
            buttonText="Contact Us"
            onClick={() => {}}
          />
        </Link>

        {/* Links to Privacy and Terms pages */}
        <span className="login-footer">By signing into this experience, you acknowledge acceptance of the site <Link className="btn-link" to={TERMS_ROUTE}>Terms & Conditions</Link>.</span>
        {/* <span className="login-footer">By signing into this experience, you acknowledge acceptance of the site <Link className="btn-link" to={TERMS_ROUTE}>Terms & Conditions</Link> & <Link className="btn-link" to={PRIVACY_ROUTE}>Privacy Policy</Link>.</span> */}
      </div>
    </main>
  );
}

export default Login;
