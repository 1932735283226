import { useEffect, useContext, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
    CHECK_IN_ROUTE,
    TASK_LIST_MY_TASKS_ROUTE,
    TASK_LIST_ALL_TASKS_ROUTE,
    TASK_LIST_PAST_TASKS_ROUTE,
    TASK_LIST_CURRENT_TASKS_ROUTE
} from '../../Routes.js';
import { UserContext } from '../../Contexts.js';
import {
    filterShiftTasks,
    checkIfPerfectShift,
    getPercentageCompleted,
    getTotalPointsValue,
    getRoleTasks,
    sortTasksByModifier
} from '../../utils/taskUtils.js';
import { checkIfExpired } from '../../utils/dateUtils.js';
import { checkInIcon } from '../../assets/images/images.js';
import Button from '../../components/Button/Button.js';
import Tabs from '../../components/Tabs/Tabs.js';
import TeamCard from '../../components/Cards/TeamCard.js';
import PerfectShiftCard from '../../components/Cards/PerfectShiftCard.js';
import completedTaskImg from '../../assets/images/tasks/Success.svg';
import ProgressBar from '../../components/Chart/ProgressBar.js';
import './TaskListPage.scss';
import { trackEvent } from '../../utils/anayticsUtils.js';

const NotCheckedIn = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(CHECK_IN_ROUTE);
    };

    return (
        <div className="no-checkin-wrapper">
            <p className='sub-copy'>Check in to see your tasks.</p>
            <Button
                buttonText="Check In"
                icon={checkInIcon}
                iconPosition="right"
                onClick={handleClick}
            />
        </div>
    )
};

export const NoTasks = () => {
    return (
        <div className="no-tasks-wrapper">
            <img src={completedTaskImg} alt="Confirmation check-mark" />
            <h2>You're All Done!</h2>
            <p className='sub-copy'>You have no more tasks on your list this shift!</p>
        </div>
    )
};

const TaskListPage = () => {
    const { employee, tasks = [], pollTasks, pageLoading, setPageLoading, initLoad, location } = useContext(UserContext);
    const [taskList, setTaskList] = useState(tasks);
    const [points, setPoints] = useState(0);
    const [percentage, setPercentage] = useState(0); // Percentage will be for the progress indicator
    const [perfectShift, setPerfectShift] = useState(false);
    const windowLocation = useLocation();

    useEffect(() => {
        getTasks();
        if (perfectShift && windowLocation.pathname === TASK_LIST_ALL_TASKS_ROUTE) setPerfectShift(false);
        if (perfectShift && windowLocation.pathname === TASK_LIST_MY_TASKS_ROUTE) setPerfectShift(true);
    }, [windowLocation.pathname]);

    useEffect(() => {
        if (employee?.id && windowLocation.pathname === TASK_LIST_MY_TASKS_ROUTE) {
            // analytics
            trackEvent('view_schedule', employee?.id, location?.storeNumber, {
                "employee_role": employee.role
            });
}
    }, [employee?.id, windowLocation.pathname]);

    useEffect(() => {
        if (employee?.isManager) {
            if (windowLocation.pathname === TASK_LIST_CURRENT_TASKS_ROUTE) {
                const next24 = Date.now() + (24 * 60 * 60 * 1000);
                const currentTasks = tasks.filter(task => checkIfExpired(task.task.startDateTime, next24) || (checkIfExpired(task.task.startDateTime) && !checkIfExpired(task.task.expiredDateTime)));
                setTaskList(sortTasksByModifier(currentTasks));
            } else {
                const completeTasks = tasks.filter(task => task.task.complete || checkIfExpired(task.task.expiredDateTime));
                setTaskList(completeTasks);
            }
        } else {
            const shiftFilteredTasks = filterShiftTasks(tasks, employee);
            if (windowLocation.pathname === TASK_LIST_MY_TASKS_ROUTE) {
                const roleTasks = getRoleTasks(employee?.role, shiftFilteredTasks);
                setTaskList(sortTasksByModifier(roleTasks, true));
            } else {
                setTaskList(sortTasksByModifier(shiftFilteredTasks, true));
            };
        }
    }, [tasks]);
    
    useEffect(() => {
        const tally = getTotalPointsValue(taskList);
        const percentCompleted = getPercentageCompleted(taskList);
        setPoints(tally);
        setPercentage(percentCompleted);
        if (checkIfPerfectShift(taskList)) {
            setPerfectShift(true);
        };
    }, [taskList]);
    
    const getTasks = async () => {
        if (!pageLoading) setPageLoading(true);
        await pollTasks(employee?.locationID);
        if (initLoad) setPageLoading(false);
    };

    return (
        <div className="task-list-page">
            <h1 className='heading-page'>Tasks</h1>
            {/* Non-manager tabs */}
            {!employee?.isManager && <Tabs
                data={[
                    { label: 'My Tasks', route: TASK_LIST_MY_TASKS_ROUTE },
                    { label: 'All Tasks', route: TASK_LIST_ALL_TASKS_ROUTE }
                ]}
                index={windowLocation.pathname === TASK_LIST_ALL_TASKS_ROUTE ? 1 : 0}
            />}

            {/* Manager tabs */}
            {employee?.isManager && <Tabs
                data={[
                    { label: 'Current Tasks', route: TASK_LIST_CURRENT_TASKS_ROUTE },
                    { label: 'Past Tasks', route: TASK_LIST_PAST_TASKS_ROUTE }
                ]}
                index={windowLocation.pathname === TASK_LIST_PAST_TASKS_ROUTE ? 1 : 0}
            />}

            {!pageLoading && <>
                {!perfectShift && windowLocation.pathname === TASK_LIST_MY_TASKS_ROUTE && <div className="task-progress-bar-wrapper"><ProgressBar className="task-progress-bar" percentage={percentage} points={points} /></div>}

                {perfectShift && windowLocation.pathname === TASK_LIST_MY_TASKS_ROUTE && <PerfectShiftCard points={points} />}

                {(windowLocation.pathname === TASK_LIST_ALL_TASKS_ROUTE || employee?.isManager) && <TeamCard isUserTeam isUser />}

                {(perfectShift || windowLocation.pathname === TASK_LIST_ALL_TASKS_ROUTE || employee?.isManager) && <hr />}

                <div className="page-padding">
                    {(!employee?.checkedIn && !employee?.isManager) ?
                        <NotCheckedIn /> :
                        taskList.length > 0 ?
                            <Outlet context={{
                                contextTasks: taskList,
                                groupDate: windowLocation.pathname === TASK_LIST_PAST_TASKS_ROUTE ? true : false,
                                paginate: windowLocation.pathname === TASK_LIST_PAST_TASKS_ROUTE || windowLocation.pathname === TASK_LIST_CURRENT_TASKS_ROUTE ? true : false
                            }} /> :
                            <NoTasks />
                    }
                </div>
            </>}
        </div>
    )
};

export default TaskListPage;