import './CloseButton.scss';

const CloseButton = props => {

    const _handleClick = () => {
        props.onClick && props.onClick()
    }

    return (
        <button className="close-button" onClick={() => _handleClick()}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={props.color ? props.color : `#fff`} d="M5.41406 4C5.02358 3.60952 4.39048 3.60952 4 4C3.60952 4.39048 3.60952 5.02358 4 5.41406L10.5859 12L4 18.5859C3.60952 18.9764 3.60952 19.6095 4 20C4.39048 20.3905 5.02358 20.3905 5.41406 20L12 13.4141L18.5859 20C18.9764 20.3905 19.6095 20.3905 20 20C20.3905 19.6095 20.3905 18.9764 20 18.5859L13.4141 12L20 5.41406C20.3905 5.02358 20.3905 4.39048 20 4C19.6095 3.60952 18.9764 3.60952 18.5859 4L12 10.5859L5.41406 4Z"  />
            </svg>
        </button>

    )
};

export default CloseButton;
