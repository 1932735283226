import './Donut.scss';

const Donut = props => {

    return (
        <div className={`dataViz ${props.className || ''}`}>
            <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#00B191" strokeWidth="2"></circle>

                <circle
                    className="donut-segment"
                    cx="21"
                    cy="21"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke="#F6F6F6"
                    strokeWidth="2"
                    strokeDasharray={`${100 - props.percent} ${props.percent + ''} `}
                    strokeDashoffset="25"></circle>

                <g className="chart-text">
                    <text x="50%" y="50%" className="chart-number">
                        {props.percent}%
                    </text>
                    <text x="50%" y="50%" className="chart-label">
                        Tasks Complete
                    </text>
                </g>
            </svg>
        </div>
    )
};

export default Donut;