import { useContext, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { UserContext } from '../../Contexts.js';
import { firstPlaceIcon, shoutOutIcon, logoShield } from '../../assets/images/images.js'
import { createTaskMention, getTotalPointValue } from '../../utils/taskUtils.js';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import Avatar from '../../components/Avatar/Avatar';
import TeamBadge from '../../components/Badge/TeamBadge.js';
import './ShoutOutModal.scss';


const ShoutOutModal = (props) => {
    const { employee, location, challenge, pollTasks } = useContext(UserContext);
    const [hasShoutOut, setShoutOut] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emoji, setEmoji] = useState(false);
    const {
        onClose,
        callBack,
        data,
        isTeamTask = false,
        mentionType = 'SHOUTOUT',
        spotLightText // Manager can't spotlight without sending text
    } = props;
    const client = generateClient();

    const _handleSubmit = async (emoji, type) => {
        if (hasShoutOut) return (
            onClose && onClose(), // should close modal window
            setShoutOut(false)
        )
        setLoading(true);
        setEmoji(emoji);

        let description, toEmployeeId, fromEmployeeId;
        fromEmployeeId = employee.id;
        if (mentionType === 'SHOUTOUT') {
            
            let taskName = null;
            if (data.task) {
                toEmployeeId = data.task.taskCompletedById;
                taskName = data.task.name;
            }
            switch (type) {
                case 'GOAT':
                    description = `You’re the GOAT ${emoji} ‘cause you always ${taskName || 'complete your tasks'} on time!`;
                    break;
                
                case 'FIRE':
                    description = `You always ${taskName || 'complete your tasks'} on time every shift! You’re fire ${emoji}!`;
                break;
            
                case 'EPIC':
                    description = `No matter what task you complete, your work is always epic ${emoji}.`;
                    break;
            
                case 'SAVAGE':
                    description = `When it was time to ${taskName || 'do your part'} today, your work was savage ${emoji}.`;
                    break;
            
                case 'CASH':
                    description = `We can always count on you to ${taskName || 'get the job done'}. You’re cash ${emoji}.`;
                    break;
            
                case 'HYPE':
                    description = `Thanks for always slaying every task you do. You are hype ${emoji}.`;
                    break;
            
                default:
                    break;
            }
        } else if (mentionType === 'BOOST') {
            // This is for setting the boost mention description
            description = `Don’t forget to ${data.task.name}! If you do, you’ll earn ${getTotalPointValue({task: {...props.data.task}}, 'boosted')} bonus points from ${employee?.name}.`;
        } else {
            // This is for setting the spotlight mention description
            description = `Congratulations! You’re in the Spotlight! Here’s what your manager had to say: "${spotLightText}"`;
        };

        if (data.task) { // If task mention
            // Creates shout out related to task
            const newMention = await createTaskMention({
                mentionType,
                shoutOutType: type,
                client,
                toEmployeeId,
                fromEmployeeId,
                task: data.task,
                challenge,
                description
            });
            await pollTasks();
            if (callBack) callBack(newMention);
        } else { // Else if spotlight mention
            
            const newMention = await createTaskMention({
                mentionType: "SHOUTOUT", // Make sure the mention type is still a shoutout, even thought we're shouting out a spotlight
                shoutOutType: null,
                client,
                toEmployeeId: data.id,
                fromEmployeeId,
                task: null,
                challenge,
                description
            });
            if (callBack) callBack(newMention);
        };

        setLoading(false);
        props.onComplete && props.onComplete();
        return setShoutOut(true);
        // handle sending shout out
    };

    const _emojiArray = () => {
        return (
            <div className={'emoji-anim-wrapper'}>
                <div className="emoji-anim">{emoji}</div>
                <div className="emoji-anim">{emoji}</div>
                <div className="emoji-anim">{emoji}</div>
                <div className="emoji-anim">{emoji}</div>
                <div className="emoji-anim">{emoji}</div>
                <div className="emoji-anim">{emoji}</div>
            </div>
        )
    };

    const Loading = () => (
        <div className='modal-loading-overlay'>
            <img className="loading-icon" src={logoShield} alt="" />
        </div>
    )

    return (
        <Modal
            {...props}
            id={'shout-out-modal'}
            kind={hasShoutOut ? 'special' : "shout-out"}
            centerContent={true}
            onClose={onClose}
            padding={true}
            location={location}
            data={data}
            icon={hasShoutOut ? shoutOutIcon : firstPlaceIcon}
            title={hasShoutOut ? 'Shout-Out Sent!' : isTeamTask ? 'Show the Team some love!' : `Show ${data?.firstName} some love!`}
            body={isTeamTask ? `What best describes the team's work?` : `What best describes ${data?.firstName} or their work?`}>
            {loading && <Loading />}
            {!hasShoutOut &&
                <div className='shout-out-buttons'>
                    <div className='button-column'>
                        <Button buttonText="🐐 the GOAT" size="lg" type="submit" kind="ghost" onClick={() => _handleSubmit("🐐", "GOAT")} />
                        <Button buttonText="🔥 fire" size="lg" type="submit" kind="ghost" onClick={() => _handleSubmit("🔥", "FIRE")} />
                        <Button buttonText="🐉 epic" size="lg" type="submit" kind="ghost" onClick={() => _handleSubmit("🐉", "EPIC")} />
                    </div>
                    <div className='button-column'>
                        <Button buttonText="⚔️ savage" size="lg" type="submit" kind="ghost" onClick={() => _handleSubmit("⚔️", "SAVAGE")} />
                        <Button buttonText="🤑 cash" size="lg" type="submit" kind="ghost" onClick={() => _handleSubmit("🤑", "CASH")} />
                        <Button buttonText="💀 hype" size="lg" type="submit" kind="ghost" onClick={() => _handleSubmit("💀", "HYPE")} />
                    </div>
                </div>
            }

            {hasShoutOut && <div className='shout-out-complete-wrapper'>
                {_emojiArray()}
                {isTeamTask ? <TeamBadge /> : <Avatar size="lg" employee={data} animated={true} />}
                <h5 className='heading-m'>You gave {data?.firstName} {data?.lastName} a Shout-Out!</h5>
                <Button buttonText={'Done'} size="lg" type="submit" onClick={() => _handleSubmit()} />
            </div>}
        </Modal>
    )
}

export default ShoutOutModal;