import './YouBadge.scss';

const YouBadge = props => {

    return (
        <div className={`isUser-badge`}>
            <span>YOU</span>
        </div>
    )
};

export default YouBadge;