export const getEmployeeByUserID = `
  query GetEmployeeByUserID($id: String = "") {
    listEmployees(filter: {userID: {eq: $id}}, limit: 3000) {
      nextToken
      items {
        id
        firstName
        lastName
        email
        phone
        userID
        photoURL
        checkedIn
        isManager
        locationID
        receivedBoostNotification
        role {
          id
          name
        }
        defaultRole {
          id
          name
        }
        shift {
          id
          startTime
          endTime
        }
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
      }
    }
  }`;

export const getEmployeeByUserEmail = `
  query GetEmployeeByUserEmail($email: String = "") {
    listEmployees(filter: {email: {eq: $email}}, limit:3000) {
      nextToken
      items {
        id
        firstName
        lastName
        email
        phone
        userID
        photoURL
        checkedIn
        isManager
        locationID
        receivedBoostNotification
        role {
          id
          name
        }
        defaultRole {
          id
          name
        }
        shift {
          id
          startTime
          endTime
        }
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
      }
    }
  }`;

export const getLocationById = `
  query getLocationById($id: ID!) {
    getLocation(id: $id) {
      challengeID
      city
      closingTime
      id
      name
      openingTime
      sprintTrophyCount
      storeNumber
      totalPoints
      totalTrophies
      roles {
        items {
          name
          id
        }
      }
    }
  }`;

export const updateEmployeeName = `
  mutation updateEmployeeName($id: ID!, $userID: String = "", $firstName: String = "", $lastName: String = "") {
    updateEmployee(condition: {userID: {eq: $userID}}, input: {
      id: $id, 
      firstName: $firstName, 
      lastName: $lastName
    }) {
      firstName
      lastName
    }
  }`;

export const updateEmployeeDefaultRole = `
  mutation updateEmployeeDefaultRole($id: ID!, $userID: String = "", $role: ID) {
    updateEmployee(
      input: {id: $id, employeeDefaultRoleId: $role}
      condition: {userID: {eq: $userID}}
    ) {
      id
      defaultRole {
        name
      }
    }
  }`;

export const listRoleTasks = `
  query listRoleTasks($id: ID!) {
    listRoleTasks(filter: {roleId: {eq: $id}}) {
      items {
        role {
          id
          name
        }
        task (limit: 500) {
          boostAvailable
          boosted
          boostedBy {
            firstName
            id
            isManager
            lastName
            userID
          }
          boostedModifier
          claimAvailable
          claimModifier
          claimedBy {
            createdAt
            defaultRole {
              name
              id
            }
            firstName
            id
            isManager
            lastName
            role {
              id
              name
            }
            userID
          }
          completed
          completedAt
          completedBy {
            defaultRole {
              id
              name
            }
            firstName
            id
            isManager
            lastName
            role {
                id
                name
            }
            userID
          }
          createdAt
          description
          eligibleEmployeeIDs
          expiredDateTime
          iconURL
          id
          lateDateTime
          lateModifier
          name
          photoURL
          pointValue
          rejected
          rejectionReason
          roles {
            items {
              role {
                name
                id
              }
            }
          }
          shoutOutPointValue
          startDateTime
          taskBoostedById
          taskClaimedById
          taskCompletedById
          updatedAt
          taskId
          isTeamTask
          teamCompletedIDs
          teamEligibleIDs
          mentions {
            items {
              mentionFromEmployeeIDId
              mentionToEmployeeIDId
              shoutOutType
              type
              sprintID
              taskID
            }
          }
        }
      }
      nextToken
    }
  }`;

export const listLocationTasksOld = `
  query listLocationTasks($id: ID!) {
    listLocations(filter: {id: {eq: $id}}) {
      items {
        roles {
          items {
            tasks {
              items {
                taskId
                task {
                  updatedAt
                  taskCompletedById
                  taskClaimedById
                  taskBoostedById
                  startDateTime
                  shoutOutPointValue
                  roles {
                    items {
                      role {
                        name
                        id
                      }
                    }
                  }
                  rejectionReason
                  rejected
                  pointValue
                  photoURL
                  name
                  lateModifier
                  lateDateTime
                  id
                  iconURL
                  eligibleEmployeeIDs
                  expiredDateTime
                  description
                  createdAt
                  completedAt
                  completedBy {
                    userID
                    role {
                      name
                      id
                    }
                    lastName
                    isManager
                    id
                    firstName
                    defaultRole {
                      id
                      name
                    }
                  }
                  completed
                  claimedBy {
                    userID
                    role {
                      id
                      name
                    }
                    lastName
                    isManager
                    id
                    firstName
                    defaultRole {
                      id
                      name
                    }
                  }
                  claimModifier
                  claimAvailable
                  boostedModifier
                  boosted
                  boostAvailable
                  boostedBy {
                    userID
                    role {
                      id
                      name
                    }
                    lastName
                    isManager
                    firstName
                    id
                    defaultRole {
                      id
                      name
                    }
                  }
                  isTeamTask
                  teamCompletedIDs
                  teamEligibleIDs
                  mentions {
                    items {
                      mentionFromEmployeeIDId
                      mentionToEmployeeIDId
                      shoutOutType
                      type
                      sprintID
                      taskID
                    }
                  }
                }
                role {
                  id
                  name
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }`;

export const listLocationTasks = `
  query ListLocationTasks ($id: String!, $nextToken: String) {
    listTasks(filter: {locationID: {eq: $id}}, limit: 3000, nextToken: $nextToken) {
      items {
        updatedAt
        taskCompletedById
        taskClaimedById
        taskBoostedById
        startDateTime
        shoutOutPointValue
        roles {
          items {
            role {
              name
              id
            }
          }
        }
        rejectionReason
        rejected
        pointValue
        photoURL
        name
        locationID
        lateModifier
        lateDateTime
        id
        iconURL
        eligibleEmployeeIDs
        expiredDateTime
        description
        createdAt
        completedAt
        completedBy {
          userID
          role {
            name
            id
          }
          lastName
          isManager
          id
          firstName
          defaultRole {
            id
            name
          }
        }
        completed
        claimedBy {
          userID
          role {
            id
            name
          }
          lastName
          isManager
          id
          firstName
          defaultRole {
            id
            name
          }
        }
        claimModifier
        claimAvailable
        boostedModifier
        boosted
        boostAvailable
        boostedBy {
          userID
          role {
            id
            name
          }
          lastName
          isManager
          firstName
          id
          defaultRole {
            id
            name
          }
        }
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        mentions {
          items {
            mentionFromEmployeeIDId
            mentionToEmployeeIDId
            shoutOutType
            type
            sprintID
            taskID
          }
        }
      }
      nextToken
    }
  }
`;

export const listChallengesSprintsLocations = `
  query ListChallengesSprintsLocations {
    listChallenges {
      items {
        id
        sprints {
          items {
            id
            name
            sprintIndex
            startDateTime
          }
        }
        locations {
          items {
            id
            name
            storeNumber
          }
        }
        currentSprintID
        previousSprintID
        sprintDuration
      }
    }
  }`;

export const getCurrentTaskByName = `
  query GetCurrentTaskByName($name: String = "", $gt: String = "", $lt: String = "") {
    listTasks(filter: {name: {eq: $name}, expiredDateTime: {gt: $gt}, startDateTime: {lt: $lt}}) {
      items {
        name
        id
        startDateTime
        expiredDateTime
        roles {
          items {
            roleId
            taskId
          }
        }
      }
    }
  }
`;

export const listUserMentions = `
  query ListUserMentions($id: ID!) {
    listMentions(filter: {mentionToEmployeeIDId: {eq: $id}}) {
      nextToken
      items {
        description
        fromEmployeeID {
          firstName
          lastName
          id
          photoURL
        }
        createdAt
        id
        mentionFromEmployeeIDId
        mentionToEmployeeIDId
        shoutOutType
        spotlightShoutOutCount
        sprintID
        taskID
        type
        toEmployeeID {
          firstName
          id
          lastName
          photoURL
        }
      }
    }
  }
`;

export const listUserFromMentions = `
  query ListUserMentions($id: ID!) {
    listMentions(filter: {mentionFromEmployeeIDId: {eq: $id}}) {
      items {
        description
        fromEmployeeID {
          firstName
          lastName
          id
          photoURL
        }
        createdAt
        id
        mentionFromEmployeeIDId
        mentionToEmployeeIDId
        shoutOutType
        spotlightShoutOutCount
        sprintID
        taskID
        type
        toEmployeeID {
          firstName
          id
          lastName
          photoURL
        }
      }
    }
  }
`;

export const getTasksInDateRange = `
  query GetTasksInDateRange($startRange: String = "", $endRange: String = "", $nextToken: String) {
    listTasks(filter: {startDateTime: {gt: $startRange, lt: $endRange}}, limit: 3000, nextToken: $nextToken) {
      nextToken
      items {
        name
        teamCompletedIDs
        taskCompletedById
        completed
        eligibleEmployeeIDs
        startDateTime
        expiredDateTime
        boosted
        claimedBy {
          id
        }
        locationID
        isTeamTask
        id
        rejected
        mentions {
          items {
            id
            type
            shoutOutType
          }
        }
      }
    }
  }
`;

export const getEligibleTasksByEmployeeID = `
  query GetEligibleTasksByEmployeeID($startRange: String = "", $endRange: String = "", $id: String = "", $nextToken: String) {
    listTasks(filter: {startDateTime: {gt: $startRange, lt: $endRange}, eligibleEmployeeIDs: {contains: $id}}, limit: 3000, nextToken: $nextToken) {
      items {
        startDateTime
        id
        name
        eligibleEmployeeIDs
        completed
        completedAt
        completedBy {
          id
          firstName
          lastName
        }
        boosted
        rejected
        isTeamTask
        pointValue
        expiredDateTime
        lateDateTime
        locationID
        taskBoostedById
        mentions {
          items {
            id
            mentionFromEmployeeIDId
            type
          }
        }
        roles {
          items {
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const getTaskCustom = /* Because GraphQL doesn't return necessary role data */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      pointValue
      claimAvailable
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      boostAvailable
      boosted
      boostedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      boostedModifier
      lateModifier
      claimModifier
      rejected
      rejectionReason
      iconURL
      photoURL
      shoutOutPointValue
      completedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      claimedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      roles {
        items {
          role {
            id
            name
          }
          __typename
        }
        nextToken
        __typename
      }
      interval
      completedAt
      mentions {
        items {
          id
          type
          description
          taskID
          shoutOutType
          sprintID
          spotlightShoutOutCount
          hasShoutOut
          createdAt
          updatedAt
          mentionFromEmployeeIDId
          mentionToEmployeeIDId
          __typename
        }
        nextToken
        __typename
      }
      eligibleEmployeeIDs
      boostDateTime
      isTeamTask
      teamCompletedIDs
      teamEligibleIDs
      locationID
      createdAt
      updatedAt
      taskBoostedById
      taskCompletedById
      taskClaimedById
      __typename
    }
  }
`;

export const listTasksCustom = /* Because GraphQL doesn't return necessary mentions or roles data */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        isTeamTask
        pointValue
        claimAvailable
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        boostAvailable
        boosted
        boostedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        boostedModifier
        lateModifier
        claimModifier
        rejected
        rejectionReason
        iconURL
        photoURL
        shoutOutPointValue
        completedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        claimedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        roles {
          items {
            role {
              name
              id
            }
          }
        }
        interval
        completedAt
        mentions {
        items {
          id
          type
          description
          taskID
          shoutOutType
          sprintID
          mentionFromEmployeeIDId
          mentionToEmployeeIDId
          __typename
        }
        nextToken
        __typename
      }
        eligibleEmployeeIDs
        boostDateTime
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        locationID
        createdAt
        updatedAt
        taskBoostedById
        taskCompletedById
        taskClaimedById
        __typename
      }
      nextToken
      __typename
    }
  }
`;
