import { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { updateTask } from '../../graphql/mutations';
import { UserContext } from '../../Contexts';
import { TASK_REPORT_ROUTE } from '../../Routes';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Button from '../../components/Button/Button';
import { trackEvent } from '../../utils/anayticsUtils';
import { updateScore } from '../../utils/scoreUtils';
import './RejectTask.scss';

const RejectTask = () => {
    const { employee, location, setLocation, tasks, setTasks, sendNotification, getUIEnvironment } = useContext(UserContext);
    const [task, setTask] = useState(null);
    const [modifier, setModifier] = useState(0);
    const [text, setText] = useState('');
    const [count, setCount] = useState(0);
    const [btnLoading, setBtnLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const client = generateClient();
    const navigate = useNavigate();

    useEffect(() => {
        const id = searchParams.get('id');

        if (tasks) {
            const currentTask = tasks.filter(t => t?.taskId === id)[0];
            setTask(currentTask);
        }
    }, [tasks, searchParams]);

    const handleModifierChange = e => {
        setModifier( parseInt(e.target.value) );
    };

    const handleTextBoxChange = (e) => {
        const currentText = e.target.value;
        const currentTextLength = currentText.split('').length;

        if (currentTextLength <= 999) {
            setText(currentText);
            setCount(currentTextLength);
        };
    };

    const handleReject = async () => {
        setBtnLoading(true);
        // analytics
        trackEvent('reject_task', employee.id, location.storeNumber, {
            "employee_role": employee.role,
            "task_id": task?.taskId,
            "task_name": task?.task?.name,
            "task_type": task?.task?.isTeamTask ? 'Team' : 'Individual', 
            "task_role": task?.role
        });
        try {
            const id = searchParams.get('id');
            let newPointValue = (task?.task.pointValue * modifier) / 100;
            if (newPointValue === null || isNaN(newPointValue)) {
                newPointValue = 0;
            }

            // Update the task
            const taskResponse = await client.graphql({
                query: updateTask, variables: {
                    input: {
                        id: id,
                        rejected: true,
                        rejectionReason: text,
                        pointValue: newPointValue
                    }
                }
            });

            // Update location total score
            const locationResponse = await updateScore(getUIEnvironment(), location?.id, - task?.task.pointValue + newPointValue);

            // Update local location and task instances
            setLocation({ ...location, ...locationResponse });

            const updatedTasks = tasks.map(t => {
                if (t.taskId === task?.task.id) {
                    return { ...task, task: { ...task.task, ...taskResponse.data.updateTask } };
                };
                return t;
            });
            setTasks(updatedTasks);

            sendNotification(
                'OOF',
                'Looks like one of your tasks was rejected by your manager. Find out why so you can up your game.',
                task?.task.id,
                task?.task.completedBy?.userID,
                null,
                null
            );

            navigate(`${TASK_REPORT_ROUTE}?id=${id}`); // Navigate back to report
        } catch (err) {
            console.error(err);
            setBtnLoading(false);
        };
    };

    const getSubtractedValue = (mod) => {
        return task?.task?.pointValue - ((task?.task?.pointValue * mod) / 100);
    };

    return (
        <div className='reject-task-wrapper page-padding'>
            {/* Removing breadcrumbs until insights are finished */}
            {/* <Breadcrumb /> */}

            <h1>Reject Task</h1>

            <p className='sub-body'>Rejecting a task will deduct points awarded for task completion. The amount of points deducted depends on why you choose to reject it.</p><br />
            <p className='sub-body'>When you reject a task, the amount of points lost and any details you provide will be shared with your team so they can improve moving forward.</p>

            <div className='reject-task-modifier-wrapper'>
                <h4 className='wrapper-label label'>How bad is it?</h4>

                <fieldset className='radio-fieldset'>
                    <input id='pretty-bad' name='modifier-radio' className='rejection-styled-radio' type='radio' value={0} onChange={handleModifierChange} checked={modifier === 0} />
                    <label htmlFor='pretty-bad' className={`heading-m reject-task-input-label`}><span className='heading-m'>😡 Pretty bad</span><span className='heading-m'>{`-${getSubtractedValue(0)}`}</span></label>

                    <input id='not-great' name='modifier-radio' className='rejection-styled-radio' type='radio' value={50} onChange={handleModifierChange} checked={modifier === 50} />
                    <label htmlFor='not-great' className={`heading-m reject-task-input-label`}><span className='heading-m'>☹ Not great</span><span className='heading-m'>{`-${getSubtractedValue(50)}`}</span></label>

                    <input id='meh' name='modifier-radio' className='rejection-styled-radio' type='radio' value={80} onChange={handleModifierChange} checked={modifier === 80} />
                    <label htmlFor='meh' className={`heading-m reject-task-input-label`}><span className='heading-m'>😒 Meh</span><span className='heading-m'>{`-${getSubtractedValue(80)}`}</span></label>

                    <input id='not-that-bad' name='modifier-radio' className='rejection-styled-radio' type='radio' value={100} onChange={handleModifierChange} checked={modifier === 100} />
                    <label htmlFor='not-that-bad' className={`heading-m reject-task-input-label`}><span className='heading-m'>😑 Not that bad</span><span className='heading-m'>No Point Loss</span></label>
                </fieldset>
            </div>

            <div className='rejection-reason-wrapper'>
                <label htmlFor="rejection-reason-textbox" className='label'>Let your team member know how to improve.<span className='optional-tag'>{'(optional)'}</span></label>
                <textarea
                    id="rejection-reason-textbox"
                    name="rejection-reason-textbox"
                    placeholder='Example: make sure the napkin container is full but not overstuffed when you stock it.'
                    maxLength={999}
                    value={text}
                    onChange={handleTextBoxChange}
                />
                <p className='textbox-bottom-text xs'>
                    <span>This message will be visible to everyone.</span>
                    <span>{count}/999</span>
                </p>
            </div>

            <Button
                buttonText="Reject"
                onClick={handleReject}
                isLoading={btnLoading}
                rejected
                disabled={!tasks}
            />
        </div>
    )
};

export default RejectTask;