import React from "react";

const SelectOption = (props) => {
  return (
    <button className="select-option"
      {...props}
      role="option"
      aria-label={props.label}
      aria-selected={props.selected}>
    {props.label}
    </button>
  )
};

export default SelectOption;