import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getTask } from '../../graphql/queries';
import { TASK_COMPLETION_ROUTE } from '../../Routes';
import { boostIcon, claimIcon } from '../../assets/images/images';
import { getTotalPointValue } from '../../utils/taskUtils';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import TaskCard from '../../components/TaskCard/TaskCard';
import './ClaimTaskModal.scss';
import { checkIfExpired } from '../../utils/dateUtils';

const ClaimTaskModal = (props) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [showBoost, setShowBoost] = useState(false);
    const navigate = useNavigate();
    const client = generateClient();

    // The application didn't rerender the modal with the button after it turned late
    // This useEffect fixes that issue.
    useEffect(() => {
        if (checkIfExpired(props.task.task?.lateDateTime)) {
            setShowBoost(true);
        }
    }, [props.task]);

    const _handleClose = () => {
        props.onClose && props.onClose();
        // do important stuff with data here
    }

    const handleBoost = async () => {
        setBtnLoading(true);
        await props.handleBoost();
        setBtnLoading(false);
        _handleClose();
    };
    
    const handleClaim = async () => {
        setBtnLoading(true);
        try {
            // Check if the task has already been completed
            const currentData = await client.graphql({
                query: getTask,
                variables: { id: props.task.taskId }
            });

            if (!currentData.data.getTask.completed) {
                navigate(`${TASK_COMPLETION_ROUTE}?type=${props.task?.task?.name?.toLowerCase().split(' ').join('-')}&id=${props.task?.taskId}`);
            } else {
                navigate(TASK_COMPLETION_ROUTE);
            };
            _handleClose();
        } catch (err) {
            console.error(err);
            setBtnLoading(false);
        };
    };

    return (
        <Modal
            {...props}
            padding={true}
            title='Claim Task'>
            
            <TaskCard role={props.task?.role} task={props.task?.task} noCTA />

            <p className="sub-body s">{`${props.task?.task?.name} is assigned to ${props.task.role?.name}. Are you sure you want to claim it? You won’t earn as many points, and that’s no fun.`}</p>
            
            <div className='modal-cta-wrapper'>
                {/* Only display boost button if task is late */}
                {showBoost &&
                    <Button
                        buttonText={`Boost Task +${props.task?.task ? getTotalPointValue(props.task, 'boosted') : 0}`}
                        size="lg"
                        icon={boostIcon}
                        isLoading={btnLoading}
                        onClick={handleBoost} />
                }
                
                <Button
                    buttonText={`Claim Task +${props.task?.task ? getTotalPointValue(props.task, 'claimed') : 0}`}
                    kind="ghost"
                    size="lg"
                    icon={claimIcon}
                    isLoading={btnLoading}
                    onClick={handleClaim} />
            </div>
        </Modal>
    )
}
export default ClaimTaskModal;