import { useState, useEffect } from 'react';
import Modal from '../../components/Modal/Modal';
import {
    notificationIcon,
    notificationStepOne,
    notificationStepTwo,
    notificationStepThree
} from '../../assets/images/images.js';

import Button from '../../components/Button/Button';

const EnableNotificationsModal = (props) => {
    const [open, setOpen] = useState(false);

    const iOScheck = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    useEffect(() => {
        if (iOScheck() === false) return; // turns off modal for browsers not using iOS
        let hasSeenNotificationModal = localStorage.getItem('hasSeenNotificationModal');
        if (!hasSeenNotificationModal) {
            setOpen(true);
            localStorage.setItem('hasSeenNotificationModal', 1);
        }
    }, [])

    return (
        <Modal
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            padding={true}
            kind="special"
            icon={notificationIcon}
            title="Get Notifications">
            <p className='sub-body'>
                We’ll send you notifications to help keep you and your team on track to earn points, complete tasks, and win gift cards.
            </p>

            <h3 className='heading-m'>1. Tap the share icon</h3>
            <img src={notificationStepOne} alt="step one visual" style={{ width: '100%' }} />
            <h3 className='heading-m'>2. From the Share menu options, select “Add to Home Screen”</h3>
            <img src={notificationStepTwo} alt="step two visual" style={{ width: '100%' }} />
            <h3 className='heading-m'>3. Tap “Add”</h3>
            <img src={notificationStepThree} alt="step three visual" style={{ width: '100%' }} />
            <Button
                buttonText='Done'
                size="lg"
                type="submit"
                onClick={() => setOpen(false)} />
        </Modal>
    )
}
export default EnableNotificationsModal;