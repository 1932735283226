import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { mVPVotesBySprintID, locationsByChallengeID } from '../../graphql/queries';
import { UserContext } from '../../Contexts.js';
import { CHECK_IN_ROUTE } from '../../Routes.js';
// import { getDiffText, getTimeDiff } from '../../utils/dateUtils.js';
import TeamBadge from '../Badge/TeamBadge.js';
import TeamBanner from '../Banner/TeamBanner';
import testData from '../../views/Dashboard/testData';
import { MVPVoteModal } from '../../modules/Modals';
import './TeamCard.scss';


const TeamCard = props => {
    const [openVoteModal, setOpenVoteModal] = useState();
    const [locations, setLocations] = useState();
    const { employee, location, challenge } = useContext(UserContext);
    const navigate = useNavigate();

    const client = generateClient();

    const [castVotes, setCastVotes] = useState([]);
    const [showVoteCountdown, setShowVoteCountdown] = useState(false);
    const [userHasVoted, setUserHasVoted] = useState(false);
    const [voteHoursRemaining, setVoteHoursRemaining] = useState(0);
    const [isWinningTeam, setIsWinningTeam] = useState(false);
    const [voteEndTime, setVoteEndTime] = useState();

    var currentSprint,sprintStartDateTime;

    const getLocations = async () => {
        const locResponse = await client.graphql({
            query: locationsByChallengeID, // needs to filter only to this challenge
            variables: {challengeID: challenge.id}
        })
        .then(response => { return response })
        .then(responseData => {
            var allLocations = responseData.data.locationsByChallengeID.items;
            var myLocations = allLocations.filter( (loc) => {
                return loc.challengeID === challenge?.id;
            });
            sortLocationsBySprintTrophyCount(myLocations);
            setIsWinningTeam(myLocations[0]?.id === employee?.locationID);
            setLocations(myLocations);
        });
        

    }

    const compareLocationsBySprintTrophyCount = (a, b) => {

        // console.log(a, b);

        if (a.sprintTrophyCount > b.sprintTrophyCount) {
            return -1;
        }
        return 1;
    }

    const sortLocationsBySprintTrophyCount = (arr) => {
        arr.sort(compareLocationsBySprintTrophyCount);
    }

    const initVoting = () => {
        // only show the MVP vote if current sprint > 1
        // only show the MVP vote if I have not already voted
        // only show the MVP vote if less than 40% of sprint duration has elapsed since start of the current sprint
        // only show the MVP vote if MY TEAM WON
        // only show countdown timer if less than 6% of voting time remains
        if (!challenge) return;

        var hourMS = 60 * 60 * 1000;

        var sprintDurationHM = {
            hours: challenge.sprintDuration.split(':')[0],
            minutes: challenge.sprintDuration.split(':')[1]
        }
        // do some sprint calcs
        var sprintEndDateTime = new Date(sprintStartDateTime.getTime() +
            sprintDurationHM.hours * hourMS +
            sprintDurationHM.minutes * 60 * 1000);
        var sprintDurationMS = sprintEndDateTime.getTime() - sprintStartDateTime.getTime();

        // do some voting date calcs: first 40% of sprint allows MVP voting
        setVoteEndTime(new Date(sprintStartDateTime.getTime() + .9 * sprintDurationMS));

    }

    // we need to pull votes for the previous sprint to see if you have already voted
    async function getCastVotes() {

        var currentSprint = challenge.getCurrentSprint();
        if (currentSprint.sprintIndex > 1) {
            const getVotesResponse = await client.graphql({
                query: mVPVotesBySprintID,
                variables: { sprintID: challenge?.previousSprintID }
            })
            .then(response => { return response })
            .then(votesResponseData => {
                const voteData = votesResponseData.data.mVPVotesBySprintID.items;
                setCastVotes(voteData);

                for (var i = 0; i < voteData.length; i++) {
                    if (voteData[i].submittedByID === employee.id) {
                        setUserHasVoted(true);
                    }
                }
                
            });
        }
    }


    const checkVoteTime = () => {

        if (!voteEndTime || !sprintStartDateTime) return;

        // var elapsedMS = sprintStartDateTime.getTime() - now.getTime();

        var voteDurationMS = voteEndTime?.getTime() - sprintStartDateTime?.getTime();
        var firstSprint = currentSprint?.sprintIndex > 1 ? false : true;

        var remainingMS = voteEndTime?.getTime() - Date.now();
        setVoteHoursRemaining(Math.floor(remainingMS / (60 * 60 * 1000)));
        var voteDurationMS = voteEndTime.getTime() - sprintStartDateTime.getTime();
        if (remainingMS < .06 * voteDurationMS) {
            setShowVoteCountdown(true)
        } else {
            setShowVoteCountdown(false);
        }
    }

    const voteCountdownTimer = setInterval(checkVoteTime, 5000);

    useEffect(() => {
        if (challenge) {

            currentSprint = challenge.getCurrentSprint();
            sprintStartDateTime = new Date(currentSprint?.startDateTime);

            getLocations();
            getCastVotes();
            initVoting();
            checkVoteTime();
        }
    }, [challenge]);

    return (
        <div className="team-card">
            {/* This TeamBanner component should only display if the employee is part of the 
            winning team. It should display for 72 hours after a challenge week ends 
            OR until the user places their vote.
            
            Countdown timer should display for last 10 hours of the voting period. */}

            {/* <p>userHasVoted: {userHasVoted ? 'yes' : 'no'}</p>
            <p>voteEndTime: {voteEndTime?.toString()}</p>
            <p>isManager: {employee.isManager ? 'yes' : 'no'}</p>
            <p>isWinningTeam: {isWinningTeam ? 'yes' : 'no'}</p>
            <p>votingExpired: {Date.now() > voteEndTime ? 'yes' : 'no'}</p> */}

            {locations && (locations.length > 0) && !employee?.isManager && isWinningTeam && 
                !userHasVoted && props.teamBanner && (Date.now() < voteEndTime) && <>
                <TeamBanner
                    kind="vote"
                    showVoteCountdown={showVoteCountdown}
                    onButtonPress={() => setOpenVoteModal(true)}
                    voteHoursRemaining={voteHoursRemaining} />
                <MVPVoteModal
                    open={openVoteModal}
                    data={testData.mvps}
                    onClose={() => {
                        setUserHasVoted(true);
                        setOpenVoteModal(false);
                    }} />
            </>}

            <div className="team-card-inner">
                <TeamBadge
                    storeNumber={location?.storeNumber}
                    isUser={props.isUser || false}
                    isFirstPlace={isWinningTeam}
                    isUserTeam={props.isUserTeam || false}
                />
                <div className='team-card-text'>
                    <h4 className="team-card-header heading-xl">
                        Store #{location?.storeNumber || "Store Number"}
                    </h4>
                    <p className="team-card-address sub-copy">
                        {location?.city || "Store Address"}
                    </p> {/* Need location address, using just the city for now. */}
                </div>
            </div>
            {props.teamBanner && !employee?.checkedIn && !employee?.isManager &&
                <TeamBanner
                    kind="check-in"
                    onButtonPress={() => navigate(CHECK_IN_ROUTE)} />}
        </div>
    )
};

export default TeamCard;