import { useContext, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { ShoutOutModal } from '../../modules/Modals';
import { UserContext } from '../../Contexts.js';
import { shoutOutIcon, shoutOutDisabledIcon, checkIcon } from '../../assets/images/images.js';
import Button from '../Button/Button';
import Avatar from '../Avatar/Avatar';
import './SpotlightCard.scss';
import { getMention } from '../../graphql/queries.js';
import { updateMention } from '../../graphql/mutations.js';

const SpotlightCard = (props) => {

    const { employee, location } = useContext(UserContext);
    const [userShoutOut, setUserShoutOut] = useState(false);
    const [openShoutOutModal, setOpenShoutOutModal] = useState();
    const [shoutOutCount, setShoutOutCount] = useState(props.data?.spotlightShoutOutCount || 0);
    const client = generateClient();
    const { data } = props;

    const _handleOpenModal = (item) => {
        setOpenShoutOutModal(true);
    }
    const _handleButtonPress = () => {
        _handleOpenModal()
    }

    useEffect(() => {
        // Check if current user has already shout out the spotlight
        // setUserShoutOut to true is so (currently broken)
        _checkIfShoutOut();
    }, [location]);

    const _checkIfShoutOut = async () => {
        const mentionRes = await client.graphql({query: getMention, variables: { id: data.id }});
        const thisMention = mentionRes.data.getMention;
        const hasShoutOut = thisMention.hasShoutOut?.some(id => id === employee?.id);
        if (hasShoutOut) setUserShoutOut(true);
    };

    // this will change to be handled from data
    const _handleDescription = (description) => {
        if (description.includes("manager")) return `Got a Spotlight from their Manager at ${location?.name} for their great work!`;
        else return description;
    };

    const _handleShoutOutCallback = async (newMention) => {
        try {
            // Increment spotlightShoutOutCount
            const currentSpotlight = await client.graphql({
                query: getMention,
                variables: { id: data.id }
            });
            const thisSpotlight = currentSpotlight.data.getMention;
            let newCount = 1;
            if (thisSpotlight.spotlightShoutOutCount !== null) {
                newCount = thisSpotlight.spotlightShoutOutCount + 1;
            };
            await client.graphql({
                query: updateMention,
                variables: {
                    input: { id: data.id, spotlightShoutOutCount: newCount, hasShoutOut: [...(thisSpotlight.hasShoutOut ? thisSpotlight.hasShoutOut : []), employee.id] }
                }
            });
            setShoutOutCount(newCount);
            setUserShoutOut(true);
        } catch (err) {
            console.error(err);
        };
    };

    return (<>
        <div className="spotlight-card-wrapper">
            <div className={`spotlight-top`}>
                <Avatar size="lg" employee={data?.toEmployeeID} />
                <div className={`spotlight-text-wrapper`}>
                    <h4 className={`spotlight-title heading-m`}>{data?.toEmployeeID?.firstName} {data?.toEmployeeID?.lastName}</h4>
                    <p className='spotlight-body s'>{_handleDescription(data?.description)}</p>
                </div>
            </div>
            <div className={`spotlight-bottom`}>
                <div className='shout-outs'>
                    <img className="shout-out-icon" src={shoutOutIcon} alt="Decorative arrow" />
                    <p className='body-xs'>{shoutOutCount}</p>
                </div>
                <Button
                    buttonText={userShoutOut ? null : "Shout-Out"}
                    size="sm"
                    type="submit"
                    icon={userShoutOut ? checkIcon : (employee.id === data?.toEmployeeID.id) ? shoutOutDisabledIcon : shoutOutIcon}
                    onClick={() => _handleButtonPress()}
                    disabled={userShoutOut || (employee.id === data?.toEmployeeID.id)} />
            </div>
        </div>
        <ShoutOutModal
            open={openShoutOutModal}
            data={data?.toEmployeeID}
            mentionType="SHOUTOUT"
            onClose={() => setOpenShoutOutModal(false)}
            callBack={_handleShoutOutCallback}
            spotLightText={data?.description}
        />
    </>
    );
}
export default SpotlightCard;
