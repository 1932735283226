import { useContext } from 'react';
import { UserContext } from '../../Contexts.js';
import { getModifierInfo, getTaskIcon, getTotalPointValue } from '../../utils/taskUtils.js';
import TaskCardTop from './TaskCardTop.js';
import TaskCardBottom from './TaskCardBottom.js';
import './TaskCard.scss';

const TaskCard = ({ role, task, noTop = false, noCTA = false, noRadius = false }) => {
    const { employee, boostTask } = useContext(UserContext);

    const checkIfHasRole = () => {
        const hasRole = task?.roles?.items?.some(r => r.role?.id === employee?.role?.id || r.item?.role?.id);
        return hasRole;
    };

    const pointValue = getTotalPointValue({
        role: task?.role,
        task: task,
        id: task?.taskId
    }); 

    return (
        <div className={`task-card ${noRadius ? 'no-radius':''}`} data-task-id={task?.id}>
            {!noTop && <TaskCardTop
                role={role}
                task={task}
                employee={employee}
                checkIfHasRole={checkIfHasRole}
            />}

            <div className="task-card-body">
                <div className="task-name">
                    <img className="task-name-image" src={ getTaskIcon(task) } alt="Task name decoration" />
                    <span className="task-name-text">{task?.name}</span>
                </div>

                <div className="task-score-modifier">
                    {getModifierInfo(task).img !== null && <img className="modifier-img" src={ getModifierInfo(task).img } alt="Score modifier decoration" />}
                    <span className="modifier-text">{ pointValue.toString() }</span>
                </div>
            </div>

            {!noCTA && <TaskCardBottom
                role={role}
                task={task}
                employee={employee}
                checkIfHasRole={checkIfHasRole}
                boostTask={boostTask}
            />}

        </div>
    )
};

export default TaskCard;