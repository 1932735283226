import TagManager from 'react-gtm-module';

export const initAnalytics = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-TR3JV469'
    }    
    TagManager.initialize(tagManagerArgs);
}

export const trackEvent = (eventName, employeeID, storeNumber, obj) => {
    var eventObj = {};
    eventObj.event = eventName;
    if (employeeID) eventObj.employee_id = employeeID;
    if (storeNumber) eventObj.location_id = storeNumber;
    eventObj.event_model = obj;

    const tagManagerArgs = {
        dataLayer: eventObj
    }
    TagManager.dataLayer(tagManagerArgs);    
}