import { createContext, useState, useEffect } from 'react';
import { generateClient } from "aws-amplify/api";
import {
    getEmployeeByUserID,
    getEmployeeByUserEmail,
    getLocationById,
    listLocationTasks,
    listChallengesSprintsLocations
} from "./graphql/customQueries.js";
import { updateTask } from './graphql/mutations.js';
import { sortTasksByDate, updateLocalTask, createTaskMention, getTotalPointValue } from './utils/taskUtils.js';
import { getTask, listEmployees, listSprints } from './graphql/queries.js';

const pushNotificationURL = 'https://txxlqa73kx6vr4kv3sebbb3tgu0wtxcf.lambda-url.us-east-1.on.aws/';
const config = {
    pushKey: "BEucF-6CEP-y0-JeZFRYNVvAW_H9TiqAev_JOc2jSQ0Q4NXLehcj8wlY6hsOT1M7EMa2tbG584SrRdK05aeMFr0",
    appSyncUrl:
        "https://3a67culyhrgfnooai756aynwwq.appsync-api.us-east-1.amazonaws.com/graphql",
    appSyncApiKey: "da2-6zprc76yz5bxddlmto77dgfhlu"
};

let pushManager;
if ('serviceWorker' in navigator) {
    // Register a service worker
    navigator.serviceWorker.register('/serviceWorker.js')
        .then(function (registration) {
            console.info('ServiceWorker registration successful with scope: ', registration.scope);
            pushManager = registration.pushManager;
        }).catch(function (err) {
            console.error('ServiceWorker registration failed: ', err);
        });
};

// Encode base64 URL into Uint8Array
function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const contactUs = () => {
    window.location.href = `mailto:${contactUsEmail}`;
};

const getUIEnvironment = () => {
    // detect our UI environment
    var env = 'prod';
    if (document.location.href.indexOf('development') !== -1) env = 'dev';
    if (document.location.href.indexOf('localhost') !== -1) env = 'dev';
    if (document.location.href.indexOf('staging') !== -1) env = 'qa';
    return env;
}

export const contactUsEmail = "taskforcesupport@dragonarmy.com";
export const UserContext = createContext();
const DefaultContext = ({ children = {} }) => {
    const [userSession, setUserSession] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [location, setLocation] = useState(null);
    const [challenge, setChallenge] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [position, setPosition] = useState('');
    const [timer, setTimer] = useState(null); // Timer for polling tasks
    const [initLoad, setInitLoad] = useState(false); // For not removing page load until done
    const [stillLoading, setStillLoading] = useState(false); // For pages that need more data and need to hold the page load
    const client = generateClient();

    useEffect(() => {
        window.addEventListener("beforeunload", e => {
            e.preventDefault();

            // Cleanup here ======================

            setTimer(null);

            // ===================================
        });
    }, []);

    async function unsubscribeFromPosition(position) {
        if (!position) {
            console.error("position is required");
            return
        }
        console.info("unsubscribing from position", position);
        try {
            const subBody = JSON.stringify({
                query: `mutation ($userId: ID!, $topic: String!){unsubscribe(userId: $userId, topic: $topic)}`,
                variables: {
                    userId: "1",
                    topic: position,
                }
            });

            // Update GraphQL mutation
            const response = await fetch(config.appSyncUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": config.appSyncApiKey,
                },
                body: subBody,
            })
            // console.log("mutation response", response);
        } catch (error) {
            console.error("Unable to send unsubscribe payload to backend", error);
        }
    };

    async function unsubscribe(position) {
        console.info("unsubscribing from service worker");
        // Check for current subscriptions
        const currentSub = await pushManager.getSubscription();

        console.info("found existing subscription to service worker:", currentSub);

        if (currentSub !== null) {
            console.info("unsubscribing from", currentSub);
            await currentSub.unsubscribe();
        }
        // when we fully unsubscribe from the service worker, we want to remove 
        // both the default all employees position, as well astheir specific position
        // TODO create an "unsubscribe from all mutation"
        await unsubscribeFromPosition(`allEmployees${getUIEnvironment()}`);
        if (position) {
            await unsubscribeFromPosition(position);
        }
    };

    async function subscribe(topic) {

        if ('serviceWorker' in navigator && 'PushManager' in window) {

            let subscription;
            try {
                const registration = await navigator.serviceWorker.ready;
                // console.info("looking for current subscription");
                subscription = await registration.pushManager.getSubscription();
                // console.info("subscription found:", subscription);

                if (!subscription) {
                    console.info("no current subscription found. will create a new one.");
                    subscription = await pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlB64ToUint8Array(config.pushKey),
                    });
                }
            } catch (error) {
                console.error("error subscribing to service worker", error);
            }

            try {
                const subBody = JSON.stringify({
                    query: `mutation ($userId: ID!, $topic: String!, $location: String!, $subscription: String!){subscribe(userId: $userId, topic: $topic, location: $location, subscription: $subscription){userId topic subscription location}}`,
                    variables: {
                        userId: employee?.userID,
                        topic: topic,
                        location: location?.storeNumber,
                        subscription: JSON.stringify(subscription)
                    }
                });

                // Update GraphQL mutation
                const response = await fetch(config.appSyncUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": config.appSyncApiKey,
                    },
                    body: subBody,
                })
                // console.log("mutation response", response);
            } catch (error) {
                console.error("Unable to send subscription payload to backend", error);
            }
        } else {
            console.info('Push messaging is not supported in this browser.');
        }
    };

    const setCurrentPosition = event => {
        try {
            const position = event.target.value;
            setPosition(position);
            console.info(`>>> Subscribing user to position: ${position}`);
        } catch (err) {
            console.error('Error occurred subscribing user to position', err);
        };
    };

    async function getStartingData(id = null, email = null) {

        console.log('>> getStartingData');

        let employeeQuery = getEmployeeByUserID;
        let employeeVariables = { id: id };
        if (id === null) {
            employeeQuery = getEmployeeByUserEmail;
            // email is toLowered in login component, but adding it here as well
            // in case this function is used elesewhere
            employeeVariables = { email: email.toLowerCase() }
        };

        try {
            // Get employee data
            const employeeResponse = await client.graphql({
                query: employeeQuery,
                variables: employeeVariables
            });
            const employeeData = employeeResponse.data.listEmployees.items[0];

            if (!employeeData) {
                throw new Error(`Employee data not Found: ${employeeData}`);
            }

            setEmployee({ ...employeeData });

            // Subscribe to employee default role notifications
            // Will be overwritten on check-in with the selected role
            // NOTE: This fails on login if we have no role & no default role, 
            // which then fails to validate the login code in the UI.
            // if (!employeeData.role) subscribe(employeeData.defaultRole.id);

            // Get location data
            const locationResponse = await client.graphql({
                query: getLocationById,
                variables: { id: employeeData.locationID }
            });
            const locationData = locationResponse.data.getLocation;
            setLocation(locationData);

            // Begin polling for tasks
            if (timer) setTimer(null); // Reset any timers created on double mounts
            await pollTasks(locationData.id);
            const newTimer = setInterval(() => pollTasks(locationData.id), 60000);
            setTimer(newTimer);

            // get the current challenge
            let challengeQuery = listChallengesSprintsLocations;
            let challengeResponse = await client.graphql({
                query: challengeQuery
            });

            const challengeData = challengeResponse.data.listChallenges.items[0];

            // add some util methods
            challengeData.getCurrentSprint = () => {
                for (var i = 0; i < challengeData.sprints.items.length; i++) {
                    if (challengeData.currentSprintID === challengeData.sprints.items[i].id) {
                        return challengeData.sprints.items[i];
                    }
                }
            }
            setChallenge({ ...challengeData });

            return { employeeData, locationData, challengeData };
        } catch (err) {
            console.error('Error occurred while getting employee and location information', err);
            return err;
        };
    };

    // Polls tasks for changes
    async function pollTasks(locationId = null) {
        const id = locationId || location?.id;
        if (id) {
            let newTasks, nextToken;
            let pageTasks = [];
            try {

                do {
                    // console.log('...fetching tasks...' + pageTasks.length);

                    const response = await client.graphql({ query: listLocationTasks, variables: { id: id, nextToken: nextToken } });
                    const newTasks = response.data.listTasks.items;
                    nextToken = response.data.listTasks.nextToken;

                    // Match old obj (may remove if we refactor and just pass newTasks to below method)
                    const matchingOldObj = newTasks.map(task => {
                        return {
                            taskId: task.id,
                            task: task,
                            role: task.roles.items[0].role,
                            name: task.name
                        }
                    });
                    pageTasks = pageTasks.concat(matchingOldObj);

                } while (nextToken);
                
                setTasks(sortTasksByDate(pageTasks));


            } catch (err) {
                console.error('An error occurred while fetching task data', err);
            };
            return newTasks;
        }
    };

    async function boostTask(taskId) {
        try {
            const currentData = await client.graphql({
                query: getTask,
                variables: { id: taskId }
            });
            
            if (!currentData.data.getTask.boosted) {
                const boostTask = await client.graphql({
                    query: updateTask,
                    variables: {
                        input: {
                            id: taskId,
                            boosted: true,
                            taskBoostedById: employee.id
                        }
                    }
                });
    
                if (boostTask.data.updateTask) {
                    const updatedTasks = updateLocalTask(boostTask.data.updateTask, tasks);
                    setTasks(updatedTasks);
                };
    
                // Create a boost type mention
                const task = tasks.find(task => task.taskId === taskId);
                const employees = await client.graphql({
                    query: listEmployees,
                    filter: {
                        locationId: { eq: location.id }
                    }
                });
                const taskEmployees = employees.data.listEmployees.items.filter(employee => {
                    return task.task.roles.items.some(r => r.role.id === employee.role?.id)
                });
                
                // Create a boost mention for each employee associated with the task's role(s)
                taskEmployees.map(toEmployee => {
                    createTaskMention({
                        mentionType: 'BOOST',
                        shoutOutType: null,
                        client,
                        toEmployeeId: toEmployee.id,
                        fromEmployeeId: employee.id,
                        task: task,
                        challenge,
                        description: `Don’t forget to ${task?.task?.name}! If you do, you’ll earn ${getTotalPointValue(task, 'boosted')} bonus points from ${employee?.firstName} ${employee?.lastName?.charAt(0).toUpperCase()}.`
                    });
    
                    // create a boost notification to the recipient
                    sendNotification(
                        'BOOST ALERT!',
                        employee.firstName + ' ' + employee.lastName.substr(0,1) + '.' +  
                            ' just gave you a boost! ' + task.name + ' now before time runs out to earn points for your team!',
                        task.taskId,
                        null,
                        task.role,
                        null
                    );
                    return toEmployee;
                });
            } else {
                const updatedTasks = updateLocalTask(currentData.data.getTask, tasks);
                setTasks(updatedTasks);
            };
        } catch (err) {
            console.error('An error occurred while trying to boost the task. ', err);
        };
    };

    // Deprecated (We now only mark task as claimed when it is completed)
    async function claimTask(taskId) {
        try {
            const currentData = await client.graphql({
                query: getTask,
                variables: { id: taskId }
            });

            if (!currentData.data.getTask.taskClaimedById) { 
                const claimTask = await client.graphql({
                    query: updateTask,
                    variables: {
                        input: {
                            id: taskId,
                            taskClaimedById: employee.id
                        }
                    }
                });
    
                if (claimTask.data.updateTask) {
                    const updatedTasks = updateLocalTask(claimTask.data.updateTask, tasks);
                    setTasks(updatedTasks);
                }
    
                // Trigger a notification
            } else {
                const updatedTasks = updateLocalTask(currentData.data.getTask, tasks);
                setTasks(updatedTasks);
            };
        } catch (err) {
            console.error('An error occurred while trying to claim the task. ', err);
        };
    };

    // Sends notifications on-the-fly 
    // sendDateTime is optional, can be null for immediate delivery
    // roleID is optional, will send to role instead of an individual employee
    function sendNotification(title, text, taskID, userID, roleID, sendDateTime) {

        fetch(pushNotificationURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                "environment": getUIEnvironment(),
                "topic": roleID ? roleID : "allEmployees" + getUIEnvironment(),
                "location": location.storeNumber,
                "title": title,
                "text": text,
                "taskID": taskID,
                "employeeID": roleID ? null : userID,
                "senderName": employee.firstName + ' ' + employee.lastName.charAt(0).toUpperCase() + '.',
                "sendDateTime": sendDateTime ? sendDateTime.toISOString().split('.')[0] : null // cron time
            })
        }).then(res => {
            console.log(res);
        }).catch(err => console.error(err));
    };

    const defaultContext = {
        userSession,
        setUserSession,
        employee,
        setEmployee,
        location,
        setLocation,
        challenge,
        setChallenge,
        tasks,
        setTasks,
        pollTasks,
        pageLoading: pageLoading || stillLoading,
        setPageLoading,
        setStillLoading,
        initLoad,
        setInitLoad,
        position,
        setPosition: setCurrentPosition,
        subscribe,
        unsubscribe,
        unsubscribeFromPosition,
        getStartingData,
        boostTask,
        claimTask,
        contactUs,
        sendNotification,
        getUIEnvironment
    };

    return <UserContext.Provider value={defaultContext}>{children}</UserContext.Provider>
};

export default DefaultContext;
