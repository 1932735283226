import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { EMPLOYEE_SPOTLIGHT_INSIGHTS_ROUTE } from '../../Routes.js';
import { UserContext } from '../../Contexts.js';
import { employeesByLocationID, getLocation, listEmployees } from '../../graphql/queries';
import { listTasksCustom, listUserMentions, listUserFromMentions, getEligibleTasksByEmployeeID } from '../../graphql/customQueries.js';
import EmployeeList from '../../modules/Employees/EmployeesList';
import Mentions from '../../modules/Mentions/MentionsList'
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { InsightsHeader } from "../../modules/Insights";
import TaskInsightsList from '../../modules/Tasks/TaskInsightsList';
import Select from '../../components/Select/Select.js';
import { trackEvent } from '../../utils/anayticsUtils';
import { checkIfExpired, formatDate, getWeekDayFromDate } from '../../utils/dateUtils.js';
import './Insights.scss';

const InsightsReportPage = (props) => {
    const { employee, location, challenge, stillLoading, setStillLoading } = useContext(UserContext); // Location here is the context location
    const locationState = useLocation(); // This is state that is passed by react router with the location hook
    const data = locationState.state;
    const d = new Date();
    const yesterday = new Date(d.setDate(d.getDate() - 1));
    const [filterDateStart, setFilterDateStart] = useState(new Date(yesterday.setHours(0, 0, 0, 0)));
    const [filterDateEnd, setFilterDateEnd] = useState(new Date(yesterday.setHours(23, 59, 59, 999)));
    const [myMentions, setMyMentions] = useState([]);
    const [fromMentions, setFromMentions] = useState([]);
    const [taskShoutouts, setTaskShoutouts] = useState([]);
    // const [stats, setStats] = useState();
    const [employees, setEmployees] = useState(data?.employees || []);
    const [taskList, setTaskList] = useState(data?.tasks || []);
    const [thisLocation, setThisLocation] = useState(data?.location || null); // This is store location state
    const [selectedOption, setSelectedOption] = useState({label: "Yesterday", value: "Yesterday"});
    const client = generateClient();
    const navigate = useNavigate();
    const [thisEmployee, setThisEmployee] = useState();
    const { storeNumber, date } = useParams();
    const [searchParams] = useSearchParams();

    const employeesByLocationPromise = async () => {

        try {
            let nextToken, newEmployees;
            let allEmployees = [];
            if (props.daily || props.employee) {
                do {
                    
                    // Get all employees
                    const response = await client.graphql({
                        query: listEmployees,
                        variables: { nextToken, limit: 3000 }
                    });
                    newEmployees = response.data.listEmployees.items;
                    nextToken = response.data.listEmployees.nextToken;
                    allEmployees = allEmployees.concat(newEmployees);
    
                } while (nextToken);
    
                setEmployees(allEmployees.filter( (emp) => !emp.isManager));

                if (searchParams.get('id')) {
                    setThisEmployee(allEmployees.find( (emp) => emp.id === searchParams.get('id')));
                }

                

            } else {
                
                if (challenge && (data?.id || storeNumber)) {
                    do {

                        // employee insights use store manager's location
                        let locationID = employee.locationID;

                        if (storeNumber) {
                            const challengeLocation = challenge.locations.items.find(loc => loc.storeNumber === storeNumber);
                            locationID = challengeLocation.id;
                        };
                        const response = await client.graphql({
                            query: employeesByLocationID,
                            variables: { locationID, nextToken, limit: 3000 }
                        });

                        console.log(response);
                        
                        newEmployees = response.data.employeesByLocationID.items;
                        nextToken = response.data.employeesByLocationID.nextToken;
                        allEmployees = allEmployees.concat(newEmployees);

                    } while (nextToken);

                    setEmployees(allEmployees.filter( (emp) => !emp.isManager));
                    
                    if (searchParams.get('id')) {
                        setThisEmployee(allEmployees.find( (emp) => emp.id === searchParams.get('id')));
                    }

                } else {
                    setEmployees(data?.employees?.items || []);
                };
                
            };

        } catch (err) {
            console.error('Error while trying to fetch location employees.', err);
        };
    };

    const mentionsPromise = async () => {
        if (!employee) return;
        if (props.employee && !thisEmployee) return;

        try {
            // fetch MY mentions
            const myResponse = await client.graphql({ query: listUserMentions, variables: { id: thisEmployee.id } });
            const myItems = myResponse.data.listMentions.items;
            setMyMentions(myItems);

            // shoutouts from a selected employee
            if (searchParams.get('id')) {
                const fromResponse = await client.graphql({ query: listUserFromMentions, variables: { id: searchParams.get('id') } });
                const fromItems = fromResponse.data.listMentions.items.filter ( (m) => m.type === 'SHOUTOUT' && 
                (new Date(m.createdAt) > filterDateStart && new Date(m.createdAt) < filterDateEnd));

                setFromMentions(fromItems);
            }
        } catch (err) {
            console.error('Error while trying to fetch mentions.', err);
        };
    };

    function groupBy(collection, property) {
        var i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    };

    const getLocationData = async () => {

        
        setStillLoading(true);

        if (props.daily || !challenge || !employee) {
            setStillLoading(false);
            return;
        }

        try {

            if (challenge) {
                var locationID;
                if (props.employee) {
                    locationID = employee.locationID;
                } else {
                    locationID = challenge.locations.items.find(loc => loc.storeNumber === storeNumber).id;
                }
                
                const res = await client.graphql({ query: getLocation, variables: { id: locationID } });
                const locData = res.data.getLocation;

                setThisLocation(locData);
                setEmployees(locData.employees.items);

                setStillLoading(false);
            }

        } catch (err) {
            console.error(err);
            setStillLoading(false);
        };
    };

    const getAllTasks = async () => {

        console.log('getAllTasks');

        setStillLoading(true);

        try {
            
            let newTasks, nextToken;
            let pageTasks = [];
            let startDateTime = filterDateStart;
            let endDateTime = filterDateEnd;
            let filter;
            let allTaskMentions = [];
            if (date) {
                let currentDate = date;
                startDateTime = new Date(`${currentDate} 00:00:00`).toISOString(); // Start of current day
                endDateTime = new Date(`${currentDate} 23:59:59`).toISOString(); // End of current day
            };
            
            if (storeNumber && props.restaurant) {
                filter = { locationID: { eq: thisLocation?.id }, startDateTime: { ge: startDateTime, le: endDateTime } };
            } else {
                filter = { startDateTime: { ge: startDateTime, le: endDateTime } };
            };

            do {
                
                // Get all tasks within the given start and end DateTime
                // For daily, this will be the same date, with different times.
                const response = await client.graphql({
                    query: listTasksCustom,
                    variables: {
                        filter,
                        limit: 2000,
                        nextToken: nextToken
                    }
                });
                newTasks = response.data.listTasks.items;
                nextToken = response.data.listTasks.nextToken;
                pageTasks = pageTasks.concat(newTasks);

            } while (nextToken);

            // gather shoutouts            
            var shoutouts = pageTasks.map( (t) => {
                allTaskMentions = allTaskMentions.concat(t.mentions.items);
                return t.mentions.items;
            });
            setTaskShoutouts(allTaskMentions.filter( (m) => {
                return m.type === 'SHOUTOUT';
            }));

            // console.log(taskMentions);
            setTaskShoutouts(allTaskMentions);



            const sortedByDate = pageTasks.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
            setTaskList(sortedByDate);
        } catch (err) {
            console.error(err);
        };

        setStillLoading(false);
    };

    const groupTasks = (tasks) => {
        const taskTypes = {
            'Check Shake Mix Level': [],
            'Check Tea Levels': [],
            'Clean ABS Nozzles': [],
            'Clean ABS Conveyor Belt': [],
            'Replace Tea Liners': [],
            'Stock Napkins': [],
            'Stock Condiments': [],
            'Wash Hands': []
        };

        tasks?.map(task => {
            if (task) {
                taskTypes[task.name]?.push(task);
                return task;
            }
        });

        let types = [];
        for (let key in taskTypes) {
            let completionPercentage = Math.round(100 * taskTypes[key].filter(task => (task.completed && !task.rejected)).length/taskTypes[key].length);
            if (taskTypes[key].length === 0) completionPercentage = 0;
            const listData = {label: key, tasks: taskTypes[key], percentage: Math.ceil(completionPercentage)}
            types.push(listData);
        };
        return types;
    };

    const getEmployeeTasks = async () => {

        console.log('getEmployeeTasks');

        setStillLoading(true);

        if (!employee) {
            setStillLoading(false);
            return;
        }

        // if we are not a manager, data is null
        
        var currentEmployeeID;


        if (!employee.isManager) {
            setThisEmployee(employee);
            currentEmployeeID = employee.id;
        } else {
            if (!employees) return;

            var selectedEmployee = employees.find( (emp) => emp.id === searchParams.get('id'));
            setThisEmployee(selectedEmployee);
            currentEmployeeID = searchParams.get('id');
        }
    
        var pageTasks = [];
        var nextToken;
       
        // load all tasks within date range for a single employee
        do {
            const response = await client.graphql({ 
                query: getEligibleTasksByEmployeeID, 
                variables: { 
                    startRange: filterDateStart.toISOString(), 
                    endRange: filterDateEnd.toISOString(), 
                    id: currentEmployeeID,
                    nextToken: nextToken
                } 
            });
            const newTasks = response.data.listTasks.items;
            nextToken = response.data.listTasks.nextToken;         
            pageTasks = pageTasks.concat(newTasks);

        } while (nextToken);

        pageTasks = pageTasks.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

        var completedCount = pageTasks.filter( (t) => (t.completed && !t.rejected)).length;
        var boostedCount = pageTasks.filter( (t) => t.taskBoostedByID === currentEmployeeID).length;

        var shoutOutCount = fromMentions.filter( (m) => m.type === 'SHOUTOUT').length;

        // setStats({
        //     tasks: {
        //         complete: completedCount,
        //         total: pageTasks.length,
        //         boosted: boostedCount
        //     },
        //     shoutOuts: shoutOutCount
        // });
        
        
        setTaskList(pageTasks);
        
        setStillLoading(false);
    };
    

    useEffect( () => {

        if (!challenge) return;
        
        if (props.restaurant) {
            // The above functions will be called in the below use effect
            // This is to ensure we have the correct location data before grabbing data relying on it
            getLocationData();
        };

        if (props.daily || props.employee) {
            
            if (!employees || employees.length === 0) {
                employeesByLocationPromise();
            }
            
            if (props.daily && taskList.length === 0) { 
                getAllTasks();
            } else if (props.employee) {
                getEmployeeTasks();
            }
        };

        if (props.daily || props.restaurant) {
            if (taskList.length > 0) {
                var allTaskMentions = [];
                var shoutouts = taskList.map( (t) => {
                    allTaskMentions = allTaskMentions.concat(t.mentions.items);
                    return t.mentions.items;
                });
                setTaskShoutouts(allTaskMentions.filter( (m) => {
                    return m.type === 'SHOUTOUT';
                }));
            }
        }

    }, [challenge]);
    
    useEffect(() => {
        
        if (props.employee) {
            mentionsPromise()
        }
    }, [thisEmployee]);

    useEffect(() => {
        // analytics
        trackEvent('view_report', employee?.id, location?.storeNumber, {
            "report_type": props.employee ? 'Employee' : props.daily ? 'Daily' : "Restaurant",
            "report_name": `${handlePageTitle()} report ${props.daily ? '' : `for ${filterDateStart}`}`
        });
    }, [props.employee, props.daily, props.restaurant, filterDateStart]);

    const handlePageTitle = () => {

        if (props.daily) {
            const tasks = taskList;
            const headerText = tasks.length > 0 ? `${getWeekDayFromDate(tasks[0]?.startDateTime)} ${formatDate(new Date(tasks[0]?.startDateTime))}` : ''
            return headerText;
        };
        
        if (props.employee) {
            let nameString = `${thisEmployee?.firstName} ${thisEmployee?.lastName}`;
            
            if (props.tasks) {
                if (nameString.substring(nameString.length-1, nameString.length) == 's') {
                    nameString += `' Tasks`;
                } else {
                    nameString += `'s Tasks`;
                }
            }
            return thisEmployee ? nameString : '';
        } 


        else if (props.restaurant) return `Store #${storeNumber}`
        else return `${locationState?.state.firstName} ${locationState?.state.lastName}`;
    };

    const handleSelectOptions = () => {
        let options = [
            {
                label: 'Today',
                value: 'Today'
            },
            {
                label: 'Yesterday',
                value: 'Yesterday'
            }
        ];

        if (challenge) {
            const sortedSprints = challenge.sprints.items.sort((a, b) => a.sprintIndex - b.sprintIndex);
            sortedSprints.map(sprint => {
                const sprintDuration = challenge.sprintDuration.split(':');
                const sprintHours = parseInt(sprintDuration[0]);
                const sprintMins = parseInt(sprintDuration[1]);
                const sprintStartDate = new Date(sprint.startDateTime);
                const expiredDateTime = new Date(sprintStartDate.setHours(sprintStartDate.getHours() + sprintHours, sprintStartDate.getMinutes() + sprintMins));

                if (checkIfExpired(expiredDateTime)) {
                    options.push({ label: sprint.name, value: sprint.name });
                };
            });
        };

        return options;
    };

    const handleSelectChange = (e) => {
        let startTime;
        let endTime;

        var paramStart = searchParams.get('start');
        var paramStartDate = paramStart ? new Date(paramStart) : null;
        var paramEnd = searchParams.get('end');
        var paramEndDate = new Date(paramStartDate).setHours(23, 59, 59, 999);


        switch (e.target.value) {
            case 'Today':
                startTime = paramStart ? paramStartDate : new Date(Date.now()).setHours(0, 0, 0, 0);
                endTime = paramEnd ? paramEndDate : new Date(Date.now()).setHours(23, 59, 59, 999);
                break;
            
            case 'Yesterday':
                startTime = paramStart ? paramStartDate : yesterday.setHours(0, 0, 0, 0);
                endTime = paramEnd ? paramEndDate : yesterday.setHours(23, 59, 59, 999);
                break;
        
            default:
                challenge.sprints.items.map(sprint => {
                    if (sprint.name === e.target.value) {
                        const sprintDuration = challenge.sprintDuration.split(':');
                        const sprintHours = parseInt(sprintDuration[0]);
                        const sprintMins = parseInt(sprintDuration[1]);
                        const sprintStartDate = new Date(sprint.startDateTime);
                        const expiredDateTime = new Date(sprintStartDate.setHours(sprintStartDate.getHours() + sprintHours, sprintStartDate.getMinutes() + sprintMins));
                        startTime = sprint.startDateTime;
                        endTime = expiredDateTime;
                    };
                });
                break;
        }

        var startDateTime = paramStart ? paramStartDate : new Date(startTime);
        var endDateTime = paramEnd ? paramEndDate : new Date(endTime);

        if (searchParams.get('start')) {
            startDateTime = new Date(searchParams.get('start'));
        }
        if (searchParams.get('end')) {
            endDateTime = new Date(searchParams.get('end'));
        }

        setFilterDateStart(startDateTime);
        setFilterDateEnd(endDateTime);
        setSelectedOption(e.target.value);
    };

    useEffect(() => { 

        // getLocationData();

        if (props.restaurant && thisLocation && !props.employee) {
            getAllTasks();
        } else if (props.employee) {
            getEmployeeTasks();
        }

        if (props.employee) {
            mentionsPromise();
        }

        
    }, [filterDateStart, locationState]);


    // employee: data is the desired employee obj, either the one selected by manager or the employee viewing themselves
    // restaurant: data.location is the store Location obj
    // daily: data contains tasks, employees

    // console.log('props:', props);
    // console.log('data:', data);
    // console.log('thisEmployee:', thisEmployee);
    // console.log(fromMentions);

    return (
        employee && <div className="insights-page">
            <div className="insights-page-header">
                {employee.isManager ? <Breadcrumb employeeData={thisEmployee}/> : '' }
                <div className="insights-header-title">
                    <h1>{handlePageTitle()}</h1>
                    {!props.daily && <>
                        <Select
                            className="insights-time-select"
                            defaultValue={{label: "Yesterday", value: "Yesterday"}}
                            value={selectedOption}
                            onChange={handleSelectChange}
                            data={handleSelectOptions().map(option => {
                                return {
                                    label: option.label,
                                    value: option.value
                                }
                            })}
                        />
                    </>}
                </div>
            </div>

            {!props.tasks ? <InsightsHeader
                spotlight={employee?.isManager && props.employee && thisEmployee?.locationID === employee.locationID} // should only show if manager viewing report is assigned to same store as employee
                onButtonPress={() => navigate(EMPLOYEE_SPOTLIGHT_INSIGHTS_ROUTE + '?id=' + thisEmployee.id, { state: thisEmployee })}
                employeeData={props.restaurant || props.daily ? false : employee.isManager ? thisEmployee : employee}
                shoutouts={props.employee ? fromMentions : taskShoutouts}
                data={taskList ? taskList : data ? data.tasks : []}
            /> : ''}
            <TaskInsightsList
                employee={props.employee}
                employeeData={thisEmployee}
                showAll={props.tasks}
                daily={props.daily}
                restaurant={props.restaurant}
                date={props.daily && taskList.length > 0 ? formatDate(new Date(taskList[0].startDateTime)) : formatDate(new Date(filterDateStart))}
                tasks={props.daily || props.restaurant ? groupTasks(taskList) : taskList}
                totalPercent={Math.round((taskList.filter(task => (task.completed && !task.rejected)).length / taskList.length) * 100)} />
            {!props.employee && <EmployeeList paginate paginationAmount={10} data={employees} />}
            {(props.employee && !props.tasks && myMentions.length > 1) && <Mentions showTitle={true} viewAll={true} data={myMentions} />}
        </div>
    )
};

export default InsightsReportPage;