import React from "react";

const Tab = props => {

    return (
        <button
            role="tab"
            id={props.id}
            aria-selected={props.active}
            aria-controls={props.tabPanelId}
            className={`tab tab-${props.kind ?? "line"} ${props.active ? "active" : "inactive"} ${props.className ? props.className : ""}`}
            onClick={props.onClick}>
            {props.tabLabel}
        </button>
    )
};

export default Tab;