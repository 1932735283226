import { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils.js";
import TaskCard from '../../components/TaskCard/TaskCard.js';
import Button from "../../components/Button/Button.js";
import './TaskList.scss';
import { TASK_LIST_ROUTE } from "../../Routes.js";

const TaskList = props => {
    const { contextTasks, groupDate = false, paginate = false, paginateLength = 10 } = useOutletContext();
    const [tasks, setTasks] = useState(props.tasks || []);
    const [showLength, setShowLength] = useState(paginateLength);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTasks([...(props.tasks ? props.tasks : []), ...(contextTasks ? contextTasks : [])]);
    }, [contextTasks, props.tasks]);

    const handleViewMore = () => {
        if (showLength + paginateLength > tasks.length) {
            setShowLength(tasks.length);
        } else {
            setShowLength(showLength + paginateLength);
        };
    };

    return (
        <div className='task-list'>
            {tasks?.length > 0 && tasks?.map((task, index) => {
                if ((index + 1) <= showLength || !paginate) {
                    return <div key={task.taskId || index}>
                        {groupDate && (index === 0 || formatDate(new Date(task.task.startDateTime)) !== formatDate(new Date(tasks[index - 1].task.startDateTime))) && <h3 className="heading-s">{formatDate(new Date(task.task.startDateTime))}</h3>}
                        <TaskCard task={task.task} role={task.role} />
                    </div>
                }
            })}
            {paginate && showLength < tasks?.length && !props.hideCTA && <Button
                buttonText="View More"
                onClick={handleViewMore}
            />}
            {!paginate && !(location.pathname.includes(TASK_LIST_ROUTE) || props.hideCTA) && <Button
                buttonText="View All Tasks"
                onClick={() => navigate(TASK_LIST_ROUTE)}
            />}
        </div>
    )
};

export default TaskList;