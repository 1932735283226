import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TaskCard from '../../components/TaskCard/TaskCard';
import { formatDate } from '../../utils/dateUtils';
import './TaskListPage.scss';

const TaskDetailsList = props => {
    const location = useLocation();
    const { state } = location;

    return (
        <div className='task-details-list page-padding'>
            <Breadcrumb />
            <h1 className='details-heading'>{state.label} Details</h1>

            <div className='task-list'>
                {state.tasks.length > 0 && state.tasks.map((task, index) => {
                    return <div key={task.taskId || index}>
                        {state.groupDate && (index === 0 || formatDate(new Date(task.startDateTime)) !== formatDate(new Date(state.tasks[index - 1].startDateTime))) && <h3 className="heading-s">{formatDate(new Date(task.startDateTime))}</h3>}
                        <TaskCard key={task.id || index} task={task} role={task.roles.items[0].role} />
                    </div>
                })}
            </div>
        </div>
    )
};

export default TaskDetailsList;