import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { updateTask } from '../../graphql/mutations';
import { UserContext } from '../../Contexts';
import { CHECK_IN_ROUTE, TASK_COMPLETION_ROUTE, TASK_LIST_MY_TASKS_ROUTE } from '../../Routes';
import { checkIfExpired, getLocalTime } from '../../utils/dateUtils';
import { savePhoto } from '../../utils/fileUtils';
import {
    checkIfPerfectShift,
    filterShiftTasks,
    getNextTask,
    getRoleShiftTasks,
    getTaskIcon,
    getTaskNameFromType,
    getTasksCompletedByUser,
    getTotalPointValue,
    getTotalPointsValue,
    updateLocalTask
} from '../../utils/taskUtils';
import { getCurrentTaskByName } from '../../graphql/customQueries';
import { boostIcon, checkInIcon, claimIcon } from '../../assets/images/images';
import Button from '../../components/Button/Button';
import TaskCard from '../../components/TaskCard/TaskCard';
import TaskSummaryCard from '../../components/Cards/TaskSummaryCard';
import NextTask from '../../modules/NextTask/NextTask';
import { trackEvent } from '../../utils/anayticsUtils.js';
import { NoTasks } from '../TaskListPage/TaskListPage';
import PerfectShiftCard from '../../components/Cards/PerfectShiftCard';
import '../../components/TaskCard/TaskCard.scss';
import '../../views/TaskCompletion/TaskCompletion.scss';
import './TaskCompletion.scss';
import { updateScore } from '../../utils/scoreUtils';
import { getTask } from '../../graphql/queries';

const SimpleVariant = ({
    children,
    heading,
    content,
    hasTwoButtons = false,
    buttonProps = {},
    buttonTwoProps = {}
}) => {
    return (
        <div className='variant-wrapper page-padding'>
            <h1>{heading}</h1>
            {children}
            <p className='sub-body s'>{content}</p>
            <div>
                <Button {...buttonProps} onClick={buttonProps.onClick} />
                {hasTwoButtons && <Button {...buttonTwoProps} onClick={buttonTwoProps.onClick} />}
            </div>
        </div>
    )
};

const TaskCompletion = () => {
    const { employee, location, setLocation, tasks, setTasks, boostTask, pageLoading, setPageLoading, getUIEnvironment } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const [task, setTask] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [btnsDisabled, setBtnsDisabled] = useState(true);
    const [completed, setCompleted] = useState(false);
    const navigate = useNavigate();
    const client = generateClient();

    useEffect(() => {
        if (searchParams.get('type')) {
            getTaskData();
        }
    }, [location]);

    useEffect(() => {
        if (task) {
            processTask();
        }
    }, [task]);

    useEffect(() => {
        const img = document.getElementById('photo-input-thumbnail');
        const wrapper = document.getElementsByClassName('photo-input-wrapper')[0];
        if (img && wrapper) {
            if (!file) {
                img.style.display = 'none';
            } else {
                img.style.display = 'block';
            };

            if (!file) {
                setBtnsDisabled(true);
                wrapper.style.border = '1px';
            } else {
                setBtnsDisabled(false);
                wrapper.style.border = '0';
            };
        };
    }, [file]);

    const getTaskData = async () => {

        var id;

        if (searchParams.get('id')) {
            id = searchParams.get('id');
        } else {
            console.log('fetching current task for QR...');

            var taskType = searchParams.get('type');

            const taskName = getTaskNameFromType(taskType);

            var now = new Date().toISOString();

            //try and fetch the current task of that type
            const getQRTask = await client.graphql({
                query: getCurrentTaskByName,
                variables: {
                    name: taskName,
                    lt: now,
                    gt: now
                }
            });
            if (getQRTask.data.listTasks.items.length > 0) {
                const qrTask = getQRTask.data.listTasks.items[0];
                id = qrTask.id;

                console.log('current QR task:', qrTask);
                setTask(
                    {
                        task: { ...qrTask, claimedBy: !qrTask.roles.items.some(role => role.name === employee.role.name) },
                        taskId: qrTask.id
                    })
            } else {
                id = '';
            }
        }

        if (tasks) {
            setPageLoading(true);
            const currentTask = tasks.filter(t => t.taskId === id)[0];

            setTask({ ...currentTask, claimedBy: currentTask ? !currentTask.roles?.items.some(role => role.name === employee.role.name) : false });
            setPageLoading(false);
        };
    };

    const handleTaskCompletion = () => {

        // analytics
        let lastTask = filterShiftTasks(tasks, employee).pop(); // get the last task available to employee in their shift
        trackEvent('complete_task', employee.id, location.storeNumber, {
            "employee_role": employee.role,
            "task_id": task.taskId,
            "task_name": task.task.name,
            "task_type": task?.task?.isTeamTask ? 'Team' : 'Individual', 
            "task_role": task.role
        });
        if (lastTask.taskId === task.taskId) {
            trackEvent('shift_complete', employee.id, location.storeNumber);
        }
        
        setCompleted(true); // Show completion verification screen
    }

    const processTask = async () => {

        setPageLoading(true);

        // If team task
        if (task?.task?.isTeamTask) {
            try {
                const res = await client.graphql({
                    query: getTask,
                    variables: { id: task.taskId }
                });
                const currentData = res.data.getTask;
                console.log(currentData);

                if (!currentData.teamCompletedIDs?.includes(employee?.id)) { // Prevent duplicate entries
                    // Update task and move past photo portion
                    let data = {
                        id: task.taskId,
                        teamCompletedIDs: [...(currentData.teamCompletedIDs ? currentData.teamCompletedIDs : []), employee?.id]
                    };
                    if (data.teamCompletedIDs.length === currentData.teamEligibleIDs.length) {
                        data = { ...data, completed: true, completedAt: new Date(Date.now()).toISOString() };
                    };

                    const updatedTask = await client.graphql({
                        query: updateTask,
                        variables: {
                            input: data
                        }
                    });
                    setTask({ ...task, task: { ...currentData, ...updatedTask.data.updateTask } });
                    const updatedTasks = updateLocalTask(updatedTask.data.updateTask, tasks);
                    setTasks(updatedTasks);
                    const pointValue = getTotalPointValue({
                        role: task.role,
                        task: updatedTask.data.updateTask,
                        id: task.taskId
                    });

                    // If last employee to complete
                    if (data.completed) {
                        // update team score
                        const updateLocationPoints = await updateScore(getUIEnvironment(), location?.id, pointValue);
                        setLocation({ ...location, ...updateLocationPoints });
                    };
                }
                handleTaskCompletion(); // handle analytics and show completion verification screen
            } catch (err) {
                console.error(err);
            };
        }
        // Don't show photo screen if task is already completed
        if (task?.task?.completed) setCompleted(true);

        setPageLoading(false);
    }

    const previewImage = () => {
        const currentFile = document.getElementById("task-photo-input").files[0];
        if (currentFile) {
            setFile(currentFile);
            const oFReader = new FileReader();
            oFReader.readAsDataURL(currentFile);
            oFReader.onload = function (oFREvent) {
                setPhoto(oFREvent.target.result);
            };
        };
    };
    const handlePhotoSubmit = async () => {
        if (!task.task?.completed) { // Don't allow resubmits

            // run analytics
            trackEvent('submit_photo', employee.id, location.storeNumber, {
                "employee_role": employee.role,
                "task_id": task.taskId,
                "task_name": task.task.name,
                "task_type": task?.task?.isTeamTask ? 'Team' : 'Individual', 
                "task_role": task.role
            });

            setLoading(true);
            try {
                const currentData = await client.graphql({
                    query: getTask,
                    variables: { id: task.taskId }
                });

                if (!currentData.data.getTask.completed) { 
                    const s3Response = await savePhoto(file); // Add photo to s3
                    const updateTaskPhoto = await client.graphql({
                        query: updateTask,
                        variables: {
                            input: {
                                id: task.taskId,
                                completed: true,
                                taskCompletedById: employee.id,
                                completedAt: new Date(Date.now()).toISOString(),
                                photoURL: [s3Response.key],
                                taskClaimedById: task.task.roles?.items.some(role => role.role?.name === employee?.role?.name) ? null : employee.id
                            }
                        }                
                    }); // Update task in db
    
                    // Update local task instance
                    const updatedTasks = updateLocalTask(updateTaskPhoto.data.updateTask, tasks);
                    setTasks(updatedTasks);
    
                    // Update location points
                    const pointValue = getTotalPointValue({
                        role: task.role,
                        task: updateTaskPhoto.data.updateTask,
                        id: task.taskId
                    }); // Adjust point value for relative modifiers
                    
                    const updateLocationPoints = await updateScore(getUIEnvironment(), location?.id, pointValue);
                    setLocation({ ...location, ...updateLocationPoints }); // Update local location instance
                    setCompleted(true); // Show completion verification screen
                } else {
                    const updatedTasks = updateLocalTask(currentData.data.getTask, tasks);
                    setTasks(updatedTasks);
                    navigate(TASK_COMPLETION_ROUTE);
                };
            } catch (err) {
                console.error('An error occurred saving the task photo. ', err);
                setLoading(false);
            }
        } else {
            setCompleted(true);
        }
    };

    const handlePhotoRetake = () => {
        document.getElementById("task-photo-input").click();
    };

    const handleBoostTask = async () => {
        await boostTask(task?.taskId);
    };

    const handleClaimTask = async () => {
        navigate(`${TASK_COMPLETION_ROUTE}?type=${task?.task?.name?.toLowerCase().split(' ').join('-')}&id=${task.taskId}`);
    };

    return (
        !pageLoading && <div className='task-completion-page'>
            {/* Checked out user variant */}
            {!employee?.checkedIn && !employee.isManager && <SimpleVariant
                heading="Check In"
                content="You have to check in to Task Force to be able to complete tasks and earn points."
                buttonProps={{
                    buttonText: "Check In",
                    onClick: () => navigate(CHECK_IN_ROUTE),
                    icon: checkInIcon,
                    iconPosition: 'right',
                    iconAltText: 'Check mark'
                }}
            />}

            {/* Task not available variant */}
            {(((employee?.checkedIn || employee.isManager) &&
            !checkIfExpired(task?.task?.startDateTime)) || !searchParams.get('id')) &&
            <SimpleVariant
                heading="Task Unavailable"
                content={`Sorry. ${task?.task?.name || 'Task'} is not available to complete until ${task?.task?.startDateTime ? getLocalTime(task?.task?.startDateTime) : 'specified time'}. But we love how amped you are to do it!`}
                buttonProps={{
                    buttonText: "View Task List",
                    onClick: () => navigate(TASK_LIST_MY_TASKS_ROUTE)
                }}
            />}

            {/* Claim task variant */}
            {(employee?.checkedIn || employee.isManager) && checkIfExpired(task?.task?.startDateTime)  &&
                !!searchParams.get('claimedBy') &&
                <SimpleVariant
                    heading="Claim Task"
                    content={`${task?.task?.name} is assigned to ${task?.role?.name}. Are you sure you want to claim it? You won’t earn as many points, and that’s no fun.`}
                    buttonProps={{
                        buttonText: `Boost Task +${getTotalPointValue(task, 'boosted')}`,
                        onClick: handleBoostTask,
                        icon: boostIcon
                    }}
                    hasTwoButtons
                    buttonTwoProps={{
                        kind: 'ghost',
                        buttonText: `Claim +${getTotalPointValue(task, 'claimed')}`,
                        onClick: handleClaimTask,
                        icon: claimIcon
                    }}
                ><TaskCard task={task?.task} role={task?.role} noCTA /></SimpleVariant>}

            {/* Task Photo */}
            {(employee?.checkedIn || employee.isManager) && checkIfExpired(task?.task?.startDateTime) &&
                !searchParams.get('claimedBy') && !completed && !task?.task?.isTeamTask &&
                <div className="take-task-photo-wrapper">
                    <div className="take-task-photo-banner">
                        <div className="task-name">
                            <img className="task-name-image" src={getTaskIcon(task?.task)} alt="Task name decoration" />
                            <span className="task-name-text">{task?.task?.name}</span>
                        </div>
                    </div>
                    <div className="variant-wrapper page-padding">
                        <h1>Take Photo</h1>
                        <p className='sub-body'>Snap a picture of your completed task.</p>
                        <div className="photo-input-wrapper">
                            <label htmlFor="task-photo-input" className="photo-input-label">
                                <span id="hiddenText">Take a picture</span>
                                <input id="task-photo-input" type="file" accept="image/*" capture="camera" onChange={previewImage} />
                                <img id="photo-input-thumbnail" src={photo} alt="Thumbnail of supplied file" />
                            </label>
                        </div>
                        <div>
                            <Button buttonText="Submit Photo" onClick={handlePhotoSubmit} disabled={btnsDisabled} isLoading={loading} />
                            <Button kind="ghost" buttonText="Retake Photo" onClick={handlePhotoRetake} disabled={btnsDisabled} isLoading={loading} />
                        </div>
                    </div>
                </div>
            }

            {/* Task Completed */}
            {(employee?.checkedIn || employee.isManager) && checkIfExpired(task?.task?.startDateTime) &&
                completed && <div className='completed-page-wrapper page-padding'>
                    <h1>Task Completed!</h1>
                    <TaskSummaryCard task={task} />
                    <hr />
                    {getNextTask(employee, tasks) ?
                        <NextTask task={getNextTask(employee, tasks)} /> :
                        checkIfPerfectShift(getRoleShiftTasks(employee, tasks), employee, location) ?
                            <PerfectShiftCard points={getTotalPointsValue(getTasksCompletedByUser(employee.id, filterShiftTasks(tasks, employee)))} /> :
                            <NoTasks />}
                </div>}
        </div>
    )
};

export default TaskCompletion;