import { useState, useEffect } from 'react';
import { dropDownIcon } from '../../assets/images/images';
import './Accordion.scss';

const AccordionItem = props => {
    const [open, isOpen] = useState(false);
    const [ready, isReady] = useState(false); // Prevents animations from firing on page load

    useEffect(() => {
        if (!ready) isReady(true);
    }, [open]);

    return (
        <div
            className={`accordion${open && ready ? ' open' : ' close'}`}
            onClick={() => {
                isOpen(current => !current);
            }}
        >
            <div className="accordion-heading">
                <span className="accordion-label">{ props.question }</span>
                <span className="accordion-arrow-wrapper">
                    <img className="accordion-arrow" src={ dropDownIcon } alt="Decorative arrow" />
                </span>
            </div>
            <div className="accordion-content-wrapper">
                <div className="accordion-content" dangerouslySetInnerHTML={{ __html: props.answer }}></div>
                
            </div>
        </div>
    )
};

export default AccordionItem;