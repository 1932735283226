import { useState } from 'react';
import { dropDownIcon } from '../../assets/images/images';
import SelectOption from './SelectOption';
import './Select.scss';

const Select = props => {
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.defaultValue);

    const _selectItem = val => {
        setSelectedOption(props.data.filter(item => item.value === val.value)[0]);
        setOpen(false);
        props.onChange && props.onChange({ target: { name: val.label, value: val.value } });
    }
    return (
        <div className={`select-wrapper ${props.className ?? props.className}`}>
            <label
                className={`select-label`}
                htmlFor={props.id ? props.id : `${props.label}-select`}>
                {props.label}
            </label>
            <button
                id={props.id ? props.id : `${props.label}-select`}
                className={`select-main ${!!props.hasError ? ' error' : ''}${props.disabled ? ' disabled' : ''}`}
                name={props.name ? props.name : `${props.label}-select`}
                placeholder={props.placeholder}
                value={props.value}
                onClick={() => setOpen(!open)}>
                <span>{selectedOption ? selectedOption.label : props.placeholder ?? '-- Select an option --'}</span>
                <img className="select-icon" src={dropDownIcon} alt={props.iconAltText || "Drop down Icon"} />
            </button>
            {open &&
                <div className="select-list" role="list">
                    {(!props.data || props.data.length < 1) && <p className='xs page-padding'>Oops, something went wrong!</p>}
                    {props?.data?.map((item, index) => {
                        return (
                            item.value ? <SelectOption
                                onClick={() => _selectItem(item)}
                                selected={selectedOption?.value === item.value}
                                key={index}
                                label={item.label}
                                value={item.value}
                                active={index} />
                                : ''
                        )
                    })}
                </div>
            }
            {open ? (<div className='onCloseOverlay' onClick={() => setOpen(false)} />) : null}
        </div>
    )
};

export default Select;