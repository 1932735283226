import './Message.scss';

const Message = props => {
    return (
        <div className={`message align-items-center border-0 ${props.show ? 'show' : 'hide'} ${props.kind}`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true">
            <div className="d-flex">
                <div className="message-body">{props.message}</div>
            </div>
        </div>
    )
};

export default Message;