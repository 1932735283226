import { useEffect, useState, useContext } from 'react';
import LeaderboardListItem from '../../components/ListItem/LeaderboardListItem';
import TeamBadge from '../../components/Badge/TeamBadge';
import './LeaderboardList.scss';
import { generateClient } from "aws-amplify/api";
import { listLocations } from '../../graphql/queries';
import { UserContext } from '../../Contexts.js';
import { trophyIcon } from "../../assets/images/images";

const LeaderboardList = props => {
    const [locations, setLocations] = useState([]);
    const client = generateClient();
    const { location, employee, challenge } = useContext(UserContext);
    var timerInterval;

    const getLocations = async () => {
        const locResponse = await client.graphql({
            query: listLocations, 
            variables: {}
        });

        // filter by challenge id
        var allLocations = locResponse.data.listLocations.items;
        var myLocations = allLocations.filter( (loc) => {
            return loc.challengeID === challenge?.id;
        });

        if (props.kind === 'weeklyResults') {
            sortLocationsBySprintTrophies(myLocations);
        }  else if (props.kind === 'trophies') {
            sortLocationsByTrophies(myLocations);
        } else {
            sortLocationsByPoint(myLocations);
        }
        setLocations(myLocations);
    }

    const compareLocationsByPoints = (a, b) => {
        if (a.totalPoints > b.totalPoints) {
            return -1;
        }
        return 1;
    }

    const sortLocationsByPoint = (arr) => {
        arr.sort(compareLocationsByPoints);
    }

    const compareLocationsByTrophies = (a, b) => {
        if (a.totalTrophies > b.totalTrophies) {
            return -1;
        }
        return 1;
    }

    const sortLocationsBySprintTrophies = (arr) => {
        arr.sort(compareLocationsBySprintTrophies);
    }


    const compareLocationsBySprintTrophies = (a, b) => {
        if (a.sprintTrophyCount > b.sprintTrophyCount) {
            return -1;
        }
        return 1;
    }

    const sortLocationsByTrophies = (arr) => {
        arr.sort(compareLocationsByTrophies);
    }

    useEffect(() => {

        getLocations();

        // update every 10 seconds
        timerInterval = setInterval(getLocations, 10000);

        return () => {
            // cleanup interval on exit
            clearInterval(timerInterval);
        }
    }, [props.kind]);

    const _renderisFirstResult = (item) => {
        if (!item) return;
        return (
            <div className='result-firstPlace-wrapper' key={'special'}>
                <h4 className='heading-xl'>Store #{locations[0]?.storeNumber}</h4>
                <TeamBadge
                    storeNumber={item?.storeNumber}
                    isUser={employee?.locationID === item?.id}
                    isFirstPlace
                    isUserTeam={employee?.locationID === item?.id} />
                <div className='rank-wrapper'>
                    <p className='bold'>+{item?.sprintTrophyCount}</p>
                    <img className="trophy-icon" src={trophyIcon} alt={props.iconAltText || "List Item Icon"} />
                </div>
            </div>
        )
    };

    const _handleTrophies = (item) => {
        if (props.kind === 'weeklyResults') return JSON.stringify(item?.sprintTrophyCount)
        if (props.kind === 'trophies') return item?.totalTrophies
        return false;
    }

    const NoTrophies = () => {
        return (
            <div className="no-trophies-wrapper">
                <img src={trophyIcon} alt="Confirmation check-mark" />
                <h2>Looking for your trophies?</h2>
                <p className='sub-body'>Check back at the end of each week to see how many trophies your team earned.</p>
            </div>
        )
    };


    if (props.kind === 'trophies' && locations[0]?.totalTrophies === 0) return <NoTrophies />; // should this check against current sprint? Only not show week 1?
    return (
        <>
            {props.kind === 'weeklyResults' && _renderisFirstResult(locations[0])}
            <ul className={`leaderboard-list-wrapper ${props.kind ? props.kind : null} ${props.className || ''}`}>
                {locations?.map((item, index) => {
                    if (props.kind === 'weeklyResults' && index < 1) return null;
                    return (
                        <LeaderboardListItem
                            {...props}
                            key={index + 1}
                            leaderboard={props.kind === 'weeklyResults'}
                            isUser={item.storeNumber === location?.storeNumber}
                            label={item.storeNumber}
                            trophies={_handleTrophies(item)} // for leaderboard this needs to show the difference from the sprint before
                            rank={index + 1}
                            points={(props.kind === 'weeklyResults' || props.kind === "trophies") ? null : item?.totalPoints}
                            data={item} />
                    )

                })}
            </ul>
        </>
    )
}

export default LeaderboardList;
