import { useContext, useEffect } from 'react';
import { UserContext } from '../../Contexts.js';
import { firstPlaceIcon } from '../../assets/images/images.js';
import Portal from './Portal';
import CloseButton from '../Button/CloseButton';
import Avatar from '../Avatar/Avatar';
import TeamBadge from '../Badge/TeamBadge.js';
import './Modal.scss';

const Modal = props => {

    const { open, id, children, title, body, onClose, overflow, kind, icon, data, padding, centerContent, isTeamTask  } = props;
    const { challenge } = useContext(UserContext);

    useEffect(() => {
        //prevents scrolling underneath for mobile & desktop browsers
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            //resets scrolling prevent styles
            document.body.style.overflow = '';
        }
    }, [open]);

    const _handleClose = () => {
        return (
            onClose && onClose()
        )
    }

    const _renderModalHeader = () => {

        if (kind === "shout-out") {
            return (
                <div className={`modal-top-wrapper shout-out`}>
                    {isTeamTask ? <TeamBadge storeNumber={props.location?.storeNumber}/> : <Avatar size="lg" employee={data} />}
                    <div className={`modal-title-wrapper shout-out`}>
                        <h5 className='modal-title heading-m'>{title}</h5>
                        <p className='body'>{body}</p>
                    </div>
                    <CloseButton
                        color={"var(--color-base)"}
                        onClick={_handleClose}
                        altText={"Modal Close Button"} />
                </div>
            )
        }

        if (kind === "vote") {
            return (
                <div className={`modal-top-wrapper vote`}>
                    <img className="team-banner-img" src={firstPlaceIcon} alt={''} />
                    <div className={`modal-title vote`}>
                        Vote for the {challenge.getCurrentSprint().name} MVP!
                    </div>
                    <CloseButton
                        color={"var(--color-primary)"}
                        onClick={onClose}
                        altText={"Modal Close Button"} />
                </div>
            )
        }

        return (
            <div className={`modal-top-wrapper ${kind}`}>
                <div className={`modal-title-wrapper ${kind}`}>
                    {icon &&
                        <img className="special-icon" src={icon} alt={"Special Icon"} />
                    }
                    <h5 className='modal-title heading-m'>{title}</h5>
                </div>
                <CloseButton
                    color={kind === 'special' ? "var(--color-primary)" : 'var(--color-base)'}
                    altText={"Modal Close Button"}
                    onClick={_handleClose} />
            </div>
        )
    };

    let modalId;
    if (id) modalId = id;
    else modalId = 'modal';

    return (
        <Portal portalId={modalId}>
            {open &&
                <div className="modal-wrapper" onClick={() => _handleClose()}>
                    <div className="modal-overlay">
                        <div onClick={e => e.stopPropagation()}  className={`modal-inner-wrapper ${overflow && "overflow"}`}>
                            {_renderModalHeader()}
                            <div className={`modal-content-wrapper ${padding ? 'padding' : ''} ${centerContent ? 'center-content' : ''}`}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Portal>
    )
}

export default Modal;
