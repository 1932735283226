// Broke apart from the TaskCard component for readability

import { getLocalTime, checkIfExpired, getDiffText, getTimeDiff } from '../../utils/dateUtils.js';
import { alertIcon } from '../../assets/images/images.js';
import './TaskCard.scss';

const TaskCardTop = props => {
    return (
        <div className={`task-card-header${props.task?.completed ? ' completed' : ''}${props.task?.rejected || (checkIfExpired(props.task?.expiredDateTime) && !props.task?.completed) ? ' rejected' : ''}`}>
            <div className="task-card-header-content">
                {/* YOU tag */}
                {(props.checkIfHasRole() && !props.task?.isTeamTask) && <span className="current-user-tag">YOU</span>}

                {/* Role Name */}
                <span className="task-card-role-name">{props.task?.isTeamTask ? 'Team' : props.task?.roles?.items.some(role => role.role?.name === props.employee?.role?.name) ? props.employee?.role?.name.split('-')[1].trim() : props.role?.name.split('-')[1].trim()}</span>

                {!props.task?.completed && !checkIfExpired(props.task?.expiredDateTime) ? 
                    // Available / Expired @ Time
                    <div className="task-card-time-limit">
                        <span className="time-limit-text">{
                            Date.now() <= new Date(props.task?.startDateTime) ?
                                `Available at ${getLocalTime(props.task?.startDateTime)}` :
                                `Expires in ${getDiffText(getTimeDiff(props.task?.expiredDateTime, Date.now()))}`
                        }</span>
                        {checkIfExpired(props.task?.lateDateTime) && !props.task?.completed && <img className="time-limit-alert-img" src={alertIcon} alt="Expiration alert notification" />}
                    </div>
                    :
                    // Completed by @ time / Expired @ time tag
                    <div className="task-card-completed-tag">
                        {props.task?.completed ? `${
                            props.task?.completedBy?.userID === props.employee?.userID ?
                            "You" : props.task?.isTeamTask ? 'Team' :
                                props.task?.completedBy?.firstName + ' ' + props.task?.completedBy?.lastName.charAt(0).toUpperCase() + "."
                            } @ ${getLocalTime(props.task?.completedAt)}` :
                            `Expired @ ${getLocalTime(props.task?.expiredDateTime)}`
                        }
                    </div>
                }
            </div>

            {/* Rejection/Expiration Message */}
            {(props.task?.rejected || (checkIfExpired(props.task?.expiredDateTime) && !props.task?.completed)) &&
                <div className="task-card-reject-message">
                    {props.task?.rejected ? 
                        `This task has been rejected: ${props.task?.rejectionReason ? props.task?.rejectionReason : 'It did not meet the required standards.'}` :
                        "This task was not completed in time and has expired."
                    }
                </div>
            }
        </div>
    )
};

export default TaskCardTop;