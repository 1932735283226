import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { rolesByLocationID } from '../../graphql/queries';
import { updateEmployee, createShift, updateTask } from '../../graphql/mutations';
import { UserContext } from '../../Contexts.js';
import { DASHBOARD_ROUTE } from '../../Routes.js';
import { checkIfExpired, formatDate, formatTime } from '../../utils/dateUtils.js';
import { checkInIcon } from '../../assets/images/images.js';
import Select from '../../components/Select/Select.js';
import TimePicker from '../../components/Picker/TimePicker.js';
import Button from '../../components/Button/Button.js';
import './Checkin.scss';
import { trackEvent } from '../../utils/anayticsUtils.js';
import { getTask } from '../../graphql/queries';
import { updateLocalTask } from '../../utils/taskUtils.js';

const Checkin = () => {
	const { employee, setEmployee, location, tasks, subscribe, sendNotification } = useContext(UserContext);
	const [roles, setRoles] = useState([]);
	const [selectedRoleID, setSelectedRoleID] = useState('');
	const [selectedRoleName, setSelectedRoleName] = useState('');
	const [shiftEndTime, setShiftEndTime] = useState('');
	const [loading, setLoading] = useState(false);
	const [isDisabled, setDisabled] = useState(true);
	const client = generateClient();
	const navigate = useNavigate();

	useEffect(() => {
		initRoles();
	}, [employee, location]);

	useEffect(() => {
		if (selectedRoleID === "" || shiftEndTime === "") {
			setDisabled(true);
		} else {
			setDisabled(false);
		};
	}, [selectedRoleID, shiftEndTime]);

	const initRoles = () => {

		if (!employee || !location) return;

		// we have both employee and location (with its roles) now
		// we used to fetch roles here, but we're already getting them from context
		setRoles(location.roles?.items);

		if (employee.defaultRole) {
			setSelectedRoleID(employee.defaultRole.id);
			setSelectedRoleName(employee.defaultRole.name);
		};
	};

	const employeePromise = async () => {
		// console.log('updating employee...', employee.userID);
		// console.log('selectedRole:', selectedRoleName);

		setLoading(true);
		try {
			const shiftStartTime = formatTime(Date.now());
			const res = await client.graphql({ query: createShift, variables: { input: { startTime: shiftStartTime, endTime: shiftEndTime } } });
			const shift = res.data.createShift;
			const response = await client.graphql({
				query: updateEmployee, variables: {
					input: {
						id: employee.id,
						employeeRoleId: selectedRoleID,
						checkedIn: true,
						employeeShiftId: shift.id
					}
				}
			}).catch((err) => {
				console.log(err);
			});
			const updatedData = response.data.updateEmployee;
			var notificationDate = new Date(`${formatDate(Date.now())} ${shiftEndTime}`);

			await Promise.all(tasks.map(async task => {

				let shiftEndDate = new Date(`${formatDate(Date.now())} ${shiftEndTime}`);
				let shiftStartDate = new Date(`${formatDate(Date.now())} ${shiftStartTime}`);
				if (shiftEndDate < shiftStartDate) {
					// Add one day to shift end
					shiftEndDate = shiftEndDate.setDate(shiftEndDate.getDate() + 1);
					// update notification date
					notificationDate = shiftEndDate;
				}

				// only update the eligibility lists of tasks in our shift
				
				// a. task starts after my shift starts AND
				// b. task starts before my shift ends

				// OR

				// c. task expires after my shift starts AND
				// d. task expires before my shift ends
				if (
					(checkIfExpired(shiftStartDate, task.task.startDateTime) && checkIfExpired(task.task.startDateTime, shiftEndDate)) ||
					(checkIfExpired(shiftStartDate, task.task.expiredDateTime) && checkIfExpired(task.task.expiredDateTime, shiftEndDate))
				) {

					// fetch latest
					var latestTask;
					const dbTaskRequest = await client.graphql({
						query: getTask,
						variables: { id: task.taskId }
					});
					latestTask = dbTaskRequest.data.getTask;

					const employeesArr = latestTask.eligibleEmployeeIDs || [];

					// if task belongs to the selected role AND I am NOT eligible for this task already,
					// add me to the task:
					const hasTask = latestTask.roles.items.some(role => role.roleId === selectedRoleID);
					if (hasTask && !employeesArr.includes(employee.id)) {
						await client.graphql({ query: updateTask, variables: { input: { id: latestTask.id, eligibleEmployeeIDs: [...employeesArr, employee.id] } } });
					};

					const teamEmployeesArr = latestTask.teamEligibleIDs || [];

					// if I am NOT eligible for this TEAM task already,
					// add me to the team task:
					if (!teamEmployeesArr.includes(employee.id)) {
						await client.graphql({ query: updateTask, variables: { input: { id: latestTask.id, teamEligibleIDs: [...teamEmployeesArr, employee.id] } } });
					};

					updateLocalTask(latestTask, tasks);
				}

			})); // Add employee to every available task's eligibleEmployeeIDs list AND task's teamEligibleIDs list

			setEmployee({ ...updatedData }); // Set employee data
			subscribe(selectedRoleID); // Subscribe to role topic


			// schedule a notification for end of shift telling employee to sign out
			sendNotification(
                'PLEASE CHECK OUT',
				'Great work today! Please check out now to complete today’s Task Force challenge.',
                '',
                employee.userID,
				null,
                notificationDate
            );

			//analytics
			trackEvent('check_in', employee.id, location.storeNumber, {"employee_role": selectedRoleName});

			navigate(DASHBOARD_ROUTE); // Navigate to dashboard
		} catch (err) {
			console.error('An error occurred while updating employee data.', err);
			setLoading(false);
		};
	};

	const handleSelectChange = (e) => {
		setSelectedRoleID(e.target.value);
		setSelectedRoleName(e.target.name);
	};

	const handlePickerChange = (e) => {
		setShiftEndTime(e.target.value);
	}

	return (

		// we need both employee and roles to render
		employee && roles && <div id="checkinView" className='page-padding'>
			<h1>Check In</h1>

			<Select
				className="check-in-input"
				label="What's your role today?"
				defaultValue={{
					value: employee?.defaultRole?.id || "",
					label: employee?.defaultRole?.name || "Choose a role"
				}}
				onChange={handleSelectChange}
				value={selectedRoleID}
				data={roles?.map(role => {
					return {
						label: role.name,
						value: role.id
					}
				})}
			/>

			<TimePicker
				className="check-in-input"
				label="What time does your shift end?"
				value={shiftEndTime}
				onChange={handlePickerChange}
			/>

			<Button
				className="checkin-btn"
				buttonText="Check In"
				icon={checkInIcon}
				iconPosition="right"
				iconAltText="Check mark"
				onClick={employeePromise}
				isLoading={loading}
				disabled={isDisabled}
			/>
		</div>
	)
};

export default Checkin;