import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../Contexts.js';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './ProfilePhotoModal.scss';
import { savePhoto } from '../../utils/fileUtils';
import Modal from '../../components/Modal/Modal';
import { updateEmployee } from '../../graphql/mutations.js';
import { generateClient } from "aws-amplify/api";
import { trackEvent } from '../../utils/anayticsUtils';

const ProfilePhotoModal = (props) => {
    const [photo, setPhoto] = useState(null);
    const [file, setFile] = useState(null);
    const [btnsDisabled, setBtnsDisabled] = useState(false);
    const { onClose } = props;
    const client = generateClient();
    const { employee, setEmployee, location } = useContext(UserContext);

    useEffect(() => {
        const img = document.getElementById('photo-input-thumbnail');
        const wrapper = document.getElementsByClassName('photo-input-wrapper')[0];
        if (img && wrapper) {
            if (!file) {
                img.style.display = 'none';
            } else {
                img.style.display = 'block';
            };

            if (!file) {
                setBtnsDisabled(true);
                wrapper.style.border = '1px';
            } else {
                setBtnsDisabled(false);
                wrapper.style.border = '0';
            };
        };
    }, [file]);

    const previewImage = () => {
        const currentFile = document.getElementById("task-photo-input").files[0];
        if (currentFile) {
            setFile(currentFile);
            const oFReader = new FileReader();
            oFReader.readAsDataURL(currentFile);
            oFReader.onload = function (oFREvent) {
                setPhoto(oFREvent.target.result);
            };
        };
    };

    const handlePhotoSubmit = async () => {
        savePhoto(file).then(
            (result) => {
                console.info('>> result:', result);
                if (result.key != null) {
                    updateEmployeePhoto(result.key);
                    onClose();
                }
            }
        );
    };

    const updateEmployeePhoto = async (filename) => {
        const response = await client.graphql({
            query: updateEmployee,
            variables: {
                input: {
                    id: employee.id,
                    photoURL: filename
                }
            }
        });
        // name updated, track analytics
        trackEvent('edit_profile', employee.id, location.storeNumber);
        setEmployee(response.data.updateEmployee);
    }

    const handlePhotoRetake = () => {
        document.getElementById("task-photo-input").click();
    };

    return (
        <Modal
            {...props}
            id={'edit-photo-modal'}
            centerContent={true}
            onClose={onClose}
            padding={true}
            title="Update Photo">

            {/* Take Photo */}
            <div className="take-profile-photo-wrapper">
                <div className="photo-input-wrapper">
                    <label htmlFor="task-photo-input" className="photo-input-label">
                        <span id="hiddenText">Take a picture</span>
                        <input id="task-photo-input" type="file" accept="image/*" onChange={previewImage} />
                        <div id="photo-input-thumbnail" style={{ backgroundImage: 'url(' + photo + ')' }} ></div>
                        <p className='sub-body'>Choose a photo</p>
                    </label>
                </div>
                <div>
                    <Button buttonText="Submit Photo" onClick={handlePhotoSubmit} disabled={btnsDisabled} />
                    <Button kind="ghost" buttonText="Retake Photo" onClick={handlePhotoRetake} disabled={btnsDisabled} />
                </div>
            </div>
        </Modal>
    )
};

export default ProfilePhotoModal;