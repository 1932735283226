import Button from '../Button/Button';
import { checkInIcon, firstPlaceIcon } from '../../assets/images/images';

const TeamBanner = (props) => {

    return (
        <div className='team-banner-wrapper'>
            <div className='team-banner-left'>
                {props.kind === "vote" && <img className="team-banner-img" src={firstPlaceIcon} alt={''} />}
                <div className='team-banner-text'>
                    <p className="heading-s">{props.kind === "check-in" ? 'Check in now to get your task list and start earning points!' : 'Vote for your MVP!'}</p>

                    {/* Countdown timer should display for last
                    10 hours of the voting period. */}
                    {props.kind === "vote" && props.showVoteCountdown && <p className="xs bold">Expires in {props.voteHoursRemaining ?? 'XX'} hours</p>}

                </div>
            </div>
            <Button
                buttonText={props.kind === "check-in" ? 'Check In' : 'Vote Now'}
                size="sm"
                type="submit"
                onClick={props.onButtonPress} icon={props.kind === "check-in" ? checkInIcon : null} />
        </div>
    )
}

export default TeamBanner;
