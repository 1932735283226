import { boostIcon, shoutOutIcon, spotLightIcon } from '../../assets/images/images.js';
import Avatar from '../Avatar/Avatar';
import './MentionCard.scss';

const MentionCard = ({ mention }) => {

    let { toEmployeeID, fromEmployeeID, type, description } = mention;

    const _handleTimeDifference = () => {
        let sentOn = Date.parse(mention.createdAt);
        let now = new Date();

        let diffTime = Math.abs(now - sentOn);
        let diffMins = Math.floor(diffTime / (1000 * 60));
        let diffTotalHours = Math.floor(diffTime / (1000 * 60 * 60));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffTotalHours > 23) return (`${diffDays} days ago`)
        if (diffMins > 59) return (`${diffTotalHours} hours ago`)
        return (`${diffMins} minutes ago`);
    }
    
    return (
        <div className="mention-card">
            <div className="mention-card-header">
                <Avatar size="md" employee={mention.toEmployeeID} />
                <p className="mention-card-header-name">
                    {(toEmployeeID?.firstName && toEmployeeID?.lastName) ?
                        toEmployeeID?.firstName + ' ' + toEmployeeID?.lastName : "Unknown User"}
                </p>

                <img
                    className="mention-card-justify-right"
                    src={
                        type === "BOOST" ?
                            boostIcon :
                            type === "SPOTLIGHT" ?
                                spotLightIcon :
                                shoutOutIcon
                    }
                    alt={type + ' icon'}
                />
            </div>
            <p className="mention-card-content">{description}</p>
            <hr />
            <div className="mention-card-footer">
                <p className="mention-card-footer-time">{_handleTimeDifference()}</p>

                {fromEmployeeID ? <div className="mention-card-justify-right">
                    <p className="mention-card-footer-name">
                        {(fromEmployeeID?.firstName && fromEmployeeID?.lastName) ?
                            fromEmployeeID?.firstName + ' ' + fromEmployeeID?.lastName : "Unknown User"}
                    </p>

                    <Avatar size="sm" employee={fromEmployeeID} />
                </div> : ''}
            </div>
        </div>
    )
};

export default MentionCard;