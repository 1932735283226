import { arrowRightIcon } from "../../assets/images/images";
import './InsightsListItem.scss';

const InsightsListItem = props => {

    return (
        <>
            <button
                className={`insights-list-item-wrapper`}
                onClick={props.onClick}>
                <img className="insight-category-icon" src={props.icon} alt={props.iconAltText || "List Item Icon"} />
                <div className="left-wrapper">
                    <p className="insights-item-label">{props.label}</p>
                    <p className="insights-item-detail">{props.detail}</p>
                </div>
                <div className="right-wrapper">
                    <img className="list-item-icon" src={arrowRightIcon} alt={props.iconAltText || "List Item Icon"} />
                </div>
            </button>
        </>
    )
};

export default InsightsListItem;