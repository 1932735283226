import { useState, useContext } from 'react';
import { spotLightIcon, spotLightDisabledIcon } from '../../assets/images/images';
import { useLocation, useNavigate} from 'react-router-dom';
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input.js';
import { UserContext } from '../../Contexts.js';
import { generateClient } from "aws-amplify/api";
import { createTaskMention } from '../../utils/taskUtils.js';
import { EMPLOYEE_REPORT_INSIGHTS_ROUTE } from '../../Routes.js';
import './Insights.scss';

const SpotlightEmployee = (props) => {
    const { employee, challenge } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const client = generateClient();
    const data = location.state;
    const [message, setMessage] = useState("");

    const createSpotlight = async () => {
        try {
            await createTaskMention({
                mentionType: "SPOTLIGHT",
                shoutOutType: null,
                client,
                toEmployeeId: data.id,
                fromEmployeeId: employee.id,
                task: null,
                challenge,
                description: message
            }).then(response => { return response })
            .then(responseData => {
                navigate(EMPLOYEE_REPORT_INSIGHTS_ROUTE + '?id=' + data.id, {state:data});
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="insights-page">
            <div className="insights-page-header">
                <Breadcrumb employeeData={data}/>
                <div className="insights-header-title">
                    <h1>Spotlight Employee</h1>
                </div>
            </div>
            <div className="insights-body">
                <p className="sub-body">
                    Let {data?.firstName} {data?.lastName}  know how much you appreciate their great work by putting them in the Task Force Spotlight!
                </p>
                <Input 
                    characterCounter
                    currentCharacterLength={message.length}
                    maxLength={999}
                    required
                    onChange={e => setMessage(e.target.value)}
                    label="Share a brief note about this employee:"
                    placeholder="Example: thank you for doing such a great job and completing all of your tasks on time!"
                    type={'textArea'}
                    helperText="This message will be visible to everyone."/>
             <Button icon={!message ? spotLightDisabledIcon : spotLightIcon} buttonText='Spotlight' onClick={createSpotlight} disabled={!message}/>

            </div>
        </div>
    )
};

export default SpotlightEmployee;