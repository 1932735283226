import { useContext, useEffect } from 'react';
import { UserContext } from '../../Contexts';
import Avatar from '../Avatar/Avatar';
import { trackEvent } from '../../utils/anayticsUtils';
import './PerfectShiftCard.scss';

const PerfectShiftCard = ({ points = 0 }) => {
    const { employee, location } = useContext(UserContext);

    useEffect(() => {
        // analytics
        trackEvent('perfect_shift', employee?.id, location?.storeNumber);
    },[])

    return (
        <div className="perfect-shift-card page-padding">
            <Avatar celebration={true} className="xl" employee={employee} />
            <div className='text-block'>
                <h2 className='heading-l'>You Had a Perfect Shift!</h2>
                <p className='sub-body'>Congratulations on completing all of your tasks this shift. You earned {points} points for Store #{location?.storeNumber}!</p>
            </div>
        </div>
    )
};

export default PerfectShiftCard;