import React from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { capitalizeWord } from "../../utils/stringUtils.js";
import './Breadcrumb.scss';

const Breadcrumb = props => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    
    const getFullPath = index => {
        const crumbs = location.pathname.slice(1).split('/');
        crumbs.length = index;
        let fullPath = crumbs.join('/');
        if (searchParams.get('id')) {
            fullPath = fullPath + '?id=' + searchParams.get('id');
        }
        return `/${fullPath}`;
    };

    return (
        <div className="breadcrumb-wrapper">
            {location.pathname.slice(1).split('/').map((path, index) => {
                if (index !== location.pathname.slice(1).split('/').length - 1) {
                    return <div key={path}>
                        <span>
                            <NavLink className="breadcrumb-link btn-link" to={getFullPath(index + 1)}>
                                {path === 'employee-report' ? props.employeeData?.firstName  + ' ' + props.employeeData?.lastName.substring(0,1) + '.': capitalizeWord(path.replace(/-/g, ' '))}
                            </NavLink>
                        </span>
                        <span className="breadcrumb-divider">/</span>
                    </div>
                };
                return <div key={path} />;
            })}
        </div>
    )
};

export default Breadcrumb;