/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPerformanceMetric = /* GraphQL */ `
  query GetPerformanceMetric($id: ID!) {
    getPerformanceMetric(id: $id) {
      id
      employee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      type
      data
      title
      content
      createdAt
      updatedAt
      performanceMetricEmployeeId
      __typename
    }
  }
`;
export const listPerformanceMetrics = /* GraphQL */ `
  query ListPerformanceMetrics(
    $filter: ModelPerformanceMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformanceMetrics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        type
        data
        title
        content
        createdAt
        updatedAt
        performanceMetricEmployeeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($id: ID!) {
    getLeaderboard(id: $id) {
      id
      sprint {
        id
        challengeID
        startDateTime
        name
        mvpVotes {
          nextToken
          __typename
        }
        sprintIndex
        mvpEmployee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        createdAt
        updatedAt
        sprintMvpEmployeeId
        __typename
      }
      locationWinnerID
      mostThanksEmployeeID
      mostBoostsEmployeeID
      mvpEmployeeID
      mostThanksCount
      mostBoostsCount
      mvpWinnerVoteCount
      snapshot
      createdAt
      updatedAt
      leaderboardSprintId
      __typename
    }
  }
`;
export const listLeaderboards = /* GraphQL */ `
  query ListLeaderboards(
    $filter: ModelLeaderboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaderboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sprint {
          id
          challengeID
          startDateTime
          name
          sprintIndex
          createdAt
          updatedAt
          sprintMvpEmployeeId
          __typename
        }
        locationWinnerID
        mostThanksEmployeeID
        mostBoostsEmployeeID
        mvpEmployeeID
        mostThanksCount
        mostBoostsCount
        mvpWinnerVoteCount
        snapshot
        createdAt
        updatedAt
        leaderboardSprintId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMVPVote = /* GraphQL */ `
  query GetMVPVote($id: ID!) {
    getMVPVote(id: $id) {
      id
      sprintID
      submittedByID
      votedForID
      storeNumber
      sprintIndex
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMVPVotes = /* GraphQL */ `
  query ListMVPVotes(
    $filter: ModelMVPVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMVPVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sprintID
        submittedByID
        votedForID
        storeNumber
        sprintIndex
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mVPVotesBySprintID = /* GraphQL */ `
  query MVPVotesBySprintID(
    $sprintID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMVPVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mVPVotesBySprintID(
      sprintID: $sprintID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sprintID
        submittedByID
        votedForID
        storeNumber
        sprintIndex
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallenge = /* GraphQL */ `
  query GetChallenge($id: ID!) {
    getChallenge(id: $id) {
      id
      name
      sprints {
        items {
          id
          challengeID
          startDateTime
          name
          sprintIndex
          createdAt
          updatedAt
          sprintMvpEmployeeId
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          totalPoints
          totalTrophies
          openingTime
          closingTime
          city
          storeNumber
          challengeID
          sprintTrophyCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintDuration
      currentSprintID
      previousSprintID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChallenges = /* GraphQL */ `
  query ListChallenges(
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sprints {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        sprintDuration
        currentSprintID
        previousSprintID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSprint = /* GraphQL */ `
  query GetSprint($id: ID!) {
    getSprint(id: $id) {
      id
      challengeID
      startDateTime
      name
      mvpVotes {
        items {
          id
          sprintID
          submittedByID
          votedForID
          storeNumber
          sprintIndex
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintIndex
      mvpEmployee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      createdAt
      updatedAt
      sprintMvpEmployeeId
      __typename
    }
  }
`;
export const listSprints = /* GraphQL */ `
  query ListSprints(
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSprints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        challengeID
        startDateTime
        name
        mvpVotes {
          nextToken
          __typename
        }
        sprintIndex
        mvpEmployee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        createdAt
        updatedAt
        sprintMvpEmployeeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sprintsByChallengeID = /* GraphQL */ `
  query SprintsByChallengeID(
    $challengeID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sprintsByChallengeID(
      challengeID: $challengeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        challengeID
        startDateTime
        name
        mvpVotes {
          nextToken
          __typename
        }
        sprintIndex
        mvpEmployee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        createdAt
        updatedAt
        sprintMvpEmployeeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamTask = /* GraphQL */ `
  query GetTeamTask($id: ID!) {
    getTeamTask(id: $id) {
      id
      description
      pointValue
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      rejected
      rejectionReason
      iconURL
      intervalMinutes
      completedEmployeeIDs
      eligibleEmployeeIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeamTasks = /* GraphQL */ `
  query ListTeamTasks(
    $filter: ModelTeamTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        pointValue
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        rejected
        rejectionReason
        iconURL
        intervalMinutes
        completedEmployeeIDs
        eligibleEmployeeIDs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      pointValue
      claimAvailable
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      boostAvailable
      boosted
      boostedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      boostedModifier
      lateModifier
      claimModifier
      rejected
      rejectionReason
      iconURL
      photoURL
      shoutOutPointValue
      completedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      claimedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      roles {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      interval
      completedAt
      mentions {
        items {
          id
          type
          description
          taskID
          shoutOutType
          sprintID
          spotlightShoutOutCount
          hasShoutOut
          createdAt
          updatedAt
          mentionFromEmployeeIDId
          mentionToEmployeeIDId
          __typename
        }
        nextToken
        __typename
      }
      eligibleEmployeeIDs
      boostDateTime
      isTeamTask
      teamCompletedIDs
      teamEligibleIDs
      locationID
      createdAt
      updatedAt
      taskBoostedById
      taskCompletedById
      taskClaimedById
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        pointValue
        claimAvailable
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        boostAvailable
        boosted
        boostedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        boostedModifier
        lateModifier
        claimModifier
        rejected
        rejectionReason
        iconURL
        photoURL
        shoutOutPointValue
        completedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        claimedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        roles {
          nextToken
          __typename
        }
        interval
        completedAt
        mentions {
          nextToken
          __typename
        }
        eligibleEmployeeIDs
        boostDateTime
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        locationID
        createdAt
        updatedAt
        taskBoostedById
        taskCompletedById
        taskClaimedById
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      question
      answer
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      id
      startTime
      endTime
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startTime
        endTime
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMention = /* GraphQL */ `
  query GetMention($id: ID!) {
    getMention(id: $id) {
      id
      type
      description
      fromEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      toEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      taskID
      shoutOutType
      sprintID
      spotlightShoutOutCount
      hasShoutOut
      createdAt
      updatedAt
      mentionFromEmployeeIDId
      mentionToEmployeeIDId
      __typename
    }
  }
`;
export const listMentions = /* GraphQL */ `
  query ListMentions(
    $filter: ModelMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        description
        fromEmployeeID {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        toEmployeeID {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        taskID
        shoutOutType
        sprintID
        spotlightShoutOutCount
        hasShoutOut
        createdAt
        updatedAt
        mentionFromEmployeeIDId
        mentionToEmployeeIDId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mentionsByTaskID = /* GraphQL */ `
  query MentionsByTaskID(
    $taskID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentionsByTaskID(
      taskID: $taskID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        description
        fromEmployeeID {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        toEmployeeID {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        taskID
        shoutOutType
        sprintID
        spotlightShoutOutCount
        hasShoutOut
        createdAt
        updatedAt
        mentionFromEmployeeIDId
        mentionToEmployeeIDId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShoutOut = /* GraphQL */ `
  query GetShoutOut($id: ID!) {
    getShoutOut(id: $id) {
      id
      phrase
      count
      style
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShoutOuts = /* GraphQL */ `
  query ListShoutOuts(
    $filter: ModelShoutOutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShoutOuts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phrase
        count
        style
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      employees {
        items {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        nextToken
        __typename
      }
      roles {
        items {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      totalTrophies
      openingTime
      closingTime
      city
      storeNumber
      challengeID
      sprintTrophyCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        employees {
          nextToken
          __typename
        }
        roles {
          nextToken
          __typename
        }
        totalPoints
        totalTrophies
        openingTime
        closingTime
        city
        storeNumber
        challengeID
        sprintTrophyCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const locationsByChallengeID = /* GraphQL */ `
  query LocationsByChallengeID(
    $challengeID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByChallengeID(
      challengeID: $challengeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        employees {
          nextToken
          __typename
        }
        roles {
          nextToken
          __typename
        }
        totalPoints
        totalTrophies
        openingTime
        closingTime
        city
        storeNumber
        challengeID
        sprintTrophyCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      tasks {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locationID
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rolesByLocationID = /* GraphQL */ `
  query RolesByLocationID(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rolesByLocationID(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
      email
      phone
      photoURL
      isManager
      locationID
      userID
      checkedIn
      defaultRole {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      shift {
        id
        startTime
        endTime
        name
        createdAt
        updatedAt
        __typename
      }
      receivedBoostNotification
      createdAt
      updatedAt
      employeeDefaultRoleId
      employeeRoleId
      employeeShiftId
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employeesByLocationID = /* GraphQL */ `
  query EmployeesByLocationID(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByLocationID(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleTask = /* GraphQL */ `
  query GetRoleTask($id: ID!) {
    getRoleTask(id: $id) {
      id
      taskId
      roleId
      task {
        id
        name
        description
        pointValue
        claimAvailable
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        boostAvailable
        boosted
        boostedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        boostedModifier
        lateModifier
        claimModifier
        rejected
        rejectionReason
        iconURL
        photoURL
        shoutOutPointValue
        completedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        claimedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        roles {
          nextToken
          __typename
        }
        interval
        completedAt
        mentions {
          nextToken
          __typename
        }
        eligibleEmployeeIDs
        boostDateTime
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        locationID
        createdAt
        updatedAt
        taskBoostedById
        taskCompletedById
        taskClaimedById
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoleTasks = /* GraphQL */ `
  query ListRoleTasks(
    $filter: ModelRoleTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        roleId
        task {
          id
          name
          description
          pointValue
          claimAvailable
          startDateTime
          completed
          lateDateTime
          expiredDateTime
          boostAvailable
          boosted
          boostedModifier
          lateModifier
          claimModifier
          rejected
          rejectionReason
          iconURL
          photoURL
          shoutOutPointValue
          interval
          completedAt
          eligibleEmployeeIDs
          boostDateTime
          isTeamTask
          teamCompletedIDs
          teamEligibleIDs
          locationID
          createdAt
          updatedAt
          taskBoostedById
          taskCompletedById
          taskClaimedById
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleTasksByTaskId = /* GraphQL */ `
  query RoleTasksByTaskId(
    $taskId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoleTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleTasksByTaskId(
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        roleId
        task {
          id
          name
          description
          pointValue
          claimAvailable
          startDateTime
          completed
          lateDateTime
          expiredDateTime
          boostAvailable
          boosted
          boostedModifier
          lateModifier
          claimModifier
          rejected
          rejectionReason
          iconURL
          photoURL
          shoutOutPointValue
          interval
          completedAt
          eligibleEmployeeIDs
          boostDateTime
          isTeamTask
          teamCompletedIDs
          teamEligibleIDs
          locationID
          createdAt
          updatedAt
          taskBoostedById
          taskCompletedById
          taskClaimedById
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleTasksByRoleId = /* GraphQL */ `
  query RoleTasksByRoleId(
    $roleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoleTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleTasksByRoleId(
      roleId: $roleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        roleId
        task {
          id
          name
          description
          pointValue
          claimAvailable
          startDateTime
          completed
          lateDateTime
          expiredDateTime
          boostAvailable
          boosted
          boostedModifier
          lateModifier
          claimModifier
          rejected
          rejectionReason
          iconURL
          photoURL
          shoutOutPointValue
          interval
          completedAt
          eligibleEmployeeIDs
          boostDateTime
          isTeamTask
          teamCompletedIDs
          teamEligibleIDs
          locationID
          createdAt
          updatedAt
          taskBoostedById
          taskCompletedById
          taskClaimedById
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
