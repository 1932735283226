import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_REPORT_INSIGHTS_ROUTE } from '../../Routes.js';
import { ListItem } from '../../components/ListItem';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import './EmployeesList.scss';
import '../../components/List/List.scss';

const EmployeeList = props => {
    const { data = [], paginationAmount = 10 } = props;
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([props.data]);
    const [showLength, setShowLength] = useState(paginationAmount);
    const navigate = useNavigate();

    const _handleItemClick = (item) => {
        navigate(EMPLOYEE_REPORT_INSIGHTS_ROUTE+'?id=' + item.id, {
            state: {
                employeeData: item,
                daily: props.daily,
                restaurant: props.restaurant,
                employee: props.employee
            } });
    }

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        const results = props.data?.filter((item) => {
            let fullName = item.firstName + ' ' + item.lastName;
            return fullName.toLowerCase().includes(searchValue.toLowerCase()) && !item.isManager;
        });

        const sorted = results?.sort((a, b) => {
            const aName = a.firstName.toLowerCase() + ' ' + a.lastName.toLowerCase();
            const bName = b.firstName.toLowerCase() + ' ' + b.lastName.toLowerCase();
            return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
        }); // Sort employees alphabetically
        setFilteredData(sorted);

    }, [searchValue,props.data])

    const _handleButtonPress = () => {
        if (!props.paginate) return;
        else {
            if (showLength + paginationAmount > data?.length) {
                setShowLength(data?.length);
            } else {
                setShowLength(showLength + paginationAmount);
            };
        };
    };

    return (
        <div className={`employees-section-wrapper ${props.hideTitle ? 'noTitle' : ''}`}>
            <div className='employees-top-block'>
                {!props.hideTitle && <h2 className='heading-section'>Employees</h2>}
                <div className='search-input-wrapper'>
                    <Input
                        placeholder="Search First or Last Name"
                        value={searchValue}
                        onChange={handleInputChange} />
                    <Button size="md" buttonText="Search" onClick={() => { }} />
                </div>
            </div>
            {(!data || data.length < 1) ?
                <p className='sub-body s'> No employees to show right now.</p>
                : <>
                    {/* TODO: Search functionality within the list */}
                    {filteredData.length === 0 ?
                        <p className='empty-list sub-body'>Hmmm ... We can’t find a match for that. Please check your search for any spelling errors or typos and try again.</p>
                        : <ul className={`list-wrapper employee`}>
                            {filteredData.map((item, index) => {
                                if (!item) return;
                                if (item.isManager) return;

                                // With this, we can pass just the paginationAmount to limit the size of a stand alone list.
                                // So long as we don't pass the paginate prop. If both props are passed, then we won't receive the View All Mentions button
                                if ( ((index + 1) <= showLength) || (!props.paginate && !props.paginationAmount)) {
                                    return <ListItem
                                        key={index}
                                        onClick={() => _handleItemClick(item)}
                                        kind="employee"
                                        data={item} />
                                }
                            })}
                            <div className='button-wrapper'>
                            {props.paginate && showLength < filteredData.length && 
                                <Button 
                                buttonText='Show More' 
                                onClick={() => _handleButtonPress()} />}
                            </div>
                        </ul>}
                </>
            }

        </div>
    )
};

export default EmployeeList;