import { trophyIcon } from "../../assets/images/images";
import LeaderboardAvatar from "../Avatar/LeaderboardAvatar";
import './LeaderboardListItem.scss';
import './ListItem.scss';

const LeaderboardListItem = props => {
    const handleRankAndPoints = () => {
        return (
            <div className="right-wrapper">
                {props.points && <p className={"detail points "} > {props.points.toLocaleString()} </p>}
                {(props.trophies || props.trophies === 0) && <>
                    <p className={`detail trophies ${props.leaderboard ? 'leaderboard' : ''}`}>{props.leaderboard ? '+' : null}{props.trophies}</p>
                    <img className="trophy-icon" src={trophyIcon} alt={props.iconAltText || "List Item Icon"} />
                </>}
            </div>
        )
    }

    return (
        <>
            <div className={`leaderboard-list-item-wrapper`}>
                <div className="left-wrapper">
                    <LeaderboardAvatar
                        rank={props.rank}
                        isUser={props.isUser} // this will likely need to change or be removed and left to component to handle check
                        label={props.rank} />
                    <p className="leaderboard-item-label m">Store #{props.label}</p>
                </div>
                {handleRankAndPoints()}
            </div>
        </>
    )
};

export default LeaderboardListItem;