import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from '../../Routes.js';
import { helpIcon } from '../../assets/images/images.js';
import Button from '../../components/Button/Button.js';
import { UserContext } from '../../Contexts.js';
import './404.scss';

const Page404 = () => {
    const { employee } = useContext(UserContext);
    const navigate = useNavigate();
    return (
        <div className="page-404 page-padding">
            <img className="img-404" src={helpIcon} alt="Error decorative indication" />
            <h2 className="header-404">Uh-oh. Looks like that page went off task.</h2>
            <p className="body-404">
                {!!employee ?
                    "We’re not sure what page you were looking for, but we can help you get back in the game. Try one of the options below." :
                    "We’re not sure what page you were looking for, but if you sign in now, we can get you back in the game."
                }
            </p>
            {!!employee ?
                <Button buttonText="View Dashboard" onClick={() => navigate(DASHBOARD_ROUTE, { replace: true })} /> :
                <Button buttonText="Sign In" onClick={() => navigate(LOGIN_ROUTE, { replace: true })} />
            }
        </div>
    )
};

export default Page404;