import Breadcrumb from "../../Breadcrumb/Breadcrumb.js";
import Button from "../../Button/Button.js";
import './EditProfileForm.scss';

const EditProfileForm = props => {
    const handleSubmit = e => {
        e.preventDefault();
        props.submit();
    };

    return (
        <div className="edit-profile-form">
            <Breadcrumb />

            <h2 className="profile-form-header">{props.header}</h2>
            
            <form onSubmit={handleSubmit}>
                <div className="profile-form-content">{props.children}</div>
                
                <Button
                    buttonText="Save Changes"
                    disabled={props.disabled}
                    type="submit"
                />

                <Button
                    buttonText="Cancel"
                    disabled={props.disabled}
                    type="button"
                    kind="ghost"
                    onClick={props.cancel}
                />
            </form>
        </div>
    )
};

export default EditProfileForm;