import { generateClient } from 'aws-amplify/api';
import { useState, useEffect } from 'react';
import { listFAQS } from '../../graphql/queries';
import AccordionItem from '../../components/Accordion/AccordionItem.js';
import './FAQ.scss'; 

const FAQ = () => {
    const [faq, setFaq] = useState([]);
    const client = generateClient();

    useEffect( () => {
		faqPromise()
	}, []);

    const faqPromise = async () => {
		const response = await client.graphql({ query: listFAQS });
        var items = response.data.listFAQS.items;
        items.sort((a,b) => a.sortOrder - b.sortOrder);
		setFaq(items);
	}

    return (
        <>
            <div className="faq-wrapper  page-padding">
                <h1>FAQs</h1>
                {faq.map((data) => {
                    return <AccordionItem
                        key={ data.id }
                        question={ data.question }
                        answer={ data.answer }
                    />
                })}
            </div>
        </>
    );
}

export default FAQ;
