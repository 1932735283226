import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { calendarIcon, storeIcon, employeeIcon, alertIcon } from "../../assets/images/images";
import { InsightsListItem } from '../../components/ListItem';
import { DAILY_INSIGHTS_ROUTE, LOCATION_INSIGHTS_ROUTE, EMPLOYEE_INSIGHTS_ROUTE } from '../../Routes.js';
import { UserContext } from '../../Contexts.js';
import './Insights.scss';

const InsightsPage = () => {
	const navigate = useNavigate();
    const { employee } = useContext(UserContext);

    return (
        <div className="insights-page index">
            <h1 className='heading-page'>Insights</h1>
            {!employee?.isManager ?
                <div className="insights-body">
                    <p className="sub-body">
                    <img className="alert-img" src={alertIcon} alt="Error decorative indication" />
                        This view is for managers only.
                    </p> 
                    
                    
                </div>
            :    

            <div className='insights-index-list-wrapper'>
                <InsightsListItem
                    label="Daily"
                    icon={calendarIcon}
                    onClick={() => navigate(DAILY_INSIGHTS_ROUTE)}
                    detail="View performance details for all restaurant locations by date."
                />
                <InsightsListItem
                    label="Restaurant"
                    icon={storeIcon}
                    onClick={() => navigate(LOCATION_INSIGHTS_ROUTE)}
                    detail="View performance details for your restaurant location."
                />
                <InsightsListItem
                    label="Employee"
                    icon={employeeIcon}
                    onClick={() => navigate(EMPLOYEE_INSIGHTS_ROUTE)}
                    detail="View performance details for your employees."
                />
            </div>
            }

        </div>
    )
};

export default InsightsPage;