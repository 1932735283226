import { arrowRightIcon } from "../../assets/images/images";
import Divider from "../Divider/Divider";
import Avatar from "../Avatar/Avatar";
import './ListItem.scss';

const ListItem = props => {

    const _handleLabel = (kind) => {
        if (kind === 'store') return `Store #${props.data.storeNumber}`;
        if (kind === 'employee' && props.data) return `${props?.data.firstName} ${props?.data.lastName}`;
        else return props.label;
    }

    return (
        <>
            <button
                className={`list-item-wrapper ${props.kind ?? null} ${props.className || ''}`}
                onClick={props.onClick}>
                <div className="left-wrapper">
                    {props.kind === 'employee' && props.data &&
                        <Avatar size="md" employee={props.data} />
                    }
                    <p className="list-item-label">
                        {_handleLabel(props.kind)}</p>
                    {props.kind === 'store' &&
                        <p className="store-address">{props.data.city}</p> // city should be address but not in data
                    }
                </div>
                <div className="right-wrapper">
                    {(props.kind === 'task' || props.kind === 'employee') && typeof props.data?.percentage === 'number' &&
                        <p className="percentage">{props.data.percentage}%</p>
                    }
                    <img className="list-item-icon" src={arrowRightIcon} alt={props.iconAltText || "List Item Icon"} />
                </div>
            </button>
            {props.kind === 'task' && <Divider />}
        </>
    )
};

export default ListItem;