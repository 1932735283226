import { useState, useEffect, useContext } from "react";
import { generateClient } from 'aws-amplify/api';
import EditProfileForm from "../../components/Forms/EditProfileForm/EditProfileForm.js";
import Input from "../../components/Input/Input.js";
import Toasts from "../../components/Toasts/Toasts.js";
import { UserContext } from '../../Contexts.js';
import { updateEmployeeDefaultRole } from "../../graphql/customQueries.js";
import { trackEvent } from '../../utils/anayticsUtils';

const EditRole = () => {
    const { employee, setEmployee, location } = useContext(UserContext);
    const [role, setRole] = useState(employee?.defaultRole?.name || "Other");
    const [isDisabled, setIsDisabled] = useState(true);
    const [toast, setToast] = useState(null);
    const client = generateClient();

    // Hide toast after 5 seconds (This will be removed if a toast handler is added)
    useEffect(() => {
        if (!!toast) {
            setTimeout(() => setToast(null), 5000);
        }
    }, [toast])

    useEffect(() => {
        if (role !== employee?.defaultRole?.name) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        };
    }, [role, employee?.defaultRole]);

    const handleRoleChange = e => {
        setRole(e.target.value);
    };

    const handleSubmit = async () => {
        console.log(">> Updating employee default role");
        const roleIndex = location?.roles?.items.findIndex(locationRole => locationRole.name === role);
        if (roleIndex !== -1) {
            const roleID = location?.roles?.items[roleIndex].id;
            const response = await client.graphql({
                query: updateEmployeeDefaultRole,
                variables: {
                    id: employee.id,
                    userID: employee.userID,
                    role: roleID
                }
            }).catch(err => {
                console.error(err);
                setToast({
                    kind: "error",
                    message: "An error occurred"
                });
            });
            // console.log(response);

            // role updated, track analytics
            trackEvent('edit_profile', employee.id, location.storeNumber);

            // Update current employee (Will need to be updated with new authentication method)
            setEmployee(employee => { return { ...employee, defaultRole: { id: roleID, name: role } } });

            // Show success notification
            setToast({
                kind: "success",
                message: "Changes saved!"
            });
        }
    };

    const handleCancel = () => {
        setRole(employee?.defaultRole?.name || "Other");
    };

    return (
        <div className="edit-role-wrapper page-padding">
            <div className="toaster-wrapper">
                <Toasts kind={toast?.kind} message={toast?.message} show={!!toast} onClose={() => setToast(null)} />
            </div>

            <EditProfileForm
                header="Default Role"
                disabled={isDisabled}
                submit={handleSubmit}
                cancel={handleCancel}
            >
                <fieldset>
                    {location?.roles?.items.map(locationRole => {
                        return <Input
                            key={locationRole.name}
                            label={locationRole.name}
                            value={locationRole.name}
                            name="role"
                            type="radio"
                            checked={role === locationRole.name}
                            onChange={handleRoleChange}
                        />
                    })}
                </fieldset>
            </EditProfileForm>
        </div>
    )
};

export default EditRole;