export const updateScore = async (env, locationID, points) => {

    console.log('>> updating score of location ' + locationID + ' by ' +  points);

    const response = await fetch('https://2kmkfoop3nk3gkd7rabft3sxma0qgeoz.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "environment": env,
            "locationID": locationID,
            "points": points
        })
    })
    const data = await response.json();
    return data.Attributes;
}