import ListItem from '../ListItem/ListItem';
import './List.scss';

const List = props => {
    if (!props.data) return;

    return (
        <ul className={`list-wrapper ${props.kind ?? null} ${props.className || ''}`}>
            {props?.data.map((item, index) => {
                return <ListItem
                    key={index}
                    onClick={props.onItemClick ? () => props.onItemClick(item) : item.id}
                    kind={props.kind}
                    label={props.kind === "date" ? item.date : (item.label || item[0].name)}
                    percentage={item?.percentage}
                    data={item} />
            })}
        </ul>
    )
};

export default List;