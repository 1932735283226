import InputFooter from './InputFooter';
import Message from '../Message/Message';
import './Input.scss';

const Input = props => {
    return (
        <div className={`input-wrapper${props.type === 'radio' || props.type === 'checkbox' ? ' reverse' : ''}`}>
            <label
                className={`input-label${props.type === 'radio' || props.type === 'checkbox' ? ' radio-label' : ''}`}
                htmlFor={props.id ? props.id : `${props.label}-input`}
            >
                {props.label}
            </label>
            {props.type === "textArea" ?
                <textarea
                    id={props.id ? props.id : `${props.label}-input`}
                    className={`input-main${!!props.hasError ? ' error' : ''}${props.disabled ? ' disabled' : ''}${props.type === 'radio' || props.type === 'checkbox' ? ' radio' : ''}`}
                    name={props.name ? props.name : `${props.label}-input`}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    required={props.required}
                />
                : <input
                    id={props.id ? props.id : `${props.label}-input`}
                    className={`input-main${!!props.hasError ? ' error' : ''}${props.disabled ? ' disabled' : ''}${props.type === 'radio' || props.type === 'checkbox' ? ' radio' : ''}`}
                    name={props.name ? props.name : `${props.label}-input`}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    type={props.type || "text"}
                    checked={props.checked}

                    // Optional settings
                    disabled={props.disabled}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    size={props.size}
                    required={props.required}
                />
            }
            {props.showMessage && props.message && <Message message={props.message} show={props.showMessage} kind="error" />}
            {(props.helperText || props.subLink) &&
                <InputFooter {...props} />}
        </div>
    )
};

export default Input;