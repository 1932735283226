import { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FAQ_ROUTE, TERMS_ROUTE } from '../../Routes.js';
import { UserContext } from '../../Contexts.js';
import Button from '../Button/Button.js';
import './Footer.scss';

const Footer = () => {
    const { employee, contactUs } = useContext(UserContext);
    const navigate = useNavigate();

    let contactUsLink = `https://docs.google.com/forms/d/e/1FAIpQLSeezKAfjjLAiSDQZSMYCaW0GXz2KENo-Q35o2xIKzCyatpddg/viewform?usp=sf_link`;

    return (
        <div className="footer">
            <hr />
            <div className="footer-wrapper">
                {!!employee && <div className="footer-btns-wrapper">
                    <Button buttonText="FAQs" onClick={() => navigate(FAQ_ROUTE)} />
                    <Link to={contactUsLink} target="_blank"><Button buttonText="Contact Us" kind="ghost" onClick={()=>{}} /></Link>
                </div>}

                {/* Terms and Privacy links */}
                <p className="privacy-terms sub-body s">
                    By signing into this experience, you acknowledge acceptance of the site
                    <Link className="privacy-terms-link" to={TERMS_ROUTE}> Terms & Conditions</Link>.
                    <br />
                    <br />
                    ©2024 ALL RIGHTS RESERVED
                </p>

                {/* <Link className="privacy-terms-link" to={PRIVACY_ROUTE}>Privacy Policy</Link> */}
            </div>
        </div>
    )
};

export default Footer;