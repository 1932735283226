import { useEffect, useContext } from 'react';
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { fetchUserAttributes } from 'aws-amplify/auth';
import ScrollToTop from './utils/ScrollToTop.js';
import { UserContext } from './Contexts.js';
import {
  LOGIN_ROUTE,
  CHECK_IN_ROUTE,
  FAQ_ROUTE,
  PROFILE_NAME_ROUTE,
  PROFILE_ROLE_ROUTE,
  PROFILE_ROUTE,
  MENTIONS_ROUTE,
  MENTIONS_MY_MENTIONS_ROUTE,
  MENTIONS_TEAM_MENTIONS_ROUTE,
  ERROR_ROUTE,
  DASHBOARD_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  TASK_LIST_ROUTE,
  TASK_LIST_MY_TASKS_ROUTE,
  TASK_LIST_ALL_TASKS_ROUTE,
  TASK_COMPLETION_ROUTE,
  TASK_REPORT_ROUTE,
  INSIGHTS_ROUTE,
  DAILY_INSIGHTS_ROUTE,
  DAILY_REPORT_INSIGHTS_ROUTE,
  EMPLOYEE_INSIGHTS_ROUTE,
  EMPLOYEE_REPORT_INSIGHTS_ROUTE,
  EMPLOYEE_REPORT_INSIGHTS_TASKS_ROUTE,
  EMPLOYEE_SPOTLIGHT_INSIGHTS_ROUTE,
  LOCATION_INSIGHTS_ROUTE,
  LOCATION_REPORT_INSIGHTS_ROUTE,
  TASK_REJECT_ROUTE,
  TASK_LIST_CURRENT_TASKS_ROUTE,
  TASK_LIST_PAST_TASKS_ROUTE,
  DAILY_TASK_DETAILS_INSIGHTS_ROUTE,
  LOCATION_TASK_DETAILS_INSIGHTS_ROUTE
} from './Routes.js';
import Header from './components/Header/Header.js';
import Footer from "./components/Footer/Footer.js";
import Loader from './components/Loader/Loader.js';
import Dashboard from './views/Dashboard/Dashboard.js';
import Checkin from './views/Checkin/Checkin.js';
import Profile from './views/Profile/Profile.js';
import EditName from './views/Profile/EditName.js';
import EditRole from './views/Profile/EditRole.js';
import Mentions from './views/Mentions/Mentions.js';
import MentionsList from './modules/Mentions/MentionsList.js';
import TaskListPage from './views/TaskListPage/TaskListPage.js';
import TaskList from './modules/Tasks/TaskList.js';
import TaskReport from './views/TaskReport/TaskReport.js';
import {
  InsightsPage,
  InsightsList,
  InsightsReport,
  SpotlightEmployee
} from './views/Insights';
import TaskCompletion from './views/TaskCompletion/TaskCompletion.js';
import RejectTask from './views/RejectTask/RejectTask.js';
import FAQ from './views/FAQ/FAQ.js';
import Page404 from './views/404/404.js';
// import PrivacyPolicy from './views/PrivacyAndTerms/PrivacyPolicy.js';
import TermsAndConditions from './views/PrivacyAndTerms/TermsAndConditions.js';
import TaskDetailsList from './views/TaskListPage/TaskDetailsListPage.js';
import "@aws-amplify/ui-react/styles.css";
import "./index.css";
import "./App.css";
import Test from './views/Test/Test.js';

function App() {
  const {
    employee,
    getStartingData,
    pageLoading,
    setPageLoading,
    setInitLoad,
    subscribe
  } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    var env = 'prod';
    if (document.location.href.indexOf('development') !== -1) env = 'dev';
    if (document.location.href.indexOf('localhost') !== -1) env = 'dev';
    if (document.location.href.indexOf('staging') !== -1) env = 'qa';
    // Subscribe to default topic
    subscribe(`allEmployees${env}`);
  }, [subscribe]);

  useEffect(() => {
    // If no employee data
    if (!employee) {
      // Check user auth
      checkAuth();
    };
  }, [employee]);

  const checkAuth = async () => {
    setPageLoading(true);
    try {
      const userAttributes = await fetchUserAttributes();
      await getStartingData(userAttributes.sub, null);
      setInitLoad(true);
    } catch (err) {
      console.error(err);

      // If not a required page
      if (location.pathname !== FAQ_ROUTE &&
        location.pathname !== TERMS_ROUTE &&
        location.pathname !== PRIVACY_ROUTE &&
        location.pathname !== ERROR_ROUTE) {

        // Navigate to login page
        navigate(LOGIN_ROUTE);
      }
    };
    setPageLoading(false);
  };

  return (

    <div className="App">

      <Header />
      <main>
        {pageLoading && <Loader />}
      <ScrollToTop/>
        <Routes>
          <Route path={DASHBOARD_ROUTE} element={<Dashboard />} />
          <Route path={CHECK_IN_ROUTE} element={<Checkin />} />
          <Route path={PROFILE_ROUTE} element={<Profile />} />
          <Route path={PROFILE_NAME_ROUTE} element={<EditName />} />
          <Route path={PROFILE_ROLE_ROUTE} element={<EditRole />} />
          <Route path={MENTIONS_ROUTE} element={<Mentions />}>
            <Route index element={<Navigate to={employee?.isManager ? MENTIONS_TEAM_MENTIONS_ROUTE : MENTIONS_MY_MENTIONS_ROUTE} replace />} />
            <Route path={MENTIONS_MY_MENTIONS_ROUTE} element={<MentionsList />} />
            <Route path={MENTIONS_TEAM_MENTIONS_ROUTE} element={<MentionsList />} />
          </Route>

          <Route path={TASK_LIST_ROUTE} element={<TaskListPage />}>
            <Route index element={<Navigate to={employee?.isManager ? TASK_LIST_CURRENT_TASKS_ROUTE : TASK_LIST_MY_TASKS_ROUTE} replace />} />
            <Route path={TASK_LIST_MY_TASKS_ROUTE} element={<TaskList />} />
            <Route path={TASK_LIST_ALL_TASKS_ROUTE} element={<TaskList />} />
            <Route path={TASK_LIST_CURRENT_TASKS_ROUTE} element={<TaskList />} />
            <Route path={TASK_LIST_PAST_TASKS_ROUTE} element={<TaskList />} />
          </Route>

          <Route path={TASK_COMPLETION_ROUTE} element={<TaskCompletion />} />
          <Route path={TASK_REPORT_ROUTE} element={<TaskReport />} />
          <Route path={TASK_REJECT_ROUTE} element={<RejectTask />} />

          <Route path={INSIGHTS_ROUTE} element={<InsightsPage />} />
          {/* Daily Insights */}
          <Route path={DAILY_INSIGHTS_ROUTE} element={<InsightsList daily />} />
          <Route path={DAILY_REPORT_INSIGHTS_ROUTE} element={<InsightsReport daily />} />
          <Route path={DAILY_TASK_DETAILS_INSIGHTS_ROUTE} element={<TaskDetailsList />} /> {/* Tasks prop will be passed via the navigation function */}

          {/* Employee Insights */}
          <Route path={EMPLOYEE_INSIGHTS_ROUTE} element={<InsightsList employee />} />
          <Route path={EMPLOYEE_REPORT_INSIGHTS_ROUTE} element={<InsightsReport employee />} />
          <Route path={EMPLOYEE_REPORT_INSIGHTS_TASKS_ROUTE} element={<InsightsReport employee tasks/>} />
          <Route path={EMPLOYEE_SPOTLIGHT_INSIGHTS_ROUTE} element={<SpotlightEmployee />} />
          
          {/* Restaurant Insights */}
          <Route path={LOCATION_INSIGHTS_ROUTE} element={<InsightsList restaurant />} />
          <Route path={LOCATION_REPORT_INSIGHTS_ROUTE} element={<InsightsReport restaurant />} />
          <Route path={LOCATION_TASK_DETAILS_INSIGHTS_ROUTE} element={<TaskDetailsList />} /> {/* Tasks prop will be passed via the navigation function */}
          
          {/* Route for testing/developing components. Remove for PROD build! */}
          {/* <Route path={'/test'} element={<Test />} /> */}

          {/* Required pages */}
          <Route path={FAQ_ROUTE} element={<FAQ />} />
          <Route path={TERMS_ROUTE} element={<TermsAndConditions />} />
          {/* <Route path={PRIVACY_ROUTE} element={<PrivacyPolicy />} /> */}

          <Route path="/" element={<Navigate to={DASHBOARD_ROUTE} replace />} />
          {/* All uncaught routes should display 404 page */}
          {/* Replace with redirect */}
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
