/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPerformanceMetric = /* GraphQL */ `
  mutation CreatePerformanceMetric(
    $input: CreatePerformanceMetricInput!
    $condition: ModelPerformanceMetricConditionInput
  ) {
    createPerformanceMetric(input: $input, condition: $condition) {
      id
      employee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      type
      data
      title
      content
      createdAt
      updatedAt
      performanceMetricEmployeeId
      __typename
    }
  }
`;
export const updatePerformanceMetric = /* GraphQL */ `
  mutation UpdatePerformanceMetric(
    $input: UpdatePerformanceMetricInput!
    $condition: ModelPerformanceMetricConditionInput
  ) {
    updatePerformanceMetric(input: $input, condition: $condition) {
      id
      employee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      type
      data
      title
      content
      createdAt
      updatedAt
      performanceMetricEmployeeId
      __typename
    }
  }
`;
export const deletePerformanceMetric = /* GraphQL */ `
  mutation DeletePerformanceMetric(
    $input: DeletePerformanceMetricInput!
    $condition: ModelPerformanceMetricConditionInput
  ) {
    deletePerformanceMetric(input: $input, condition: $condition) {
      id
      employee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      type
      data
      title
      content
      createdAt
      updatedAt
      performanceMetricEmployeeId
      __typename
    }
  }
`;
export const createLeaderboard = /* GraphQL */ `
  mutation CreateLeaderboard(
    $input: CreateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    createLeaderboard(input: $input, condition: $condition) {
      id
      sprint {
        id
        challengeID
        startDateTime
        name
        mvpVotes {
          nextToken
          __typename
        }
        sprintIndex
        mvpEmployee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        createdAt
        updatedAt
        sprintMvpEmployeeId
        __typename
      }
      locationWinnerID
      mostThanksEmployeeID
      mostBoostsEmployeeID
      mvpEmployeeID
      mostThanksCount
      mostBoostsCount
      mvpWinnerVoteCount
      snapshot
      createdAt
      updatedAt
      leaderboardSprintId
      __typename
    }
  }
`;
export const updateLeaderboard = /* GraphQL */ `
  mutation UpdateLeaderboard(
    $input: UpdateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    updateLeaderboard(input: $input, condition: $condition) {
      id
      sprint {
        id
        challengeID
        startDateTime
        name
        mvpVotes {
          nextToken
          __typename
        }
        sprintIndex
        mvpEmployee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        createdAt
        updatedAt
        sprintMvpEmployeeId
        __typename
      }
      locationWinnerID
      mostThanksEmployeeID
      mostBoostsEmployeeID
      mvpEmployeeID
      mostThanksCount
      mostBoostsCount
      mvpWinnerVoteCount
      snapshot
      createdAt
      updatedAt
      leaderboardSprintId
      __typename
    }
  }
`;
export const deleteLeaderboard = /* GraphQL */ `
  mutation DeleteLeaderboard(
    $input: DeleteLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    deleteLeaderboard(input: $input, condition: $condition) {
      id
      sprint {
        id
        challengeID
        startDateTime
        name
        mvpVotes {
          nextToken
          __typename
        }
        sprintIndex
        mvpEmployee {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        createdAt
        updatedAt
        sprintMvpEmployeeId
        __typename
      }
      locationWinnerID
      mostThanksEmployeeID
      mostBoostsEmployeeID
      mvpEmployeeID
      mostThanksCount
      mostBoostsCount
      mvpWinnerVoteCount
      snapshot
      createdAt
      updatedAt
      leaderboardSprintId
      __typename
    }
  }
`;
export const createMVPVote = /* GraphQL */ `
  mutation CreateMVPVote(
    $input: CreateMVPVoteInput!
    $condition: ModelMVPVoteConditionInput
  ) {
    createMVPVote(input: $input, condition: $condition) {
      id
      sprintID
      submittedByID
      votedForID
      storeNumber
      sprintIndex
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMVPVote = /* GraphQL */ `
  mutation UpdateMVPVote(
    $input: UpdateMVPVoteInput!
    $condition: ModelMVPVoteConditionInput
  ) {
    updateMVPVote(input: $input, condition: $condition) {
      id
      sprintID
      submittedByID
      votedForID
      storeNumber
      sprintIndex
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMVPVote = /* GraphQL */ `
  mutation DeleteMVPVote(
    $input: DeleteMVPVoteInput!
    $condition: ModelMVPVoteConditionInput
  ) {
    deleteMVPVote(input: $input, condition: $condition) {
      id
      sprintID
      submittedByID
      votedForID
      storeNumber
      sprintIndex
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChallenge = /* GraphQL */ `
  mutation CreateChallenge(
    $input: CreateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    createChallenge(input: $input, condition: $condition) {
      id
      name
      sprints {
        items {
          id
          challengeID
          startDateTime
          name
          sprintIndex
          createdAt
          updatedAt
          sprintMvpEmployeeId
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          totalPoints
          totalTrophies
          openingTime
          closingTime
          city
          storeNumber
          challengeID
          sprintTrophyCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintDuration
      currentSprintID
      previousSprintID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChallenge = /* GraphQL */ `
  mutation UpdateChallenge(
    $input: UpdateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    updateChallenge(input: $input, condition: $condition) {
      id
      name
      sprints {
        items {
          id
          challengeID
          startDateTime
          name
          sprintIndex
          createdAt
          updatedAt
          sprintMvpEmployeeId
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          totalPoints
          totalTrophies
          openingTime
          closingTime
          city
          storeNumber
          challengeID
          sprintTrophyCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintDuration
      currentSprintID
      previousSprintID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChallenge = /* GraphQL */ `
  mutation DeleteChallenge(
    $input: DeleteChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    deleteChallenge(input: $input, condition: $condition) {
      id
      name
      sprints {
        items {
          id
          challengeID
          startDateTime
          name
          sprintIndex
          createdAt
          updatedAt
          sprintMvpEmployeeId
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          totalPoints
          totalTrophies
          openingTime
          closingTime
          city
          storeNumber
          challengeID
          sprintTrophyCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintDuration
      currentSprintID
      previousSprintID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSprint = /* GraphQL */ `
  mutation CreateSprint(
    $input: CreateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    createSprint(input: $input, condition: $condition) {
      id
      challengeID
      startDateTime
      name
      mvpVotes {
        items {
          id
          sprintID
          submittedByID
          votedForID
          storeNumber
          sprintIndex
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintIndex
      mvpEmployee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      createdAt
      updatedAt
      sprintMvpEmployeeId
      __typename
    }
  }
`;
export const updateSprint = /* GraphQL */ `
  mutation UpdateSprint(
    $input: UpdateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    updateSprint(input: $input, condition: $condition) {
      id
      challengeID
      startDateTime
      name
      mvpVotes {
        items {
          id
          sprintID
          submittedByID
          votedForID
          storeNumber
          sprintIndex
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintIndex
      mvpEmployee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      createdAt
      updatedAt
      sprintMvpEmployeeId
      __typename
    }
  }
`;
export const deleteSprint = /* GraphQL */ `
  mutation DeleteSprint(
    $input: DeleteSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    deleteSprint(input: $input, condition: $condition) {
      id
      challengeID
      startDateTime
      name
      mvpVotes {
        items {
          id
          sprintID
          submittedByID
          votedForID
          storeNumber
          sprintIndex
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sprintIndex
      mvpEmployee {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      createdAt
      updatedAt
      sprintMvpEmployeeId
      __typename
    }
  }
`;
export const createTeamTask = /* GraphQL */ `
  mutation CreateTeamTask(
    $input: CreateTeamTaskInput!
    $condition: ModelTeamTaskConditionInput
  ) {
    createTeamTask(input: $input, condition: $condition) {
      id
      description
      pointValue
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      rejected
      rejectionReason
      iconURL
      intervalMinutes
      completedEmployeeIDs
      eligibleEmployeeIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeamTask = /* GraphQL */ `
  mutation UpdateTeamTask(
    $input: UpdateTeamTaskInput!
    $condition: ModelTeamTaskConditionInput
  ) {
    updateTeamTask(input: $input, condition: $condition) {
      id
      description
      pointValue
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      rejected
      rejectionReason
      iconURL
      intervalMinutes
      completedEmployeeIDs
      eligibleEmployeeIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeamTask = /* GraphQL */ `
  mutation DeleteTeamTask(
    $input: DeleteTeamTaskInput!
    $condition: ModelTeamTaskConditionInput
  ) {
    deleteTeamTask(input: $input, condition: $condition) {
      id
      description
      pointValue
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      rejected
      rejectionReason
      iconURL
      intervalMinutes
      completedEmployeeIDs
      eligibleEmployeeIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      pointValue
      claimAvailable
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      boostAvailable
      boosted
      boostedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      boostedModifier
      lateModifier
      claimModifier
      rejected
      rejectionReason
      iconURL
      photoURL
      shoutOutPointValue
      completedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      claimedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      roles {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      interval
      completedAt
      mentions {
        items {
          id
          type
          description
          taskID
          shoutOutType
          sprintID
          spotlightShoutOutCount
          hasShoutOut
          createdAt
          updatedAt
          mentionFromEmployeeIDId
          mentionToEmployeeIDId
          __typename
        }
        nextToken
        __typename
      }
      eligibleEmployeeIDs
      boostDateTime
      isTeamTask
      teamCompletedIDs
      teamEligibleIDs
      locationID
      createdAt
      updatedAt
      taskBoostedById
      taskCompletedById
      taskClaimedById
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      pointValue
      claimAvailable
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      boostAvailable
      boosted
      boostedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      boostedModifier
      lateModifier
      claimModifier
      rejected
      rejectionReason
      iconURL
      photoURL
      shoutOutPointValue
      completedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      claimedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      roles {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      interval
      completedAt
      mentions {
        items {
          id
          type
          description
          taskID
          shoutOutType
          sprintID
          spotlightShoutOutCount
          hasShoutOut
          createdAt
          updatedAt
          mentionFromEmployeeIDId
          mentionToEmployeeIDId
          __typename
        }
        nextToken
        __typename
      }
      eligibleEmployeeIDs
      boostDateTime
      isTeamTask
      teamCompletedIDs
      teamEligibleIDs
      locationID
      createdAt
      updatedAt
      taskBoostedById
      taskCompletedById
      taskClaimedById
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      pointValue
      claimAvailable
      startDateTime
      completed
      lateDateTime
      expiredDateTime
      boostAvailable
      boosted
      boostedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      boostedModifier
      lateModifier
      claimModifier
      rejected
      rejectionReason
      iconURL
      photoURL
      shoutOutPointValue
      completedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      claimedBy {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      roles {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      interval
      completedAt
      mentions {
        items {
          id
          type
          description
          taskID
          shoutOutType
          sprintID
          spotlightShoutOutCount
          hasShoutOut
          createdAt
          updatedAt
          mentionFromEmployeeIDId
          mentionToEmployeeIDId
          __typename
        }
        nextToken
        __typename
      }
      eligibleEmployeeIDs
      boostDateTime
      isTeamTask
      teamCompletedIDs
      teamEligibleIDs
      locationID
      createdAt
      updatedAt
      taskBoostedById
      taskCompletedById
      taskClaimedById
      __typename
    }
  }
`;
export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      id
      startTime
      endTime
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      id
      startTime
      endTime
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      id
      startTime
      endTime
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMention = /* GraphQL */ `
  mutation CreateMention(
    $input: CreateMentionInput!
    $condition: ModelMentionConditionInput
  ) {
    createMention(input: $input, condition: $condition) {
      id
      type
      description
      fromEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      toEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      taskID
      shoutOutType
      sprintID
      spotlightShoutOutCount
      hasShoutOut
      createdAt
      updatedAt
      mentionFromEmployeeIDId
      mentionToEmployeeIDId
      __typename
    }
  }
`;
export const updateMention = /* GraphQL */ `
  mutation UpdateMention(
    $input: UpdateMentionInput!
    $condition: ModelMentionConditionInput
  ) {
    updateMention(input: $input, condition: $condition) {
      id
      type
      description
      fromEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      toEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      taskID
      shoutOutType
      sprintID
      spotlightShoutOutCount
      hasShoutOut
      createdAt
      updatedAt
      mentionFromEmployeeIDId
      mentionToEmployeeIDId
      __typename
    }
  }
`;
export const deleteMention = /* GraphQL */ `
  mutation DeleteMention(
    $input: DeleteMentionInput!
    $condition: ModelMentionConditionInput
  ) {
    deleteMention(input: $input, condition: $condition) {
      id
      type
      description
      fromEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      toEmployeeID {
        id
        firstName
        lastName
        email
        phone
        photoURL
        isManager
        locationID
        userID
        checkedIn
        defaultRole {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        role {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        shift {
          id
          startTime
          endTime
          name
          createdAt
          updatedAt
          __typename
        }
        receivedBoostNotification
        createdAt
        updatedAt
        employeeDefaultRoleId
        employeeRoleId
        employeeShiftId
        __typename
      }
      taskID
      shoutOutType
      sprintID
      spotlightShoutOutCount
      hasShoutOut
      createdAt
      updatedAt
      mentionFromEmployeeIDId
      mentionToEmployeeIDId
      __typename
    }
  }
`;
export const createShoutOut = /* GraphQL */ `
  mutation CreateShoutOut(
    $input: CreateShoutOutInput!
    $condition: ModelShoutOutConditionInput
  ) {
    createShoutOut(input: $input, condition: $condition) {
      id
      phrase
      count
      style
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShoutOut = /* GraphQL */ `
  mutation UpdateShoutOut(
    $input: UpdateShoutOutInput!
    $condition: ModelShoutOutConditionInput
  ) {
    updateShoutOut(input: $input, condition: $condition) {
      id
      phrase
      count
      style
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShoutOut = /* GraphQL */ `
  mutation DeleteShoutOut(
    $input: DeleteShoutOutInput!
    $condition: ModelShoutOutConditionInput
  ) {
    deleteShoutOut(input: $input, condition: $condition) {
      id
      phrase
      count
      style
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      employees {
        items {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        nextToken
        __typename
      }
      roles {
        items {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      totalTrophies
      openingTime
      closingTime
      city
      storeNumber
      challengeID
      sprintTrophyCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      employees {
        items {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        nextToken
        __typename
      }
      roles {
        items {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      totalTrophies
      openingTime
      closingTime
      city
      storeNumber
      challengeID
      sprintTrophyCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      employees {
        items {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        nextToken
        __typename
      }
      roles {
        items {
          id
          name
          locationID
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      totalTrophies
      openingTime
      closingTime
      city
      storeNumber
      challengeID
      sprintTrophyCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      tasks {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locationID
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      tasks {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locationID
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      tasks {
        items {
          id
          taskId
          roleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locationID
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      photoURL
      isManager
      locationID
      userID
      checkedIn
      defaultRole {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      shift {
        id
        startTime
        endTime
        name
        createdAt
        updatedAt
        __typename
      }
      receivedBoostNotification
      createdAt
      updatedAt
      employeeDefaultRoleId
      employeeRoleId
      employeeShiftId
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      photoURL
      isManager
      locationID
      userID
      checkedIn
      defaultRole {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      shift {
        id
        startTime
        endTime
        name
        createdAt
        updatedAt
        __typename
      }
      receivedBoostNotification
      createdAt
      updatedAt
      employeeDefaultRoleId
      employeeRoleId
      employeeShiftId
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      photoURL
      isManager
      locationID
      userID
      checkedIn
      defaultRole {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      shift {
        id
        startTime
        endTime
        name
        createdAt
        updatedAt
        __typename
      }
      receivedBoostNotification
      createdAt
      updatedAt
      employeeDefaultRoleId
      employeeRoleId
      employeeShiftId
      __typename
    }
  }
`;
export const createRoleTask = /* GraphQL */ `
  mutation CreateRoleTask(
    $input: CreateRoleTaskInput!
    $condition: ModelRoleTaskConditionInput
  ) {
    createRoleTask(input: $input, condition: $condition) {
      id
      taskId
      roleId
      task {
        id
        name
        description
        pointValue
        claimAvailable
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        boostAvailable
        boosted
        boostedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        boostedModifier
        lateModifier
        claimModifier
        rejected
        rejectionReason
        iconURL
        photoURL
        shoutOutPointValue
        completedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        claimedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        roles {
          nextToken
          __typename
        }
        interval
        completedAt
        mentions {
          nextToken
          __typename
        }
        eligibleEmployeeIDs
        boostDateTime
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        locationID
        createdAt
        updatedAt
        taskBoostedById
        taskCompletedById
        taskClaimedById
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoleTask = /* GraphQL */ `
  mutation UpdateRoleTask(
    $input: UpdateRoleTaskInput!
    $condition: ModelRoleTaskConditionInput
  ) {
    updateRoleTask(input: $input, condition: $condition) {
      id
      taskId
      roleId
      task {
        id
        name
        description
        pointValue
        claimAvailable
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        boostAvailable
        boosted
        boostedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        boostedModifier
        lateModifier
        claimModifier
        rejected
        rejectionReason
        iconURL
        photoURL
        shoutOutPointValue
        completedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        claimedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        roles {
          nextToken
          __typename
        }
        interval
        completedAt
        mentions {
          nextToken
          __typename
        }
        eligibleEmployeeIDs
        boostDateTime
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        locationID
        createdAt
        updatedAt
        taskBoostedById
        taskCompletedById
        taskClaimedById
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRoleTask = /* GraphQL */ `
  mutation DeleteRoleTask(
    $input: DeleteRoleTaskInput!
    $condition: ModelRoleTaskConditionInput
  ) {
    deleteRoleTask(input: $input, condition: $condition) {
      id
      taskId
      roleId
      task {
        id
        name
        description
        pointValue
        claimAvailable
        startDateTime
        completed
        lateDateTime
        expiredDateTime
        boostAvailable
        boosted
        boostedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        boostedModifier
        lateModifier
        claimModifier
        rejected
        rejectionReason
        iconURL
        photoURL
        shoutOutPointValue
        completedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        claimedBy {
          id
          firstName
          lastName
          email
          phone
          photoURL
          isManager
          locationID
          userID
          checkedIn
          receivedBoostNotification
          createdAt
          updatedAt
          employeeDefaultRoleId
          employeeRoleId
          employeeShiftId
          __typename
        }
        roles {
          nextToken
          __typename
        }
        interval
        completedAt
        mentions {
          nextToken
          __typename
        }
        eligibleEmployeeIDs
        boostDateTime
        isTeamTask
        teamCompletedIDs
        teamEligibleIDs
        locationID
        createdAt
        updatedAt
        taskBoostedById
        taskCompletedById
        taskClaimedById
        __typename
      }
      role {
        id
        name
        tasks {
          nextToken
          __typename
        }
        locationID
        type
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
