import SpotlightCard from '../../components/Cards/SpotlightCard';
import './Spotlight.scss';

const Spotlight = (props) => {
    const { data } = props;

    return (
        <div className="spotlight-wrapper">
            <div className='spotlight-text-block'>
                <h2 className='heading-section'>
                    Spotlights
                </h2>
            </div>
            <div className='spotlight-carousel-wrapper'>
                {data?.slice(0, 10).map((item, index) => {
                    return (
                        <SpotlightCard key={index} data={item}/>
                    )
                })}
            </div>

        </div>
    );
}
export default Spotlight;

