import { useState, useEffect, useContext } from "react";
import { generateClient } from 'aws-amplify/api';
import { updateEmployeeName } from "../../graphql/customQueries.js";
import EditProfileForm from "../../components/Forms/EditProfileForm/EditProfileForm.js";
import Input from "../../components/Input/Input.js";
import Toasts from "../../components/Toasts/Toasts.js";
import { UserContext } from '../../Contexts.js';
import { trackEvent } from '../../utils/anayticsUtils';

const EditName = () => {
    const { employee, setEmployee, location } = useContext(UserContext);
    const [firstName, setFirstName] = useState(employee?.firstName || "");
    const [lastName, setLastName] = useState(employee?.lastName || "");
    const [isDisabled, setIsDisabled] = useState(true);
    const [toast, setToast] = useState(null);
    const client = generateClient();

    useEffect(() => {
        if ((firstName !== employee?.firstName && firstName !== "") ||
            (lastName !== employee?.lastName && lastName !== "")) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        };
    }, [firstName, lastName, employee]);

    // Hide toast after 5 seconds (This will be removed if a toast handler is added)
    useEffect(() => {
        if (!!toast) {
            setTimeout(() => setToast(null), 5000);
        }
    }, [toast])

    const handleSubmit = async () => {
        console.log(">> Updating employee name");
        const response = await client.graphql({
            query: updateEmployeeName,
            variables: {
                id: employee.id,
                userID: employee.userID,
                firstName: firstName,
                lastName: lastName
            }
        }).catch(err => {
            console.error(err);
            setToast({
                kind: "error",
                message: "An error occurred"
            });
        });
        // console.log(response);

        // name updated, track analytics
        trackEvent('edit_profile', employee.id, location.storeNumber);

        // Update current employee (Will need to be updated with new authentication method)
        setEmployee(employee => { return { ...employee, firstName, lastName } });

        // Show success notification
        setToast({
            kind: "success",
            message: "Changes saved!"
        });
    };

    const handleCancel = () => {
        setFirstName(employee.firstName || "");
        setLastName(employee.lastName || "");
    };

    return (
        <div className="edit-name-wrapper page-padding">
            <div className="toaster-wrapper">
                <Toasts kind={toast?.kind} message={toast?.message} show={!!toast} onClose={() => setToast(null)} />
            </div>

            <EditProfileForm
                header="Edit Name"
                disabled={isDisabled}
                submit={handleSubmit}
                cancel={handleCancel}
            >
                <Input
                    label="First Name"
                    placeholder={employee?.firstName}
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                />

                <Input
                    label="Last Name"
                    placeholder={employee?.lastName}
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                />
            </EditProfileForm>
        </div>
    )
};

export default EditName;