import YouBadge from '../Badge/YouBadge';
import './LeaderboardAvatar.scss';

const LeaderboardAvatar = props => {
    return (
        <>
            <div className={`leaderboard-avatar-wrapper ${props.isUser ? 'isUser' : ''}`}>
                <p className="leaderboard-item-label">{props?.label}</p>
                {props.isUser && <YouBadge />}
            </div>
        </>
    )
};

export default LeaderboardAvatar;