import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from "./Tab";
import './Tabs.scss';

const Tabs = props => {
    const [activeTab, setActiveTab] = useState(props.index || 0);
    const navigate = useNavigate();

    const _handleClick = (index, item) => {
        setActiveTab(index);
        if(activeTab === index) return;
        props.onChange && props.onChange();

        if (props.callBack) {
            props.callBack();
        };
        
        if (props.kind === 'pill') return;

        if (item.route) {
            navigate(item.route);
        };

    };
    if (!props.data) return (<p style={{ textAlign: 'center' }}>No data found for tabs component</p>);
    return (
        <div
            role="tabpanel"
            id={props.id}
            aria-labelledby={props.tabId}
            tabIndex={0}
            className={`tabs-wrapper ${props.kind ?? "line"} ${props.className ?? props.className}`}>

            {props?.data?.map((item, index) => (
                <Tab
                    key={index}
                    tabPanelId={props.id}
                    id={props.tabId}
                    active={index === activeTab}
                    kind={props.kind}
                    tabLabel={item.label}
                    onClick={() => _handleClick(index, item)} />
            ))}
        </div>
    )
};

export default Tabs;