import { spotLightIcon } from '../../assets/images/images';
import Avatar from '../../components/Avatar/Avatar';
import Button from '../../components/Button/Button';
import Stats from '../../components/Stats/Stats';
import './InsightsHeader.scss';

const InsightsHeader = props => {

    const getStatDataFromProps = () => {
        const taskTotal = props.data.length;
        const completedTasks = props.data.filter(task => (task.completed && !task.rejected)).length;
        const boostedTasks = props.data.filter(task => task.boosted).length;
        let shoutOuts = props.shoutouts.length;
        // console.log(taskTotal);
        // console.log(completedTasks);
        // console.log(boostedTasks);
        // console.log(shoutOuts);
        return {
            complete: completedTasks,
            total: taskTotal,
            boosted: boostedTasks,
            shoutOuts
        };
    };

    return (
        props.employeeData && <div className='insights-header-wrapper'>
            {props.employeeData && <Avatar size="xxl" employee={props.employeeData} />}
            {/* CTA Text should be:
            - 'Spotlight' w/ spotlightIcon
                --Figma Note: "This CTA should only display if the manager viewing
                the report is assigned to the same store as the employee."
            - 'View Profile' Employee view
            */}
            {props.spotlight && <Button icon={spotLightIcon} buttonText='Spotlight' onClick={props.onButtonPress} />}
            
            {/* hiding stats for now because they are not adding up properly */}
            {/* <Stats data={getStatDataFromProps()} /> */}
        </div>
    )
};
export default InsightsHeader;