// Broke apart from the TaskCard component for readability

import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getEmployee, getTask } from '../../graphql/queries.js';
import { TASK_COMPLETION_ROUTE, TASK_REPORT_ROUTE } from '../../Routes.js';
import { UserContext } from '../../Contexts.js';
import { boostIcon, claimIcon, shoutOutIcon, checkIcon } from '../../assets/images/images.js';
import { checkIfExpired } from '../../utils/dateUtils.js';
import { getTotalPointValue, updateLocalTask } from '../../utils/taskUtils.js';
import { trackEvent } from '../../utils/anayticsUtils';
import Button from '../Button/Button';
import { ClaimTaskModal, ShoutOutModal } from '../../modules/Modals/index.js';
import './TaskCard.scss';

const TaskCardBottom = props => {
    const { employee, location, tasks } = useContext(UserContext);
    const [openClaimModal, setOpenClaimModal] = useState(false);
    const [openShoutOutModal, setOpenShoutOutModal] = useState(false);
    const [completedBy, setCompletedBy] = useState(null);
    const [hasShoutOut, setHasShoutOut] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const client = generateClient();

    useEffect(() => {
        if (props.task?.completedBy) {
            getCompletedByData(props.task);
        }
        const mentions = props.task?.mentions?.items;
        if (mentions?.some(mention => mention.taskID === props.task.id && mention.mentionFromEmployeeIDId === employee?.id)) {
            setHasShoutOut(true);
        }
    }, [props.task]);

    const getCompletedByData = async (t) => {
        try {
            const res = await client.graphql({
                query: getEmployee,
                variables: { id: t?.completedBy?.id }
            });
            setCompletedBy(res.data.getEmployee);
        } catch (err) {
            console.error(err);
        };
    };

    const handleCompleteTask = async () => {
        setBtnLoading(true);
        try {
            // Check if the task has already been completed
            const currentData = await client.graphql({
                query: getTask,
                variables: { id: props.task.id }
            });
    
            if (!currentData.data.getTask.completed) {
                const type = props.task.name.toLowerCase().split(' ').join('-');
                if (!props.task.isTeamTask) {
                    navigate(`${TASK_COMPLETION_ROUTE}?type=${type}&id=${props.task.id}`);
                } else {
                    navigate(`${TASK_COMPLETION_ROUTE}?type=${type}&id=${props.task.id}`, { props: { noPhoto: true }});
                };
            } else {
                updateLocalTask(currentData.data.getTask, tasks);
                navigate(TASK_COMPLETION_ROUTE); // Navigate to Task Unavailable screen
            };
        } catch (err) {
            console.error(err);
            setBtnLoading(false);
        };
    };

    const handleBoostTask = () => {
        //analytics
        trackEvent('boost_task', employee.id, location.storeNumber, {
            "employee_role": employee.role,
            "task_id": props.task?.id,
            "task_name": props.task?.name,
            "task_type": props.task?.isTeamTask ? 'Team' : 'Individual',
            "task_role": props.task?.role
        });
        props.boostTask(props.task.id);
    };
    
    const handleClaimTask = async () => {
        setBtnLoading(true);
        try {
            // Check if the task has already been completed
            const currentData = await client.graphql({
                query: getTask,
                variables: { id: props.task.id }
            });

            if (!currentData.data.getTask.completed) {
                if (!props.task.boosted) {
                    setOpenClaimModal(true);
                } else {
                    navigate(`${TASK_COMPLETION_ROUTE}?type=${props.task?.name?.toLowerCase().split(' ').join('-')}&id=${props.task?.id}`);
                };

                //analytics
                trackEvent('claim_task', employee.id, location.storeNumber, {
                    "employee_role": employee.role,
                    "task_id": props.task?.id,
                    "task_name": props.task?.name,
                    "task_type": props.task?.isTeamTask ? 'Team' : 'Individual',
                    "task_role": props.task?.role
                });
            } else {
                updateLocalTask(currentData.data.getTask, tasks);
            };
        } catch (err) {
            console.error(err);
            setBtnLoading(false);
        };
    };
    
    const handleShoutOut = () => {
        setOpenShoutOutModal(true);
    };
    const shoutOutCallback = (mention) => {
        setHasShoutOut(true);
    };
    
    const handleViewDetails = () => {
        navigate(`${TASK_REPORT_ROUTE}?id=${props.task.id}`);
    };

    return (
        <>
            {/* If task is available AND task is NOT a team task unless user is a manager
                (completed by a manager OR Current user is a manager OR
                (Task is NOT expired AND
                NOT rejected))
            */}
            {(checkIfExpired(props.task?.startDateTime) &&
            (props.task.completedBy?.isManager || props.employee?.isManager ||
            (!(checkIfExpired(props.task?.expiredDateTime) && !props.task?.completed) &&
            !props.task?.rejected))) &&
                <div className="task-card-footer">
                    <ClaimTaskModal
                        open={openClaimModal}
                        employee={props.employee}
                        task={{
                            task: props.task,
                            role: props.role,
                            taskId: props.task.id
                        }}
                        handleBoost={handleBoostTask}
                        handleClaim={() => props.claimTask(props.task.id)}
                        onClose={() => setOpenClaimModal(false)}
                    />

                    <ShoutOutModal
                        open={openShoutOutModal}
                        data={{ ...completedBy, task: props.task }}
                        callBack={shoutOutCallback}
                        onClose={() => setOpenShoutOutModal(false)}
                        isTeamTask={props.task?.isTeamTask}
                    />

                    <hr />
                    
                    {/* If task is NOT complete AND NOT expired AND (NOT claimed OR user has role) */}
                    {!props.task?.completed && (!checkIfExpired(props.task?.expiredDateTime) || props.task?.completed) && (!props.task?.claimedBy || props.checkIfHasRole()) && !(props.employee?.isManager && props.task?.isTeamTask) &&
                        <div className="main-cta-footer">
                            {/* If the current user is a manager OR the current user has the required role */}
                            {props.checkIfHasRole() && !props.employee?.isManager ?
                                // Will need a counter of some kind before being active. For team based tasks. e.g. Counting people who have washed hands.
                                <Button
                                    className="task-btn"
                                    buttonText={props.task?.isTeamTask && props.task?.teamCompletedIDs?.includes(employee?.id) ? `${props.task?.teamCompletedIDs.length}/${props.task?.teamEligibleIDs.length} Complete` : "Complete This Task"}
                                    onClick={handleCompleteTask}
                                    disabled={props.task?.isTeamTask && props.task?.teamCompletedIDs?.includes(employee?.id)}
                                    isLoading={btnLoading}
                                /> :
                                // Else if current user does NOT have required role AND task is late AND task is NOT claimed AND task is NOT boosted AND task is NOT a team task
                                (!props.checkIfHasRole() || props.employee?.isManager) &&
                                    (checkIfExpired(props.task?.lateDateTime) && !props.task?.completed) &&
                                    !props.task.claimedBy && !props.task?.boosted && !props.task?.isTeamTask ?
                                    <div className="two-cta">
                                        <Button
                                            className="task-btn"
                                            buttonText={`Boost +${getTotalPointValue({task: {...props.task}}, 'boosted')}`}
                                            icon={boostIcon}
                                            onClick={handleBoostTask}
                                        />
                                        <Button
                                            className="task-btn task-claim-btn"
                                            buttonText={`Claim +${getTotalPointValue({task: {...props.task}}, 'claimed')}`}
                                            icon={claimIcon}
                                            kind="ghost"
                                            onClick={handleClaimTask}
                                        />
                                    </div> :
                                    !props.task.claimedBy && checkIfExpired(props.task?.startDateTime) && !props.task?.isTeamTask ? <Button
                                        className="task-btn task-claim-btn"
                                        buttonText={`Claim +${getTotalPointValue({task: {...props.task}}, 'claimed')}`}
                                        icon={claimIcon}
                                        kind="ghost"
                                        onClick={handleClaimTask}
                                    /> : ''
                            }
                        </div>
                    }

                    {/* If is completed OR expired */}
                    {(props.task?.completed || checkIfExpired(props.task?.expiredDateTime)) &&
                        (!props.task?.isTeamTask || props.employee?.isManager) &&
                        <div className="completed-footer">
                            <div className="footer-text">
                                {/* If task was completed by a manager */}
                                {props.task?.completedBy?.isManager ?
                                    <div className="manager-completed">This task was completed by a manager.</div> :
                                    <div className="task-shout-outs">
                                        {/* If task is not rejected or expired */}
                                        {!props.task?.rejected && !props.task?.isTeamTask && (!checkIfExpired(props.task?.expiredDateTime) || props.task?.completed) && <>
                                            <img className="shout-outs-icon" src={shoutOutIcon} alt="Shout outs decoration" />
                                            <span className="shout-outs-num">{props.task?.mentions?.items.filter(mention => mention.type === 'SHOUTOUT').length}</span>
                                        </>}
                                    </div>
                                }
                            </div>

                            {/* If current user is a manager */}
                            {props.employee?.isManager ?
                                <Button
                                    className="task-btn footer-btn"
                                    size="sm"
                                    buttonText="View Details"
                                    onClick={handleViewDetails}
                                /> :
                                // Else if user is NOT the one who completed the task AND task is NOT rejected AND task is NOT expired AND task not completed by manager AND NOT a team task
                                props.task?.completedBy?.userID !== props.employee?.userID &&
                                (!checkIfExpired(props.task?.expiredDateTime) || props.task.completed) &&
                                !props.task?.rejected &&
                                !props.task?.completedBy?.isManager &&
                                !props.task?.isTeamTask ?
                                <Button
                                    className="task-btn footer-btn"
                                    size="sm"
                                    buttonText={hasShoutOut ? null : "Shout-Out"}
                                    iconPosition={hasShoutOut ? "left" : "right"}
                                    icon={hasShoutOut ? checkIcon : shoutOutIcon}
                                    disabled={hasShoutOut}
                                    onClick={handleShoutOut}
                                /> : <span></span>
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
};

export default TaskCardBottom;