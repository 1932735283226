import { useEffect, useState } from 'react';
import { generateClient } from "aws-amplify/api";
import { listTasks, listLocations } from '../../graphql/queries';
import { listLocationTasks } from '../../graphql/customQueries';
import './TaskAdmin.scss';

const TaskAdmin = () => {

    const client = generateClient();

    const [locations, setLocations] = useState([]);
    const [tasks, setTasks] = useState([]);

    useEffect(()=>{
        getLocations();      
        getTasks();
    },[]);
    
    const getTasks = async () => {

        var nextToken;
        var pageTasks = [];

        do {
            const taskResponse = await client.graphql({
                query: listTasks,
                variables: {limit: 3000, nextToken: nextToken}
            });
            nextToken = taskResponse.data.listTasks.nextToken;
            pageTasks = pageTasks.concat(taskResponse.data.listTasks.items);

        } while (nextToken);

        console.log(pageTasks);

        sortTasksByDate(pageTasks);
        // sortTasksByID(pageTasks);
        
        setTasks(pageTasks);
        
    }

    const getLocations = async () => {
        const locResponse = await client.graphql({
            query: listLocations
        })
        .then(response => { return response })
        .then(responseData => {
            var allLocations = responseData.data.listLocations.items;
            setLocations(allLocations);
        });
    }

    const sortTasksByDate = (arr) => {
        arr.sort(compareTasksByDate);
    }

    const compareTasksByDate = (a,b) => {
        if (a.startDateTime < b.startDateTime) {
            return -1;
        }
        return 1;
    }

    const sortTasksByID = (arr) => {
        arr.sort(compareTasksByID);
    }

    const compareTasksByID = (a,b) => {
        if (a.id < b.id) return -1;
        return 1;
    }

    
    
    

    const listItems = tasks ? tasks.map(task =>
        <tr key={task.id} id={task.id}>
            <td><strong>{task.name}</strong></td>
            <td>location: {locations.find( (o) => o.id === task.locationID).name}</td>
            <td>start: {new Date(task.startDateTime).toLocaleDateString()} {new Date(task.startDateTime).toLocaleTimeString()}</td>
            <td>boost: {task.boostAvailable ? new Date(task.boostDateTime).toLocaleDateString() + ' ' + new Date(task.boostDateTime).toLocaleTimeString() : 'n/a'}</td>
            <td>boostable: {task.boostAvailable ? 'yes' : 'no'}</td>
            <td>claimable: {task.claimAvailable ? 'yes' : 'no'}</td>
            <td>late: {new Date(task.lateDateTime).toLocaleDateString()} {new Date(task.lateDateTime).toLocaleTimeString()}</td>
            <td>expires: {new Date(task.expiredDateTime).toLocaleDateString()} {new Date(task.expiredDateTime).toLocaleTimeString()}</td>
        </tr>
      ) : <tr>
            <td><strong>no tasks</strong></td>
        </tr>;


        return <div className='page-padding'>
            <h2>Task Admin</h2>
            <table className="taskAdmin">
                <tbody>
                    {listItems}
                </tbody>
            </table>
        </div>
  };

  export default TaskAdmin;