import { useNavigate, useParams } from 'react-router-dom';
// import { generateClient } from 'aws-amplify/api';
import { DAILY_TASK_DETAILS_INSIGHTS_ROUTE, LOCATION_TASK_DETAILS_INSIGHTS_ROUTE, EMPLOYEE_REPORT_INSIGHTS_TASKS_ROUTE } from '../../Routes';
// import { getLocation } from '../../graphql/queries';
import { List } from '../../components/List';
import Donut from '../../components/Chart/Donut';
import Button from '../../components/Button/Button';
import TaskCard from '../../components/TaskCard/TaskCard';
import { formatDate } from '../../utils/dateUtils';
import './TaskInsightsList.scss';

const TaskInsightsList = props => {
    let tasks = props.showAll ? props.tasks : props.tasks.slice(0, 5) || [];
    const navigate = useNavigate();
    const { storeNumber } = useParams();
    // const client = generateClient();

    const handleTaskClick = async (e) => {
        // console.log(e);
        const taskName = e.label.toLowerCase().split(' ').join('-');
        if (props.daily) {
            const date = formatDate(new Date(props.date)).split('/').join('-');
            navigate(DAILY_TASK_DETAILS_INSIGHTS_ROUTE.replace(':date', date).replace(':taskName', taskName), { state: {...e} });
        } else if (props.restaurant) {
            navigate(LOCATION_TASK_DETAILS_INSIGHTS_ROUTE.replace(':storeNumber', storeNumber).replace(':taskName', taskName), { state: {...e} });
        };
    };

    return (
        <div className='tasks-insights-section-wrapper'>
            <div className={`tasks-insights-top-block`}>
                <h2 className='heading-section'>Tasks</h2>
                <Donut percent={props?.totalPercent || 0} />
            </div>
            {!props.employee ?
                <List kind="task" data={props.tasks || []} onItemClick={handleTaskClick} />
                :
                <div className='task-list'>
                    <h3 className='heading-s'>{props.date ? props.date : null}</h3>
                    {tasks?.length > 0 && tasks?.map((task, index) => {
                        return <TaskCard key={task.id || index} task={task} role={task.role} />
                    })}
                    {!props.showAll ? <Button buttonText='View All Tasks' onClick={() => {
                                navigate(EMPLOYEE_REPORT_INSIGHTS_TASKS_ROUTE + '?id=' + props.employeeData.id, {state:props.employeeData}) 
                            }
                        } />
                        : ''}
                </div>
            }

        </div>
    )
};

export default TaskInsightsList;