import { boostIcon, claimIcon, lateIcon } from '../../assets/images/images.js';
import { checkIfExpired } from '../../utils/dateUtils.js';
import { getItemizedPointValue, getTotalPointValue } from '../../utils/taskUtils.js';
import TaskCard from '../TaskCard/TaskCard.js';
import './TaskSummaryCard.scss';

const SummaryModifierRow = ({ image, text, points }) => {
    return (
        <div className="summary-modifier-row">
            <img className='summary-modifier-icon' src={image} alt="Modifier decoration" />
            <span className='summary-modifier-name label'>{text}</span>
            {/* <span className={`summary-modifier-points label ${points < 0 ? 'negative' : ''}`}>{points}</span> */}
        </div>
    )
};

const TaskSummaryCard = ({ task }) => {
    return (
        <div className="task-summary-wrapper">
            <TaskCard role={task.role} task={task.task} noTop noCTA noRadius />

            <div className="summary-content-wrapper">
                <div className="summary-content-modifier-list">
                    {checkIfExpired(task.task?.lateDateTime, task.task?.completedAt) && !task.task?.claimedBy && <SummaryModifierRow image={lateIcon} text="Late" points={getItemizedPointValue(task, 'late')} />}
                    {!!task.task?.claimedBy ? <SummaryModifierRow image={claimIcon} text="Claimed" points={getItemizedPointValue(task, 'claimed')} /> :
                    task.task?.boosted ? <SummaryModifierRow image={boostIcon} text="Boost" points={getItemizedPointValue(task, 'boosted')} /> : ''}
                </div>
                
                {(checkIfExpired(task.task?.lateDateTime, task.task?.completedAt) || !!task.task?.claimedBy || task.task?.boosted) &&
                    <>
                        <p className='sub-body s summary-text'>
                        {task.task?.isTeamTask && task.task?.teamCompletedIDs.length === task.task?.teamEligibleIDs ? "You’ve completed this team task, but not everyone else has." :
                            task.task?.isTeamTask && task.task?.teamCompletedIDs.length !== task.task?.teamEligibleIDs ? "Everyone has completed the team task!" :
                            !!task.task?.claimedBy ?
                                'You Claimed this task from someone else, so you earned fewer points. Next time consider Boosting so your team can earn more points.' : 
                                task.task?.boosted ? `${task.task.boostedBy?.firstName} ${task.task.boostedBy?.lastName?.charAt(0)?.toUpperCase()}. Boosted your Late task and helped you earn more points.` :
                                    'You completed this task Late so you earned fewer points.'}
                        </p>
                        <hr />
                    </>}
                
                <div className="summary-points-earned">
                    <h3 className='heading-m'>{task.task.isTeamTask ? 'Hands Washed' : 'Total Points Earned'}</h3>
                    <span className="points-text">{ task.task.isTeamTask ? `${task.task?.teamCompletedIDs?.length}/${task.task?.teamEligibleIDs?.length}` : getTotalPointValue(task) }</span>
                </div>
            </div>
        </div>
    )
};

export default TaskSummaryCard;