import { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { calendarIcon, employeeIcon, storeIcon, alertIcon } from "../../assets/images/images.js";
import { DAILY_REPORT_INSIGHTS_ROUTE, EMPLOYEE_REPORT_INSIGHTS_ROUTE, LOCATION_REPORT_INSIGHTS_ROUTE } from '../../Routes.js';
import { ListItem } from '../../components/ListItem/index.js';
import { generateClient } from "aws-amplify/api";
import { listLocations, listEmployees, employeesByLocationID } from '../../graphql/queries';
import { listTasksCustom } from '../../graphql/customQueries.js';
import { UserContext } from '../../Contexts.js';
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb.js";
import EmployeeList from '../../modules/Employees/EmployeesList.js';
import { trackEvent } from '../../utils/anayticsUtils';
import { formatDate, getWeekDayFromDate } from '../../utils/dateUtils.js';
import './Insights.scss';

const InsightsList = (props) => {

    const [locations, setLocations] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [tasks, setTasks] = useState([]);
    const client = generateClient();
    const { employee, location, setStillLoading } = useContext(UserContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getLocations = async () => {
        setStillLoading(true);
        
        try {
            const locResponse = await client.graphql({ query: listLocations });
            const locData = locResponse.data.listLocations.items;
            setLocations(locData);
        } catch (err) {
            console.error(err);
        };

        setStillLoading(false);
    };

    const getAllTasks = async () => {
        setStillLoading(true);

        let newTasks, nextToken;
        let pageTasks = [];
        try {

            do {
                // console.log('...fetching tasks...' + pageTasks.length);

                const response = await client.graphql({ query: listTasksCustom, variables: { limit: 1250, nextToken: nextToken } });
                newTasks = response.data.listTasks.items;
                nextToken = response.data.listTasks.nextToken;
                pageTasks = pageTasks.concat(newTasks);

            } while (nextToken);

            let groupedByDate = [];
            let currentGroup = [];
            const sortedByDate = pageTasks.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
            sortedByDate.map((task, index) => {
                const currentDate = currentGroup.length > 0 ? formatDate(new Date(currentGroup[0].startDateTime)) : null;
                const taskDate = formatDate(new Date(task.startDateTime));
                if (!currentDate || currentDate === taskDate) {
                    currentGroup.push(task);
                } else {
                    groupedByDate.push([...currentGroup]);
                    currentGroup.length = 0;
                    currentGroup.push(task);
                };

                // If last item
                if (index + 1 === pageTasks.length) {
                    groupedByDate.push([...currentGroup]);
                };
                return task;
            });

            // Remove future tasks
            const cutOffDate = new Date(Date.now()).setHours(23, 59, 59, 999);
            const removeFutureTasks = groupedByDate.filter(arr => {
                const taskDate = formatDate(new Date(arr[0].startDateTime));
                if (cutOffDate > new Date(taskDate)) {
                    return 1;
                };
                return 0;
            });

            setTasks(removeFutureTasks);
        } catch (err) {
            console.error(err);
        };

        setStillLoading(false);
    };

    const employeesPromise = async () => {

        if (!employee) return;
        try {
            let nextToken, newEmployees;
            let allEmployees = [];

            if (props.daily || props.restaurant || props.employee) {
                
                do {
                    
                    // Get all employees
                    const response = await client.graphql({
                        query: listEmployees,
                        variables: { nextToken }
                    });
                    newEmployees = response.data.listEmployees.items;
                    nextToken = response.data.listEmployees.nextToken;
                    allEmployees = allEmployees.concat(newEmployees);

                } while (nextToken);
            } else {

                // This should not get reached for now, because the insights employee list ALSO should show all employees in the pilot.
                // Managers should be able to see employees from other stores.
                // Leaving this here for now, in case we need to revert that.

                // props.employee : employee insights list
                do {

                    // employee insights use store manager's location
                    let locationID = employee.locationID;

                    const response = await client.graphql({
                        query: employeesByLocationID,
                        variables: { locationID, nextToken, limit: 3000 }
                    });
                    newEmployees = response.data.employeesByLocationID.items;
                    nextToken = response.data.employeesByLocationID.nextToken;
                    allEmployees = allEmployees.concat(newEmployees);

                } while (nextToken);

                setEmployees(allEmployees);
            }

            setEmployees(allEmployees);
        } catch (err) {
            console.error('Error while trying to fetch employees.', err);
        };
    }

    useEffect(() => {
        if (!employee?.isManager) return;
        // analytics
        trackEvent('view_report', employee.id, location.storeNumber, {
            "report_type": props.employee ? 'Employee' : props.daily ? 'Daily' : "Restaurant",
            "report_name": `${handlePageTitle()} report list`,
        });
    }, [props.employee, props.daily, props.restaurant])

    useEffect(() => {
        employeesPromise();
        if (props.daily) {
            getAllTasks();
        } else if (props.restaurant) {
            getLocations();
        };
    }, [employee]);

    const _handleClick = (item) => {

        if (props.daily) {
            navigate(DAILY_REPORT_INSIGHTS_ROUTE.replace(':date', formatDate(new Date(item[0].startDateTime)).split('/').join('-')), {
                state: {
                    tasks: item,
                    daily: props.daily,
                    restaurant: props.restaurant,
                    employee: props.employee,
                    employees
                }
            });
        } else if (props.employee) {

            navigate(EMPLOYEE_REPORT_INSIGHTS_ROUTE + '?id=' + item.id, {
                state: {
                    employeeData: item,
                    daily: props.daily,
                    restaurant: props.restaurant,
                    employee: props.employee
                }
            });
        } else {
            // console.log(item);
            navigate(LOCATION_REPORT_INSIGHTS_ROUTE.replace(':storeNumber', item.storeNumber), {
                state: {
                    location: item,
                    daily: props.daily,
                    restaurant: props.restaurant,
                    employee: props.employee
                }
            });
        };
    };

    const handlePageTitle = () => {
        if (props.daily) return 'Daily';
        if (props.employee) return 'Employee';
        else return  'Restaurant';
    }

    let incomingData;
    let headerIcon;
    if (props.daily) {
        incomingData = tasks;
        headerIcon = calendarIcon;
    } else if (props.restaurant) {
        incomingData = locations;
        headerIcon = storeIcon;
    } else {
        headerIcon = employeeIcon;
    };
    
    return (
        <div className={`insights-page ${props.employee ?? 'employee'} `}>
            <div className="insights-page-header">
                <Breadcrumb />
                <div className="insights-header-title icon">
                    <img src={headerIcon} alt="calendar icon" />
                    <h1>{handlePageTitle()}</h1>
                </div>
            </div>

            {(props.daily || !employee?.isManager) &&
                <div className="insights-body">
                    {!employee?.isManager ?
                        <p className="sub-body">
                            <img className="alert-img" src={alertIcon} alt="Error decorative indication" />
                            This view is for managers only.
                        </p>
                        : <p className="sub-body">
                            Select a date to view its task completion rates, individual employee performance, and more.
                        </p>}

                </div>
            }
            <div className='insights-list-wrapper'>
                {employee?.isManager && <>
                    {(props.daily || props.restaurant) ?
                        <ul className={`list-wrapper ${props.daily ? 'date' : 'employee'}`}>
                            {incomingData.map((item, index) => {
                                return <ListItem
                                    key={index}
                                    onClick={() => _handleClick(item)}
                                    kind={props.daily ? "date" : "store"}
                                    label={props.daily ? `${getWeekDayFromDate(item[0].startDateTime)} ${formatDate(new Date(item[0].startDateTime))}` : item.name}
                                    data={item} />
                            })}
                        </ul>
                        :
                        <div className='insights-employee-list-wrapper'>
                            <EmployeeList
                                hideTitle
                                data={employees}
                                paginate
                                paginationAmount={10}
                                onItemClick={() => _handleClick()}
                            />
                        </div>
                    }
                </>}
            </div>
        </div>
    )
};

export default InsightsList;