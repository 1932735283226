import { Link } from 'react-router-dom';
import { TASK_LIST_MY_TASKS_ROUTE } from '../../Routes';
import { arrowRightIcon } from '../../assets/images/images';
import TaskCard from '../../components/TaskCard/TaskCard';
import './NextTask.scss';

const NextTask = ({ task, className, noCTA = true }) => {

    return (
        <div className={`next-task-wrapper ${className ? className : ''}`}>
            <div className='next-task-heading'>
                <h2>Your Next Task</h2>
                <Link className='task-list-link btn-link' to={TASK_LIST_MY_TASKS_ROUTE}>Your Task List <img className="link-arrow" src={arrowRightIcon} alt='Right Chevron' /></Link>
            </div>

            <TaskCard role={task?.role} task={task?.task} noCTA={noCTA} />
        </div>
    )
};

export default NextTask;