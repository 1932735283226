import { formatDate, checkIfExpired } from './dateUtils.js';
import { useEffect } from 'react';
import { UserContext } from '../Contexts.js';

import {
    successIcon,
    absNozzleIcon,
    conveyorBeltIcon,
    linerIcon,
    napkinsIcon,
    teaLeafIcon,
    condimentsIcon,
    iceCreamIcon,
    washHandsIcon,
    boostIcon,
    claimIcon,
    lateIcon,
    rejectedIcon
} from '../assets/images/images.js';
import { createMention, updateLocation } from '../graphql/mutations.js';
import { capitalizeWord } from './stringUtils.js';
import { updateScore } from './scoreUtils.js';
import { getTask } from '../graphql/queries.js';
import { updateTask } from '../graphql/mutations.js';

// Filters tasks based upon shift time. Returns an array of filtered tasks.
export const filterShiftTasks = (tasks, employee) => {
    const shiftStartTime = new Date(`${formatDate(Date.now())} ${employee?.shift?.startTime}`);
    let shiftEndTime = new Date(`${formatDate(Date.now())} ${employee?.shift?.endTime}`);
    if (shiftEndTime < shiftStartTime) {
        // If the shift end time is before the shift start time, add one day to shift end time
        shiftEndTime = new Date(shiftEndTime.setDate(shiftEndTime.getDate() + 1));
    };
    const shiftTasks = tasks.filter(task => shiftStartTime <= new Date(task.task?.expiredDateTime) && new Date(task.task?.expiredDateTime) <= shiftEndTime);
    return shiftTasks;
};

// Sorts tasks by date. Returns an array of sorted tasks.
export const sortTasksByDate = tasks => {
    const sortedTasks = tasks.sort((a, b) => new Date(a.task.startDateTime) - new Date(b.task.startDateTime));
    return sortedTasks;
};

// Strip an array of roles of it's tasks. Returns a new array of all the tasks, with no repeats.
export const getTasksFromLocationRoles = (roles) => {
    let tasksArr = [];
    roles.map(role => {
        return role.tasks.items.filter(task => {
            if (task) {
                const taskIndex = tasksArr.findIndex(element => element.taskId === task?.taskId);
                if (taskIndex === -1) {
                    tasksArr.push(task);
                };
                return task;
            };
            return false;
        });
    });
    return tasksArr;
};

// Get all tasks related to a role. Returns an array of tasks.
export const getRoleTasks = (role, tasks = []) => {
    const roleTasks = tasks.filter(task => task.task?.roles?.items.some(r => r.role?.id === role?.id));
    return roleTasks;
};

// Get all tasks related to a role for a certain shift. Returns an array of tasks.
export const getRoleShiftTasks = (employee, tasks = []) => {
    const shiftTasks = filterShiftTasks(tasks, employee);
    const roleShiftTasks = getRoleTasks(employee.role, shiftTasks);
    return roleShiftTasks;
};

// Get all tasks completed by user id
export const getTasksCompletedByUser = (id, tasks) => {
    const completedByUser = tasks.filter(task => task.task?.taskCompletedById === id);
    return completedByUser;
};

// Get the modifier specifics by supplied modifier. Returns object of modifier information
export const getInfoByModifier = (mod) => {
    switch (mod) {
        case 'expired':
            return {
                img: null,
                text: "0%",
                value: 0 // Percent value of modifier
            };
        
        case 'rejected':
            return {
                img: rejectedIcon,
                text: "-50%",
                value: 50
            };
        
        case 'claimed':
            return {
                img: claimIcon,
                text: "-60%",
                value: 40
            };
        
        case 'boosted':
            return {
                img: boostIcon,
                text: "-10%",
                value: 90
            };
        
        case 'late':
            return {
                img: lateIcon,
                text: "-50%",
                value: 50
            };
    
        default:
            return {
                img: null,
                text: '100%',
                value: 100
            };
    }
};

// Get modifier info based upon task properties. Returns modifier information.
export const getModifierInfo = (task) => {
    let modifier;
    if (checkIfExpired(task?.expiredDateTime) && !task?.completed) { // If expired
        modifier = getInfoByModifier('expired');
    } else if (task?.rejected) { // Else if rejected
        modifier = getInfoByModifier('rejected');
    } else if (!!task?.claimedBy) { // Else if claimed
        modifier = getInfoByModifier('claimed');
    } else if (task?.boosted) { // Else if is boosted
        modifier = getInfoByModifier('boosted');
    } else if (checkIfExpired(task?.lateDateTime, task?.completedAt) ||
        (!task?.completedAt && checkIfExpired(task?.lateDateTime))) { // Else if late
        modifier = getInfoByModifier('late');
    } else { // Else default
        modifier = getInfoByModifier();
    };
    return modifier;
};

// Get icon for a specific task. Returns task icon
export const getTaskIcon = (task) => {
    const taskName = task?.name?.toLowerCase();
    let icon;
    if (taskName?.includes('wash hands')) {
        icon = washHandsIcon;
    } else if (taskName?.includes('condiments')) {
        icon = condimentsIcon;
    } else if (taskName?.includes('abs conveyor belt')) {
        icon = conveyorBeltIcon;
    } else if (taskName?.includes('abs nozzle')) {
        icon = absNozzleIcon;
    } else if (taskName?.includes('shake mix')) {
        icon = iceCreamIcon;
    } else if (taskName?.includes('liner')) {
        icon = linerIcon;
    } else if (taskName?.includes('tea')) {
        icon = teaLeafIcon;
    } else if (taskName?.includes('napkins')) {
        icon = napkinsIcon;
    } else {
        icon = successIcon;
    };
    return icon;
};

// Get the task name from supplied task type
export const getTaskNameFromType = type => {
    const wordArr = type.toLowerCase().split('-');
    const capWordsArr = wordArr.map(word => capitalizeWord(word));
    const taskName = capWordsArr.join(' ').replace(' Abs ', ' ABS ');
    console.log(taskName);
    return taskName;
};

// Applies modifier to point value. Returns modified point value
export const getPointValueAfterModifier = (pointValue, modifierPercentage) => {
    const newPointValue = (pointValue * modifierPercentage) / 100;
    return newPointValue;
};

// Gets the points that a specific modifier added or reduced (for itemized lists). Returns modified point value.
export const getItemizedPointValue = (task, modifier) => {
    const modifierPercentage = getInfoByModifier(modifier).value;
    const modifiedPointValue = getPointValueAfterModifier(task.task?.pointValue, modifierPercentage);
    let newPointValue;
    if (modifierPercentage !== 100) {
        newPointValue = task.task?.pointValue - modifiedPointValue;
    };
    
    if (modifier === 'boosted') {
        newPointValue -= getPointValueAfterModifier(task.task?.pointValue, getInfoByModifier('boosted').value);
    } else {
        newPointValue = -newPointValue;
    }
    return newPointValue;
};

// Get the total points that a SINGLE task is worth, including the modifier hierarchy. Returns total point value
export const getTotalPointValue = (task, mod = null) => {
    let modifier = getModifierInfo(task.task).value;
    if (mod) modifier = getInfoByModifier(mod).value;
    const totalPointValue = getPointValueAfterModifier(task.task?.pointValue, modifier);
    return totalPointValue;
};

// Get the total points that MULTIPLE tasks are worth, including the modifiers. Returns total sum of all points
export const getTotalPointsValue = (tasks) => {
    const pointSum = tasks.reduce((total, task) => {
        if (task.task?.completed) { // If task is completed
            return total + getTotalPointValue(task); // Add point value to total
        } else { // Else
            return total; // Return total
        };
    }, 0);
    return pointSum;
};

// Check for perfect shift. If all were completed and none were late, claimed or boosted
// Returns boolean
export const checkIfPerfectShift = (tasks) => {
    const allCompleted = !tasks.some(task => !task.task?.completed);
    const hasDisqualifier = tasks.some(task => new Date(task.task?.lateDateTime) < new Date(task.task?.completedAt || Date.now()) || !!task.task?.claimedBy || !!task.task?.boostedBy);
    if (tasks.length > 0 && allCompleted && !hasDisqualifier) return true;
    return false;
};

// Checks how many of the supplied tasks are marked complete. Returns int.
export const getPercentageCompleted = (tasks) => {
    if (tasks.length === 0) return 100;
    const completedTasks = tasks.filter(task => (task.task?.completed && !task.task?.rejected));
    const percentCompleted = Math.round((completedTasks.length * 100) / tasks.length);
    return percentCompleted;
};

// Check if there is another task. If so, return the task. Else return false.
export const getNextTask = (employee, tasks) => {
    const roleTasks = getRoleTasks(employee.role, tasks); // Get role tasks
    const shiftTasks = filterShiftTasks(roleTasks, employee); // Get role shift tasks
    const upcomingTasks = shiftTasks.filter(task => new Date(task.task?.expiredDateTime) > new Date(Date.now()) && !task.task?.completed); // Get tasks that happen after now
    // If the upcomingTasks array has at least one task
    if (upcomingTasks.length > 0) {
        return upcomingTasks[0]; // Return the next task
    };
    return false;
};

// Update a specific task in the task list
export const updateLocalTask = (task, tasks) => {
    let updatedTasks = tasks.map(t => {
        if (t.taskId === task.id) {
            return {
                role: t.role,
                task: {
                    ...task,
                    roles: t.roles
                },
                taskId: task.id
            }
        };
        return t;
    });
    return updatedTasks;
};

// Sort tasks by modifier
export const sortTasksByModifier = (tasks, includeComplete = false) => {
    let lateTasks = [];
    let boostedTasks = [];
    let availableTasks = [];
    let notAvailableTasks = [];
    let completeTasks = [];

    tasks.map(task => {
        if (
            checkIfExpired(task.task?.lateDateTime) &&
            !checkIfExpired(task.task?.expiredDateTime) &&
            !task.task?.completed &&
            !task.task?.boosted) {
            lateTasks.push(task);
        } else if (task.task?.boosted && !task.task?.completed && !checkIfExpired(task.task?.expiredDateTime)) {
            boostedTasks.push(task);
        } else if (checkIfExpired(task.task?.startDateTime) && !checkIfExpired(task.task?.expiredDateTime) && !task.task?.completed) {
            availableTasks.push(task);
        } else if (!checkIfExpired(task.task?.startDateTime)) {
            notAvailableTasks.push(task);
        } else {
            if (includeComplete) {
                completeTasks.push(task);
            }
        }
        return task;
    });

    // Destructuring seems to prepend, rather than append as one would expect...
    const sortedTasks = [
        ...boostedTasks,
        ...lateTasks,
        ...availableTasks,
        ...notAvailableTasks,
        ...completeTasks,
    ];

    return sortedTasks;
};

// Creates a mention associated with a task (Shoutout or Boost (default shoutout))
export const createTaskMention = async ({
    mentionType = 'SHOUTOUT',
    shoutOutType,
    client,
    toEmployeeId,
    fromEmployeeId,
    task,
    challenge,
    description = ''
}) => {
    try {
        // Create a mention
        const mention = await client.graphql({
            query: createMention,
            variables: {
                input: {
                    taskID: task?.id,
                    sprintID: challenge?.getCurrentSprint().id,
                    type: mentionType,
                    shoutOutType,
                    mentionToEmployeeIDId: toEmployeeId,
                    mentionFromEmployeeIDId: fromEmployeeId,
                    description: description
                }
            }
        });
        
        var env = 'prod';
        if (document.location.href.indexOf('development') !== -1) env = 'dev';
        if (document.location.href.indexOf('localhost') !== -1) env = 'dev';
        if (document.location.href.indexOf('staging') !== -1) env = 'qa';

        // Update the location points, you get 2 points for giving a shoutout
        // updateScore(env, location?.id, 2);
        
        return mention;
    } catch (err) {
        console.error(err);
    };
};

export const updateTasksAtCheckout = async ( 
    {
        tasks, 
        employee,
        client,
        location
    }) => {
    const result = await Promise.all(tasks.map(async task => {
        let updatedTask, dbTask;

        // check for completing a team task by leaving
        if (task.task.isTeamTask && task.task?.teamEligibleIDs?.length > 0) {

            // we need to fetch an updated task to see if anyone has completed their part of the team task
            const dbTaskRequest = await client.graphql({
                query: getTask,
                variables: { id: task.taskId }
            });
            dbTask = dbTaskRequest.data.getTask;

            // task.teamCompletedIDs could be absent/null
            let userHasCompletedTeamTask = false;
            if (dbTask.teamCompletedIDs) {
                for (var i=0; i<dbTask.teamCompletedIDs.length; i++) {
                    if (dbTask.teamCompletedIDs[i] == employee.id) {
                        userHasCompletedTeamTask = true;
                        break;
                    }
                }
            }

            // if user has NOT completed this team task, remove them from the eligibility list,
            // then check to see if task is cpmpleted without them
            if (!userHasCompletedTeamTask) {

                // make a list of eligible employees that does not include me
                console.log('>> removing user from team task ' + task.taskId + ' eligibility');
                const newEligibleIDs = dbTask.teamEligibleIDs.filter(id => id !== employee.id);
                var inputObj = {
                    id: task.taskId,
                    teamEligibleIDs: newEligibleIDs
                }
                
                // teamCompletedIDs could still be absent/null
                // is this un-completed team task now complete?
                if ( !dbTask.completed && dbTask.teamCompletedIDs && (dbTask.teamCompletedIDs.length === newEligibleIDs.length)) {
                    // task should now be considered complete without me, although we are trusting that employees in each list are the same
                    
                    console.log('>> marking team task ' + task.taskId + ' as complete');
                    inputObj.completed = true;
                    inputObj.completedAt = new Date(Date.now()).toISOString();

                    // update score
                    const pointValue = getTotalPointValue({
                        role: task.role,
                        task: dbTask,
                        id: task.taskId
                    });
                    // can't use getUIEnvironment, I have no Context
                    var env = 'prod';
                    if (document.location.href.indexOf('development') !== -1) env = 'dev';
                    if (document.location.href.indexOf('localhost') !== -1) env = 'dev';
                    if (document.location.href.indexOf('staging') !== -1) env = 'qa';

                    console.log('>> submitting ' + pointValue + ' points to ' + env + ' for team task completion');
                    await updateScore(env, location.id, pointValue);
                }

                // update eligibility list and completed state in the database
                const res = await client.graphql({
                    query: updateTask,
                    variables: {
                        input: inputObj
                    }
                });

                updatedTask = res.data.updateTask;

            } else {
                // user HAS completed this team task, nothing changes. they stay in both eligible and completed lists.
                // console.log('>> user HAS completed team task ' + task.taskId + ', they will remain in the eligibility and completed lists.');
            }
        }
        return {...task, task: { ...task.task, ...updatedTask }};
    }));

    return result;
}