import { StorageImage } from '@aws-amplify/ui-react-storage';
import { getOverrideProps } from "../../ui-components/utils";
import './Avatar.scss';


const Avatar = props => {

    const { employee, team, overrides } = props;

    const getInitials = () => {
        const firstInitial = employee?.firstName?.charAt(0) || 'T';
        const lastInitial = employee?.lastName?.charAt(0) || 'F';
        const initials = firstInitial?.toUpperCase() + lastInitial?.toUpperCase();
        return initials;
    };

    return (
        <div className={props.celebration ? `avatar-celebration-wrapper` : `avatar-outer-wrapper`}>
            <div className={`avatar-wrapper ${props.size ? props.size : ''} ${props.className || ''} ${props.animated ? 'animated' : ''}`}>
                {!!employee?.photoURL ? <StorageImage
                    imgKey={employee?.photoURL}
                    accessLevel="public"
                    alt={employee?.firstName + ' ' + employee?.lastName}
                    {...getOverrideProps(overrides, "image")}
                    ></StorageImage>
                    : <span className="avatar-placeholder">{ getInitials() }</span>
                }
            </div>
        </div>
    )
};

export default Avatar;