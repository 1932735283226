const testData = {
    "tabs": [
        {
            label: 'Weekly Rank',
            route: 'route1'
        },
        {
            label: 'Trophies',
            route: 'route2'
        }
    ],
    "storePoints": [
        {
            label: '12345',
            points: '12,716',
            rank: '1',
        },
        {
            label: '12345',
            points: '12,716',
            rank: '2',
        },
        {
            label: '12345',
            points: '12,716',
            rank: '3',
        },
        {
            label: '12348',
            points: '12,716',
            rank: '4',
        }
    ],

    "storeRank": [
        {
            label: '12345',
            rank: '1',
            trophies: 364,
            isUser: true
        },
        {
            label: '12345',
            rank: '2',
            trophies: 364,
        },
        {
            label: '12345',
            rank: '3',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '4',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '5',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '6',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '7',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '8',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '9',
            trophies: 364,
        },
        {
            label: '12348',
            rank: '10',
            trophies: 364,
        },

    ],
    "employees": [
        {
            firstName: "John",
            lastName: "Wick",
            photoURL: "wick.jpg",
            streak: 12,
            shoutOuts: 4,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Dan",
            lastName: "Adams",
            photoURL: "88.jpg",
            streak: 123,
            shoutOuts: 43,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Robert",
            lastName: "Nguyen",
            photoURL: "64.jpg",
            streak: 1,
            shoutOuts: 45,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Leslie",
            lastName: "Gibson",
            photoURL: "1.jpg",
            streak: 12,
            shoutOuts: 4,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Jackie",
            lastName: "Garcia",
            photoURL: "2.jpg",
            streak: 12,
            shoutOuts: 4,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "John",
            lastName: "Wick 2",
            photoURL: "wick.jpg",
            streak: 12,
            shoutOuts: 4,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Dan",
            lastName: "Adams 2",
            photoURL: "88.jpg",
            streak: 123,
            shoutOuts: 43,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Robert",
            lastName: "Nguyen 2",
            photoURL: "64.jpg",
            streak: 1,
            shoutOuts: 45,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Leslie",
            lastName: "Gibson 2",
            photoURL: "1.jpg",
            streak: 12,
            shoutOuts: 4,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
        {
            firstName: "Jackie",
            lastName: "Garcia 2",
            photoURL: "2.jpg",
            streak: 12,
            shoutOuts: 4,
            percentage: 82,
            rejectedTasks: 3,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            }
        },
    ],

    "employee": {
        firstName: "John",
        lastName: "Wick 6",
        photoURL: "wick.jpg"
    },
    "mvps": [
        {
            label: "John Wick",
            value: "John Wick"
        },
        {
            label: "John Wick 2",
            value: "John Wick 2"
        },
        {
            label: "John Wick 3",
            value: "John Wick 3"
        },
        {
            label: "John Wick 4",
            value: "John Wick 4"
        },
        {
            label: "John Wick 5",
            value: "John Wick 5"
        },
        {
            label: "John Wick 6",
            value: "John Wick 6"
        },
        {
            label: "John Wick 7",
            value: "John Wick 7"
        },
        {
            label: "John Wick 8",
            value: "John Wick 8"
        },
    ],
    "mentions": [
        {
            "mentioned": {
                firstName: "The",
                lastName: "Manager",
                photoURL: "ian-mcshane3.jpg",

            },
            "sentOn": 'Wed Feb 14 2024 19:45:30 GMT-0500',

            "description": "You always complete your tasks on time every shift! You’re [🔥 fire]",
            "mentioner": {
                firstName: "John",
                lastName: "Wick",
                photoURL: "wick.jpg",

            }
        },
        {
            "mentioned": {
                firstName: "The Other",
                lastName: "Manager",
                photoURL: "ian-mcshane3.jpg",

            },
            "sentOn": 'Wed Feb 14 2024 17:45:30 GMT-0500',

            "description": "Don’t forget to [Task Name]! If you do, you’ll earn [99] bonus points from [FirstName].",
            "mentioner": {
                firstName: "John",
                lastName: "Wick 2",
                photoURL: "wick.jpg",

            }
        },
        {
            "mentioned": {
                firstName: "That",
                lastName: "Manager",
                photoURL: "ian-mcshane3.jpg",

            },
            "sentOn": 'Wed Feb 14 2024 19:45:30 GMT-0500',

            "description": "You always complete your tasks on time every shift! You’re [🔥 fire]",
            "mentioner": {
                firstName: "John",
                lastName: "Wick 3",
                photoURL: "wick.jpg",

            }
        },
        {
            "mentioned": {
                firstName: "This",
                lastName: "Manager",
                photoURL: "ian-mcshane3.jpg",

            },
            "sentOn": 'Wed Feb 14 2024 19:45:30 GMT-0500',

            "description": "Don’t forget to [Task Name]! If you do, you’ll earn [99] bonus points from [FirstName].",
            "mentioner": {
                firstName: "John",
                lastName: "Wick 4",
                photoURL: "wick.jpg",

            }
        },
        {
            "mentioned": {
                firstName: "That",
                lastName: "Guy",
                photoURL: "ian-mcshane3.jpg",

            },
            "sentOn": 'Wed Feb 14 2024 19:45:30 GMT-0500',

            "description": "Don’t forget to [Task Name]! If you do, you’ll earn [99] bonus points from [FirstName].",
            "mentioner": {
                firstName: "John",
                lastName: "Wick 5 (Probably)",
                photoURL: "wick.jpg",

            }
        },

    ],
    "stores": [
        {
            store: '12345',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '12289',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '96547',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '25678',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '33651',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '77428',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '96524',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            store: '35869',
            address: "2929 Peachtree Rd, Atlanta, GA 30305",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        }
    ],
    "dailyInsights": [
        {
            id: 1,
            date: "Wednesday 02/21/2024",
            tasksComplete: 16,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }

        },
        {
            id: 2,
            date: "Tuesday 02/20/2024",
            tasksComplete: 16,
            tasks: {
                complete: 8,
                total: 20,
                boosted: 1,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 3,
            date: "Monday 02/19/2024",
            tasksComplete: 16,
            tasks: {
                complete: 10,
                total: 20,
                boosted: 0,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 80,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 55,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 25,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 85,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 4,
            date: "Sunday 02/18/2024",
            tasksComplete: 16,
            tasks: {
                complete: 12,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 5,
            date: "Saturday 02/17/2024",
            tasksComplete: 16,
            tasks: {
                complete: 4,
                total: 20,
                boosted: 0,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 6,
            date: "Friday 02/16/2024",
            tasksComplete: 16,
            tasks: {
                complete: 3,
                total: 20,
                boosted: 0,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 7,
            date: "Thrusday 02/15/2024",
            tasksComplete: 16,
            tasks: {
                complete: 20,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 8,
            date: "Wednesday 02/14/2024",
            tasksComplete: 16,
            tasks: {
                complete: 20,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 9,
            date: "Teusday 02/13/2024",
            tasksComplete: 16,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 10,
            date: "Monday 02/12/2024",
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            }
        },
        {
            id: 11,
            date: "Sunday 02/11/2024",
            tasksComplete: 16,
            tasks: {
                complete: 16,
                total: 20,
                boosted: 3,
                taskTypes: [
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 20,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 35,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Nozzles',
                        percentage: 10,
                        id: "",
                    },
                    {
                        label: 'Clean ABS Converyor Belt',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Replace Tea Liners',
                        percentage: 15,
                        id: "",
                    },
                    {
                        label: 'Stock Napkins',
                        percentage: 15,
                        id: "",
                    }
                ]
            },
            shoutOuts: {
                complete: 12
            },
        }
    ],
    "stats": [
        {
            label: 'Tasks Complete',
            number: 16,
            total: 120,
        },
        {
            label: 'Shout-Outs Shared',
            number: 12
        },
        {
            label: 'Tasks Boosted',
            number: 3
        }
    ],
    "tasks": [
        {
            kind: 'task',
            label: 'Clean ABS Nozzles',
            percentage: 80,
            id: "",
        },
        {
            kind: 'task',
            label: 'Clean ABS Converyor Belt',
            percentage: 55,
            id: "",
        },
        {
            kind: 'task',
            label: 'Replace Tea Liners',
            percentage: 25,
            id: "",
        },
        {
            kind: 'task',
            label: 'Stock Napkins',
            percentage: 85,
            id: "",
        },
        {
            kind: 'task',
            label: 'Clean ABS Nozzles',
            percentage: 80,
            id: "",
        },
        {
            kind: 'task',
            label: 'Clean ABS Converyor Belt',
            percentage: 55,
            id: "",
        },
        {
            kind: 'task',
            label: 'Replace Tea Liners',
            percentage: 25,
            id: "",
        },
        {
            kind: 'task',
            label: 'Stock Napkins',
            percentage: 85,
            id: "",
        },
    ],
    "fullTasks": [
        {
            "taskId": "6bdd7bab-c665-4d12-9907-52699cf26076",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.323Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-26T22:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-26T22:55:00.000Z",
                "id": "6bdd7bab-c665-4d12-9907-52699cf26076",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-26T23:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:55.506Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "bcd853e4-12d8-4780-be12-db9274c68df0",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.346Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-26T23:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-26T23:55:00.000Z",
                "id": "bcd853e4-12d8-4780-be12-db9274c68df0",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T00:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:55.626Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "a897d13f-87ed-42a6-93da-eaa60b2c5d4e",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.360Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T00:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T00:55:00.000Z",
                "id": "a897d13f-87ed-42a6-93da-eaa60b2c5d4e",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T01:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:55.726Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "50c3b9de-f96f-4810-8519-f1ca2b42a586",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.378Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T01:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T01:55:00.000Z",
                "id": "50c3b9de-f96f-4810-8519-f1ca2b42a586",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T02:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:55.864Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "27ca4b42-1ceb-464e-ad5e-4137745fc3d2",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.381Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T02:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T02:55:00.000Z",
                "id": "27ca4b42-1ceb-464e-ad5e-4137745fc3d2",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T03:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:56.005Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "447746b4-82b4-4790-8492-291c0920efdc",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.349Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T03:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T03:55:00.000Z",
                "id": "447746b4-82b4-4790-8492-291c0920efdc",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T04:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:56.145Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "7bbcdd3f-8af8-4a93-a24f-289f0320d65e",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.317Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T04:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T04:55:00.000Z",
                "id": "7bbcdd3f-8af8-4a93-a24f-289f0320d65e",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T05:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:56.644Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "eade0439-31b4-4c9a-94c7-906675dd6dd0",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.364Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T05:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T05:55:00.000Z",
                "id": "eade0439-31b4-4c9a-94c7-906675dd6dd0",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T06:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:56.846Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "99b256aa-48d4-4445-a715-fe041b384c72",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.357Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T06:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T06:55:00.000Z",
                "id": "99b256aa-48d4-4445-a715-fe041b384c72",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T07:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.044Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "1715f110-d4ef-4c6e-baa1-1d51f77386b3",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.349Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T07:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T07:55:00.000Z",
                "id": "1715f110-d4ef-4c6e-baa1-1d51f77386b3",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T08:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.166Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "007ee5a5-a705-4986-99a9-e24c5a1a6eab",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.386Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T08:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T08:55:00.000Z",
                "id": "007ee5a5-a705-4986-99a9-e24c5a1a6eab",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T09:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.306Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "e8b600cc-8306-4928-bac0-1743645f3d5f",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.382Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T09:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T09:55:00.000Z",
                "id": "e8b600cc-8306-4928-bac0-1743645f3d5f",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T10:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.404Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "0b491906-7f0f-4fe7-a15e-ce1a7c847b0d",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.327Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T10:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T10:55:00.000Z",
                "id": "0b491906-7f0f-4fe7-a15e-ce1a7c847b0d",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T11:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.524Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "0d09fa93-1977-4022-8dee-0b0dbc89a68d",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.367Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T11:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T11:55:00.000Z",
                "id": "0d09fa93-1977-4022-8dee-0b0dbc89a68d",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T12:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.646Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "f5fb2e09-1d3d-488d-9e91-e7f9c45b6b4d",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.379Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T12:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T12:55:00.000Z",
                "id": "f5fb2e09-1d3d-488d-9e91-e7f9c45b6b4d",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T13:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.824Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "a6c02c46-ef12-4ca5-8bb2-b465ecd82cbf",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.380Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T13:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T13:55:00.000Z",
                "id": "a6c02c46-ef12-4ca5-8bb2-b465ecd82cbf",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T14:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:57.946Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "e84ec954-0650-4f81-8fc4-45954f7d7358",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.401Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T14:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T14:55:00.000Z",
                "id": "e84ec954-0650-4f81-8fc4-45954f7d7358",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T15:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:58.264Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "4666fe89-9ef5-4cef-a1ad-74b480d06edf",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.397Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T15:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T15:55:00.000Z",
                "id": "4666fe89-9ef5-4cef-a1ad-74b480d06edf",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T16:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:58.865Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        },
        {
            "taskId": "1650447e-ccfa-4209-a195-71e4ef362935",
            "task": {
                "updatedAt": "2024-02-26T22:59:45.399Z",
                "taskCompletedById": null,
                "taskClaimedById": null,
                "taskBoostedById": null,
                "startDateTime": "2024-02-27T16:45:00.000Z",
                "shoutOutPointValue": 0,
                "roles": {
                    "items": [
                        {
                            "role": {
                                "name": "10635 - Shake Station",
                                "id": "4818784e-ef75-4a54-8fd5-247090e8d941"
                            }
                        }
                    ]
                },
                "rejectionReason": null,
                "rejected": false,
                "pointValue": 10,
                "photoURL": [],
                "name": "Check Shake Mix Level",
                "lateModifier": 0,
                "lateDateTime": "2024-02-27T16:55:00.000Z",
                "id": "1650447e-ccfa-4209-a195-71e4ef362935",
                "iconURL": null,
                "eligibleEmployeeIDs": [
                    "da712b05-77f2-4a38-9544-508839c037a8"
                ],
                "expiredDateTime": "2024-02-27T17:25:00.000Z",
                "description": null,
                "createdAt": "2024-02-26T19:30:59.023Z",
                "completedAt": null,
                "completedBy": null,
                "completed": false,
                "claimedBy": null,
                "claimModifier": 0,
                "claimAvailable": true,
                "boostedModifier": 0,
                "boosted": false,
                "boostAvailable": true,
                "boostedBy": null
            },
            "role": {
                "id": "4818784e-ef75-4a54-8fd5-247090e8d941",
                "name": "10635 - Shake Station"
            }
        }
    ]

}

export default testData;