import { Link } from 'react-router-dom';
import { TASK_LIST_MY_TASKS_ROUTE } from '../../Routes';
import { arrowRightIcon } from '../../assets/images/images';
import './ProgressBar.scss';

const ProgressBar = props => {

    return (
        <div className='progress-wrapper'>
            <div className='progress-heading'>
                <h3 className='heading-s'>{props.percentage}% of Tasks Complete</h3>
                {typeof props.points === 'number' ? <p className='xs bold'>{props.points} points</p> 
                : <Link className='task-list-link btn-link' to={TASK_LIST_MY_TASKS_ROUTE}>Your Task List <img className="link-arrow" src={arrowRightIcon} alt='Right Chevron' /></Link>
                }
            </div>
            <div className={`progress-bar ${props.className || ''}`}>
                <div style={{ width: `${props.percentage}%` }} className={`progress-bar-indicator`} />
            </div>
        </div>
    )
};

export default ProgressBar;