// This is currently set up to show a single toast and have the show hide controlled by the parent component
// This is because there is only one place where this is currently used. But this can be easily wrapped
// in a handler.
import './Toasts.scss';

const Toasts = props => {
    return (
        <div className={`toast align-items-center border-0 ${props.show ? 'show': 'hide'} ${props.kind}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
                <div className="toast-body">{props.message}</div>
                <button type="button" className="btn-close btn-close-white me-2 m-auto toast-close-btn" data-bs-dismiss="toast" aria-label="Close" onClick={props.onClose}></button>
            </div>
        </div>
    )
};

export default Toasts;