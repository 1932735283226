const taskLoaderResponse = {
    "notifications": [
        {
            "name": "qa_task_late_d77018d5-81e1-4915-975c-c64729c97ef5",
            "id": "9acfeceb-0a00-44e9-9899-ed131b710c26",
            "time": "2024-03-06T16:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_7b426ad0-ddab-4754-b047-210222659a1b",
            "id": "24b1bd0c-504c-4ea1-ae60-108d2ae1674b",
            "time": "2024-03-06T17:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_4fcbd0b1-86eb-4288-b7b4-ca6adabd29f4",
            "id": "fe37691d-1fcd-4a7f-8e2c-e88f6e1efd6a",
            "time": "2024-03-06T18:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_boost_6f99a8e5-91d9-4054-8636-5e3349a6a80e",
            "id": "9acfeceb-0a00-44e9-9899-ed131b710c26",
            "time": "2024-03-06T15:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_92bb4a98-4883-4ec2-87d4-8d52b50cf982",
            "id": "eefceea3-f3e5-44ad-8ba2-0df9a4d81edd",
            "time": "2024-03-06T19:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_boost_e9c983f3-f810-48fa-a5c3-bd54451d57bf",
            "id": "24b1bd0c-504c-4ea1-ae60-108d2ae1674b",
            "time": "2024-03-06T16:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_fcfef4f6-a667-41c3-906e-103d759578be",
            "id": "8c01847b-490e-4eac-a591-c2db495d1f25",
            "time": "2024-03-06T20:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_boost_405bd751-cf24-4b99-8d78-82785a81d696",
            "id": "eefceea3-f3e5-44ad-8ba2-0df9a4d81edd",
            "time": "2024-03-06T18:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_95747cb0-7e7c-4e95-9091-39cf0744499e",
            "id": "2bb8e54b-0699-4ebc-9a31-8a86cbd926fd",
            "time": "2024-03-06T21:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_1cbab455-fdab-4a9f-895c-9f46215b63fb",
            "id": "b80f01ce-26c4-4124-9bb1-2729e4b129d2",
            "time": "2024-03-06T22:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_boost_2ef8e242-2fe8-4727-a31b-13e2851c276c",
            "id": "fe37691d-1fcd-4a7f-8e2c-e88f6e1efd6a",
            "time": "2024-03-06T17:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_bc84c61a-af62-4cd0-bffd-8ff104a95d91",
            "id": "37512561-b9ae-4877-811a-814ce6ec3fab",
            "time": "2024-03-06T23:01:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_boost_f7de93b3-0a2a-4fff-9fd2-26131d2fee25",
            "id": "2bb8e54b-0699-4ebc-9a31-8a86cbd926fd",
            "time": "2024-03-06T20:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_boost_762111db-e308-4d1f-8128-9d31e31c94dd",
            "id": "8c01847b-490e-4eac-a591-c2db495d1f25",
            "time": "2024-03-06T19:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_0bf645f4-f3bf-4ef3-9055-4d5b23ba0952",
            "id": "2d500b4c-886d-4301-9edc-ce38302ab10b",
            "time": "2024-03-06T16:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_8dc48ae1-78f4-4565-8d4d-3d014e21ecc9",
            "id": "b80f01ce-26c4-4124-9bb1-2729e4b129d2",
            "time": "2024-03-06T21:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_55522b2b-4315-4ae0-829d-d8a466e311f5",
            "id": "db2b6da5-87b7-4fef-84b5-0b42b13a4fd1",
            "time": "2024-03-06T17:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_9c207607-fc12-4717-8741-a63ad8f9074a",
            "id": "da45a571-e20c-4409-b53d-3edc602acc5d",
            "time": "2024-03-06T18:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_d83a9850-f51b-499c-86ed-cd2c6d6161bf",
            "id": "0418a86f-a554-4fb6-9246-dcb8f1cce612",
            "time": "2024-03-06T19:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_d63fa38f-1167-4dea-b39f-4be89234ec09",
            "id": "2d500b4c-886d-4301-9edc-ce38302ab10b",
            "time": "2024-03-06T16:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_04c2192c-2169-4262-aedb-f12e068f2cdf",
            "id": "37512561-b9ae-4877-811a-814ce6ec3fab",
            "time": "2024-03-06T22:57:00",
            "type": "Clean ABS Nozzles"
        },
        {
            "name": "qa_task_late_2757fc5c-a010-444a-a14f-3dd0b78a9cb8",
            "id": "b92da223-d86d-4005-8a02-97c15d53b34c",
            "time": "2024-03-06T20:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_6451b262-3fc4-446d-b91b-95596527d879",
            "id": "db2b6da5-87b7-4fef-84b5-0b42b13a4fd1",
            "time": "2024-03-06T17:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_f31c8e63-ad2a-4fe1-aa73-906eec3936a0",
            "id": "81af982a-c710-4c39-add8-dad8e5567e15",
            "time": "2024-03-06T21:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_d407d75c-7276-424d-9f66-abf1cb5a64c1",
            "id": "fd86f68f-18f9-4b00-9a1f-7b2dbf361af6",
            "time": "2024-03-06T22:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_d40faa57-65c3-41d0-8cdf-acd43c2bebde",
            "id": "da45a571-e20c-4409-b53d-3edc602acc5d",
            "time": "2024-03-06T18:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_95e84731-d753-4a61-ac60-f545b35fbc08",
            "id": "b92da223-d86d-4005-8a02-97c15d53b34c",
            "time": "2024-03-06T20:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_276c4958-9af7-4230-92a7-f8b49bc850e6",
            "id": "0418a86f-a554-4fb6-9246-dcb8f1cce612",
            "time": "2024-03-06T19:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_71614889-bbb9-41b7-a436-07cc24068730",
            "id": "7f529a73-b737-4c9a-947e-1a9f061f7e8d",
            "time": "2024-03-06T23:05:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_2b0c2d3c-7662-42bd-b80c-601c92e6ddfa",
            "id": "81af982a-c710-4c39-add8-dad8e5567e15",
            "time": "2024-03-06T21:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_53fbc696-3d42-4ca3-a568-4306b6ec7f71",
            "id": "1be43ca8-5905-408c-98ad-289b05608dc8",
            "time": "2024-03-06T15:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_boost_15d54920-0ad5-474b-938b-9bd191e93103",
            "id": "7f529a73-b737-4c9a-947e-1a9f061f7e8d",
            "time": "2024-03-06T23:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_boost_2946da32-1eab-4223-bde0-22ec51a9e1eb",
            "id": "fd86f68f-18f9-4b00-9a1f-7b2dbf361af6",
            "time": "2024-03-06T22:01:00",
            "type": "Clean ABS Conveyor Belt"
        },
        {
            "name": "qa_task_late_9fd4ef0e-6436-4952-b511-4ea63affe31e",
            "id": "af73a991-95ff-4869-b000-e733a1c23a0a",
            "time": "2024-03-06T16:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_9c3e7119-13ca-4e20-88ad-7d9ceb79f2ad",
            "id": "e21b35ff-9909-4893-a03b-2f3855fba4bf",
            "time": "2024-03-06T17:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_677174ba-5a6f-4288-93c3-9d52c7dce6fa",
            "id": "52e1b1b2-8062-4b54-ad8b-fb31ffdc051e",
            "time": "2024-03-06T18:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_de8a8b99-a478-4b98-8e18-2410818c8a95",
            "id": "1be43ca8-5905-408c-98ad-289b05608dc8",
            "time": "2024-03-06T15:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_ed4fc6ff-39df-4e7c-a91f-637c466ac661",
            "id": "af73a991-95ff-4869-b000-e733a1c23a0a",
            "time": "2024-03-06T16:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_e867a93f-7cac-44a3-9751-8791318b2f6a",
            "id": "0f3beef5-08a0-4b46-879e-1c37e0cc11b7",
            "time": "2024-03-06T19:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_eac0d965-7464-4790-95bb-106ba9b92b20",
            "id": "00aaeab4-ff6d-4dc2-8391-d6298a6b0e4a",
            "time": "2024-03-06T20:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_b1e3d298-79ac-492b-9b56-bc23598f08dd",
            "id": "52e1b1b2-8062-4b54-ad8b-fb31ffdc051e",
            "time": "2024-03-06T18:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_c0051440-52b1-47e4-92ef-69d0abdcf29b",
            "id": "e21b35ff-9909-4893-a03b-2f3855fba4bf",
            "time": "2024-03-06T17:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_4226cb4d-9674-4fe7-ae60-85009ce626c7",
            "id": "4062e500-a586-4ed6-817e-b735e2f07ec4",
            "time": "2024-03-06T21:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_69801b7d-90a4-4edc-8452-3d63e8d9ab8d",
            "id": "0f3beef5-08a0-4b46-879e-1c37e0cc11b7",
            "time": "2024-03-06T19:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_boost_c0fc7f2c-4aac-4bff-9bef-d415d406a060",
            "id": "af73a991-95ff-4869-b000-e733a1c23a0a",
            "time": "2024-03-06T16:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_boost_2655d55e-6f01-4d19-b8c2-97b3f7b85e1f",
            "id": "1be43ca8-5905-408c-98ad-289b05608dc8",
            "time": "2024-03-06T15:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_1ef5fc35-3300-4375-b875-b37632c69b62",
            "id": "2edbbe1e-0c19-4577-8c92-5bbb9aa13497",
            "time": "2024-03-06T22:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_6771ef24-9046-4e27-bb9d-bbb728538aac",
            "id": "d1f36ebc-1741-4d0d-b66b-800183d26fc5",
            "time": "2024-03-06T15:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_843660ab-260e-414d-9ce7-14d43a337eed",
            "id": "00aaeab4-ff6d-4dc2-8391-d6298a6b0e4a",
            "time": "2024-03-06T20:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_0ffe6114-72ac-47f4-b0ad-0fd135e4332e",
            "id": "27a5b6a0-74a8-4391-9177-63ca6daa7c31",
            "time": "2024-03-06T16:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_bb5f7a93-6c24-4593-befc-6c6238599daa",
            "id": "52e1b1b2-8062-4b54-ad8b-fb31ffdc051e",
            "time": "2024-03-06T18:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_boost_7af34a2b-dd8f-460f-bd20-0b2f6ef8746b",
            "id": "e21b35ff-9909-4893-a03b-2f3855fba4bf",
            "time": "2024-03-06T17:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_36f70203-394d-4e81-8e61-a68f09b17c52",
            "id": "4062e500-a586-4ed6-817e-b735e2f07ec4",
            "time": "2024-03-06T21:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_46c0bf97-bddd-4c82-8f77-e6355dd39361",
            "id": "4e0a807e-c08f-4d8d-996c-b0800eb1a6b0",
            "time": "2024-03-06T16:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_63d96d33-2981-49ef-b0bb-22b631acddac",
            "id": "0f3beef5-08a0-4b46-879e-1c37e0cc11b7",
            "time": "2024-03-06T19:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_5e252317-b7b3-4ef5-9474-5955e3867ee9",
            "id": "3390c950-a6b6-4374-9939-2d5802ce6139",
            "time": "2024-03-06T17:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_c102420d-c891-4763-9210-d0099b5af002",
            "id": "2de4aae4-a76d-4e7d-8d1a-d1d3a1a14261",
            "time": "2024-03-06T17:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_db4e5fc6-3903-48af-b252-dbf0a8132bd2",
            "id": "27a5b6a0-74a8-4391-9177-63ca6daa7c31",
            "time": "2024-03-06T16:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_d1568299-e649-4610-9598-a12c8e32077f",
            "id": "4e0a807e-c08f-4d8d-996c-b0800eb1a6b0",
            "time": "2024-03-06T16:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_87789e27-5742-4b6e-92b6-6946dde99d7d",
            "id": "00aaeab4-ff6d-4dc2-8391-d6298a6b0e4a",
            "time": "2024-03-06T20:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_boost_0c60fe8f-2654-4f1e-bfd0-d40a0ab261f4",
            "id": "4062e500-a586-4ed6-817e-b735e2f07ec4",
            "time": "2024-03-06T21:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_758b34de-a785-4dab-9f05-c85d34d6cd12",
            "id": "d1f36ebc-1741-4d0d-b66b-800183d26fc5",
            "time": "2024-03-06T15:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_608a1941-605f-4dd0-a135-34dc59a0d6bc",
            "id": "2edbbe1e-0c19-4577-8c92-5bbb9aa13497",
            "time": "2024-03-06T22:17:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_late_b0b02089-f081-44c0-b36a-2a8cc5ec6fb0",
            "id": "81a7b522-0470-45a1-8f2e-11a102bed564",
            "time": "2024-03-06T18:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_4d905abd-5056-4ff4-abd6-5f0dfa378d3f",
            "id": "3390c950-a6b6-4374-9939-2d5802ce6139",
            "time": "2024-03-06T17:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_79fcb147-9d39-44b3-b2a7-8980ee7e6c50",
            "id": "6fa16f99-3d60-48c9-87d4-538235d19ae4",
            "time": "2024-03-06T18:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_2218860a-f303-49a3-bbf7-f9ad81a8de9a",
            "id": "bf1f25b9-9266-4dd0-80d7-2401209ac4e5",
            "time": "2024-03-06T19:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_30bd2963-f4d2-491b-baae-41c8b659a9df",
            "id": "27a5b6a0-74a8-4391-9177-63ca6daa7c31",
            "time": "2024-03-06T16:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_2a795dfe-d430-4ec2-8f80-fd050786fe2b",
            "id": "4e0a807e-c08f-4d8d-996c-b0800eb1a6b0",
            "time": "2024-03-06T16:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_cbc8d3f2-738e-4bfa-bb24-54df55aa8994",
            "id": "2de4aae4-a76d-4e7d-8d1a-d1d3a1a14261",
            "time": "2024-03-06T17:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_6f4788ea-d7f0-4dea-8123-7fc723036581",
            "id": "2edbbe1e-0c19-4577-8c92-5bbb9aa13497",
            "time": "2024-03-06T22:13:00",
            "type": "Replace Tea Liners"
        },
        {
            "name": "qa_task_boost_7c2a83bf-0379-4380-b762-9a67133c2d8f",
            "id": "d1f36ebc-1741-4d0d-b66b-800183d26fc5",
            "time": "2024-03-06T15:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_78e7bf39-a730-4932-bd8e-dcfdee7c56c7",
            "id": "81a7b522-0470-45a1-8f2e-11a102bed564",
            "time": "2024-03-06T18:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_c5aab8bb-d311-450b-99f8-04402e7b8979",
            "id": "eb4fd97b-5170-421f-b8ec-fa5492136ba4",
            "time": "2024-03-06T19:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_f299bc74-155c-47d5-bfef-8d279e73e38a",
            "id": "3390c950-a6b6-4374-9939-2d5802ce6139",
            "time": "2024-03-06T17:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_c140cc99-fe79-4552-a651-a5ac949b1ece",
            "id": "d41361b6-2f33-4dbf-8562-db34f788338d",
            "time": "2024-03-06T20:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_ac0e5cb2-8c70-4625-82e0-fafdb4133bd6",
            "id": "bf1f25b9-9266-4dd0-80d7-2401209ac4e5",
            "time": "2024-03-06T19:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_28d8f1a0-4ac8-44f4-bf4c-657efe71b98a",
            "id": "6fa16f99-3d60-48c9-87d4-538235d19ae4",
            "time": "2024-03-06T18:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_301751bb-9f24-44d1-aca4-21d6c075e29b",
            "id": "81a7b522-0470-45a1-8f2e-11a102bed564",
            "time": "2024-03-06T18:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_d3395600-62cc-4ebd-a22d-26a3a0e9242f",
            "id": "2de4aae4-a76d-4e7d-8d1a-d1d3a1a14261",
            "time": "2024-03-06T17:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_ce9b7c36-e5a7-454b-b7c6-4d62c617ddc5",
            "id": "45b40d91-9a00-4601-84b0-f8762d8f6744",
            "time": "2024-03-06T20:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_e421a3a8-bc04-434e-8c1f-4e389ad30476",
            "id": "eb4fd97b-5170-421f-b8ec-fa5492136ba4",
            "time": "2024-03-06T19:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_e7ac82a5-4e8a-4a12-bcfd-0dd366ddc2b3",
            "id": "778fba08-c44b-4bd6-bc95-7338c6efc5cc",
            "time": "2024-03-06T21:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_c5e62658-1db6-4cac-87c2-623f6003957f",
            "id": "6fa16f99-3d60-48c9-87d4-538235d19ae4",
            "time": "2024-03-06T18:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_b592b818-eda8-4183-920c-0f1bd41646be",
            "id": "bf1f25b9-9266-4dd0-80d7-2401209ac4e5",
            "time": "2024-03-06T19:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_5ebd169d-fa62-4dd0-be5a-a789a6ec28d3",
            "id": "d41361b6-2f33-4dbf-8562-db34f788338d",
            "time": "2024-03-06T20:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_319e6bef-6f24-4c33-8b2f-ab555f84bf58",
            "id": "4aeaa6af-9308-4202-990c-bf4254eac5d3",
            "time": "2024-03-06T21:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_fbcd588f-a6e9-4e1e-b643-79a699cea5f1",
            "id": "4a99ff0c-dcb9-40d4-bd0f-0c4aacc3e58f",
            "time": "2024-03-06T22:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_47a737f9-ea50-4dbd-ab52-b1b5951f8de9",
            "id": "45b40d91-9a00-4601-84b0-f8762d8f6744",
            "time": "2024-03-06T20:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_6c70b78c-3c8f-4a9b-8c3b-712c2b5a9cae",
            "id": "eb4fd97b-5170-421f-b8ec-fa5492136ba4",
            "time": "2024-03-06T19:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_d2af0974-af1f-4f5e-b550-e3a384ca9846",
            "id": "df8489a3-2bb5-4cc6-8584-5d930ebf200d",
            "time": "2024-03-06T22:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_53c317c6-c82a-4116-8af0-1b9b6ca2cb0f",
            "id": "96968043-3ef2-47a2-8aeb-de8ba1f337b3",
            "time": "2024-03-06T23:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_74f4196d-ecbf-4744-8cf2-230f5800d83d",
            "id": "d41361b6-2f33-4dbf-8562-db34f788338d",
            "time": "2024-03-06T20:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_1f431a60-e373-4ffc-8932-4a876cbe08f9",
            "id": "778fba08-c44b-4bd6-bc95-7338c6efc5cc",
            "time": "2024-03-06T21:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_9c8b82be-264e-4125-bae6-5dfbc99ce34d",
            "id": "4a99ff0c-dcb9-40d4-bd0f-0c4aacc3e58f",
            "time": "2024-03-06T22:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_301310ed-0d62-45bd-a8c3-f103614a8b51",
            "id": "4aeaa6af-9308-4202-990c-bf4254eac5d3",
            "time": "2024-03-06T21:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_7a17d4de-4ff4-450b-99bd-a366cb7b2660",
            "id": "45b40d91-9a00-4601-84b0-f8762d8f6744",
            "time": "2024-03-06T20:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_05699d07-a26c-4943-a03b-3e889455badf",
            "id": "da079c62-ef26-4fb2-9f5c-f8b4d8be9b73",
            "time": "2024-03-06T15:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_1c1a21a0-5d30-43fc-8870-1d8008745511",
            "id": "4ad2f8d3-add3-460f-9e54-76b1f7a5f854",
            "time": "2024-03-06T15:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_f175dd0d-a495-4fe8-ae26-d21a22ea2683",
            "id": "96968043-3ef2-47a2-8aeb-de8ba1f337b3",
            "time": "2024-03-06T23:07:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_ae8afe2a-7174-496b-880c-4c10a089139e",
            "id": "4a99ff0c-dcb9-40d4-bd0f-0c4aacc3e58f",
            "time": "2024-03-06T22:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_9cca2188-15a0-4cc0-be3c-c94ea6806829",
            "id": "778fba08-c44b-4bd6-bc95-7338c6efc5cc",
            "time": "2024-03-06T21:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_f5b23e7b-0e78-4b1f-89ab-5c8e0df4a498",
            "id": "df8489a3-2bb5-4cc6-8584-5d930ebf200d",
            "time": "2024-03-06T22:37:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_424eb9d9-23ea-48b4-bcb0-c27f78337ad0",
            "id": "ac5cf072-cdb1-448d-a77a-d1a137e91c73",
            "time": "2024-03-06T16:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_9eddb331-fb4f-4d48-9d47-8e6996b6901a",
            "id": "19d1c58d-b184-4c17-bca0-f49a126b72f8",
            "time": "2024-03-06T16:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_2c5640b3-cd57-4eb3-a664-be73d017d849",
            "id": "4aeaa6af-9308-4202-990c-bf4254eac5d3",
            "time": "2024-03-06T21:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_a4ce5eb3-19be-40cd-88b1-9696f531e7a3",
            "id": "eb0e7074-f535-4247-afdf-7685482f044d",
            "time": "2024-03-06T17:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_1fdc61f6-f957-44d1-a21f-e9d5ddaada00",
            "id": "b09b0690-dc3e-4bb1-bb16-770ad3da33e8",
            "time": "2024-03-06T17:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_96b0f3ce-a470-4606-906e-59c47122cee6",
            "id": "4ad2f8d3-add3-460f-9e54-76b1f7a5f854",
            "time": "2024-03-06T15:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_91dc6b12-3012-4c1b-82e0-86dd32e618a0",
            "id": "b11a83c3-0295-4f41-96e3-4bbebfba1c3e",
            "time": "2024-03-06T18:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_561fc06d-2feb-40b2-a3bb-bc3187e6e992",
            "id": "da079c62-ef26-4fb2-9f5c-f8b4d8be9b73",
            "time": "2024-03-06T15:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_6937c57d-569e-4229-a81c-157914904888",
            "id": "df8489a3-2bb5-4cc6-8584-5d930ebf200d",
            "time": "2024-03-06T22:33:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_boost_6e58f8b4-a8ec-45a5-a920-be13b1262a23",
            "id": "96968043-3ef2-47a2-8aeb-de8ba1f337b3",
            "time": "2024-03-06T23:03:00",
            "type": "Check Tea Levels"
        },
        {
            "name": "qa_task_late_79163409-5936-437e-8c5f-3f627b89ea85",
            "id": "2deeb88c-91fc-453b-8bb0-ecb9566f500c",
            "time": "2024-03-06T18:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_aad72d64-35da-48be-b6a3-5ddf85173e75",
            "id": "869f5f81-5484-442b-a242-06069e8a5d3a",
            "time": "2024-03-06T19:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_9f8213a1-5e74-4fbc-9f1b-321feaf93226",
            "id": "75497cba-c85a-4ccb-946f-fdbe962415b9",
            "time": "2024-03-06T19:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_c9246a23-919e-45bd-8e4d-f3fa89106abf",
            "id": "b09b0690-dc3e-4bb1-bb16-770ad3da33e8",
            "time": "2024-03-06T17:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_6138cabe-2e5a-4daa-ab53-693a60c441d2",
            "id": "4ad2f8d3-add3-460f-9e54-76b1f7a5f854",
            "time": "2024-03-06T15:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_76743db4-6157-48b6-972f-d1f91fc1ea34",
            "id": "b11a83c3-0295-4f41-96e3-4bbebfba1c3e",
            "time": "2024-03-06T18:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_a48408dc-6de8-4fbd-b748-dd19b825d2e2",
            "id": "da079c62-ef26-4fb2-9f5c-f8b4d8be9b73",
            "time": "2024-03-06T15:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_c275579c-fbc0-4b4e-b090-5651ccbddf5d",
            "id": "bb8f0296-022d-4549-9021-7cac76e274a1",
            "time": "2024-03-06T20:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_76f52372-c6d4-43f1-a152-a8b75352a218",
            "id": "b869d06f-ee4b-4882-b20c-b9788e352d56",
            "time": "2024-03-06T20:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_fb0bdadf-6e79-4be4-ab4f-c38796a521d3",
            "id": "eb0e7074-f535-4247-afdf-7685482f044d",
            "time": "2024-03-06T17:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_ce06e495-93ba-4852-a847-f00ba3fa9d52",
            "id": "19d1c58d-b184-4c17-bca0-f49a126b72f8",
            "time": "2024-03-06T16:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_c384f177-fc11-47da-a0d8-627907f7a91d",
            "id": "ac5cf072-cdb1-448d-a77a-d1a137e91c73",
            "time": "2024-03-06T16:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_aa0840aa-a843-4cae-8063-909db493e18d",
            "id": "6ec9212e-3bb3-4bf8-9046-db4f1d13e7f2",
            "time": "2024-03-06T21:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_cda699dd-8f38-4ad1-b230-84bd3f3a0bd7",
            "id": "75497cba-c85a-4ccb-946f-fdbe962415b9",
            "time": "2024-03-06T19:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_0f9387fb-6826-4979-91a0-cfd15d6792ca",
            "id": "b09b0690-dc3e-4bb1-bb16-770ad3da33e8",
            "time": "2024-03-06T17:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_a9f5cc80-e305-4085-a8c0-372e30bed411",
            "id": "68977087-b725-4527-84c3-0a0d584511b7",
            "time": "2024-03-06T21:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_2d321b45-e931-4f41-a81e-17b06cffad65",
            "id": "869f5f81-5484-442b-a242-06069e8a5d3a",
            "time": "2024-03-06T19:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_9abc6717-6399-401f-9e15-fe816e9327df",
            "id": "2deeb88c-91fc-453b-8bb0-ecb9566f500c",
            "time": "2024-03-06T18:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_f4b5c488-3d42-4a9c-ac85-4718d5bf9cd9",
            "id": "727c17cb-2e58-45c8-87a4-9f3e811b494d",
            "time": "2024-03-06T22:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_669c0b3d-51d2-4224-aaad-462669cc1954",
            "id": "bb8f0296-022d-4549-9021-7cac76e274a1",
            "time": "2024-03-06T20:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_f2a5d696-97b3-478d-add6-c343d2fca756",
            "id": "b869d06f-ee4b-4882-b20c-b9788e352d56",
            "time": "2024-03-06T20:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_684259ea-85d9-4ba5-8e98-fd6e2530011b",
            "id": "ac5cf072-cdb1-448d-a77a-d1a137e91c73",
            "time": "2024-03-06T16:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_4da6100b-8473-4945-8707-99783cf823c8",
            "id": "19d1c58d-b184-4c17-bca0-f49a126b72f8",
            "time": "2024-03-06T16:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_a312ba9e-4a95-4087-966f-0ac6c0d507bb",
            "id": "eb0e7074-f535-4247-afdf-7685482f044d",
            "time": "2024-03-06T17:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_3ce2a107-8b4d-4183-83f8-61b16e398505",
            "id": "12f0fbc3-2a93-4603-a962-b7e8c797064e",
            "time": "2024-03-06T22:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_8f06b05f-883a-47a3-90c0-5f265dd3dc05",
            "id": "b11a83c3-0295-4f41-96e3-4bbebfba1c3e",
            "time": "2024-03-06T18:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_22b448b6-e406-437b-9222-d27911668f64",
            "id": "1899f94c-0b48-4295-90d7-4240a706516f",
            "time": "2024-03-06T15:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_e2151f13-5db3-471a-8e9e-bc9f7e76f388",
            "id": "6ec9212e-3bb3-4bf8-9046-db4f1d13e7f2",
            "time": "2024-03-06T21:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_1a292283-59cb-4ca7-8f7c-edb91ea595db",
            "id": "75497cba-c85a-4ccb-946f-fdbe962415b9",
            "time": "2024-03-06T19:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_fb29af24-00f6-4b0e-bf1d-1ac6fcc525d6",
            "id": "899490e2-cfd6-440c-b272-d9e2da7f697a",
            "time": "2024-03-06T16:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_2e326a65-7a48-42f0-b9aa-b36c0ba5ab53",
            "id": "6faba84e-aa6e-45d5-966e-2fbee49cfa3a",
            "time": "2024-03-06T17:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_d8552e24-0c6a-42f2-b340-ce53feafe314",
            "id": "68977087-b725-4527-84c3-0a0d584511b7",
            "time": "2024-03-06T21:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_19a6abbe-5257-4250-93dd-25787503c63f",
            "id": "2deeb88c-91fc-453b-8bb0-ecb9566f500c",
            "time": "2024-03-06T18:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_a8d31027-7bae-4d92-b1fc-1af1d39d8725",
            "id": "9e7489e9-b727-465e-a156-fc522ac58beb",
            "time": "2024-03-06T18:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_d5aa1149-cbe0-415b-9a89-a27d4ad83edd",
            "id": "727c17cb-2e58-45c8-87a4-9f3e811b494d",
            "time": "2024-03-06T22:22:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_8c169824-88a2-44ac-9f77-025b25b49455",
            "id": "b869d06f-ee4b-4882-b20c-b9788e352d56",
            "time": "2024-03-06T20:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_587a5b2a-541f-4687-bb55-62f0a7ef94f1",
            "id": "bb8f0296-022d-4549-9021-7cac76e274a1",
            "time": "2024-03-06T20:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_9593ed36-367b-4606-af04-f3b46974718a",
            "id": "869f5f81-5484-442b-a242-06069e8a5d3a",
            "time": "2024-03-06T19:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_356ba9cc-d69e-4331-92a5-8dceb85f2ea0",
            "id": "7f36dc06-33b3-4918-bad2-33ad85a0bb5d",
            "time": "2024-03-06T19:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_9e5149ce-d1be-4393-809d-2770c8f15791",
            "id": "12f0fbc3-2a93-4603-a962-b7e8c797064e",
            "time": "2024-03-06T22:52:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_late_1ad94f0e-31bb-470d-ba3c-8de293117c2c",
            "id": "2d724357-c814-4620-a422-8f5cb03c4f8c",
            "time": "2024-03-06T20:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_boost_06b3499d-4123-48a6-ae0f-cbf8e8a89435",
            "id": "1899f94c-0b48-4295-90d7-4240a706516f",
            "time": "2024-03-06T15:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_boost_bb5c7da6-484c-4c7a-b499-934f1d867580",
            "id": "6ec9212e-3bb3-4bf8-9046-db4f1d13e7f2",
            "time": "2024-03-06T21:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_62875855-2ebc-4feb-9aba-b8f872caac37",
            "id": "899490e2-cfd6-440c-b272-d9e2da7f697a",
            "time": "2024-03-06T16:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_19db23ee-75f6-48b9-91d0-2486bc7cb732",
            "id": "f0a3e90a-291c-4ace-90dc-504bda343fae",
            "time": "2024-03-06T21:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_7a001ae6-2706-47c6-8b40-df9f4681ddf0",
            "id": "3b2c2d3c-5fdc-4d0f-a829-c5304dd9ea92",
            "time": "2024-03-06T22:11:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_boost_37c4c4f8-081e-418e-bcf5-64a40ca64b2b",
            "id": "7f36dc06-33b3-4918-bad2-33ad85a0bb5d",
            "time": "2024-03-06T19:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_boost_adb5e63f-c1b6-4951-9b07-fe43f889a9bf",
            "id": "12f0fbc3-2a93-4603-a962-b7e8c797064e",
            "time": "2024-03-06T22:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_c5250966-1a08-48b4-917d-505e27cf06d4",
            "id": "2d724357-c814-4620-a422-8f5cb03c4f8c",
            "time": "2024-03-06T20:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_boost_48dbc5d5-e89b-4c83-825d-ba03ddf5c21d",
            "id": "68977087-b725-4527-84c3-0a0d584511b7",
            "time": "2024-03-06T21:48:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_d930fc79-6535-4c6d-928c-1bacfc64f56a",
            "id": "6faba84e-aa6e-45d5-966e-2fbee49cfa3a",
            "time": "2024-03-06T17:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_boost_0a44e83f-b4ef-451d-9d8e-7827354dbf8a",
            "id": "727c17cb-2e58-45c8-87a4-9f3e811b494d",
            "time": "2024-03-06T22:18:00",
            "type": "Stock Condiments"
        },
        {
            "name": "qa_task_boost_4020c6cf-7691-4c1e-b048-8c2333169e53",
            "id": "9e7489e9-b727-465e-a156-fc522ac58beb",
            "time": "2024-03-06T18:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_83afbd0c-40b5-4f44-ba53-09e92edd9e0a",
            "id": "51c9cded-258a-4ba9-84f0-ac20c4a8b581",
            "time": "2024-03-06T15:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_boost_bd671155-a900-4f61-a80c-83480eec2407",
            "id": "f0a3e90a-291c-4ace-90dc-504bda343fae",
            "time": "2024-03-06T21:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_4602666a-2cd4-43e8-861f-cfb9125dfe95",
            "id": "f4a25780-eb09-488f-8ae6-2c857beee8b5",
            "time": "2024-03-06T15:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_fc15b1c2-4419-4ae2-8640-3ccb8bd35fc7",
            "id": "a837e73a-52f5-417c-8473-275669639a3d",
            "time": "2024-03-06T15:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_boost_b2ffc44d-a74c-4289-a417-5909db73d959",
            "id": "3b2c2d3c-5fdc-4d0f-a829-c5304dd9ea92",
            "time": "2024-03-06T22:07:00",
            "type": "Stock Napkins"
        },
        {
            "name": "qa_task_late_1753110e-575c-47aa-8999-87207355e4be",
            "id": "c2c9c1a0-f0e3-4d18-ada6-00696b07c12e",
            "time": "2024-03-06T15:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_fef5e6af-9981-4b7c-b94a-ceef4f5afc3a",
            "id": "eedc5519-1aa4-41a1-8d12-3aebb6bb9aa7",
            "time": "2024-03-06T16:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_c882fa27-6598-4d10-b717-6d7bae14c019",
            "id": "869aa48f-cb4d-4bb9-af27-52aeb1893ccf",
            "time": "2024-03-06T16:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_f491ac0d-420f-4d80-b123-1d819bf5e71b",
            "id": "a10ed379-1569-4720-8e9f-35c5ed4dd298",
            "time": "2024-03-06T16:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_08934bf6-c0ef-4190-af29-660ba6c23a1d",
            "id": "2fe920d9-802e-42d3-bcb6-707c8bede77f",
            "time": "2024-03-06T16:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_093be932-7286-4d76-89c4-7f65180544d0",
            "id": "e1d5c8e3-291e-42f1-825c-f52f1b7cfe0e",
            "time": "2024-03-06T17:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_e33524e8-4e1d-445e-8ebe-d23c3a1f2ec1",
            "id": "927f9dd6-9ce4-4c65-90d3-981a7c7c0a26",
            "time": "2024-03-06T17:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_6c27f1be-87e0-4b77-b122-babccbe1e6d1",
            "id": "2a075fdb-3b66-48d6-958e-26db46445976",
            "time": "2024-03-06T17:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_0a76a6de-62e1-4e4c-b344-d132129db9b7",
            "id": "0bf3469b-1892-4713-a54e-2a5b3e3b0ee2",
            "time": "2024-03-06T17:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_9ea12f2a-d722-4728-bbc9-f4fdf93968d9",
            "id": "a46ff3f0-0cc8-4b29-9ff4-c183e24e99df",
            "time": "2024-03-06T18:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_9c18a3c5-91eb-46c3-908b-995cc42d0bed",
            "id": "ad14b2da-347c-442b-93c0-b31d77324227",
            "time": "2024-03-06T18:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_0c3ce796-9646-46c2-85f0-78bffcba1538",
            "id": "bb208e12-e490-4da3-8bd8-cd4e8b17817c",
            "time": "2024-03-06T18:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_066bfb4a-8ac6-4d87-86e3-c5e4edad1f86",
            "id": "366a32dd-eed3-4b68-a482-ecd00327a1ac",
            "time": "2024-03-06T18:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_144b761b-9d1f-4b9b-a1f7-4511871196aa",
            "id": "b2cfd30f-c8e2-49ac-875f-a2186a40c006",
            "time": "2024-03-06T19:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_0f664f42-c639-419c-9f46-8333eb2b880b",
            "id": "8e39ba6a-c452-4541-a317-199ab9c51499",
            "time": "2024-03-06T19:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_acf04cd2-70a3-4f81-bec3-529c9ba1224e",
            "id": "55196421-6615-4322-9250-9b33e5563d5c",
            "time": "2024-03-06T19:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_f26e8e95-6dfa-4265-a4f7-f22c25be6701",
            "id": "845d46c1-5857-4b91-90be-ac95a9ebf6fe",
            "time": "2024-03-06T19:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_201fa21d-1f67-4294-a2d7-ce80e8d12e38",
            "id": "26240f7b-fbb7-4453-8e22-c68b144c15ac",
            "time": "2024-03-06T20:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_c3529655-727e-43ed-b206-c964b5ddd58f",
            "id": "a18d8689-8a08-4880-94c5-ad8b938bdd9d",
            "time": "2024-03-06T20:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_6d3c91d7-ea33-4cff-90ef-4fd61a10361c",
            "id": "8d6e8860-b74b-4ce5-a599-876e392a2d6b",
            "time": "2024-03-06T20:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_8c3c3ed2-58dd-44da-9495-2dee93e9e3ec",
            "id": "82a9100e-8523-4cbe-8fcf-15f4bcc69970",
            "time": "2024-03-06T20:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_178f00e9-8700-4c24-a468-168cc57b30d6",
            "id": "9b0a32b7-0750-4f3a-bfa5-c7e6c792ee7c",
            "time": "2024-03-06T21:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_25d84191-9e2f-4445-b9dd-e28adef14299",
            "id": "11ceafad-7c15-4fd1-9ba3-78847fe0a54a",
            "time": "2024-03-06T21:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_97898e3e-c89d-4b58-bbc8-a762a02f6b83",
            "id": "0bf92dda-1ca2-4969-959f-46cda66ce917",
            "time": "2024-03-06T21:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_5c96be83-7435-40e7-ad48-e2f0b1563f04",
            "id": "578c5c02-bb07-4d5f-8dfa-1c8fa19541dc",
            "time": "2024-03-06T21:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_851bbbcd-5be3-4035-9dc2-b88b5eb3ff58",
            "id": "75005a97-1862-43c5-8c43-a9c65dd047a7",
            "time": "2024-03-06T22:14:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_ceb3d12e-d97b-4fb2-9e85-c047376a6121",
            "id": "04ac4c48-c28d-4960-9400-389c10e1f8d3",
            "time": "2024-03-06T22:29:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_7d7093b3-3253-4dde-96e4-46e736b9524f",
            "id": "5fe7bc0b-4f37-4686-827b-e0af1747e8e6",
            "time": "2024-03-06T22:44:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_9cd75368-e5fb-46da-aada-c8ef372fb483",
            "id": "0046675e-8f5e-4d26-8689-6f6d37fcc6fc",
            "time": "2024-03-06T22:59:30",
            "type": "Check Shake Mix Level"
        },
        {
            "name": "qa_task_late_8a21ced4-08cf-446c-bd4f-a6889e50893c",
            "id": "3a98dcaa-bed8-46b9-8bbe-9f7dc6c7a61d",
            "time": "2024-03-06T15:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_facff80f-09ff-4b8d-bf97-b5e549e97bc5",
            "id": "0981b01f-cb61-4ed5-93ef-5c699884b7ed",
            "time": "2024-03-06T15:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_c6c21abb-ab77-4504-b93a-5f8ac80c16ce",
            "id": "4b32e0e7-ed9c-431d-82f1-47d3ca898003",
            "time": "2024-03-06T15:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_78ba4ef0-478e-4426-b92d-42784a60a703",
            "id": "0981b01f-cb61-4ed5-93ef-5c699884b7ed",
            "time": "2024-03-06T15:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_c590bc01-d65e-49aa-ba35-fccc0f55230f",
            "id": "20f99944-ff0a-4c7d-b145-ea84f171bc50",
            "time": "2024-03-06T15:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_9b69564d-02c1-452c-a254-75892142f53b",
            "id": "3a98dcaa-bed8-46b9-8bbe-9f7dc6c7a61d",
            "time": "2024-03-06T15:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_7c2ceb6f-ca07-42bb-899b-482168c9a53e",
            "id": "4b32e0e7-ed9c-431d-82f1-47d3ca898003",
            "time": "2024-03-06T15:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_49a9805e-dad1-4095-974b-aa9d114766e1",
            "id": "0096a054-1094-4831-94d4-8f046331faa6",
            "time": "2024-03-06T16:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_002f7a62-3386-4f04-bad6-a19f429057d3",
            "id": "4e7cbdaf-95aa-4355-85f1-6f3e55bd6961",
            "time": "2024-03-06T16:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_0050e933-406a-40e2-8752-91601484b75d",
            "id": "4b32e0e7-ed9c-431d-82f1-47d3ca898003",
            "time": "2024-03-06T15:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_72f8dd8c-2887-45af-8bd6-437ab263e0c5",
            "id": "20f99944-ff0a-4c7d-b145-ea84f171bc50",
            "time": "2024-03-06T15:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_b7356413-4e7b-4d7a-b495-fbfc6fa79146",
            "id": "0981b01f-cb61-4ed5-93ef-5c699884b7ed",
            "time": "2024-03-06T15:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_73519e31-7cc7-43ab-a19c-8ee187392287",
            "id": "0096a054-1094-4831-94d4-8f046331faa6",
            "time": "2024-03-06T16:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_3d4502bb-0e59-493c-81d7-6583726e103a",
            "id": "61f3db86-2790-4da9-a3d5-97da2897b129",
            "time": "2024-03-06T16:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f05cb456-e61c-43f9-a3a9-bebcba2d4b1f",
            "id": "3a98dcaa-bed8-46b9-8bbe-9f7dc6c7a61d",
            "time": "2024-03-06T15:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_36d7f28e-21d2-42b0-90ac-f068d7ec640b",
            "id": "b3f63bf4-8a39-4a40-8728-896f4ceff554",
            "time": "2024-03-06T16:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_98b1742c-63b0-40a2-a8a6-63660fb40000",
            "id": "4e7cbdaf-95aa-4355-85f1-6f3e55bd6961",
            "time": "2024-03-06T16:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_907c6e48-2057-4986-95a8-8e4c1f54a27d",
            "id": "4b32e0e7-ed9c-431d-82f1-47d3ca898003",
            "time": "2024-03-06T15:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_db409bfa-6255-4ffc-b59a-215818759af6",
            "id": "0981b01f-cb61-4ed5-93ef-5c699884b7ed",
            "time": "2024-03-06T15:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_75ddad86-497e-47ed-8372-94c7c7aee83b",
            "id": "9e980102-867b-44b3-922b-1c0a0d143656",
            "time": "2024-03-06T17:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_85c441f1-1052-40b7-ba4d-4816b5a07a0b",
            "id": "0096a054-1094-4831-94d4-8f046331faa6",
            "time": "2024-03-06T16:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_dc52f966-ff48-404f-8078-7e9e391cd331",
            "id": "70fc4985-6b83-4dce-8b71-099af46e42fa",
            "time": "2024-03-06T17:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_1d832836-be95-417b-9cfb-82d5ab29fc8d",
            "id": "20f99944-ff0a-4c7d-b145-ea84f171bc50",
            "time": "2024-03-06T15:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_e0d2d078-43fe-4f0f-9ee9-f686abd9cb75",
            "id": "1298efc8-bd73-4063-93e5-4276f94d92d3",
            "time": "2024-03-06T17:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f10f82ec-2325-4fd1-b6a8-6c06aadd272a",
            "id": "3a98dcaa-bed8-46b9-8bbe-9f7dc6c7a61d",
            "time": "2024-03-06T15:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_3b9cf707-3d3c-40b4-846c-5dc4a057d579",
            "id": "661dac12-21df-4c79-8cd3-add809e53058",
            "time": "2024-03-06T17:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_16370fdb-ecc1-49a1-9533-e062b72cabbf",
            "id": "61f3db86-2790-4da9-a3d5-97da2897b129",
            "time": "2024-03-06T16:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_7053f1a7-0424-4227-a5b9-dcee443f4867",
            "id": "bee21b96-78eb-4c9e-912f-6d29b9469be6",
            "time": "2024-03-06T18:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_1f7f5337-1921-48a0-b99e-8d2f7b368d70",
            "id": "b3f63bf4-8a39-4a40-8728-896f4ceff554",
            "time": "2024-03-06T16:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6cc8986c-58be-4dda-a854-6389ea575a30",
            "id": "4cc16b25-0059-433b-a1b7-77b03c43aed6",
            "time": "2024-03-06T18:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_0bc1e2fd-447f-4701-91eb-a4249da5e34f",
            "id": "4e7cbdaf-95aa-4355-85f1-6f3e55bd6961",
            "time": "2024-03-06T16:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_74982a99-39cf-4a34-a96d-0b927e3fb242",
            "id": "7875e283-63c5-439c-b9c4-e38837b66505",
            "time": "2024-03-06T18:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_a7224782-cd3d-4a17-b891-14fac2c84581",
            "id": "70fc4985-6b83-4dce-8b71-099af46e42fa",
            "time": "2024-03-06T17:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6bd99867-8032-4c1c-a839-1407307eb0e2",
            "id": "0096a054-1094-4831-94d4-8f046331faa6",
            "time": "2024-03-06T16:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_b5d5e3d8-8288-40aa-a6cf-9c2c6f18f253",
            "id": "20f99944-ff0a-4c7d-b145-ea84f171bc50",
            "time": "2024-03-06T15:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f7260aed-f9b2-4939-b907-f9dc2b723700",
            "id": "9e980102-867b-44b3-922b-1c0a0d143656",
            "time": "2024-03-06T17:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_ba67a28d-eda2-40dd-8915-0f2f5853d11c",
            "id": "589e15a6-8cce-4aef-ba8b-7bda63adcf5c",
            "time": "2024-03-06T18:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_b9fb50f9-688a-4766-9d91-20f137d8db59",
            "id": "1298efc8-bd73-4063-93e5-4276f94d92d3",
            "time": "2024-03-06T17:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_654b6cd3-7a9b-4ded-b3a6-e6525de03b17",
            "id": "cc4c8636-e0a4-4c12-96bc-b45f67a139fc",
            "time": "2024-03-06T19:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_8ba384fc-7b94-40b8-92db-8253f19ca0a8",
            "id": "61f3db86-2790-4da9-a3d5-97da2897b129",
            "time": "2024-03-06T16:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_31c631e5-7d53-47f6-a45d-bf4df6bcba39",
            "id": "b3f63bf4-8a39-4a40-8728-896f4ceff554",
            "time": "2024-03-06T16:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_ed348d6b-6c75-4286-bf72-9dbe13e44b45",
            "id": "4e7cbdaf-95aa-4355-85f1-6f3e55bd6961",
            "time": "2024-03-06T16:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_bc37785c-e78a-40c5-99b1-27be68936270",
            "id": "bee21b96-78eb-4c9e-912f-6d29b9469be6",
            "time": "2024-03-06T18:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f9064ec0-f22f-467e-8ef5-02c238c8e877",
            "id": "4cc16b25-0059-433b-a1b7-77b03c43aed6",
            "time": "2024-03-06T18:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_ba16bfd8-dfff-422e-a667-8d965a153c19",
            "id": "7875e283-63c5-439c-b9c4-e38837b66505",
            "time": "2024-03-06T18:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_4b30abdf-dc03-4612-9077-0c9b6c9bbe59",
            "id": "70fc4985-6b83-4dce-8b71-099af46e42fa",
            "time": "2024-03-06T17:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_66e9b979-2c2b-4955-acfd-69fdbcc1e1e0",
            "id": "661dac12-21df-4c79-8cd3-add809e53058",
            "time": "2024-03-06T17:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_3e99322f-3718-46dd-bc90-d31c3b1efa93",
            "id": "9e980102-867b-44b3-922b-1c0a0d143656",
            "time": "2024-03-06T17:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_e7cc29c3-a849-4376-9cc6-f6ec3293f659",
            "id": "b404cbac-a035-40fb-89df-57c4632c6163",
            "time": "2024-03-06T19:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_43864362-6672-436b-8acb-0d81e1a91503",
            "id": "589e15a6-8cce-4aef-ba8b-7bda63adcf5c",
            "time": "2024-03-06T18:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_83d05e4b-08a8-4779-bbd0-5686119abe3f",
            "id": "d12f4c6e-3fb8-4739-bb06-e1625d303100",
            "time": "2024-03-06T19:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_86cb833c-319f-47ce-8ad8-5acdd1f02351",
            "id": "49fe6e8d-19d8-4fe9-8ef8-e323de3c84be",
            "time": "2024-03-06T19:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6d919aa4-3808-4645-8bf4-0cb7a01fa0c9",
            "id": "1298efc8-bd73-4063-93e5-4276f94d92d3",
            "time": "2024-03-06T17:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_66444c3e-207f-46ca-87a1-e52f08e53750",
            "id": "cc4c8636-e0a4-4c12-96bc-b45f67a139fc",
            "time": "2024-03-06T19:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_8db8804b-85f7-4261-9f99-3eb3c06e6bc9",
            "id": "61f3db86-2790-4da9-a3d5-97da2897b129",
            "time": "2024-03-06T16:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_059a74a8-98a5-479f-9146-78b9a191f05f",
            "id": "b3f63bf4-8a39-4a40-8728-896f4ceff554",
            "time": "2024-03-06T16:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_e5721388-8112-4a84-b4dd-883f087d2559",
            "id": "bee21b96-78eb-4c9e-912f-6d29b9469be6",
            "time": "2024-03-06T18:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_29c58d90-5521-4565-9096-552cfd91645d",
            "id": "7875e283-63c5-439c-b9c4-e38837b66505",
            "time": "2024-03-06T18:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_16a12ef7-9459-4d6a-a50a-fb603437b0f7",
            "id": "4cc16b25-0059-433b-a1b7-77b03c43aed6",
            "time": "2024-03-06T18:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_cc1e746a-ce80-4d46-8e3e-8f491e949095",
            "id": "70fc4985-6b83-4dce-8b71-099af46e42fa",
            "time": "2024-03-06T17:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_0e81c93e-d3df-4622-aaf5-fe15316dc7d8",
            "id": "5c6f575a-bfd2-4b87-8f48-75a372b9b762",
            "time": "2024-03-06T20:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_54a45fed-1e7e-48b2-8a23-5588ff3d51c2",
            "id": "661dac12-21df-4c79-8cd3-add809e53058",
            "time": "2024-03-06T17:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_96ba2276-489f-4c43-864c-2877abab838b",
            "id": "f8930650-9d08-412b-ae3b-19a318c6be22",
            "time": "2024-03-06T20:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_583da609-c8db-441a-97eb-ead87c3f293a",
            "id": "18c703c9-827f-4743-8f7d-73d0b03542c6",
            "time": "2024-03-06T20:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6beaa992-4609-4181-836e-a683ee60a217",
            "id": "9e980102-867b-44b3-922b-1c0a0d143656",
            "time": "2024-03-06T17:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_1a3645e7-571e-48c9-bcb2-457a51460ce4",
            "id": "d12f4c6e-3fb8-4739-bb06-e1625d303100",
            "time": "2024-03-06T19:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_90686652-1bb8-4ed1-ae8b-2530620adc49",
            "id": "49fe6e8d-19d8-4fe9-8ef8-e323de3c84be",
            "time": "2024-03-06T19:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_a0281caf-a01f-4778-a56d-40a7099d5e20",
            "id": "cc4c8636-e0a4-4c12-96bc-b45f67a139fc",
            "time": "2024-03-06T19:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_c154425b-5af6-4245-8019-34b0d0f0dfbc",
            "id": "b404cbac-a035-40fb-89df-57c4632c6163",
            "time": "2024-03-06T19:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_85b090f1-d94b-4f44-9c65-b165fadf98e9",
            "id": "1298efc8-bd73-4063-93e5-4276f94d92d3",
            "time": "2024-03-06T17:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_4b016169-14ca-4d52-85fc-e81efc17edb7",
            "id": "bee21b96-78eb-4c9e-912f-6d29b9469be6",
            "time": "2024-03-06T18:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_41ec7ce9-4aa1-463f-a3d9-ced28d1646ea",
            "id": "7875e283-63c5-439c-b9c4-e38837b66505",
            "time": "2024-03-06T18:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_58e4c549-f1a3-4e85-beb1-764092451e59",
            "id": "4cc16b25-0059-433b-a1b7-77b03c43aed6",
            "time": "2024-03-06T18:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_e107f3ae-4467-415c-8493-5c72c825490c",
            "id": "23f7a439-f3db-4958-957e-6171d276f77a",
            "time": "2024-03-06T20:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_38c34650-5ce7-45c4-ac5c-0ee7522b3c57",
            "id": "e9a5eaad-7c41-47db-a9d8-5e9d784833e7",
            "time": "2024-03-06T21:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_bc8da74b-6619-4126-83d4-12d69d9b9f5f",
            "id": "589e15a6-8cce-4aef-ba8b-7bda63adcf5c",
            "time": "2024-03-06T18:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_083a6bcb-57dc-4460-8e65-99dce86619e3",
            "id": "5c6f575a-bfd2-4b87-8f48-75a372b9b762",
            "time": "2024-03-06T20:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_23cbf0bd-f250-409c-91a6-b4bd8eff05df",
            "id": "3442f5f2-ef44-4925-ac6e-fab887804a88",
            "time": "2024-03-06T21:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_86d997d1-dea6-45a0-8d27-8e6345e20bf1",
            "id": "661dac12-21df-4c79-8cd3-add809e53058",
            "time": "2024-03-06T17:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_455f2d86-1e33-4ab8-972a-86d0b2326926",
            "id": "1d7e8532-3104-4430-b740-74227400e8c7",
            "time": "2024-03-06T21:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_d846e2ec-3302-49c0-9a31-57b2d76581db",
            "id": "18c703c9-827f-4743-8f7d-73d0b03542c6",
            "time": "2024-03-06T20:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_91692f53-ee38-462b-8403-c9ffa4c3924c",
            "id": "d12f4c6e-3fb8-4739-bb06-e1625d303100",
            "time": "2024-03-06T19:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_196fecf2-5a71-4700-aa1c-3031c66bef7a",
            "id": "49fe6e8d-19d8-4fe9-8ef8-e323de3c84be",
            "time": "2024-03-06T19:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_d896cc76-5ed1-4d8d-8047-d215612fbbcf",
            "id": "cc4c8636-e0a4-4c12-96bc-b45f67a139fc",
            "time": "2024-03-06T19:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_eb9947fa-1a28-49db-a271-6fe6d076f3c2",
            "id": "b404cbac-a035-40fb-89df-57c4632c6163",
            "time": "2024-03-06T19:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6c11079f-e3d3-4187-9685-558eb7ce471d",
            "id": "b5530fec-7026-4d0f-9a64-da9e79dd01bd",
            "time": "2024-03-06T21:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_30cc403f-fc87-4666-87de-65aeabbcd939",
            "id": "23f7a439-f3db-4958-957e-6171d276f77a",
            "time": "2024-03-06T20:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_2658393b-4402-4480-9f5e-76e2933bf936",
            "id": "f8930650-9d08-412b-ae3b-19a318c6be22",
            "time": "2024-03-06T20:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_c750c431-670d-48a2-a222-4b0b0d179429",
            "id": "d2d90696-7e3d-4d10-9cce-3f28f07cc66e",
            "time": "2024-03-06T22:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_848c7de6-6602-4802-b117-7f2578e92c3c",
            "id": "1e2b5a50-d01e-4a42-8c2f-9c040176bfac",
            "time": "2024-03-06T22:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_648f2549-0342-438e-b05c-58dadbe21ab0",
            "id": "b13d1c94-9024-4866-bbd9-cb8104a4d1e6",
            "time": "2024-03-06T22:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_02f86c54-a2c4-406e-9d2c-c684cf966e0d",
            "id": "589e15a6-8cce-4aef-ba8b-7bda63adcf5c",
            "time": "2024-03-06T18:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_c08badf4-8f56-4e7a-9a92-bdf40fcf7c18",
            "id": "b5005831-61d5-4f63-ad5f-13868ffd2699",
            "time": "2024-03-06T22:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_dd78317e-ad0d-4563-827d-9ca03870b04a",
            "id": "d12f4c6e-3fb8-4739-bb06-e1625d303100",
            "time": "2024-03-06T19:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_fdce3249-497c-4301-8115-8a0a4151d227",
            "id": "5c6f575a-bfd2-4b87-8f48-75a372b9b762",
            "time": "2024-03-06T20:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_5493580c-f6d4-4ea0-952c-364b0ffbf76f",
            "id": "e9a5eaad-7c41-47db-a9d8-5e9d784833e7",
            "time": "2024-03-06T21:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_5c8e8c7b-79bb-4541-ba2d-bd4b81bd1f96",
            "id": "b404cbac-a035-40fb-89df-57c4632c6163",
            "time": "2024-03-06T19:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_e3af5698-f96b-43bb-8077-e58b24676863",
            "id": "18c703c9-827f-4743-8f7d-73d0b03542c6",
            "time": "2024-03-06T20:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6d8153c4-d488-4958-a95c-5bc3bf21f326",
            "id": "1d7e8532-3104-4430-b740-74227400e8c7",
            "time": "2024-03-06T21:34:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f39d2b8d-be85-4b5a-9f6b-53c059a1d06b",
            "id": "23f7a439-f3db-4958-957e-6171d276f77a",
            "time": "2024-03-06T20:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f805a196-eb57-4956-a1fb-1f30d1c862d4",
            "id": "49fe6e8d-19d8-4fe9-8ef8-e323de3c84be",
            "time": "2024-03-06T19:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_52b5873d-7a82-4622-9eb7-3635194d3d22",
            "id": "b5530fec-7026-4d0f-9a64-da9e79dd01bd",
            "time": "2024-03-06T21:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_2d248125-9ee4-46b3-a296-dd947ae9d3e5",
            "id": "3442f5f2-ef44-4925-ac6e-fab887804a88",
            "time": "2024-03-06T21:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_af2d344f-356a-42da-bd2b-b79e072923c4",
            "id": "d2d90696-7e3d-4d10-9cce-3f28f07cc66e",
            "time": "2024-03-06T22:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_b3944b68-02f0-4f6c-ad39-ecc174316fbb",
            "id": "f8930650-9d08-412b-ae3b-19a318c6be22",
            "time": "2024-03-06T20:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_371d04a8-9a9e-411a-9963-9fcf2145a30c",
            "id": "1e2b5a50-d01e-4a42-8c2f-9c040176bfac",
            "time": "2024-03-06T22:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_f45bf492-727e-441b-9e22-448fd8bc7a9f",
            "id": "b5005831-61d5-4f63-ad5f-13868ffd2699",
            "time": "2024-03-06T22:49:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_9dc01ac0-8b43-4cac-acea-98e13f725b7c",
            "id": "e9a5eaad-7c41-47db-a9d8-5e9d784833e7",
            "time": "2024-03-06T21:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_56feb9e2-86d7-4788-b1d1-1f98f601006e",
            "id": "5c6f575a-bfd2-4b87-8f48-75a372b9b762",
            "time": "2024-03-06T20:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_e0d2b302-e53c-4904-ab51-1ed487febc2f",
            "id": "d2d90696-7e3d-4d10-9cce-3f28f07cc66e",
            "time": "2024-03-06T22:04:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_6b3d204a-daed-47d8-b9a4-5106af8e7fce",
            "id": "f8930650-9d08-412b-ae3b-19a318c6be22",
            "time": "2024-03-06T20:19:30",
            "type": "Wash Hands"
        },
        {
            "name": "qa_task_late_157626d2-2697-4d5c-a40d-1ce253707d24",
            "id": "b13d1c94-9024-4866-bbd9-cb8104a4d1e6",
            "time": "2024-03-06T22:34:30",
            "type": "Wash Hands"
        }
    ],
    "tasks": [
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:52.290Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:02:00.000Z",
            "id": "9acfeceb-0a00-44e9-9899-ed131b710c26",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:52.290Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T15:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:53.250Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:02:00.000Z",
            "id": "24b1bd0c-504c-4ea1-ae60-108d2ae1674b",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:53.250Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T16:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:53.911Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:02:00.000Z",
            "id": "fe37691d-1fcd-4a7f-8e2c-e88f6e1efd6a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:53.911Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T17:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:54.428Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:02:00.000Z",
            "id": "eefceea3-f3e5-44ad-8ba2-0df9a4d81edd",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:54.428Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T18:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:54.910Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:02:00.000Z",
            "id": "8c01847b-490e-4eac-a591-c2db495d1f25",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:54.910Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T19:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:55.150Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:02:00.000Z",
            "id": "2bb8e54b-0699-4ebc-9a31-8a86cbd926fd",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:55.150Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T20:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:55.351Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:02:00.000Z",
            "id": "b80f01ce-26c4-4124-9bb1-2729e4b129d2",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:55.351Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T21:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:55.651Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T23:02:00.000Z",
            "id": "37512561-b9ae-4877-811a-814ce6ec3fab",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:55.651Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T22:52:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:57:00.000Z",
            "name": "Clean ABS Nozzles",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:56.067Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:06:00.000Z",
            "id": "2d500b4c-886d-4301-9edc-ce38302ab10b",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:56.067Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T15:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:56.790Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:06:00.000Z",
            "id": "db2b6da5-87b7-4fef-84b5-0b42b13a4fd1",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:56.790Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T16:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:57.109Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:06:00.000Z",
            "id": "da45a571-e20c-4409-b53d-3edc602acc5d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:57.109Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T17:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:57.409Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:06:00.000Z",
            "id": "0418a86f-a554-4fb6-9246-dcb8f1cce612",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:57.409Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T18:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:57.727Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:06:00.000Z",
            "id": "b92da223-d86d-4005-8a02-97c15d53b34c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:57.727Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T19:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:57.847Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:06:00.000Z",
            "id": "81af982a-c710-4c39-add8-dad8e5567e15",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:57.847Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T20:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:58.049Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:06:00.000Z",
            "id": "fd86f68f-18f9-4b00-9a1f-7b2dbf361af6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:58.049Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T21:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T23:01:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:58.229Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T23:06:00.000Z",
            "id": "7f529a73-b737-4c9a-947e-1a9f061f7e8d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:58.229Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "150",
            "startDateTime": "2024-03-06T22:56:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T23:01:00.000Z",
            "name": "Clean ABS Conveyor Belt",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:58.430Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:18:00.000Z",
            "id": "1be43ca8-5905-408c-98ad-289b05608dc8",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:58.430Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T15:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:58.571Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:18:00.000Z",
            "id": "af73a991-95ff-4869-b000-e733a1c23a0a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:58.571Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T16:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:58.748Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:18:00.000Z",
            "id": "e21b35ff-9909-4893-a03b-2f3855fba4bf",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:58.748Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T17:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:58.869Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:18:00.000Z",
            "id": "52e1b1b2-8062-4b54-ad8b-fb31ffdc051e",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:58.869Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T18:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:59.071Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:18:00.000Z",
            "id": "0f3beef5-08a0-4b46-879e-1c37e0cc11b7",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:59.071Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T19:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:59.229Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:18:00.000Z",
            "id": "00aaeab4-ff6d-4dc2-8391-d6298a6b0e4a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:59.229Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T20:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:59.570Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:18:00.000Z",
            "id": "4062e500-a586-4ed6-817e-b735e2f07ec4",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:59.570Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T21:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:13:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:59.850Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:18:00.000Z",
            "id": "2edbbe1e-0c19-4577-8c92-5bbb9aa13497",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:59.850Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T22:08:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:13:00.000Z",
            "name": "Replace Tea Liners",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T14:59:59.990Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:38:00.000Z",
            "id": "d1f36ebc-1741-4d0d-b66b-800183d26fc5",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T14:59:59.990Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T15:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:00.330Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:08:00.000Z",
            "id": "27a5b6a0-74a8-4391-9177-63ca6daa7c31",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:00.330Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T15:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:00.607Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:38:00.000Z",
            "id": "4e0a807e-c08f-4d8d-996c-b0800eb1a6b0",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:00.607Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T16:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:00.710Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:08:00.000Z",
            "id": "3390c950-a6b6-4374-9939-2d5802ce6139",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:00.710Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T16:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:01.010Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:38:00.000Z",
            "id": "2de4aae4-a76d-4e7d-8d1a-d1d3a1a14261",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:01.010Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T17:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:01.631Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:08:00.000Z",
            "id": "81a7b522-0470-45a1-8f2e-11a102bed564",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:01.631Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T17:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:01.888Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:38:00.000Z",
            "id": "6fa16f99-3d60-48c9-87d4-538235d19ae4",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:01.888Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T18:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:02.228Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:08:00.000Z",
            "id": "bf1f25b9-9266-4dd0-80d7-2401209ac4e5",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:02.228Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T18:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:02.550Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:38:00.000Z",
            "id": "eb4fd97b-5170-421f-b8ec-fa5492136ba4",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:02.550Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T19:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:02.789Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:08:00.000Z",
            "id": "d41361b6-2f33-4dbf-8562-db34f788338d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:02.789Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T19:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:03.308Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:38:00.000Z",
            "id": "45b40d91-9a00-4601-84b0-f8762d8f6744",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:03.308Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T20:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:03.529Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:08:00.000Z",
            "id": "778fba08-c44b-4bd6-bc95-7338c6efc5cc",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:03.529Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T20:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:04.031Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:38:00.000Z",
            "id": "4aeaa6af-9308-4202-990c-bf4254eac5d3",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:04.031Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T21:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:04.631Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:08:00.000Z",
            "id": "4a99ff0c-dcb9-40d4-bd0f-0c4aacc3e58f",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:04.631Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T21:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:33:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:04.929Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:38:00.000Z",
            "id": "df8489a3-2bb5-4cc6-8584-5d930ebf200d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:04.929Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T22:28:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:33:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T23:03:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:05.249Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T23:08:00.000Z",
            "id": "96968043-3ef2-47a2-8aeb-de8ba1f337b3",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:05.249Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "30",
            "startDateTime": "2024-03-06T22:58:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T23:03:00.000Z",
            "name": "Check Tea Levels",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:05.630Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:23:00.000Z",
            "id": "da079c62-ef26-4fb2-9f5c-f8b4d8be9b73",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:05.630Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:05.871Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:53:00.000Z",
            "id": "4ad2f8d3-add3-460f-9e54-76b1f7a5f854",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:05.871Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:06.391Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:23:00.000Z",
            "id": "ac5cf072-cdb1-448d-a77a-d1a137e91c73",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:06.391Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:06.548Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:53:00.000Z",
            "id": "19d1c58d-b184-4c17-bca0-f49a126b72f8",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:06.548Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:06.830Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:23:00.000Z",
            "id": "eb0e7074-f535-4247-afdf-7685482f044d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:06.830Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:06.971Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:53:00.000Z",
            "id": "b09b0690-dc3e-4bb1-bb16-770ad3da33e8",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:06.971Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:07.387Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:23:00.000Z",
            "id": "b11a83c3-0295-4f41-96e3-4bbebfba1c3e",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:07.387Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:07.709Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:53:00.000Z",
            "id": "2deeb88c-91fc-453b-8bb0-ecb9566f500c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:07.709Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:08.008Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:23:00.000Z",
            "id": "869f5f81-5484-442b-a242-06069e8a5d3a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:08.008Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:08.229Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:53:00.000Z",
            "id": "75497cba-c85a-4ccb-946f-fdbe962415b9",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:08.229Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:08.810Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:23:00.000Z",
            "id": "bb8f0296-022d-4549-9021-7cac76e274a1",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:08.810Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:09.147Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:53:00.000Z",
            "id": "b869d06f-ee4b-4882-b20c-b9788e352d56",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:09.147Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:09.411Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:23:00.000Z",
            "id": "6ec9212e-3bb3-4bf8-9046-db4f1d13e7f2",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:09.411Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:09.848Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:53:00.000Z",
            "id": "68977087-b725-4527-84c3-0a0d584511b7",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:09.848Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:18:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:10.249Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:23:00.000Z",
            "id": "727c17cb-2e58-45c8-87a4-9f3e811b494d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:10.249Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:13:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:18:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:48:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:10.610Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:53:00.000Z",
            "id": "12f0fbc3-2a93-4603-a962-b7e8c797064e",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:10.610Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:43:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:48:00.000Z",
            "name": "Stock Condiments",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:11.028Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:12:00.000Z",
            "id": "1899f94c-0b48-4295-90d7-4240a706516f",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:11.028Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T15:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:11.267Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:12:00.000Z",
            "id": "899490e2-cfd6-440c-b272-d9e2da7f697a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:11.267Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T16:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:11.550Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:12:00.000Z",
            "id": "6faba84e-aa6e-45d5-966e-2fbee49cfa3a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:11.550Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T17:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:11.869Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:12:00.000Z",
            "id": "9e7489e9-b727-465e-a156-fc522ac58beb",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:11.869Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T18:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:12.210Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:12:00.000Z",
            "id": "7f36dc06-33b3-4918-bad2-33ad85a0bb5d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:12.210Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T19:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:12.570Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:12:00.000Z",
            "id": "2d724357-c814-4620-a422-8f5cb03c4f8c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:12.570Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T20:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:12.849Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:12:00.000Z",
            "id": "f0a3e90a-291c-4ace-90dc-504bda343fae",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:12.849Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T21:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:07:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:13.110Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:12:00.000Z",
            "id": "3b2c2d3c-5fdc-4d0f-a829-c5304dd9ea92",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:13.110Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "50",
            "startDateTime": "2024-03-06T22:02:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:07:00.000Z",
            "name": "Stock Napkins",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:13.508Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:15:00.000Z",
            "id": "51c9cded-258a-4ba9-84f0-ac20c4a8b581",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:13.508Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:13.649Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:30:00.000Z",
            "id": "f4a25780-eb09-488f-8ae6-2c857beee8b5",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:13.650Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:13.790Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:45:00.000Z",
            "id": "a837e73a-52f5-417c-8473-275669639a3d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:13.790Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T15:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:13.987Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:00:00.000Z",
            "id": "c2c9c1a0-f0e3-4d18-ada6-00696b07c12e",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:13.987Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:14.130Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:15:00.000Z",
            "id": "eedc5519-1aa4-41a1-8d12-3aebb6bb9aa7",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:14.130Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:14.587Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:30:00.000Z",
            "id": "869aa48f-cb4d-4bb9-af27-52aeb1893ccf",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:14.587Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:15.150Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:45:00.000Z",
            "id": "a10ed379-1569-4720-8e9f-35c5ed4dd298",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:15.150Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T16:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:15.330Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:00:00.000Z",
            "id": "2fe920d9-802e-42d3-bcb6-707c8bede77f",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:15.330Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:15.468Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:15:00.000Z",
            "id": "e1d5c8e3-291e-42f1-825c-f52f1b7cfe0e",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:15.468Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:15.609Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:30:00.000Z",
            "id": "927f9dd6-9ce4-4c65-90d3-981a7c7c0a26",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:15.609Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:15.749Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:45:00.000Z",
            "id": "2a075fdb-3b66-48d6-958e-26db46445976",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:15.749Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T17:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:15.908Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:00:00.000Z",
            "id": "0bf3469b-1892-4713-a54e-2a5b3e3b0ee2",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:15.908Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:16.068Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:15:00.000Z",
            "id": "a46ff3f0-0cc8-4b29-9ff4-c183e24e99df",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:16.068Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:16.268Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:30:00.000Z",
            "id": "ad14b2da-347c-442b-93c0-b31d77324227",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:16.268Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:16.368Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:45:00.000Z",
            "id": "bb208e12-e490-4da3-8bd8-cd4e8b17817c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:16.368Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T18:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:16.548Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:00:00.000Z",
            "id": "366a32dd-eed3-4b68-a482-ecd00327a1ac",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:16.548Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:16.750Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:15:00.000Z",
            "id": "b2cfd30f-c8e2-49ac-875f-a2186a40c006",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:16.750Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.008Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:30:00.000Z",
            "id": "8e39ba6a-c452-4541-a317-199ab9c51499",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.008Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.190Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:45:00.000Z",
            "id": "55196421-6615-4322-9250-9b33e5563d5c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.190Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T19:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.330Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:00:00.000Z",
            "id": "845d46c1-5857-4b91-90be-ac95a9ebf6fe",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.330Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.450Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:15:00.000Z",
            "id": "26240f7b-fbb7-4453-8e22-c68b144c15ac",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.450Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.589Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:30:00.000Z",
            "id": "a18d8689-8a08-4880-94c5-ad8b938bdd9d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.589Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.750Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:45:00.000Z",
            "id": "8d6e8860-b74b-4ce5-a599-876e392a2d6b",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.750Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T20:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:17.869Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:00:00.000Z",
            "id": "82a9100e-8523-4cbe-8fcf-15f4bcc69970",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:17.869Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.029Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:15:00.000Z",
            "id": "9b0a32b7-0750-4f3a-bfa5-c7e6c792ee7c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.029Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.169Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:30:00.000Z",
            "id": "11ceafad-7c15-4fd1-9ba3-78847fe0a54a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.169Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.329Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:45:00.000Z",
            "id": "0bf92dda-1ca2-4969-959f-46cda66ce917",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.329Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T21:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.508Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:00:00.000Z",
            "id": "578c5c02-bb07-4d5f-8dfa-1c8fa19541dc",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.508Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:12:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.609Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:15:00.000Z",
            "id": "75005a97-1862-43c5-8c43-a9c65dd047a7",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.609Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:10:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:12:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:27:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.730Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:30:00.000Z",
            "id": "04ac4c48-c28d-4960-9400-389c10e1f8d3",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.730Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:25:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:27:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:42:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:18.870Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:45:00.000Z",
            "id": "5fe7bc0b-4f37-4686-827b-e0af1747e8e6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:18.870Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:40:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:42:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "a0194f64-c86f-40a4-90a4-e6d3427a9856"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": true,
            "isTeamTask": false,
            "boostDateTime": "2024-03-06T22:57:00.000Z",
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:19.207Z",
            "boostAvailable": true,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T23:00:00.000Z",
            "id": "0046675e-8f5e-4d26-8689-6f6d37fcc6fc",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:19.207Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:55:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:57:00.000Z",
            "name": "Check Shake Mix Level",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:19.388Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:05:00.000Z",
            "id": "3a98dcaa-bed8-46b9-8bbe-9f7dc6c7a61d",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:19.388Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:19.769Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:20:00.000Z",
            "id": "0981b01f-cb61-4ed5-93ef-5c699884b7ed",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:19.769Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:19.908Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:35:00.000Z",
            "id": "4b32e0e7-ed9c-431d-82f1-47d3ca898003",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:19.908Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:20.107Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T15:50:00.000Z",
            "id": "20f99944-ff0a-4c7d-b145-ea84f171bc50",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:20.107Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T15:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T15:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:20.389Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:05:00.000Z",
            "id": "0096a054-1094-4831-94d4-8f046331faa6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:20.389Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:20.608Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:20:00.000Z",
            "id": "4e7cbdaf-95aa-4355-85f1-6f3e55bd6961",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:20.608Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:21.070Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:35:00.000Z",
            "id": "61f3db86-2790-4da9-a3d5-97da2897b129",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:21.070Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:21.389Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T16:50:00.000Z",
            "id": "b3f63bf4-8a39-4a40-8728-896f4ceff554",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:21.389Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T16:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T16:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:21.869Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:05:00.000Z",
            "id": "9e980102-867b-44b3-922b-1c0a0d143656",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:21.869Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:22.048Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:20:00.000Z",
            "id": "70fc4985-6b83-4dce-8b71-099af46e42fa",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:22.048Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:22.268Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:35:00.000Z",
            "id": "1298efc8-bd73-4063-93e5-4276f94d92d3",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:22.268Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:22.467Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T17:50:00.000Z",
            "id": "661dac12-21df-4c79-8cd3-add809e53058",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:22.467Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T17:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T17:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:22.691Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:05:00.000Z",
            "id": "bee21b96-78eb-4c9e-912f-6d29b9469be6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:22.691Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:23.129Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:20:00.000Z",
            "id": "4cc16b25-0059-433b-a1b7-77b03c43aed6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:23.129Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:23.469Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:35:00.000Z",
            "id": "7875e283-63c5-439c-b9c4-e38837b66505",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:23.469Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:23.810Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T18:50:00.000Z",
            "id": "589e15a6-8cce-4aef-ba8b-7bda63adcf5c",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:23.810Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T18:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T18:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:24.267Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:05:00.000Z",
            "id": "cc4c8636-e0a4-4c12-96bc-b45f67a139fc",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:24.267Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:25.269Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:20:00.000Z",
            "id": "b404cbac-a035-40fb-89df-57c4632c6163",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:25.269Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:25.849Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:35:00.000Z",
            "id": "d12f4c6e-3fb8-4739-bb06-e1625d303100",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:25.849Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:26.290Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T19:50:00.000Z",
            "id": "49fe6e8d-19d8-4fe9-8ef8-e323de3c84be",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:26.290Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T19:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T19:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:27.108Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:05:00.000Z",
            "id": "5c6f575a-bfd2-4b87-8f48-75a372b9b762",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:27.108Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:27.288Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:20:00.000Z",
            "id": "f8930650-9d08-412b-ae3b-19a318c6be22",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:27.288Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:27.689Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:35:00.000Z",
            "id": "18c703c9-827f-4743-8f7d-73d0b03542c6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:27.689Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:28.370Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T20:50:00.000Z",
            "id": "23f7a439-f3db-4958-957e-6171d276f77a",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:28.370Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T20:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T20:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:28.729Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:05:00.000Z",
            "id": "e9a5eaad-7c41-47db-a9d8-5e9d784833e7",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:28.729Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:28.971Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:20:00.000Z",
            "id": "3442f5f2-ef44-4925-ac6e-fab887804a88",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:28.971Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:29.429Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:35:00.000Z",
            "id": "1d7e8532-3104-4430-b740-74227400e8c7",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:29.429Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:30.009Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T21:50:00.000Z",
            "id": "b5530fec-7026-4d0f-9a64-da9e79dd01bd",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:30.009Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T21:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T21:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:30.251Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:05:00.000Z",
            "id": "d2d90696-7e3d-4d10-9cce-3f28f07cc66e",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:30.251Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:00:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:02:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:30.429Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:20:00.000Z",
            "id": "1e2b5a50-d01e-4a42-8c2f-9c040176bfac",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:30.429Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:15:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:17:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:30.570Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:35:00.000Z",
            "id": "b13d1c94-9024-4866-bbd9-cb8104a4d1e6",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:30.570Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:30:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:32:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        },
        {
            "claimModifier": 0,
            "shoutOutPointValue": 0,
            "rejected": false,
            "roles": [
                "769671da-0ada-40a1-b5b5-716601519a8c",
                "a0194f64-c86f-40a4-90a4-e6d3427a9856",
                "9a05d017-d43c-4eec-8138-e7650c3cb3c3",
                "d0335c46-1477-484c-af83-bc59a354d8c2"
            ],
            "__typename": "Task",
            "description": null,
            "claimAvailable": false,
            "isTeamTask": true,
            "photoURL": [],
            "createdAt": "2024-03-06T15:00:30.830Z",
            "boostAvailable": false,
            "lateModifier": 0,
            "expiredDateTime": "2024-03-06T22:50:00.000Z",
            "id": "b5005831-61d5-4f63-ad5f-13868ffd2699",
            "iconURL": null,
            "boosted": false,
            "updatedAt": "2024-03-06T15:00:30.830Z",
            "completed": false,
            "taskBoostedById": null,
            "taskCompletedById": null,
            "pointValue": "10",
            "startDateTime": "2024-03-06T22:45:00.000Z",
            "locationID": "626ffaf1-e600-46bd-afff-3fa2c58e0956",
            "lateDateTime": "2024-03-06T22:47:00.000Z",
            "name": "Wash Hands",
            "taskClaimedById": null,
            "rejectionReason": null,
            "boostedModifier": 0
        }
    ]
}

export default taskLoaderResponse;
