import { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
import './TaskLoader.scss';

import taskLoaderResponse from '../TaskLoader/data_response';

const TaskLoader = () => {

    // const [searchParams] = useSearchParams();
    const [tasks, setTasks] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const compareTasksByDate = (a,b) => {
        if (new Date(a.startDateTime) < new Date(b.startDateTime)) {
            return -1;
        }
        return 1;
    }

    const compareNotificationsByDate = (a,b) => {
        if (new Date(a.time).getTime() < new Date(b.time).getTime()) {
            return -1;
        }
        return 1;
    }

    const getData = () => {

        console.log('getData');
        // var fileName = searchParams.get('file');
        // fetch('../lambda/TaskLoader/responses/' + fileName)
        // .then( (response) => {
        //     var data = response.json();

        //     setTasks( sortTasksByDate(data.tasks) );
        //     setNotifications( sortNotificationsByDate(data.notifications) );

        // })
        // .catch(error => console.error(error));

        var sortedTasks = sortTasksByDate(taskLoaderResponse.tasks);
        setTasks( sortedTasks );

        var sortedNotifications = sortNotificationsByDate(taskLoaderResponse.notifications);
        setNotifications( sortedNotifications );


    }

    const sortTasksByDate = (arr) => {
        console.log('sortTasks');
        return arr.sort(compareTasksByDate);
    }
    const sortNotificationsByDate = (arr) => {
        console.log('sortNotifications');
        return arr.sort(compareNotificationsByDate);
    }

    
    useEffect(()=>{
        getData();
    },[]);

    const taskItems = tasks ? tasks.map(task =>
        <tr key={task.id}>
            <td><strong>{task.name}</strong></td>
            <td>start: {new Date(task.startDateTime).toLocaleDateString()} {new Date(task.startDateTime).toLocaleTimeString()} EST</td>
            <td>boost: {new Date(task.boostDateTime).toLocaleDateString()} {new Date(task.boostDateTime).toLocaleTimeString()} EST</td>
            <td>boostable: {task.boostAvailable ? 'yes' : 'no'}</td>
            <td>expires: {new Date(task.expiredDateTime).toLocaleDateString()} {new Date(task.expiredDateTime).toLocaleTimeString()} EST</td>
        </tr>
      ) : <tr>
            <td><strong>no tasks</strong></td>
        </tr>;


    const notificationItems = notifications ? notifications.map((notific,index) =>
        <tr key={index}>
            <td><strong>{notific.name}</strong></td>
            <td>time: {new Date(notific.time + 'Z').toLocaleDateString()} {new Date(notific.time+'Z').toLocaleTimeString()} EST</td>
            <td>type: {notific.type}</td>
        </tr>) : <tr>
            <td><strong>no tasks</strong></td>
        </tr>;


    return <div className='page-padding'>

        <div id="TaskLoaderNotifications">
            <h2>Notifications</h2>
            <table className="taskAdmin">
                <tbody>
                    {notificationItems}
                </tbody>
            </table>
        </div>

        <div id="TaskLoaderTasks">
            <h2>Tasks</h2>
            <table className="taskAdmin">
                <tbody>
                    {taskItems}
                </tbody>
            </table>
        </div>
    </div>
};

  export default TaskLoader;