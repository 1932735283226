import { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import { getOverrideProps } from '../../ui-components/utils';
import { getEmployee, getLocation } from '../../graphql/queries';
import { getTaskCustom } from '../../graphql/customQueries';
import { UserContext } from '../../Contexts';
import { TASK_REJECT_ROUTE } from '../../Routes';
import { checkIfExpired, formatDate, formatTime } from '../../utils/dateUtils';
import Button from '../../components/Button/Button';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TaskCard from '../../components/TaskCard/TaskCard';
import { shoutOutIcon, checkInIcon, lateIcon, rejectedIcon, checkIcon } from '../../assets/images/images';
import { ShoutOutModal } from '../../modules/Modals';
import './TaskReport.scss';

const TaskReport = (props) => {
    const { employee, location, tasks, setStillLoading } = useContext(UserContext);
    const [task, setTask] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [searchParams] = useSearchParams();
    const [openShoutOutModal, setOpenShoutOutModal] = useState(false);
    const [completedBy, setCompletedBy] = useState(null);
    const [taskLocation, setTaskLocation] = useState(location);
    const navigate = useNavigate();
    const client = generateClient();

    useEffect(() => { 
        if (!task) {
            setTaskLocation(location);
        }
    }, [location]);
    
    const getTaskData = async (taskId) => {
        setStillLoading(true);

        try {

            const res = await client.graphql({ query: getTaskCustom, variables: { id: taskId } });
            const resData = res.data.getTask;
            const task = {
                role: {...resData.roles.items[0].role},
                task: resData,
                taskId: taskId
            };
            setTask(task);

            // If task location does not match the current user's location
            if (resData.locationID !== location?.id) {
                const locationRes = await client.graphql({ query: getLocation, variables: { id: resData.locationID } });
                const locationData = locationRes.data.getLocation;
                setTaskLocation(locationData);
            };

        } catch (err) {
            console.error(err);
        };

        setStillLoading(false);
    };

    useEffect(() => {
        const id = searchParams.get('id');
        const currentTask = tasks.filter(t => t?.taskId === id)[0];
        if (currentTask) {
            const mentions = currentTask?.task?.mentions?.items;
            if (mentions?.some(mention => mention.taskID === currentTask?.taskId && mention.mentionFromEmployeeIDId === employee?.id) ||
            currentTask?.task?.isTeamTask) {
                setBtnDisabled(true);
            }
            
            setTask(currentTask);
            
            if (!currentTask?.task?.isTeamTask && currentTask?.task.completed) getCompletedByData(currentTask);
        } else {
            getTaskData(id);
        };
    }, [tasks, searchParams]);

    const getCompletedByData = async (t) => {
        if (!t) return;
        try {
            const res = await client.graphql({
                query: getEmployee,
                variables: { id: t?.task?.completedBy.id }
            });
            setCompletedBy(res.data.getEmployee);
        } catch (err) {
            console.error(err);
        };
    };

    const getDisplayData = () => {
        let data;

        if (task?.task.rejected) {
            data = {
                icon: rejectedIcon,
                text: 'Rejected'
            }
        } else if (checkIfExpired(task?.task.expiredDateTime) && !task?.task.completed) {
            data = {
                icon: lateIcon,
                text: 'Expired'
            }
        } else {
            data = {
                icon: checkInIcon,
                text: 'Completed'
            }
        }
        return data;
    };

    const handleShoutOut = () => {
        setOpenShoutOutModal(true);
    };

    const handleRejection = () => {
        navigate(`${TASK_REJECT_ROUTE}?id=${searchParams.get('id')}`);
    };

    return (
        <div className='task-review-page page-padding'>
            {!task?.task?.isTeamTask && 
                <ShoutOutModal
                    open={openShoutOutModal}
                    data={{ ...completedBy, task: task?.task }}
                    onClose={() => setOpenShoutOutModal(false)}
                    isTeamTask={task?.task.isTeamTask}
                />
            }

            <Breadcrumb />

            <h1>Task Report</h1>
        
            <div className='review-card-wrapper'>
                <TaskCard role={task?.role} task={task?.task} noRadius noCTA />

                <div className='review-card-content-wrapper'>
                    {/* Photo */}
                    {task && !task.task.isTeamTask && task.task.completed && <div>
                        <h4 className='heading-s'>Photo</h4>
                        <StorageImage
                            className='report-img'
                            imgKey={task.task?.photoURL[0]}
                            alt={task.task?.name + 'completed'}
                            accessLevel="public"
                            {...getOverrideProps(props.overrides, "image")}
                        ></StorageImage>
                    </div>}

                    {/* Task Status */}
                    <h4 className='heading-s'>Task Status</h4>
                    <span className='task-status'><img className='task-status-icon' src={getDisplayData().icon} alt="Task status decoration" />{getDisplayData().text}</span>
                    <hr />

                    {/* Rejection Reason */}
                    {task?.task.rejected && <div>
                        <h4 className='heading-s'>Rejection Reason</h4>
                        <span>{task?.task.rejectionReason || 'N/A'}</span>
                        <hr />
                    </div>}

                    {/* Restaurant */}
                    <h4 className='heading-s'>Restaurant</h4>
                    <span>Store #{taskLocation?.storeNumber}</span>
                    <hr />

                    {/* Date & Time */}
                    <h4 className='heading-s'>Date & Time</h4>
                    <span>{
                        task?.task.completedAt ?
                            formatDate(new Date(task?.task.completedAt)) :
                            checkIfExpired(task?.task.expiredDateTime) ?
                                formatDate(new Date(task?.task.expiredDateTime)) :
                                'Date'} @ {task?.task.completedAt ?
                                formatTime(new Date(task?.task.completedAt), true) :
                                checkIfExpired(task?.task.expiredDateTime) ?
                                formatTime(new Date(task?.task.expiredDateTime), true) :
                                'Time'}
                    </span>
                    <hr />

                    {/* Role */}
                    {!task?.task.isTeamTask && <div>
                        <h4 className='heading-s'>Role</h4>
                        <span>{task?.role?.name || 'N/A'}</span>
                        <hr />

                        {/* Completed By */}
                        <h4 className='heading-s'>Completed By</h4>
                        <span>{task?.task.completedBy ? task?.task.completedBy?.firstName + ' ' + task?.task.completedBy?.lastName : 'N/A'}</span>
                    </div>}

                </div>
            </div>

            {(task?.task.locationID === employee.locationID) && (task?.task?.completed || !checkIfExpired(task?.task?.expiredDateTime)) ? <Button
                buttonText={!btnDisabled ? "Shout-Out" : "Shout-Out Sent"}
                onClick={handleShoutOut}
                disabled={btnDisabled}
                icon={!btnDisabled ? shoutOutIcon : checkIcon}
            /> : ''}

            {(task?.task.locationID === employee.locationID) && (!task?.task.rejected && !(checkIfExpired(task?.task.expiredDateTime) && !task?.task.completed)) &&
                taskLocation?.id === location?.id && <Button
                buttonText="Reject"
                kind="ghost"
                onClick={handleRejection}
                rejected
            />}
        </div>
    )
};

export default TaskReport;