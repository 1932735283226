import { useState, useContext, useEffect } from 'react';
import { listEmployees, listMentions, listPerformanceMetrics } from '../../graphql/queries';
import { listUserMentions } from '../../graphql/customQueries.js';
import { generateClient } from "aws-amplify/api";
import { UserContext } from '../../Contexts';
import { Leaderboard } from '../../modules/Leaderboard';
import curveImg from '../../assets/images/header/Header-Curve.png'
import Spotlight from '../../modules/Spotlight/Spotlight';
import { Insights } from '../../modules/Insights';
import MentionsList from '../../modules/Mentions/MentionsList'
import TeamCard from '../../components/Cards/TeamCard';
import NextTask from '../../modules/NextTask/NextTask.js';
import { LeaderboardResultsModal, EnableNotificationsModal } from '../../modules/Modals';
import EmployeeList from '../../modules/Employees/EmployeesList';
import { calendarTBDIcon } from '../../assets/images/images.js';
import { getNextTask } from '../../utils/taskUtils.js';
import './Dashboard.scss';

const Dashboard = () => {
    const [employees, setEmployees] = useState([]);
    const [userMentions, setUserMentions] = useState([]);
    const [allUsersStoreMentions, setAllUsersStoreMentions] = useState([]);
    const [spotlights, setSpotlights] = useState([]);
    const [performanceMetrics, setPerformanceMetrics] = useState([]);
    const [headerCurveHeight, setHeaderCurveHeight] = useState(0);
    const client = generateClient();
    const { employee, location, tasks, challenge, pageLoading } = useContext(UserContext);

    const employeesPromise = async () => {
        if (!location) return;

        try {
            const response = await client.graphql({
                query: listEmployees, // needs to check against current sprint
            });
            const employees = response.data.listEmployees.items;

            // filter to just my store
            var storeEmployees = employees.filter((emp) => {
                return emp.locationID === location.id;
            });

            setEmployees(storeEmployees);

        } catch (err) {
            console.error('Error while trying to fetch employees.', err);
        };
    }

    // Handles header curve scroll interaction
    useEffect(() => {
        const headerCurve = document.getElementById('headerCurve');
        const dashboardHeader = document.getElementById('dashboardHeader');

        setHeaderCurveHeight(headerCurve.clientHeight === 0 ? 61 : headerCurve.clientHeight);
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop < 0) return;

            headerCurve.style.height = `${headerCurveHeight - scrollTop}px`;
            dashboardHeader.style.transform = `translateY(${0 - scrollTop}px)`;
            dashboardHeader.style.opacity = `${1 - (scrollTop * 0.05)}`;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerCurveHeight]);

    const sortMentionsByDate = (mentions) => {
        const sorted = mentions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        return sorted;
    };

    const getInsightCardData = async () => {

        if (!employee) return;
        
        const insightsResp = await client.graphql({ query: listPerformanceMetrics });
        var cardData = insightsResp.data.listPerformanceMetrics.items;
        cardData = cardData.filter( (card) => card.employee.locationID === employee.locationID);
        cardData = cardData.slice(0,10);

        setPerformanceMetrics(cardData);
    }

    const getUserMentions = async () => {
        if (!employee || !location) return;
        try {
            // fetch MY mentions
            const response = await client.graphql({ query: listUserMentions, variables: { id: employee?.id } });
            const mentions = response.data.listMentions.items;
            setUserMentions(sortMentionsByDate(mentions));
        } catch (err) {
            console.error('Error while trying to fetch mentions.', err);
        };
    }

    const getAllMentions = async () => {
        if (!employee || !location) return;

        try {
            const mentionsRes = await client.graphql({ query: listMentions });
            var all = mentionsRes.data.listMentions.items;

            // filter to only mentions in my store per Erica
            var allInMyStore = all.filter((m) => {
                return (m.toEmployeeID?.locationID === location.id);
            });

            var allSpotlights = all.filter((m) => {
                return (m.type === "SPOTLIGHT");
            });

            // limit cards to 10
            var tenSpotlights = allSpotlights.slice(0,10);

            setAllUsersStoreMentions(sortMentionsByDate(allInMyStore));
            setSpotlights(sortMentionsByDate(tenSpotlights));
        } catch (err) {
            console.error(err);
        };
    }

    const showNoActiveChallenge = () => {
        if (pageLoading) return;
        return (
            <div className='no-challenges-wrapper'>
                <img className="category-tbd-icon" src={calendarTBDIcon} alt={"calendar waiting icon"} />
                <div className='text-block'>
                    <h4 className='heading-l'>Your Challenge Hasn’t Started Yet</h4>
                    <p className='sub-body'>Check back soon!</p>
                </div>
            </div>
        )
    }

    useEffect(() => {
        employeesPromise();
        getUserMentions();
        getAllMentions();
    }, [challenge]);

    // useEffect( () => {
    //     getInsightCardData();
    // }, [employee])

    return (
        <div className="dashboard-page">
            <div className='dashboard-header'>
                <div className='dashboard-title-wrapper'>
                    <h1 id="dashboardHeader">Dashboard</h1>
                </div>
                <img id="headerCurve" className={`header-curve`} src={curveImg} alt="" />
            </div>
            {(!challenge || !challenge.currentSprintID) ? showNoActiveChallenge() :
                <>
                    {/* Leaderboard Results Modal -
                    This modal should display the first time the user visits the dashboard after a challenge week ends.

                    {/* There are two versions depending on if the user is part of the winning team or not. */}
                    <LeaderboardResultsModal
                        title={`Week ${challenge?.getCurrentSprint().sprintIndex - 1} Results`}
                    />

                    <EnableNotificationsModal
                    />

                    {/* Team Card -  */}
                    <TeamCard
                        teamBanner={true} // both banners are available to show on homepage
                        isUser={true} // only shows user's team info
                        isUserTeam={true} // only shows user's team info
                    />

                    {/* Task Section Will Go Here -
                    ONLY SHOWS WHEN CHECKED IN
                    If there are no remaining tasks for the shift, this component should not display */
                    }
                    {(employee?.checkedIn && tasks && !employee.isManager) ?
                        getNextTask(employee, tasks) ? <NextTask className="padding-base" task={getNextTask(employee, tasks)} noCTA={false} /> : ''
                        : ''}

                    {/* Leaderboard - */}
                    <Leaderboard />

                    {/* Spotlight -
                    This component should contain a maximum of 10 cards, sorted by date/time newest to oldest. */}
                    {spotlights && spotlights.length > 0 && <Spotlight data={spotlights} />}

                    {/* Insights - MANAGER ONLY
                    This component should contain a maximum of 10 cards, sorted by date/time newest to oldest. */}
                    
                    {/* Removing until insights are finished */}
                    {/* {employee?.isManager && <Insights data={performanceMetrics} />} */}

                    {/* Mentions -
                    This component should contain a maximum of 5 cards, sorted by date/time newest to oldest. */}
                    <MentionsList
                        showTitle={true}
                        paginationAmount={5}
                        data={employee?.isManager ? allUsersStoreMentions : userMentions}
                    />

                    {/* Employees - Manager View Only
                    This component should include all employees assigned to the restaurant that this manager is assigned to.*/}

                    {/* Removing until insights are finished */}
                    {employee?.isManager && <EmployeeList paginate paginationAmount={10} data={employees} />}
                </>
            }
        </div>
    )
};

export default Dashboard;