import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { LOGIN_ROUTE } from './Routes.js';
import App from './App';
import Login from './views/Login/Login';
import TaskAdmin from './views/TaskAdmin/TaskAdmin.js';
import TaskLoader from './views/TaskLoader/TaskLoader.js';
import reportWebVitals from './reportWebVitals';
import config from './amplifyconfiguration.json';
import UserContextWrapper from './Contexts.js';
import 'bootstrap/dist/css/bootstrap.css';

// styles
import './index.css';
import './assets/scss/typography.scss';
import './assets/scss/globals.scss';

import { initAnalytics } from './utils/anayticsUtils.js';

import { updateScore } from './utils/scoreUtils.js';

initAnalytics();

Amplify.configure(config);

const router = createBrowserRouter([
  {
    path: LOGIN_ROUTE,
    element: <Login />
  },
  {
    path: 'taskadmin',
    element: <TaskAdmin/>
  },
  {
    path: 'taskloader',
    element: <TaskLoader/>
  },
  {
    path: '/*',
    element: <App />
  }
]);

window.dataLayer.push({
  event: 'pageview'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContextWrapper>
      <RouterProvider router={router} />
    </UserContextWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
