import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { listUserMentions } from '../../graphql/customQueries';
import { listMentions } from '../../graphql/queries';
import { MENTIONS_MY_MENTIONS_ROUTE, MENTIONS_TEAM_MENTIONS_ROUTE } from '../../Routes';
import { UserContext } from '../../Contexts';
import Tabs from '../../components/Tabs/Tabs';
import MentionsList from '../../modules/Mentions/MentionsList'
import { trackEvent } from '../../utils/anayticsUtils.js';
import './Mentions.scss';

const Mentions = () => {
    const { employee, location } = useContext(UserContext);
    const [myMentions, setMyMentions] = useState(null);
    const [allMentions, setAllMentions] = useState(null);
    const windowLocation = useLocation();
    const client = generateClient();

    useEffect(() => {
        getUserMentions();
        getAllMentions();
    }, [employee, location]);

    useEffect(() => {
        // analytics
        trackEvent('view_mentions', employee?.id, location?.storeNumber);

    },[])

    const sortMentionsByDate = (mentions) => {
        const sorted = mentions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        return sorted;
    };

    // get mentions TO ME
    const getUserMentions = async () => {
        if (!employee || !location) return;

        try {
            let myMentions = [];
            let nextToken;
            do {
                const mentionsRes = await client.graphql({ query: listUserMentions, variables: { id: employee.id, nextToken: nextToken } });
                let newMentions = mentionsRes.data.listMentions.items;
                nextToken = mentionsRes.data.listMentions.nextToken;
                myMentions = myMentions.concat(newMentions);
            } while (nextToken);

            setMyMentions(sortMentionsByDate(myMentions));
        } catch (err) {
            console.error(err);
        };
    };

    const getAllMentions = async () => {
        if (!employee || !location) return;

        try {
            // const mentionsRes = await client.graphql({ query: listMentions });
            // var all = mentionsRes.data.listMentions.items;
            let all = [];
            let nextToken;
            do {
                const mentionsRes = await client.graphql({ query: listMentions, variables: {nextToken: nextToken } });
                let newMentions = mentionsRes.data.listMentions.items;
                nextToken = mentionsRes.data.listMentions.nextToken;
                all = all.concat(newMentions);
            } while (nextToken);

            // filter to only mentions in my store per Erica
            var allInMyStore = all.filter( (m) => {
                return (m.toEmployeeID?.locationID === location.id);
            });

            setAllMentions(sortMentionsByDate(allInMyStore));
        } catch (err) {
            console.error(err);
        };
    }

    return (
        employee && <>
            <h1 className='heading-page'>Mentions</h1>
            {!employee.isManager && <Tabs data={[
                    { label: 'My Mentions', route: MENTIONS_MY_MENTIONS_ROUTE },
                    { label: 'Team Mentions', route: MENTIONS_TEAM_MENTIONS_ROUTE }
                ]}
                index={windowLocation.pathname === MENTIONS_TEAM_MENTIONS_ROUTE ? 1 : 0} />}

            <div className="mentions-page">
                { windowLocation.pathname === MENTIONS_TEAM_MENTIONS_ROUTE ? 
                    <MentionsList data={allMentions} paginate /> :
                    <MentionsList data={myMentions} paginate />
                }
            </div>
        </>
    )
};

export default Mentions;