import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getTask } from '../../graphql/queries.js';
import {
    CHECK_IN_ROUTE,
    DASHBOARD_ROUTE,
    INSIGHTS_ROUTE,
    MENTIONS_ROUTE,
    PROFILE_ROUTE,
    TASK_LIST_ROUTE,
    EMPLOYEE_REPORT_INSIGHTS_ROUTE,
    MENTIONS_TEAM_MENTIONS_ROUTE
} from '../../Routes.js';
import { generateClient } from "aws-amplify/api";
import { updateEmployee, updateTask } from '../../graphql/mutations.js';
import { UserContext } from '../../Contexts.js';
import {
    logo,
    checkInIcon,
    rejectedIcon,
    dashboardIcon,
    tasksIcon,
    lightIcon,
    mentionsIcon,
    profileIcon
} from '../../assets/images/images.js';
import Button from '../Button/Button.js';
import { trackEvent } from '../../utils/anayticsUtils.js';
import './Header.scss';
import { updateTasksAtCheckout } from '../../utils/taskUtils.js';

const NavButton = props => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { employee, location } = useContext(UserContext);

    const checkPathMatch = path => {
        const pathMatches = pathname.includes(path);
        return pathMatches;
    };

    const handleClick = () => {

        // analytics
        trackEvent('nav_click', employee.id, location.storeNumber, {
            "link_text": props.buttonText
        });

        navigate(props.currentPath);
        props.setActive(false);
    };

    return (
        <>
            <Button
                {...props}
                className={`nav-btn ${checkPathMatch(props.currentPath) ? 'active-page' : ''}`}
                size="full"
                onClick={handleClick}
            />
            <hr />
        </>
    )
};

const Header = () => {
    const { employee, setEmployee, location, tasks, setTasks, position, unsubscribe } = useContext(UserContext);
    const [isActive, setIsActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const client = generateClient();

    useEffect(() => {
        setIsOpen(isActive);
    }, [isActive]);

    const handleCheckOut = async () => {
        var roleBeforeCheckout = employee.role.name;

        unsubscribe(position); // Unsubscribe from notifications
        const response = await client.graphql({
            query: updateEmployee,
            variables: {
                input: {
                    id: employee.id,
                    receivedBoostNotification: false,
                    checkedIn: false,
                    employeeRoleId: null
                }
            }
        });
        setEmployee(response.data.updateEmployee);
        
        const updatedTasks = await updateTasksAtCheckout({tasks, employee, client, location});
        setTasks(updatedTasks);

        // analytics
        trackEvent('check_out', employee.id, location.storeNumber, {'employee_role': roleBeforeCheckout});

        setIsActive(false);
        navigate(DASHBOARD_ROUTE);
    };

    const handleCheckIn = () => {
        setIsActive(false);
        navigate(CHECK_IN_ROUTE);
    };

    const handleLogoButton = () => {
        if (window.location.pathname === DASHBOARD_ROUTE) return navigate(0);
        navigate(DASHBOARD_ROUTE)
    };

    return (
        <>
            <div className="header-navigation">
                <button
                    className="logo-wrapper"
                    type="button"
                    onClick={handleLogoButton}
                >
                    <img className="header-logo" src={logo} alt="Task Force logo" />
                </button>

                {!!employee &&
                    <>
                        {!employee?.isManager && <>
                            {!employee.checkedIn ?
                                <Button
                                    size="sm"
                                    buttonText="Check In"
                                    iconPosition="right"
                                    icon={checkInIcon}
                                    className="header-btn"
                                    onClick={handleCheckIn}
                                /> :
                                <Button
                                    size="sm"
                                    buttonText="Check Out"
                                    iconPosition="right"
                                    icon={rejectedIcon}
                                    className="header-btn"
                                    onClick={handleCheckOut}
                                />
                            }
                        </>}

                        <button
                            className={`hamburger hamburger--squeeze ${isActive ? "is-active" : ""} hamburger-menu`}
                            type="button"
                            onClick={() => setIsActive(current => !current)}
                        >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </>
                }
            </div>

            <div className={isOpen ? "navigation-menu open" : "navigation-menu closed"}>
                <NavButton  icon={dashboardIcon} buttonText="Dashboard" currentPath={DASHBOARD_ROUTE} setActive={setIsActive} />
                {/* Below dates will need to be updated once we get specifics (or figure out how the "Challenge" is gauged) */}
                {employee && <>
                    <NavButton icon={tasksIcon} buttonText="Tasks" currentPath={TASK_LIST_ROUTE} setActive={setIsActive} />
                    <NavButton icon={lightIcon} buttonText="Insights" currentPath={employee?.isManager ? INSIGHTS_ROUTE : EMPLOYEE_REPORT_INSIGHTS_ROUTE + '?id=' + employee?.id} setActive={setIsActive} />
                    <NavButton icon={mentionsIcon} buttonText="Mentions" currentPath={employee.isManager ? MENTIONS_TEAM_MENTIONS_ROUTE : MENTIONS_ROUTE} setActive={setIsActive} />
                </>}
                <NavButton icon={profileIcon} buttonText="Profile" currentPath={PROFILE_ROUTE} setActive={setIsActive} />
            </div>
        </>
    )
};

export default Header;