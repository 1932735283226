export const LOGIN_ROUTE = '/sign-in';
export const DASHBOARD_ROUTE = '/dashboard';
export const TASK_LIST_ROUTE = '/task-list';
export const TASK_LIST_MY_TASKS_ROUTE = '/task-list/employee';
export const TASK_LIST_ALL_TASKS_ROUTE = '/task-list/store';
export const TASK_LIST_CURRENT_TASKS_ROUTE = '/task-list/current';
export const TASK_LIST_PAST_TASKS_ROUTE = '/task-list/past';
export const TASK_COMPLETION_ROUTE = '/tasks';
export const CHECK_IN_ROUTE = '/check-in';
export const CHECK_OUT_ROUTE = '/check-out';
export const MENTIONS_ROUTE = '/mentions';
export const MENTIONS_MY_MENTIONS_ROUTE = '/mentions/my-mentions';
export const MENTIONS_TEAM_MENTIONS_ROUTE = '/mentions/team-mentions';
export const INSIGHTS_ROUTE = '/insights';

export const DAILY_INSIGHTS_ROUTE = '/insights/daily';
export const DAILY_REPORT_INSIGHTS_ROUTE = '/insights/daily/:date'; // :date will be a string in the format 'dd-mm-yyyy'
export const DAILY_TASK_DETAILS_INSIGHTS_ROUTE = '/insights/daily/:date/:taskName'; // :date will be a string in the format 'dd-mm-yyyy'

export const LOCATION_INSIGHTS_ROUTE = '/insights/restaurant';
export const LOCATION_REPORT_INSIGHTS_ROUTE = '/insights/restaurant/:storeNumber';
export const LOCATION_TASK_DETAILS_INSIGHTS_ROUTE = '/insights/restaurant/:storeNumber/:taskName';

export const EMPLOYEE_INSIGHTS_ROUTE = '/insights/employee';
export const EMPLOYEE_REPORT_INSIGHTS_ROUTE = '/insights/employee/employee-report';
export const EMPLOYEE_REPORT_INSIGHTS_TASKS_ROUTE = '/insights/employee/employee-report/tasks'
export const EMPLOYEE_SPOTLIGHT_INSIGHTS_ROUTE = '/insights/employee/employee-report/spotlight';

export const TASK_REPORT_ROUTE = '/insights/employee/task-report';
export const TASK_REJECT_ROUTE = '/insights/employee/task-report/reject';

export const PROFILE_ROUTE = '/profile';
export const PROFILE_NAME_ROUTE = '/profile/edit';
export const PROFILE_ROLE_ROUTE = '/profile/role';
export const FAQ_ROUTE = '/insights/faqs';
export const PRIVACY_ROUTE = '/privacy-policy';
export const TERMS_ROUTE = '/terms-of-use';
export const ERROR_ROUTE = '/404-error';